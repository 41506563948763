import { tWithArgs, Localized_formatDate, Localized_translate, getCurrentLanguage, t } from "../Localization.js";
import { Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { tuple_type, record_type, list_type, unit_type, bool_type, string_type, int32_type, union_type, option_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { StatusAuction, Users_UserModule_userId, Lots_Category, SellLotState, PagingResult$2, Lots_Queries_Lot, LotStatus, PagingQuery$1, LotsQueryPayload, SearchCriteria, Users_User, HomeInfo_CatalogImages_get_Empty, Errors_ServerError, Lots_LotToFavouritesRequest, Errors_ServerError$reflection, HomeInfo_CatalogImages$reflection, SpecialOffersText$reflection, Users_User$reflection, AuctionPreviewInfo$reflection, PagingResult$2$reflection, Lots_Queries_Lot$reflection, LotsQueryPayload$reflection } from "../Shared/Shared.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_favouritesChanged, String_isDigit, Cmd_fromAsync, Deferred$1, Remoting_handleNonAuth, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { CatalogStream_ScheduleChanged_toDates, StreamFrom_Response$reflection } from "../Shared/Bidding.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { homeApi, lotsAuthApi, lotsApi } from "../Communication.js";
import { head, map, cons, filter, contains, tryFind, empty, ofArray, singleton as singleton_1, isEmpty } from "../fable_modules/fable-library.3.6.1/List.js";
import { tryFind as tryFind_1, empty as empty_1, ofList } from "../fable_modules/fable-library.3.6.1/Map.js";
import { round, createObj, fastStructuralHash, equals, int32ToString, unescapeDataString } from "../fable_modules/fable-library.3.6.1/Util.js";
import { map as map_2, bind, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { Cmd_OfFunc_result, Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { map as map_1, empty as empty_2, collect, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { navigate, toPath, Route, AuctionState, navigateTo } from "../Router.js";
import { join, isNullOrEmpty } from "../fable_modules/fable-library.3.6.1/String.js";
import { parse } from "../fable_modules/fable-library.3.6.1/Int32.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { CurrencyModule_noneWhenZero, ImageUrlModule_toString, CategoryIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { LocalizedStringModule_from } from "../bidflow/src/infrastructure/Localization.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { rangeDouble } from "../fable_modules/fable-library.3.6.1/Range.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "../fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { ScreenSize, useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "../fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { Currency_format, Currency_currencySymbol, LotPrefix_getPrefix, LotPrefix_isPriority, MediaQuery_customBreakpoints } from "../Common.js";
import { PropHelpers_createOnKey } from "../fable_modules/Feliz.1.45.0/Properties.fs.js";
import { key_enter } from "../fable_modules/Feliz.1.45.0/Key.fs.js";
import { Img_originalImageUrlInPng, Img_onImageLoadFail, Img_lotMedium800ImagePathContain, Img_imagePlaceholder } from "../Css.js";
import { TimerInCatalog } from "../Components/Timers.js";
import { AnimatedNumberType, View } from "../Components/AnimatedNumber.js";
import { create } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { compare } from "../fable_modules/fable-library.3.6.1/Date.js";
import { mediumInline } from "../Components/Loader.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useCatalogStream_Static_5365EDEF } from "../Components/SignalR.js";
import { RenderCatalog } from "./PreviewCatalog.js";

export function p(key) {
    return t("catalogue." + key);
}

export function l(key) {
    return t("lotDetails." + key);
}

export function h(key) {
    return t("home." + key);
}

export class Types_StreamStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotStarted", "Error", "Streaming", "Finished"];
    }
}

export function Types_StreamStatus$reflection() {
    return union_type("Catalog.Types.StreamStatus", [], Types_StreamStatus, () => [[], [["Item", option_type(class_type("System.Exception"))]], [], []]);
}

export class Types_State extends Record {
    constructor(AuctionId, CurrentPage, TotalPages, SearchString, SelectedCategoryId, LoadedLots, IsLowPriorityWasNot, IsFirstPriorityWasNot, AuctionInfo, AddToFavourites, IsLiked, User, SpecialOffersText, Images, PredictedStartLiveBiddingDates) {
        super();
        this.AuctionId = AuctionId;
        this.CurrentPage = (CurrentPage | 0);
        this.TotalPages = (TotalPages | 0);
        this.SearchString = SearchString;
        this.SelectedCategoryId = SelectedCategoryId;
        this.LoadedLots = LoadedLots;
        this.IsLowPriorityWasNot = IsLowPriorityWasNot;
        this.IsFirstPriorityWasNot = IsFirstPriorityWasNot;
        this.AuctionInfo = AuctionInfo;
        this.AddToFavourites = AddToFavourites;
        this.IsLiked = IsLiked;
        this.User = User;
        this.SpecialOffersText = SpecialOffersText;
        this.Images = Images;
        this.PredictedStartLiveBiddingDates = PredictedStartLiveBiddingDates;
    }
}

export function Types_State$reflection() {
    return record_type("Catalog.Types.State", [], Types_State, () => [["AuctionId", class_type("System.Int64")], ["CurrentPage", int32_type], ["TotalPages", int32_type], ["SearchString", string_type], ["SelectedCategoryId", option_type(int32_type)], ["LoadedLots", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagingResult$2$reflection(LotsQueryPayload$reflection(), Lots_Queries_Lot$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagingResult$2$reflection(LotsQueryPayload$reflection(), Lots_Queries_Lot$reflection())]], [["ErrorValue", string_type]]]))], ["IsLowPriorityWasNot", bool_type], ["IsFirstPriorityWasNot", bool_type], ["AuctionInfo", AuctionPreviewInfo$reflection()], ["AddToFavourites", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["IsLiked", list_type(class_type("System.Int64"))], ["User", Users_User$reflection()], ["SpecialOffersText", SpecialOffersText$reflection()], ["Images", HomeInfo_CatalogImages$reflection()], ["PredictedStartLiveBiddingDates", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Int64"), class_type("System.DateTimeOffset")])]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PagingGoto", "CategoryChanged", "SearchStringChanged", "SearchRequested", "GoToLot", "LoadLots", "ChangeLikeStatus", "RenderLikedInHeader", "LoadLikedLots", "LoadImages", "FromServer"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Catalog.Types.Msg", [], Types_Msg, () => [[["Item", int32_type]], [["Item", option_type(int32_type)]], [["Item", string_type]], [], [["Item", class_type("System.Int64")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(PagingResult$2$reflection(LotsQueryPayload$reflection(), Lots_Queries_Lot$reflection()), bool_type, bool_type), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(PagingResult$2$reflection(LotsQueryPayload$reflection(), Lots_Queries_Lot$reflection()), bool_type, bool_type)]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Int64")], ["Item2", bool_type], ["Item3", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(class_type("System.Int64")), class_type("System.Int64")), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(class_type("System.Int64")), class_type("System.Int64"))]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(class_type("System.Int64")), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(class_type("System.Int64"))]], [["ErrorValue", Errors_ServerError$reflection()]]]))]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [HomeInfo_CatalogImages$reflection(), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", HomeInfo_CatalogImages$reflection()]], [["ErrorValue", Errors_ServerError$reflection()]]])]], [["Item", StreamFrom_Response$reflection()]]]);
}

export function Cmd_load(info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(lotsApi().lots(info), (_arg1) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_getCurrentAuctionId(lotId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getCurrentAuctionIds()), (_arg1) => {
        const auctionIds = _arg1;
        return isEmpty(auctionIds) ? singleton.Return(new Types_Msg(7, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Current auction was not found")))) : singleton.Return(new Types_Msg(7, new AsyncOperationStatus$1(1, new FSharpResult$2(0, [auctionIds, lotId]))));
    })), (_arg2) => singleton.Return(new Types_Msg(7, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_changeLikeStatus(lotId, isToAdd, auctionId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => (isToAdd ? singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().addToFavourites(new Lots_LotToFavouritesRequest(lotId, auctionId))), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(6, lotId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg1.fields[0])))) : singleton.Return(new Types_Msg(6, lotId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))) : singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().deleteFromFavourites(ofList(singleton_1([auctionId, singleton_1(lotId)])))), (_arg2) => ((_arg2.tag === 1) ? singleton.Return(new Types_Msg(6, lotId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.fields[0])))) : singleton.Return(new Types_Msg(6, lotId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))))), (_arg3) => singleton.Return(new Types_Msg(6, lotId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg3.message))))));
}

export function Cmd_getLikedLots(auctionId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getLikedLots(singleton_1(auctionId))), (_arg1) => singleton.Return(new Types_Msg(8, new AsyncOperationStatus$1(1, new FSharpResult$2(0, ofArray(_arg1))))))), (_arg2) => singleton.Return(new Types_Msg(8, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message)))))));
}

export function Cmd_loadImages(auctionId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(homeApi().getCatalogImages(auctionId)), (_arg1) => singleton.Return(new Types_Msg(9, new FSharpResult$2(0, _arg1))))), (_arg2) => singleton.Return(new Types_Msg(9, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message))))));
}

export function State_AuctionState_trySearchString(x) {
    const matchValue = x.Criteria;
    switch (matchValue.tag) {
        case 1: {
            return unescapeDataString(matchValue.fields[0]);
        }
        case 2: {
            return int32ToString(matchValue.fields[0]);
        }
        default: {
            return void 0;
        }
    }
}

export function State_AuctionState_tryCategoryId(x) {
    const matchValue = x.Criteria;
    if (matchValue.tag === 3) {
        return matchValue.fields[0];
    }
    else {
        return void 0;
    }
}

export function State_init(info, auction, texts, user) {
    return [new Types_State(auction.Id, info.Page, 1, defaultArg(State_AuctionState_trySearchString(info), ""), State_AuctionState_tryCategoryId(info), new Deferred$1(1), false, false, auction, new Deferred$1(0), empty(), user, texts, HomeInfo_CatalogImages_get_Empty(), empty_1()), Cmd_batch(toList(delay(() => append(equals(user, new Users_User(0)) ? singleton_2(Cmd_none()) : singleton_2(Cmd_fromAsync(Cmd_getLikedLots(info.AuctionId))), delay(() => append(singleton_2(Cmd_OfFunc_result(new Types_Msg(5, new AsyncOperationStatus$1(0)))), delay(() => singleton_2(Cmd_fromAsync(Cmd_loadImages(info.AuctionId))))))))))];
}

export function State_isValidPage(page, total) {
    if (0 < page) {
        return page <= total;
    }
    else {
        return false;
    }
}

export function State_updateStateNewPage(page, state) {
    if (State_isValidPage(page, state.TotalPages)) {
        return [new Types_State(state.AuctionId, page, state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
    }
    else {
        return [state, Cmd_none()];
    }
}

export function State_update(info, msg, state) {
    if (msg.tag === 1) {
        return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, msg.fields[0], state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), navigateTo(new Route(3, (msg.fields[0] == null) ? (new AuctionState(info.AuctionId, 1, new SearchCriteria(0))) : (new AuctionState(info.AuctionId, 1, new SearchCriteria(3, msg.fields[0])))))];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
            }
            else {
                return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0][0])), msg.fields[0].fields[0].fields[0][2], msg.fields[0].fields[0].fields[0][1], state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, new Deferred$1(1), state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_fromAsync(Cmd_load(new PagingQuery$1(new LotsQueryPayload(info.AuctionId, getCurrentLanguage(), info.Criteria), info.Page, 100, void 0)))];
        }
    }
    else if (msg.tag === 2) {
        return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, msg.fields[0], state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [state, navigateTo(new Route(3, new AuctionState(info.AuctionId, 1, isNullOrEmpty(state.SearchString) ? (new SearchCriteria(0)) : (String_isDigit(state.SearchString) ? (new SearchCriteria(2, parse(state.SearchString.trim(), 511, false, 32))) : (new SearchCriteria(1, state.SearchString))))))];
    }
    else if (msg.tag === 4) {
        if (equals(state.AddToFavourites, new Deferred$1(1))) {
            return [state, Cmd_none()];
        }
        else {
            return [state, navigateTo(new Route(4, msg.fields[0]))];
        }
    }
    else if (msg.tag === 6) {
        if (msg.fields[2].tag === 1) {
            if (msg.fields[2].fields[0].tag === 1) {
                return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, new Deferred$1(2, new FSharpResult$2(1, msg.fields[2].fields[0].fields[0])), state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
            }
            else {
                return [state, Cmd_fromAsync(Cmd_getCurrentAuctionId(msg.fields[0]))];
            }
        }
        else if (state.User.tag === 0) {
            return [state, Cmd_none()];
        }
        else {
            return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, new Deferred$1(1), state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_fromAsync(Cmd_changeLikeStatus(msg.fields[0], msg.fields[1], state.AuctionId))];
        }
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
            }
            else {
                const lot = tryFind((lotId) => equals_1(lotId, msg.fields[0].fields[0].fields[0][1]), state.IsLiked);
                return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, new Deferred$1(2, new FSharpResult$2(0, void 0)), contains(state.AuctionId, msg.fields[0].fields[0].fields[0][0], {
                    Equals: (x_1, y_1) => equals_1(x_1, y_1),
                    GetHashCode: (x_1) => fastStructuralHash(x_1),
                }) ? ((lot != null) ? ((Remoting_favouritesChanged(-1), filter((x_2) => (!equals_1(x_2, msg.fields[0].fields[0].fields[0][1])), state.IsLiked))) : ((Remoting_favouritesChanged(1), cons(msg.fields[0].fields[0].fields[0][1], state.IsLiked)))) : ((lot != null) ? filter((x_3) => (!equals_1(x_3, msg.fields[0].fields[0].fields[0][1])), state.IsLiked) : cons(msg.fields[0].fields[0].fields[0][1], state.IsLiked)), state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, empty(), state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
            }
            else {
                return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, msg.fields[0].fields[0].fields[0], state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 1) {
            return [state, Cmd_none()];
        }
        else {
            return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, msg.fields[0].fields[0], state.PredictedStartLiveBiddingDates), Cmd_none()];
        }
    }
    else if (msg.tag === 10) {
        if (msg.fields[0].tag === 3) {
            const matchValue_1 = state.LoadedLots;
            let pattern_matching_result, lots_2;
            if (matchValue_1.tag === 2) {
                if (matchValue_1.fields[0].tag === 0) {
                    pattern_matching_result = 0;
                    lots_2 = matchValue_1.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    if (msg.fields[0].fields[0].tag === 2) {
                        return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, new Deferred$1(2, new FSharpResult$2(0, new PagingResult$2(lots_2.Query, lots_2.Page, lots_2.PageSize, lots_2.Total, map((x_6) => {
                            if (equals_1(x_6.LotId, msg.fields[0].fields[0].fields[0].LotId)) {
                                const lot_2 = x_6;
                                return new Lots_Queries_Lot(lot_2.LotId, lot_2.IndexLotInAuction, lot_2.DisplayId, lot_2.ItemId, lot_2.AuctionId, lot_2.Name, new LotStatus(2), lot_2.CurrentBid, lot_2.PreviousBid, lot_2.Estimate, lot_2.Category, lot_2.Images, lot_2.Priority);
                            }
                            else {
                                return x_6;
                            }
                        }, lots_2.Result)))), state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
                    }
                    else if (msg.fields[0].fields[0].tag === 1) {
                        return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, new Deferred$1(2, new FSharpResult$2(0, new PagingResult$2(lots_2.Query, lots_2.Page, lots_2.PageSize, lots_2.Total, map((x_7) => {
                            if (equals_1(x_7.LotId, msg.fields[0].fields[0].fields[0].LotId)) {
                                const lot_3 = x_7;
                                return new Lots_Queries_Lot(lot_3.LotId, lot_3.IndexLotInAuction, lot_3.DisplayId, lot_3.ItemId, lot_3.AuctionId, lot_3.Name, new LotStatus(3, new SellLotState(2)), lot_3.CurrentBid, lot_3.PreviousBid, lot_3.Estimate, lot_3.Category, lot_3.Images, lot_3.Priority);
                            }
                            else {
                                return x_7;
                            }
                        }, lots_2.Result)))), state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
                    }
                    else if (msg.fields[0].fields[0].tag === 3) {
                        const info_4 = msg.fields[0].fields[0].fields[0];
                        const matchValue_3 = tryFind((x_8) => equals_1(x_8.LotId, info_4.LotId), lots_2.Result);
                        if (matchValue_3 == null) {
                            return [state, Cmd_none()];
                        }
                        else {
                            return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, new Deferred$1(2, new FSharpResult$2(0, new PagingResult$2(lots_2.Query, lots_2.Page, lots_2.PageSize, lots_2.Total, map((x_9) => {
                                if (equals_1(x_9.LotId, info_4.LotId)) {
                                    const lot_4 = x_9;
                                    return new Lots_Queries_Lot(lot_4.LotId, lot_4.IndexLotInAuction, lot_4.DisplayId, lot_4.ItemId, lot_4.AuctionId, lot_4.Name, info_4.HasSold ? (new LotStatus(3, new SellLotState(1))) : (new LotStatus(3, new SellLotState(0))), lot_4.CurrentBid, lot_4.PreviousBid, lot_4.Estimate, lot_4.Category, lot_4.Images, lot_4.Priority);
                                }
                                else {
                                    return x_9;
                                }
                            }, lots_2.Result)))), state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
                        }
                    }
                    else if (msg.fields[0].fields[0].tag === 4) {
                        return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, CatalogStream_ScheduleChanged_toDates(msg.fields[0].fields[0].fields[0])), Cmd_none()];
                    }
                    else {
                        const info_1 = msg.fields[0].fields[0].fields[0];
                        const matchValue_2 = tryFind((x_4) => equals_1(x_4.LotId, info_1.LotId), lots_2.Result);
                        if (matchValue_2 == null) {
                            return [state, Cmd_none()];
                        }
                        else {
                            return [new Types_State(state.AuctionId, state.CurrentPage, state.TotalPages, state.SearchString, state.SelectedCategoryId, new Deferred$1(2, new FSharpResult$2(0, new PagingResult$2(lots_2.Query, lots_2.Page, lots_2.PageSize, lots_2.Total, map((x_5) => {
                                if (equals_1(x_5.LotId, info_1.LotId)) {
                                    const lot_1 = x_5;
                                    return new Lots_Queries_Lot(lot_1.LotId, lot_1.IndexLotInAuction, lot_1.DisplayId, lot_1.ItemId, lot_1.AuctionId, lot_1.Name, lot_1.BidStatus, info_1.Bid, info_1.PreviousBid, lot_1.Estimate, lot_1.Category, lot_1.Images, lot_1.Priority);
                                }
                                else {
                                    return x_5;
                                }
                            }, lots_2.Result)))), state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), Cmd_none()];
                        }
                    }
                }
                case 1: {
                    return [state, Cmd_none()];
                }
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new Types_State(state.AuctionId, msg.fields[0], state.TotalPages, state.SearchString, state.SelectedCategoryId, state.LoadedLots, state.IsLowPriorityWasNot, state.IsFirstPriorityWasNot, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.User, state.SpecialOffersText, state.Images, state.PredictedStartLiveBiddingDates), navigateTo(new Route(3, new AuctionState(info.AuctionId, msg.fields[0], info.Criteria)))];
    }
}

export function View_SelectCategory(view_SelectCategoryInputProps) {
    const width = view_SelectCategoryInputProps.width;
    const onSelect = view_SelectCategoryInputProps.onSelect;
    const auctionId = view_SelectCategoryInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisibility = patternInput[1];
    const isVisible = patternInput[0];
    const categories = useFeliz_React__React_useDeferred_Static_2344FC52(lotsApi().categories(auctionId), [auctionId]);
    switch (categories.tag) {
        case 3:
        case 1: {
            return null;
        }
        case 2: {
            const categories_2 = cons(new Lots_Category(CategoryIdModule_empty, LocalizedStringModule_from(singleton_1([getCurrentLanguage(), t("catalogue.all.categories")]))), categories.fields[0]);
            return react.createElement(react.Fragment, {}, ...toList(delay(() => {
                let props_5, props_12;
                return (width.tag === 0) ? singleton_2((props_5 = ofArray([["className", join(" ", ["has-dropdown", "is-size-6-mobile", "is-hoverable", "hovered-level-item"])], ["style", {
                    textAlign: "center",
                    marginLeft: 0,
                    color: "#444444",
                }], ["onClick", (_arg1) => {
                    setVisibility(!isVisible);
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("span", {
                    className: "is-uppercase",
                    children: p("navbar.categories"),
                })), delay(() => append(singleton_2(createElement("i", {
                    className: join(" ", ["fa", "fa-chevron-down"]),
                    style: {
                        paddingLeft: 6,
                    },
                })), delay(() => {
                    let elms;
                    return isVisible ? singleton_2((elms = toList(delay(() => collect((category) => {
                        let props_2;
                        const categoryId = (category.Id === CategoryIdModule_empty) ? (void 0) : category.Id;
                        return singleton_2((props_2 = ofArray([["className", join(" ", ["navbar-catalogue-categories-mobile"])], ["children", Localized_translate(category.Name)], ["onClick", (_arg2) => {
                            onSelect(categoryId);
                            setVisibility(false);
                        }]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_2)))));
                    }, categories_2))), createElement("div", {
                        className: "navbar-dropdown",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))) : empty_2();
                }))))))))]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_5))))) : ((width.tag === 1) ? singleton_2((props_5 = ofArray([["className", join(" ", ["has-dropdown", "is-size-6-mobile", "is-hoverable", "hovered-level-item"])], ["style", {
                    textAlign: "center",
                    marginLeft: 0,
                    color: "#444444",
                }], ["onClick", (_arg1) => {
                    setVisibility(!isVisible);
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("span", {
                    className: "is-uppercase",
                    children: p("navbar.categories"),
                })), delay(() => append(singleton_2(createElement("i", {
                    className: join(" ", ["fa", "fa-chevron-down"]),
                    style: {
                        paddingLeft: 6,
                    },
                })), delay(() => {
                    let elms;
                    return isVisible ? singleton_2((elms = toList(delay(() => collect((category) => {
                        let props_2;
                        const categoryId = (category.Id === CategoryIdModule_empty) ? (void 0) : category.Id;
                        return singleton_2((props_2 = ofArray([["className", join(" ", ["navbar-catalogue-categories-mobile"])], ["children", Localized_translate(category.Name)], ["onClick", (_arg2) => {
                            onSelect(categoryId);
                            setVisibility(false);
                        }]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_2)))));
                    }, categories_2))), createElement("div", {
                        className: "navbar-dropdown",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))) : empty_2();
                }))))))))]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_5))))) : singleton_2((props_12 = ofArray([["className", join(" ", ["has-dropdown", "hovered-level-item", "is-hoverable"])], ["style", {
                    textAlign: "center",
                    color: "#444444",
                    display: "grid",
                }], ["onClick", (_arg3) => {
                    setVisibility(!isVisible);
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_7;
                    return append(singleton_2((props_7 = ofArray([["className", "is-uppercase"], ["style", {
                        padding: ((((((0 + "px ") + 35) + "px ") + 0) + "px ") + 0) + "px",
                        color: "#444444",
                    }], ["id", "catalog-navbar-dropdown-arrow"], ["children", p("navbar.categories")]]), createElement("a", createObj(Helpers_combineClasses("navbar-link", props_7))))), delay(() => {
                        let elms_1;
                        return isVisible ? singleton_2((elms_1 = toList(delay(() => collect((category_1) => {
                            let props_9;
                            const categoryId_1 = (category_1.Id === CategoryIdModule_empty) ? (void 0) : category_1.Id;
                            return singleton_2((props_9 = ofArray([["className", join(" ", ["navbar-catalogue-categories"])], ["children", Localized_translate(category_1.Name)], ["onClick", (_arg4) => {
                                onSelect(categoryId_1);
                            }]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_9)))));
                        }, categories_2))), createElement("div", {
                            className: "navbar-dropdown",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        }))) : empty_2();
                    }));
                }))))]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_12))))));
            })));
        }
        default: {
            return null;
        }
    }
}

function View_renderPagination(current, total, dispatch) {
    let props_6, elms;
    const children_7 = singleton_1((props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => map_1((p_1) => {
        let children_2, props, children;
        const children_4 = singleton_1((children_2 = singleton_1((props = toList(delay(() => append((current === p_1) ? singleton_2(["className", "is-current"]) : empty_2(), delay(() => append(singleton_2(["onClick", (x) => {
            dispatch(new Types_Msg(0, p_1));
            x.preventDefault();
        }]), delay(() => singleton_2(["children", p_1]))))))), (children = singleton_1(createElement("a", createObj(Helpers_combineClasses("pagination-link", props)))), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })))), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })));
        return createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        });
    }, rangeDouble(1, 1, total)))), createElement("ul", {
        className: "pagination-list",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("nav", createObj(Helpers_combineClasses("pagination", props_6)))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_7)),
    });
}

export function View_navbar(view_navbarInputProps) {
    let props_5, props, props_3, props_18;
    const dispatch = view_navbarInputProps.dispatch;
    const state = view_navbarInputProps.state;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput[1];
    const isActive = patternInput[0];
    const navRef = useReact_useRef_1505(void 0);
    const elemRef = navRef;
    const callback = (_arg1) => {
        setActive(false);
    };
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = matchValue, copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            document.addEventListener(eventType, fn, addOptions);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                document.removeEventListener(eventType, fn, removeOptions);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = matchValue_1, copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_7 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_7), [options_7]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_7), [options_7]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            document.addEventListener(eventType_1, fn_1, addOptions_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                document.removeEventListener(eventType_1, fn_1, removeOptions_1);
            }
        });
    }));
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    let isNeedMargin;
    const matchValue_2 = state.LoadedLots;
    let pattern_matching_result_2, lots;
    if (matchValue_2.tag === 1) {
        pattern_matching_result_2 = 0;
    }
    else if (matchValue_2.tag === 2) {
        if (matchValue_2.fields[0].tag === 0) {
            pattern_matching_result_2 = 1;
            lots = matchValue_2.fields[0].fields[0];
        }
        else {
            pattern_matching_result_2 = 2;
        }
    }
    else {
        pattern_matching_result_2 = 0;
    }
    switch (pattern_matching_result_2) {
        case 0: {
            isNeedMargin = false;
            break;
        }
        case 1: {
            isNeedMargin = (!equals(filter((x) => (x.Priority === 2), lots.Result), empty()));
            break;
        }
        case 2: {
            isNeedMargin = false;
            break;
        }
    }
    const minHeight = 45;
    const props_20 = ofArray([["className", "is-white"], ["style", createObj(toList(delay(() => append(singleton_2(["minHeight", minHeight]), delay(() => append(isNeedMargin ? singleton_2(["marginBottom", 0]) : empty_2(), delay(() => {
        if (state.User.tag === 0) {
            switch (width.tag) {
                case 0:
                case 1: {
                    return singleton_2(["top", 58]);
                }
                default: {
                    return append(singleton_2(["top", 0]), delay(() => singleton_2(["marginTop", 0])));
                }
            }
        }
        else {
            switch (width.tag) {
                case 0:
                case 1: {
                    return singleton_2(["top", 58]);
                }
                default: {
                    return empty_2();
                }
            }
        }
    })))))))], ["className", join(" ", ["navbar-catalogue"])], ["ref", navRef], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["style", createObj(toList(delay(() => append(singleton_2(["minHeight", minHeight]), delay(() => append(singleton_2(["maxHeight", minHeight]), delay(() => {
        switch (width.tag) {
            case 1:
            case 2: {
                return singleton_2(["width", 40 + "%"]);
            }
            default: {
                return empty_2();
            }
        }
    })))))))], ["children", Interop_reactApi.Children.toArray([(props = toList(delay(() => append(singleton_2(["className", "py-0"]), delay(() => append(singleton_2(["className", join(" ", ["navbar-catalogue-item", "is-size-6-mobile", "overflowHidden", "navbarAuctionTitle"])]), delay(() => append(singleton_2(["className", "is-uppercase"]), delay(() => singleton_2(["children", (Localized_translate(state.AuctionInfo.HeadInfo.Head) + " ") + Localized_formatDate("d MMMM", "d MMMM", state.AuctionInfo.Date)]))))))))), createElement("div", createObj(Helpers_combineClasses("navbar-item", props)))), (props_3 = ofArray([["style", {
        height: minHeight,
        color: "#cdcdcd",
        padding: ((((((6 + "px ") + 24) + "px ") + 8) + "px ") + 24) + "px",
    }], ["className", "has-text-grey-light"], ["onClick", (_arg1_1) => {
        setActive(!isActive);
    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-align-right", "fa-lg", "catalogMenuMobile"]),
    })])]]), createElement("a", createObj(Helpers_combineClasses("navbar-burger", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("navbar-brand", props_5)))), (props_18 = toList(delay(() => append(isActive ? singleton_2(["className", "is-active"]) : empty_2(), delay(() => append(singleton_2(["className", join(" ", ["navbar-menu-white", "navbar-menu-mobile"])]), delay(() => append(singleton_2(["style", {
        minHeight: minHeight,
    }]), delay(() => {
        let elms, props_7, elms_1, props_15, props_13, value_92;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_7 = toList(delay(() => append(singleton_2(["className", join(" ", ["hovered-level-item"])]), delay(() => append(singleton_2(["className", "is-uppercase"]), delay(() => {
            const matchValue_4 = state.AuctionInfo.Status;
            switch (matchValue_4.tag) {
                case 1: {
                    return append(singleton_2(["style", {
                        color: "#444444",
                        textAlign: "center",
                    }]), delay(() => append(singleton_2(["children", p("auction.active")]), delay(() => singleton_2(["href", toPath(new Route(2, state.AuctionId))])))));
                }
                case 2: {
                    return append(singleton_2(["style", {
                        color: "#444444",
                        textAlign: "center",
                    }]), delay(() => append(singleton_2(["children", p("auction.active")]), delay(() => singleton_2(["href", toPath(new Route(2, state.AuctionId))])))));
                }
                case 3: {
                    return append(singleton_2(["style", {
                        color: "#444444",
                        textAlign: "center",
                    }]), delay(() => append(singleton_2(["children", p("auction.live")]), delay(() => singleton_2(["href", toPath(new Route(15))])))));
                }
                default: {
                    return append(singleton_2(["style", {
                        color: "#444444",
                        textAlign: "center",
                        cursor: "default",
                    }]), delay(() => singleton_2(["children", p("auction.closed")])));
                }
            }
        })))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_7)))), createElement(View_SelectCategory, {
            auctionId: state.AuctionId,
            onSelect: (arg_2) => {
                dispatch(new Types_Msg(1, arg_2));
            },
            width: width,
        })]), createElement("div", {
            className: "navbar-start",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton_1((props_15 = ofArray([["className", "px-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", join(" ", ["navbar-line"])], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(ofArray([["className", join(" ", ["search-input"])], ["placeholder", p("navbar.search")], (value_92 = state.SearchString, ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_92)) : false) {
                e.value = value_92;
            }
        }]), ["onChange", (ev_2) => {
            dispatch(new Types_Msg(2, ev_2.target.value));
        }], ["onKeyDown", (ev_3) => {
            PropHelpers_createOnKey(key_enter, (_arg2) => {
                dispatch(new Types_Msg(3));
            }, ev_3);
        }]]))), createElement("button", {
            className: join(" ", ["search-button"]),
            children: Interop_reactApi.Children.toArray([createElement("i", {
                style: {
                    fontSize: 16 + "px",
                },
                className: join(" ", ["fa", "fa-4x", "fa-search"]),
            })]),
            onClick: (x_1) => {
                x_1.preventDefault();
                dispatch(new Types_Msg(3));
            },
        })])]]), createElement("div", createObj(Helpers_combineClasses("field", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("navbar-item", props_15))))), createElement("div", {
            className: "navbar-end",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    })))))))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_18))))])]]);
    return createElement("nav", createObj(Helpers_combineClasses("navbar", props_20)));
}

export function View_renderLots(view_renderLotsInputProps) {
    const width = view_renderLotsInputProps.width;
    const removeFromFavourites = view_renderLotsInputProps.removeFromFavourites;
    const addToFavourites = view_renderLotsInputProps.addToFavourites;
    const goToLot = view_renderLotsInputProps.goToLot;
    const state = view_renderLotsInputProps.state;
    const lot = view_renderLotsInputProps.lot;
    const renderCard = (lot_1, state_1, addToFavourites_1, removeFromFavourites_1) => {
        let elms, props_24;
        const props_26 = ofArray([["className", "catalog-lot-card"], ["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => {
            let images, image, props_1;
            return append(singleton_2((images = lot_1.Images, (image = (isEmpty(images) ? Img_imagePlaceholder : Img_lotMedium800ImagePathContain(head(images))), (props_1 = ofArray([["id", "catalog-card-image"], ["style", {
                cursor: "pointer",
                marginLeft: 0,
                marginRight: 0,
                textAlign: "center",
                marginBottom: 0,
            }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
                id: "catalog-lot-img",
                src: ImageUrlModule_toString(image),
                onError: (x_2) => {
                    Img_onImageLoadFail(x_2);
                },
            })])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_1))))))), delay(() => {
                let matchValue, startDate;
                return append((matchValue = lot_1.BidStatus, (matchValue.tag === 1) ? ((startDate = tryFind_1(lot_1.LotId, state_1.PredictedStartLiveBiddingDates), singleton_2(createElement(TimerInCatalog, {
                    dateUntil: startDate,
                    dependencies: [startDate],
                })))) : ((matchValue.tag === 2) ? singleton_2(createElement("span", {
                    className: join(" ", ["thumbnail-number-item"]),
                    children: t("home.tag.biddingNow"),
                })) : ((matchValue.tag === 3) ? ((matchValue.fields[0].tag === 1) ? singleton_2(createElement("span", {
                    className: join(" ", ["thumbnail-number-item"]),
                    children: t("home.tag.completed"),
                })) : ((matchValue.fields[0].tag === 0) ? singleton_2(createElement("span", {
                    className: join(" ", ["thumbnail-number-item"]),
                    children: t("home.tag.completed"),
                })) : singleton_2(createElement("span", {
                    className: join(" ", ["thumbnail-number-item"]),
                    children: p("withdrawn.state"),
                })))) : ((startDate = tryFind_1(lot_1.LotId, state_1.PredictedStartLiveBiddingDates), singleton_2(createElement(TimerInCatalog, {
                    dateUntil: startDate,
                    dependencies: [startDate],
                }))))))), delay(() => {
                    let props_7;
                    return LotPrefix_isPriority(lot_1.Priority) ? singleton_2((props_7 = ofArray([["className", join(" ", ["thumbnail-number-item-star"])], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-star"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_7))))) : empty_2();
                }));
            }));
        })), createElement("div", {
            className: "card-image",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_24 = ofArray([["id", "catalog-card-content"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("h2", createObj(toList(delay(() => {
            const name_16 = Localized_translate(lot_1.Name);
            const prefix = LotPrefix_getPrefix(lot_1.Priority);
            const displayId = (lot_1.DisplayId === "") ? "" : (`${prefix}${lot_1.DisplayId}. `);
            return append(singleton_2(["className", join(" ", ["name-lot-in-catalog"])]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("span", {
                className: "twoLineWrap",
                children: (name_16 === "") ? "-" : (`${displayId}${name_16}`),
            })])])));
        }))))), delay(() => append(singleton_2(createElement("p", createObj(toList(delay(() => append(singleton_2(["className", join(" ", toList(delay(() => append(singleton_2("estimate-in-catalog"), delay(() => (equals(lot_1.BidStatus, new LotStatus(3, new SellLotState(2))) ? singleton_2("colorRed") : empty_2()))))))]), delay(() => {
            const matchValue_1 = lot_1.BidStatus;
            let pattern_matching_result;
            if (matchValue_1.tag === 1) {
                pattern_matching_result = 1;
            }
            else if (matchValue_1.tag === 2) {
                pattern_matching_result = 1;
            }
            else if (matchValue_1.tag === 3) {
                if (matchValue_1.fields[0].tag === 1) {
                    pattern_matching_result = 3;
                }
                else if (matchValue_1.fields[0].tag === 2) {
                    pattern_matching_result = 4;
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(["children", "created"]);
                }
                case 1: {
                    return singleton_2(["children", p("currentBid.state")]);
                }
                case 2: {
                    return singleton_2(["children", p("unsold.state")]);
                }
                case 3: {
                    return singleton_2(["children", p("finalPrice.state")]);
                }
                case 4: {
                    return singleton_2(["children", p("withdrawn.state")]);
                }
            }
        }))))))), delay(() => {
            let props_19, props_14, props_17;
            return append(singleton_2((props_19 = ofArray([["className", "mb-0"], ["className", join(" ", ["actual-bid-in-catalog"])], ["children", Interop_reactApi.Children.toArray([(props_14 = toList(delay(() => append(singleton_2(["className", "px-0"]), delay(() => append(singleton_2(["className", "py-0"]), delay(() => {
                let matchValue_2;
                return append((matchValue_2 = lot_1.BidStatus, (matchValue_2.tag === 3) ? ((matchValue_2.fields[0].tag === 2) ? singleton_2(["className", join(" ", ["colorRed"])]) : ((matchValue_2.fields[0].tag === 0) ? singleton_2(["className", join(" ", ["colorRed"])]) : ((empty_2())))) : ((empty_2()))), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const bid = defaultArg(lot_1.CurrentBid, 0);
                    const oldBid = defaultArg(lot_1.PreviousBid, 0);
                    let isNotBidding;
                    const matchValue_3 = lot_1.BidStatus;
                    let pattern_matching_result_1;
                    if (matchValue_3.tag === 0) {
                        pattern_matching_result_1 = 0;
                    }
                    else if (matchValue_3.tag === 3) {
                        if (matchValue_3.fields[0].tag === 2) {
                            pattern_matching_result_1 = 0;
                        }
                        else if (matchValue_3.fields[0].tag === 0) {
                            pattern_matching_result_1 = 0;
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            isNotBidding = true;
                            break;
                        }
                        case 1: {
                            isNotBidding = false;
                            break;
                        }
                    }
                    const symbol = createElement("span", createObj(toList(delay(() => append(singleton_2(["style", createObj(toList(delay(() => append(singleton_2(["marginLeft", 5]), delay(() => ((lot_1.CurrentBid == null) ? singleton_2(["display", "none"]) : singleton_2(["display", "block"])))))))]), delay(() => append(singleton_2(["className", join(" ", toList(delay(() => append(singleton_2("actual-bid-in-catalog"), delay(() => (isNotBidding ? singleton_2("colorRed") : empty_2()))))))]), delay(() => ((bid === 0) ? singleton_2(["children", ""]) : singleton_2(["children", ` ${Currency_currencySymbol()}`]))))))))));
                    return singleton_2(createElement(View, {
                        number: bid,
                        oldNumber: oldBid,
                        currencySymbol: symbol,
                        typeOfComponent: new AnimatedNumberType(0),
                        needBlinkGreen: false,
                        needBlinkRed: false,
                        needBlink: true,
                        isActiveBiddingComponent: false,
                    }));
                }))))])));
            })))))), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_17 = ofArray([["className", "px-0"], ["className", "py-0"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((state_1.User.tag === 0) ? singleton_2(null) : singleton_2(createElement("i", createObj(toList(delay(() => {
                const isLiked = tryFind((x_3) => equals_1(x_3, lot_1.LotId), state_1.IsLiked);
                return append(singleton_2(["className", join(" ", toList(delay(() => append(singleton_2("fa-heart"), delay(() => append(singleton_2("heard-icon"), delay(() => ((isLiked != null) ? append(singleton_2("colorPrimary"), delay(() => singleton_2("fa"))) : append(singleton_2("colorBlack"), delay(() => singleton_2("far")))))))))))]), delay(() => append(singleton_2(["style", {
                    float: "right",
                    padding: 10,
                }]), delay(() => {
                    const matchValue_5 = state_1.AuctionInfo.Status;
                    switch (matchValue_5.tag) {
                        default: {
                            if (isLiked == null) {
                                return singleton_2(["onClick", (x_5) => {
                                    x_5.preventDefault();
                                    addToFavourites_1(lot_1.LotId);
                                }]);
                            }
                            else {
                                const l_1 = isLiked;
                                return singleton_2(["onClick", (x_4) => {
                                    x_4.preventDefault();
                                    removeFromFavourites_1(l_1);
                                }]);
                            }
                        }
                    }
                }))));
            }))))))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_19))))), delay(() => {
                let value_94;
                const matchValue_6 = bind((x_6) => CurrencyModule_noneWhenZero(x_6), lot_1.Estimate);
                if (matchValue_6 != null) {
                    const estimate = matchValue_6;
                    return singleton_2(createElement("p", {
                        className: join(" ", ["estimate-in-catalog"]),
                        children: Interop_reactApi.Children.toArray([p("lot.estimate"), (value_94 = Currency_format(estimate), createElement("strong", {
                            children: [value_94],
                        }))]),
                    }));
                }
                else {
                    return singleton_2(createElement("div", {
                        className: join(" ", ["estimate-in-catalog-zero"]),
                    }));
                }
            }));
        }))))))))]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_24))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("card", props_26)));
    };
    const props_37 = ofArray([["className", "is-multiline"], ["id", "last-catalog-column"], ["className", join(" ", ["catalog-lots-container", "catalog-box"])], ["style", {
        flexWrap: "wrap",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((file) => {
        let props_29, props_32, props_35;
        return (width.tag === 0) ? singleton_2((props_29 = ofArray([["id", "catalog-card"], ["style", {
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0,
            paddingBottom: 0,
            cursor: "pointer",
        }], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            href: toPath(new Route(4, file.LotId)),
            children: Interop_reactApi.Children.toArray([renderCard(file, state, addToFavourites, removeFromFavourites)]),
        })])], ["onClick", (x_8) => {
            x_8.preventDefault();
            goToLot(file.LotId);
        }]]), createElement("div", createObj(Helpers_combineClasses("column", props_29))))) : ((width.tag === 1) ? singleton_2((props_29 = ofArray([["id", "catalog-card"], ["style", {
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0,
            paddingBottom: 0,
            cursor: "pointer",
        }], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            href: toPath(new Route(4, file.LotId)),
            children: Interop_reactApi.Children.toArray([renderCard(file, state, addToFavourites, removeFromFavourites)]),
        })])], ["onClick", (x_8) => {
            x_8.preventDefault();
            goToLot(file.LotId);
        }]]), createElement("div", createObj(Helpers_combineClasses("column", props_29))))) : ((width.tag === 2) ? singleton_2((props_32 = ofArray([["className", "is-4"], ["id", "catalog-card"], ["style", {
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0,
            paddingBottom: 0,
            cursor: "pointer",
        }], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            href: toPath(new Route(4, file.LotId)),
            children: Interop_reactApi.Children.toArray([renderCard(file, state, addToFavourites, removeFromFavourites)]),
        })])], ["onClick", (x_9) => {
            x_9.preventDefault();
            goToLot(file.LotId);
        }]]), createElement("div", createObj(Helpers_combineClasses("column", props_32))))) : singleton_2((props_35 = ofArray([["className", "is-3"], ["id", "catalog-card"], ["style", {
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0,
            paddingBottom: 0,
            cursor: "pointer",
        }], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            href: toPath(new Route(4, file.LotId)),
            children: Interop_reactApi.Children.toArray([renderCard(file, state, addToFavourites, removeFromFavourites)]),
        })])], ["onClick", (x_10) => {
            x_10.preventDefault();
            goToLot(file.LotId);
        }]]), createElement("div", createObj(Helpers_combineClasses("column", props_35)))))));
    }, lot)))))]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_37)));
}

export function View_getTotalPages(total, pageSize) {
    return ~(~round(Math.ceil(total / pageSize)));
}

export function View_renderPreviewImage(img) {
    if (img != null) {
        const i = img;
        const props_1 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("img", {
            className: "previewImageCenter",
            src: ImageUrlModule_toString(Img_originalImageUrlInPng(i)),
            alt: "Placeholder image",
        })])]);
        return createElement("figure", createObj(Helpers_combineClasses("image", props_1)));
    }
    else {
        return null;
    }
}

export function View_renderHeaderWithImage(title, subtitle, image) {
    const title_1 = Localized_translate(title);
    const subtitle_1 = Localized_translate(subtitle);
    return createElement("div", {
        className: join(" ", toList(delay(() => (((title_1 !== "") ? true : (subtitle_1 !== "")) ? singleton_2("catalog-priority-title-box") : singleton_2("catalog-priority-image-box"))))),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(View_renderPreviewImage(image)), delay(() => {
            let props;
            return append((title_1 !== "") ? singleton_2((props = ofArray([["style", {
                whiteSpace: "pre-line",
            }], ["className", join(" ", ["catalog-text-h1"])], ["children", title_1]]), createElement("p", createObj(Helpers_combineClasses("", props))))) : empty_2(), delay(() => {
                let props_2;
                return (subtitle_1 !== "") ? singleton_2((props_2 = ofArray([["style", {
                    whiteSpace: "pre-line",
                }], ["className", join(" ", ["catalog-text-h2"])], ["children", subtitle_1]]), createElement("p", createObj(Helpers_combineClasses("", props_2))))) : empty_2();
            }));
        })))))),
    });
}

export function View_renderContent(lots, state, dispatch, width) {
    let elms, props, props_4, elms_1;
    const TotalItems = lots.Total | 0;
    const PageSize = lots.PageSize | 0;
    let showPriorityHeaders;
    const releaseFeatureDate = create(2022, 8, 8, 0, 0, 0, 0);
    showPriorityHeaders = defaultArg(map_2((x) => (compare(x, releaseFeatureDate) > 0), state.AuctionInfo.PublishDate), false);
    const TotalPages = View_getTotalPages(TotalItems, PageSize) | 0;
    const renderListOfLots = (list) => createElement(View_renderLots, {
        lot: list,
        state: state,
        goToLot: (x_1) => {
            dispatch(new Types_Msg(4, x_1));
        },
        addToFavourites: (id) => {
            dispatch(new Types_Msg(6, id, true, new AsyncOperationStatus$1(0)));
        },
        removeFromFavourites: (id_1) => {
            dispatch(new Types_Msg(6, id_1, false, new AsyncOperationStatus$1(0)));
        },
        width: width,
    });
    let paging;
    const props_6 = ofArray([["id", "catalog-paging"], ["className", "catalog-box-paging"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props = toList(delay(() => collect((i) => ((state.CurrentPage === i) ? singleton_2(["children", tWithArgs("catalogue.paging", {
        FirstLotNumberOnPage: (PageSize * (i - 1)) + 1,
        LastLotNumberOnPage: (i !== TotalPages) ? (PageSize * i) : TotalItems,
        TotalItems: TotalItems,
    })]) : empty_2()), toList(rangeDouble(1, 1, TotalPages))))), createElement("div", createObj(Helpers_combineClasses("level-item", props))))), createElement("div", {
        className: "level-left",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_4 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1(View_renderPagination(state.CurrentPage, TotalPages, dispatch)), createElement("div", {
        className: "level-item",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("level-right", props_4))))])]]);
    paging = createElement("nav", createObj(Helpers_combineClasses("level", props_6)));
    const children_2 = toList(delay(() => append(singleton_2(paging), delay(() => {
        let firstPriorityList, mainPriorityList, lowPriorityList;
        return append(showPriorityHeaders ? ((firstPriorityList = filter((x_2) => (x_2.Priority === 2), lots.Result), (mainPriorityList = filter((x_3) => (x_3.Priority === 1), lots.Result), (lowPriorityList = filter((x_4) => (x_4.Priority === 0), lots.Result), append((!isEmpty(firstPriorityList)) ? append(singleton_2(renderListOfLots(firstPriorityList)), delay(() => (((!isEmpty(mainPriorityList)) ? true : (!isEmpty(lowPriorityList))) ? singleton_2(createElement("div", {
            className: join(" ", ["catalog-space-between-priorities"]),
        })) : empty_2()))) : empty_2(), delay(() => append(singleton_2(View_renderHeaderWithImage(state.SpecialOffersText.TitleMain, state.SpecialOffersText.SubtitleMain, state.Images.CatalogPriorityImage)), delay(() => append(singleton_2(renderListOfLots(mainPriorityList)), delay(() => ((!isEmpty(lowPriorityList)) ? append(state.IsLowPriorityWasNot ? append(((!isEmpty(firstPriorityList)) ? true : (!isEmpty(mainPriorityList))) ? singleton_2(createElement("div", {
            className: join(" ", ["catalog-space-between-priorities-before-market"]),
        })) : empty_2(), delay(() => singleton_2(View_renderHeaderWithImage(state.SpecialOffersText.TitleLow, state.SpecialOffersText.SubtitleLow, state.Images.LowPriorityImage)))) : empty_2(), delay(() => singleton_2(renderListOfLots(lowPriorityList)))) : empty_2()))))))))))) : singleton_2(renderListOfLots(lots.Result)), delay(() => singleton_2(paging)));
    }))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function View_renderFirstPriority(lots, state) {
    if (!isEmpty(filter((x) => (x.Priority === 2), lots.Result))) {
        const titleHigh = Localized_translate(state.SpecialOffersText.TitleHigh);
        const subtitleHigh = Localized_translate(state.SpecialOffersText.SubtitleHigh);
        return createElement("div", {
            className: join(" ", toList(delay(() => (((titleHigh !== "") ? true : (subtitleHigh !== "")) ? singleton_2("catalog-priority-title-box") : singleton_2("catalog-priority-image-box"))))),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (state.IsFirstPriorityWasNot ? append(singleton_2(View_renderPreviewImage(state.Images.FirstPriorityImage)), delay(() => {
                let props;
                return append((titleHigh !== "") ? singleton_2((props = ofArray([["style", {
                    whiteSpace: "pre-line",
                }], ["className", join(" ", ["catalog-text-h1"])], ["children", titleHigh]]), createElement("p", createObj(Helpers_combineClasses("", props))))) : empty_2(), delay(() => {
                    let props_2;
                    return (subtitleHigh !== "") ? singleton_2((props_2 = ofArray([["style", {
                        whiteSpace: "pre-line",
                    }], ["className", join(" ", ["catalog-text-h2"])], ["children", subtitleHigh]]), createElement("p", createObj(Helpers_combineClasses("", props_2))))) : empty_2();
                }));
            })) : empty_2()))))),
        });
    }
    else {
        return null;
    }
}

export function View_Render(view_RenderInputProps) {
    const dispatch = view_RenderInputProps.dispatch;
    const state = view_RenderInputProps.state;
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    return createElement("div", {
        className: join(" ", ["background-container", "padding_inherit"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement(View_navbar, {
            state: state,
            dispatch: dispatch,
        })), delay(() => {
            let props;
            const matchValue = state.LoadedLots;
            let pattern_matching_result, lots;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 1) {
                    pattern_matching_result = 2;
                }
                else {
                    pattern_matching_result = 1;
                    lots = matchValue.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2((props = singleton_1(["children", Interop_reactApi.Children.toArray([mediumInline(l("loading.lot"))])]), createElement("section", createObj(Helpers_combineClasses("section", props)))));
                }
                case 1: {
                    return append(singleton_2(View_renderFirstPriority(lots, state)), delay(() => singleton_2(createElement("div", {
                        className: join(" ", toList(delay(() => (equals(width, new ScreenSize(0)) ? singleton_2("catalog-box") : empty_2())))),
                        children: Interop_reactApi.Children.toArray([View_renderContent(lots, state, dispatch, width)]),
                    }))));
                }
                case 2: {
                    return singleton_2(null);
                }
            }
        })))))),
    });
}

export function View_RenderCatalog(view_RenderCatalogInputProps) {
    let matchValue;
    const dependencies = view_RenderCatalogInputProps.dependencies;
    const user = view_RenderCatalogInputProps.user;
    const info = view_RenderCatalogInputProps.info;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(info, view_RenderCatalogInputProps.auction, view_RenderCatalogInputProps.texts, user), (msg, state) => State_update(info, msg, state), dependencies);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    useFeliz_React__React_useCatalogStream_Static_5365EDEF((arg) => {
        dispatch(new Types_Msg(10, arg));
    }, (matchValue = state_1.LoadedLots, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? map((x_1) => x_1.LotId, matchValue.fields[0].fields[0].Result) : empty()) : empty()), Users_UserModule_userId(user));
    return createElement(View_Render, {
        state: state_1,
        dispatch: dispatch,
    });
}

export function AuctionCatalog_RenderAuctionCatalog(auctionCatalog_RenderAuctionCatalogInputProps) {
    let auctionId, props;
    const user = auctionCatalog_RenderAuctionCatalogInputProps.user;
    const info = auctionCatalog_RenderAuctionCatalogInputProps.info;
    const auctionAndDefaultCountDown = useFeliz_React__React_useDeferred_Static_2344FC52((auctionId = info.AuctionId, singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(lotsApi().auctionById(auctionId), (_arg1) => {
        const auction = _arg1;
        return singleton.Bind(lotsApi().getTextForAuctionId(auctionId), (_arg2) => {
            if (auction == null) {
                navigate(toPath(new Route(1)));
                return singleton.Return(new FSharpResult$2(1, "Unexpected auction status"));
            }
            else {
                return singleton.Return(new FSharpResult$2(0, [auction, _arg2]));
            }
        });
    })), (_arg3) => singleton.Return(new FSharpResult$2(1, _arg3.message))))), [info.AuctionId]);
    let pattern_matching_result, error, auction_1, texts_1, ex_1;
    if (auctionAndDefaultCountDown.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (auctionAndDefaultCountDown.tag === 3) {
        pattern_matching_result = 1;
        error = auctionAndDefaultCountDown.fields[0];
    }
    else if (auctionAndDefaultCountDown.tag === 2) {
        if (auctionAndDefaultCountDown.fields[0].tag === 1) {
            pattern_matching_result = 3;
            ex_1 = auctionAndDefaultCountDown.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
            auction_1 = auctionAndDefaultCountDown.fields[0].fields[0][0];
            texts_1 = auctionAndDefaultCountDown.fields[0].fields[0][1];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return createElement("div", {
                className: join(" ", ["background-container", "padding_inherit"]),
                children: Interop_reactApi.Children.toArray([(props = singleton_1(["children", Interop_reactApi.Children.toArray([mediumInline(h("loading"))])]), createElement("section", createObj(Helpers_combineClasses("section", props))))]),
            });
        }
        case 1: {
            const value_3 = error.message;
            return createElement("div", {
                children: [value_3],
            });
        }
        case 2: {
            if (auction_1.IsPreviewPublished ? equals(auction_1.Status, new StatusAuction(2)) : false) {
                return createElement(RenderCatalog, {
                    info: auction_1,
                    user: user,
                });
            }
            else {
                return createElement(View_RenderCatalog, {
                    info: info,
                    auction: auction_1,
                    texts: texts_1,
                    user: user,
                    dependencies: [info],
                });
            }
        }
        case 3: {
            const value_4 = `${ex_1}`;
            return createElement("div", {
                children: [value_4],
            });
        }
    }
}

