import { Record } from "../../../fable_modules/fable-library.3.6.1/Types.js";
import { list_type, record_type, int32_type } from "../../../fable_modules/fable-library.3.6.1/Reflection.js";
import { tail as tail_1, head, isEmpty, ofArray } from "../../../fable_modules/fable-library.3.6.1/List.js";
import { op_Division, toNumber } from "../../../fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "../../../fable_modules/fable-library.3.6.1/Decimal.js";
import { numberHash, comparePrimitives, max } from "../../../fable_modules/fable-library.3.6.1/Util.js";
import { takeWhile, contains, unfold } from "../../../fable_modules/fable-library.3.6.1/Seq.js";

export class Interval extends Record {
    constructor(From, To, BidSize) {
        super();
        this.From = (From | 0);
        this.To = (To | 0);
        this.BidSize = (BidSize | 0);
    }
}

export function Interval$reflection() {
    return record_type("Bidflow.Infrastructure.Auction.Shared.Bid.Interval", [], Interval, () => [["From", int32_type], ["To", int32_type], ["BidSize", int32_type]]);
}

export class Settings extends Record {
    constructor(Intervals) {
        super();
        this.Intervals = Intervals;
    }
}

export function Settings$reflection() {
    return record_type("Bidflow.Infrastructure.Auction.Shared.Bid.Settings", [], Settings, () => [["Intervals", list_type(Interval$reflection())]]);
}

export const defaultSettings = new Settings(ofArray([new Interval(0, 200, 200), new Interval(200, 1000, 50), new Interval(1000, 2000, 100), new Interval(2000, 5000, 200), new Interval(5000, 10000, 500), new Interval(10000, 50000, 1000), new Interval(50000, 100000, 2000), new Interval(100000, 2147483647, 5000)]));

export function evalNext(min, step, x) {
    return (((~(~toNumber(op_Division(new Decimal(x - min), new Decimal(step))))) + 1) * step) + min;
}

export function evalPrev(min, step, x) {
    return ((~(~toNumber(op_Division(new Decimal(x - min), new Decimal(step))))) * step) + min;
}

export function boundary(intervals, x) {
    const findBoundary = (_arg1_mut) => {
        findBoundary:
        while (true) {
            const _arg1 = _arg1_mut;
            if (!isEmpty(_arg1)) {
                const interval = head(_arg1);
                if ((x >= interval.From) ? (x < interval.To) : false) {
                    return [max((x_1, y) => comparePrimitives(x_1, y), interval.From, evalPrev(interval.From, interval.BidSize, x)), evalNext(interval.From, interval.BidSize, x)];
                }
                else {
                    _arg1_mut = tail_1(_arg1);
                    continue findBoundary;
                }
            }
            else {
                throw (new Error("Invalid input"));
            }
            break;
        }
    };
    return findBoundary(intervals);
}

export function next(intervals) {
    return (arg) => boundary(intervals, arg)[1];
}

export function prev(intervals) {
    return (arg) => boundary(intervals, arg)[0];
}

export function nextWithSteps(interval_mut, steps_mut, x_mut) {
    nextWithSteps:
    while (true) {
        const interval = interval_mut, steps = steps_mut, x = x_mut;
        if (steps > 0) {
            interval_mut = interval;
            steps_mut = (steps - 1);
            x_mut = next(interval)(x);
            continue nextWithSteps;
        }
        else {
            return x | 0;
        }
        break;
    }
}

export function availableBids(interval) {
    return unfold((current) => {
        const next_1 = next(interval)(current) | 0;
        return [next_1, next_1];
    }, 0);
}

export function isValidBid(intervals, amount) {
    return contains(amount, takeWhile((x) => (x <= amount), availableBids(intervals)), {
        Equals: (x_1, y) => (x_1 === y),
        GetHashCode: (x_1) => numberHash(x_1),
    });
}

export function isInvalidBid(intervals) {
    return (arg) => (!isValidBid(intervals, arg));
}

