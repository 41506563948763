import { t } from "./Localization.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { Users_RegisteredCompanyAccount, Users_RegisteredCompanyAccount$reflection } from "./Shared/Shared.js";
import { record_type, unit_type, bool_type, class_type, list_type, union_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi } from "./Communication.js";
import { PhoneModule_ofString, EmailModule_ofString, PhoneModule_empty, CountryCodeModule_empty, EmailModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { value as value_309 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { MediaQuery_customBreakpoints, CountriesInput_getSortedList, Form_getFieldError, Form_validationCompanyErrors } from "./Common.js";
import { GTM_Cmd_pushSignup } from "./Google.js";
import { Route, navigateTo } from "./Router.js";
import { React_functionComponent_2F9D7239, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { printf, toText, join } from "./fable_modules/fable-library.3.6.1/String.js";
import { map, fold, cons, empty as empty_2, singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { modalWindow } from "./TermsAndConditions.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { large } from "./Components/Loader.js";

export function p(key) {
    return t("createAccount." + key);
}

export function b(key) {
    return t("profileSettings." + key);
}

export class Types_State extends Record {
    constructor(User, ResidentCountry, FormErrors, FormValidation, Registration) {
        super();
        this.User = User;
        this.ResidentCountry = ResidentCountry;
        this.FormErrors = FormErrors;
        this.FormValidation = FormValidation;
        this.Registration = Registration;
    }
}

export function Types_State$reflection() {
    return record_type("RegisterCompanyAccount.Types.State", [], Types_State, () => [["User", Users_RegisteredCompanyAccount$reflection()], ["ResidentCountry", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["FormValidation", bool_type], ["Registration", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadResidentCountry", "EmailChanged", "PasswordChanged", "PasswordConfirmation", "VatNumberChanged", "CompanyNameChanged", "FirstNameChanged", "LastNameChanged", "AddressChanged", "ZipCodeChanged", "CityChanged", "CountryChanged", "PhoneChanged", "NewsletterCheckboxChanged", "RegisterAccount", "EventSentToGTM"];
    }
}

export function Types_Msg$reflection() {
    return union_type("RegisterCompanyAccount.Types.Msg", [], Types_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], []]);
}

export function Cmd_loadResidentCountry() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(usersApi().loadResidentCompanySettings(), (_arg1) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.Country)))))), (_arg2) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_register(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(usersApi().registerCompanyAccount(state.User), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(14, new AsyncOperationStatus$1(1, new FSharpResult$2(1, p("error.email.already.exist"))))) : singleton.Return(new Types_Msg(14, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Types_Msg(14, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init() {
    return [new Types_State(new Users_RegisteredCompanyAccount("", EmailModule_empty, "", "", "", "", "", "", "", "", CountryCodeModule_empty, PhoneModule_empty, false), new Deferred$1(0), empty(), false, new Deferred$1(0)), Cmd_OfFunc_result(new Types_Msg(0, new AsyncOperationStatus$1(0)))];
}

export function State_update(msg, state) {
    let inputRecord;
    const residentCountry = (stateResidentCountry) => {
        let pattern_matching_result, result;
        if (stateResidentCountry.tag === 1) {
            pattern_matching_result = 0;
        }
        else if (stateResidentCountry.tag === 2) {
            if (stateResidentCountry.fields[0].tag === 0) {
                pattern_matching_result = 2;
                result = stateResidentCountry.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return CountryCodeModule_empty;
            }
            case 1: {
                return CountryCodeModule_empty;
            }
            case 2: {
                return result;
            }
        }
    };
    if (msg.tag === 1) {
        let newState;
        const inputRecord_1 = state.User;
        newState = (new Users_RegisteredCompanyAccount(inputRecord_1.CompanyName, msg.fields[0], inputRecord_1.Password, inputRecord_1.PasswordConfirmation, inputRecord_1.VatNumber, inputRecord_1.FirstName, inputRecord_1.LastName, inputRecord_1.Address, inputRecord_1.PostalCode, inputRecord_1.CityAddress, inputRecord_1.Country, inputRecord_1.Phone, inputRecord_1.IsNewsletterCheckboxOn));
        return [new Types_State(newState, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 2) {
        let newState_1;
        const inputRecord_2 = state.User;
        newState_1 = (new Users_RegisteredCompanyAccount(inputRecord_2.CompanyName, inputRecord_2.CompanyEmail, msg.fields[0], inputRecord_2.PasswordConfirmation, inputRecord_2.VatNumber, inputRecord_2.FirstName, inputRecord_2.LastName, inputRecord_2.Address, inputRecord_2.PostalCode, inputRecord_2.CityAddress, inputRecord_2.Country, inputRecord_2.Phone, inputRecord_2.IsNewsletterCheckboxOn));
        return [new Types_State(newState_1, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_1, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 3) {
        let newState_2;
        const inputRecord_3 = state.User;
        newState_2 = (new Users_RegisteredCompanyAccount(inputRecord_3.CompanyName, inputRecord_3.CompanyEmail, inputRecord_3.Password, msg.fields[0], inputRecord_3.VatNumber, inputRecord_3.FirstName, inputRecord_3.LastName, inputRecord_3.Address, inputRecord_3.PostalCode, inputRecord_3.CityAddress, inputRecord_3.Country, inputRecord_3.Phone, inputRecord_3.IsNewsletterCheckboxOn));
        return [new Types_State(newState_2, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_2, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 5) {
        let newState_3;
        const inputRecord_4 = state.User;
        newState_3 = (new Users_RegisteredCompanyAccount(msg.fields[0], inputRecord_4.CompanyEmail, inputRecord_4.Password, inputRecord_4.PasswordConfirmation, inputRecord_4.VatNumber, inputRecord_4.FirstName, inputRecord_4.LastName, inputRecord_4.Address, inputRecord_4.PostalCode, inputRecord_4.CityAddress, inputRecord_4.Country, inputRecord_4.Phone, inputRecord_4.IsNewsletterCheckboxOn));
        return [new Types_State(newState_3, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_3, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 4) {
        let newState_4;
        const inputRecord_5 = state.User;
        newState_4 = (new Users_RegisteredCompanyAccount(inputRecord_5.CompanyName, inputRecord_5.CompanyEmail, inputRecord_5.Password, inputRecord_5.PasswordConfirmation, msg.fields[0], inputRecord_5.FirstName, inputRecord_5.LastName, inputRecord_5.Address, inputRecord_5.PostalCode, inputRecord_5.CityAddress, inputRecord_5.Country, inputRecord_5.Phone, inputRecord_5.IsNewsletterCheckboxOn));
        return [new Types_State(newState_4, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_4, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 6) {
        let newState_5;
        const inputRecord_6 = state.User;
        newState_5 = (new Users_RegisteredCompanyAccount(inputRecord_6.CompanyName, inputRecord_6.CompanyEmail, inputRecord_6.Password, inputRecord_6.PasswordConfirmation, inputRecord_6.VatNumber, msg.fields[0], inputRecord_6.LastName, inputRecord_6.Address, inputRecord_6.PostalCode, inputRecord_6.CityAddress, inputRecord_6.Country, inputRecord_6.Phone, inputRecord_6.IsNewsletterCheckboxOn));
        return [new Types_State(newState_5, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_5, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 7) {
        let newState_6;
        const inputRecord_7 = state.User;
        newState_6 = (new Users_RegisteredCompanyAccount(inputRecord_7.CompanyName, inputRecord_7.CompanyEmail, inputRecord_7.Password, inputRecord_7.PasswordConfirmation, inputRecord_7.VatNumber, inputRecord_7.FirstName, msg.fields[0], inputRecord_7.Address, inputRecord_7.PostalCode, inputRecord_7.CityAddress, inputRecord_7.Country, inputRecord_7.Phone, inputRecord_7.IsNewsletterCheckboxOn));
        return [new Types_State(newState_6, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_6, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 8) {
        let newState_7;
        const inputRecord_8 = state.User;
        newState_7 = (new Users_RegisteredCompanyAccount(inputRecord_8.CompanyName, inputRecord_8.CompanyEmail, inputRecord_8.Password, inputRecord_8.PasswordConfirmation, inputRecord_8.VatNumber, inputRecord_8.FirstName, inputRecord_8.LastName, msg.fields[0], inputRecord_8.PostalCode, inputRecord_8.CityAddress, inputRecord_8.Country, inputRecord_8.Phone, inputRecord_8.IsNewsletterCheckboxOn));
        return [new Types_State(newState_7, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_7, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 9) {
        let newState_8;
        const inputRecord_9 = state.User;
        newState_8 = (new Users_RegisteredCompanyAccount(inputRecord_9.CompanyName, inputRecord_9.CompanyEmail, inputRecord_9.Password, inputRecord_9.PasswordConfirmation, inputRecord_9.VatNumber, inputRecord_9.FirstName, inputRecord_9.LastName, inputRecord_9.Address, msg.fields[0], inputRecord_9.CityAddress, inputRecord_9.Country, inputRecord_9.Phone, inputRecord_9.IsNewsletterCheckboxOn));
        return [new Types_State(newState_8, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_8, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 10) {
        let newState_9;
        const inputRecord_10 = state.User;
        newState_9 = (new Users_RegisteredCompanyAccount(inputRecord_10.CompanyName, inputRecord_10.CompanyEmail, inputRecord_10.Password, inputRecord_10.PasswordConfirmation, inputRecord_10.VatNumber, inputRecord_10.FirstName, inputRecord_10.LastName, inputRecord_10.Address, inputRecord_10.PostalCode, msg.fields[0], inputRecord_10.Country, inputRecord_10.Phone, inputRecord_10.IsNewsletterCheckboxOn));
        return [new Types_State(newState_9, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_9, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 11) {
        let newState_10;
        const inputRecord_11 = state.User;
        newState_10 = (new Users_RegisteredCompanyAccount(inputRecord_11.CompanyName, inputRecord_11.CompanyEmail, inputRecord_11.Password, inputRecord_11.PasswordConfirmation, inputRecord_11.VatNumber, inputRecord_11.FirstName, inputRecord_11.LastName, inputRecord_11.Address, inputRecord_11.PostalCode, inputRecord_11.CityAddress, msg.fields[0], inputRecord_11.Phone, inputRecord_11.IsNewsletterCheckboxOn));
        return [new Types_State(newState_10, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_10, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 12) {
        let newState_11;
        const inputRecord_12 = state.User;
        newState_11 = (new Users_RegisteredCompanyAccount(inputRecord_12.CompanyName, inputRecord_12.CompanyEmail, inputRecord_12.Password, inputRecord_12.PasswordConfirmation, inputRecord_12.VatNumber, inputRecord_12.FirstName, inputRecord_12.LastName, inputRecord_12.Address, inputRecord_12.PostalCode, inputRecord_12.CityAddress, inputRecord_12.Country, msg.fields[0], inputRecord_12.IsNewsletterCheckboxOn));
        return [new Types_State(newState_11, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_11, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 13) {
        let newState_12;
        const inputRecord_13 = state.User;
        newState_12 = (new Users_RegisteredCompanyAccount(inputRecord_13.CompanyName, inputRecord_13.CompanyEmail, inputRecord_13.Password, inputRecord_13.PasswordConfirmation, inputRecord_13.VatNumber, inputRecord_13.FirstName, inputRecord_13.LastName, inputRecord_13.Address, inputRecord_13.PostalCode, inputRecord_13.CityAddress, inputRecord_13.Country, inputRecord_13.Phone, msg.fields[0]));
        return [new Types_State(newState_12, state.ResidentCountry, value_309(Form_validationCompanyErrors(residentCountry(state.ResidentCountry), newState_12, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 14) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.User, state.ResidentCountry, state.FormErrors, state.FormValidation, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new Types_State(state.User, state.ResidentCountry, state.FormErrors, state.FormValidation, new Deferred$1(2, new FSharpResult$2(0, void 0))), GTM_Cmd_pushSignup(new Types_Msg(15))];
            }
        }
        else {
            const newState_13 = new Types_State(state.User, state.ResidentCountry, state.FormErrors, true, state.Registration);
            const errors_13 = Form_validationCompanyErrors(residentCountry(state.ResidentCountry), state.User, newState_13.FormValidation);
            if (value_309(errors_13).Equals(empty())) {
                return [new Types_State(state.User, state.ResidentCountry, value_309(errors_13), true, new Deferred$1(1)), Cmd_fromAsync(Cmd_register(state))];
            }
            else {
                return [new Types_State(state.User, state.ResidentCountry, value_309(errors_13), true, new Deferred$1(0)), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 15) {
        return [state, navigateTo(new Route(12))];
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(state.User, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation, state.Registration), Cmd_none()];
        }
        else {
            return [new Types_State((inputRecord = state.User, new Users_RegisteredCompanyAccount(inputRecord.CompanyName, inputRecord.CompanyEmail, inputRecord.Password, inputRecord.PasswordConfirmation, inputRecord.VatNumber, inputRecord.FirstName, inputRecord.LastName, inputRecord.Address, inputRecord.PostalCode, inputRecord.CityAddress, msg.fields[0].fields[0].fields[0], inputRecord.Phone, inputRecord.IsNewsletterCheckboxOn)), new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation, state.Registration), Cmd_OfFunc_result(new Types_Msg(11, msg.fields[0].fields[0].fields[0]))];
        }
    }
    else {
        return [new Types_State(state.User, new Deferred$1(1), state.FormErrors, state.FormValidation, state.Registration), Cmd_fromAsync(Cmd_loadResidentCountry())];
    }
}

export function View_TermsAndConditionsCA() {
    let elms, props;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setVisible = patternInput[1];
    const elms_1 = singleton_1((elms = ofArray([(props = ofArray([["className", "is-fullwidth"], ["className", "is-primary"], ["style", {
        marginTop: 30,
    }], ["className", join(" ", ["create-account-button"])], ["children", p("button.termsAndConditions")], ["onClick", (_arg1) => {
        setVisible("is-active");
    }]]), createElement("button", createObj(Helpers_combineClasses("button", props)))), modalWindow(patternInput[0], setVisible)]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })));
    return createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

export const View_agreement = React_functionComponent_2F9D7239((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isVisible = patternInput[0];
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let elms_1, elms;
        return append(singleton_2((elms_1 = singleton_1((elms = singleton_1(createElement("label", {
            className: "checkbox",
            children: Interop_reactApi.Children.toArray([createElement("input", {
                type: "checkbox",
                onClick: (_arg1) => {
                    patternInput[1](!isVisible);
                },
            }), createElement("span", {
                style: {
                    paddingLeft: 10,
                    fontSize: 16 + "px",
                },
                children: p("agreement"),
            })]),
        })), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => append(singleton_2(createElement(View_TermsAndConditionsCA, null)), delay(() => {
            let elms_2, props_6;
            return isVisible ? singleton_2((elms_2 = singleton_1((props_6 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["className", "is-fullwidth"]), delay(() => append(equals(props.registrationState, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["create-account-button"])]), delay(() => append(singleton_2(["children", p("button.createAccount")]), delay(() => singleton_2(["onClick", (x) => {
                x.preventDefault();
                props.registration();
            }]))))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))) : empty_1();
        }))));
    })));
});

export function View_renderRegistrationError(state) {
    const matchValue = state.Registration;
    let pattern_matching_result, error;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 1;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return null;
        }
        case 1: {
            const props = ofArray([["style", {
                whiteSpace: "pre-line",
                marginTop: 10,
            }], ["className", "is-danger"], ["children", error]]);
            return createElement("div", createObj(Helpers_combineClasses("notification", props)));
        }
    }
}

export function View_view(state, dispatch, width) {
    let props_98, props_6, props_15, props_23, props_31, props_39, props_47, props_55, props_64, props_72, props_80, props_88, props_96, elms_1, elms;
    return react.createElement(react.Fragment, {}, (props_98 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["style", {
        paddingBottom: 0,
    }], ["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props;
        const errors = Form_getFieldError(state.FormErrors, "Email");
        return append(singleton_2((props = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "email"]), delay(() => append(singleton_2(["placeholder", b("email.placeholder")]), delay(() => singleton_2(["onChange", (ev) => {
            dispatch(new Types_Msg(1, EmailModule_ofString(ev.target.value)));
        }]))))))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props)))))), delay(() => {
            let props_2;
            return append(singleton_2((props_2 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_1, x) => toText(printf("%s %s"))(s_1)(x), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_2))))), delay(() => {
                let props_4;
                return singleton_2((props_4 = singleton_1(["className", join(" ", ["fas", "fa-envelope", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_4)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_6)))), createElement("div", {
        style: {
            fontSize: 16 + "px",
            textAlign: "left",
            paddingBottom: 10,
        },
        children: b("subtitle.subtitle"),
    }), (props_15 = ofArray([["style", {
        paddingBottom: 0,
    }], ["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_9;
        const errors_1 = Form_getFieldError(state.FormErrors, "Password");
        return append(singleton_2((props_9 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_1, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "password"]), delay(() => append(singleton_2(["placeholder", p("user.password")]), delay(() => singleton_2(["onChange", (ev_1) => {
            dispatch(new Types_Msg(2, ev_1.target.value));
        }]))))))))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_9)))))), delay(() => {
            let props_11;
            return append(singleton_2((props_11 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_2, x_2) => toText(printf("%s %s"))(s_2)(x_2), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_11))))), delay(() => {
                let props_13;
                return singleton_2((props_13 = singleton_1(["className", join(" ", ["fas", "fa-lock", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_13)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_15)))), (props_23 = ofArray([["style", {
        paddingBottom: 30,
    }], ["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_17;
        const errors_2 = Form_getFieldError(state.FormErrors, "PasswordConfirmation");
        return append(singleton_2((props_17 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_2, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "password"]), delay(() => append(singleton_2(["placeholder", p("user.confirmPassword")]), delay(() => singleton_2(["onChange", (ev_2) => {
            dispatch(new Types_Msg(3, ev_2.target.value));
        }]))))))))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_17)))))), delay(() => {
            let props_19;
            return append(singleton_2((props_19 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_3, x_4) => toText(printf("%s %s"))(s_3)(x_4), "", errors_2)]]), createElement("p", createObj(Helpers_combineClasses("help", props_19))))), delay(() => {
                let props_21;
                return singleton_2((props_21 = singleton_1(["className", join(" ", ["fas", "fa-lock", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_21)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_23)))), (props_31 = ofArray([["style", {
        paddingBottom: 0,
    }], ["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_25;
        const errors_3 = Form_getFieldError(state.FormErrors, "CompanyName");
        return append(singleton_2((props_25 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_3, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => append(singleton_2(["placeholder", p("companyName.placeholder")]), delay(() => singleton_2(["onChange", (ev_3) => {
            dispatch(new Types_Msg(5, ev_3.target.value));
        }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_25)))))), delay(() => {
            let props_27;
            return append(singleton_2((props_27 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_4, x_6) => toText(printf("%s %s"))(s_4)(x_6), "", errors_3)]]), createElement("p", createObj(Helpers_combineClasses("help", props_27))))), delay(() => {
                let props_29;
                return singleton_2((props_29 = singleton_1(["className", join(" ", ["fas", "fa-user", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_29)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_31)))), (props_39 = ofArray([["style", {
        paddingBottom: 0,
    }], ["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_33;
        const errors_4 = Form_getFieldError(state.FormErrors, "Address");
        return append(singleton_2((props_33 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_4, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => append(singleton_2(["placeholder", b("address.placeholder")]), delay(() => singleton_2(["onChange", (ev_4) => {
            dispatch(new Types_Msg(8, ev_4.target.value));
        }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_33)))))), delay(() => {
            let props_35;
            return append(singleton_2((props_35 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_5, x_8) => toText(printf("%s %s"))(s_5)(x_8), "", errors_4)]]), createElement("p", createObj(Helpers_combineClasses("help", props_35))))), delay(() => {
                let props_37;
                return singleton_2((props_37 = singleton_1(["className", join(" ", ["fa", "fa-map-marker", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_37)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_39)))), (props_47 = ofArray([["style", {
        paddingBottom: 0,
    }], ["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_41;
        const errors_5 = Form_getFieldError(state.FormErrors, "ZipCode");
        return append(singleton_2((props_41 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_5, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => append(singleton_2(["placeholder", b("postalCode.placeholder")]), delay(() => singleton_2(["onChange", (ev_5) => {
            dispatch(new Types_Msg(9, ev_5.target.value));
        }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_41)))))), delay(() => {
            let props_43;
            return append(singleton_2((props_43 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_6, x_10) => toText(printf("%s %s"))(s_6)(x_10), "", errors_5)]]), createElement("p", createObj(Helpers_combineClasses("help", props_43))))), delay(() => {
                let props_45;
                return singleton_2((props_45 = singleton_1(["className", join(" ", ["fa", "fa-map-marker", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_45)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_47)))), (props_55 = ofArray([["style", {
        paddingBottom: 0,
    }], ["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_49;
        const errors_6 = Form_getFieldError(state.FormErrors, "City");
        return append(singleton_2((props_49 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_6, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => append(singleton_2(["placeholder", b("city.placeholder")]), delay(() => singleton_2(["onChange", (ev_6) => {
            dispatch(new Types_Msg(10, ev_6.target.value));
        }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_49)))))), delay(() => {
            let props_51;
            return append(singleton_2((props_51 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_7, x_12) => (`${s_7} ${x_12}`), "", errors_6)]]), createElement("p", createObj(Helpers_combineClasses("help", props_51))))), delay(() => {
                let props_53;
                return singleton_2((props_53 = singleton_1(["className", join(" ", ["fa", "fa-map-marker", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_53)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_55)))), (props_64 = ofArray([["style", {
        paddingBottom: 10,
    }], ["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        const errors_7 = Form_getFieldError(state.FormErrors, "Country");
        return append(singleton_2(createElement("span", createObj(toList(delay(() => append((!equals(errors_7, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", toList(delay(() => append(singleton_2("select"), delay(() => append(singleton_2("is-fullwidth"), delay(() => ((width.tag === 0) ? singleton_2("is-medium") : ((width.tag === 1) ? singleton_2("is-medium") : singleton_2("is-large"))))))))))]), delay(() => {
            let value_182;
            return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("select", createObj(ofArray([["className", "select-profile"], ["style", createObj(toList(delay(() => ((width.tag === 0) ? singleton_2(["height", 37.5 + "px"]) : ((width.tag === 1) ? singleton_2(["height", 37.5 + "px"]) : singleton_2(["height", 50]))))))], ["name", "country"], (value_182 = state.User.Country, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_182)) : false) {
                    e.value = value_182;
                }
            }]), ["onChange", (ev_7) => {
                dispatch(new Types_Msg(11, ev_7.target.value));
            }], ["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => createElement("option", {
                value: tupledArg[0],
                children: tupledArg[1],
            }), CountriesInput_getSortedList())))]])))])]);
        }))))))))), delay(() => {
            let props_60;
            return append(singleton_2((props_60 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_8, x_13) => (`${s_8} ${x_13}`), "", errors_7)]]), createElement("p", createObj(Helpers_combineClasses("help", props_60))))), delay(() => singleton_2(createElement("span", {
                className: join(" ", ["icon", "is-small", "is-left"]),
                children: Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-map-marker"]),
                })]),
            }))));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_64)))), (props_72 = ofArray([["style", {
        paddingBottom: 30,
    }], ["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_66;
        const errors_8 = Form_getFieldError(state.FormErrors, "VatNumber");
        return append(singleton_2((props_66 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_8, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => append(singleton_2(["placeholder", p("user.vatNumber")]), delay(() => singleton_2(["onChange", (ev_8) => {
            dispatch(new Types_Msg(4, ev_8.target.value));
        }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_66)))))), delay(() => {
            let props_68;
            return append(singleton_2((props_68 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_9, x_15) => toText(printf("%s %s"))(s_9)(x_15), "", errors_8)]]), createElement("p", createObj(Helpers_combineClasses("help", props_68))))), delay(() => {
                let props_70;
                return singleton_2((props_70 = singleton_1(["className", join(" ", ["fas", "fa-user", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_70)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_72)))), (props_80 = ofArray([["style", {
        paddingBottom: 0,
    }], ["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_74;
        const errors_9 = Form_getFieldError(state.FormErrors, "FirstName");
        return append(singleton_2((props_74 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_9, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => append(singleton_2(["placeholder", b("firstName.placeholder")]), delay(() => singleton_2(["onChange", (ev_9) => {
            dispatch(new Types_Msg(6, ev_9.target.value));
        }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_74)))))), delay(() => {
            let props_76;
            return append(singleton_2((props_76 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_10, x_17) => toText(printf("%s %s"))(s_10)(x_17), "", errors_9)]]), createElement("p", createObj(Helpers_combineClasses("help", props_76))))), delay(() => {
                let props_78;
                return singleton_2((props_78 = singleton_1(["className", join(" ", ["fas", "fa-user", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_78)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_80)))), (props_88 = ofArray([["style", {
        paddingBottom: 0,
    }], ["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_82;
        const errors_10 = Form_getFieldError(state.FormErrors, "LastName");
        return append(singleton_2((props_82 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_10, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => append(singleton_2(["placeholder", b("lastName.placeholder")]), delay(() => singleton_2(["onChange", (ev_10) => {
            dispatch(new Types_Msg(7, ev_10.target.value));
        }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_82)))))), delay(() => {
            let props_84;
            return append(singleton_2((props_84 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_11, x_19) => toText(printf("%s %s"))(s_11)(x_19), "", errors_10)]]), createElement("p", createObj(Helpers_combineClasses("help", props_84))))), delay(() => {
                let props_86;
                return singleton_2((props_86 = singleton_1(["className", join(" ", ["fas", "fa-user", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_86)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_88)))), (props_96 = ofArray([["style", {
        paddingBottom: 0,
    }], ["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_90;
        const errors_11 = Form_getFieldError(state.FormErrors, "Phone");
        return append(singleton_2((props_90 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "is-medium"]) : ((width.tag === 1) ? singleton_2(["className", "is-medium"]) : singleton_2(["className", "is-large"])), delay(() => append((!equals(errors_11, empty_2())) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => append(singleton_2(["placeholder", b("phone.placeholder")]), delay(() => singleton_2(["onChange", (ev_11) => {
            dispatch(new Types_Msg(12, PhoneModule_ofString(ev_11.target.value)));
        }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_90)))))), delay(() => {
            let props_92;
            return append(singleton_2((props_92 = ofArray([["style", {
                color: "#FF0000",
            }], ["children", fold((s_13, x_20) => (`${s_13} ${x_20}`), "", errors_11)]]), createElement("p", createObj(Helpers_combineClasses("help", props_92))))), delay(() => {
                let props_94;
                return singleton_2((props_94 = singleton_1(["className", join(" ", ["fa", "fa-phone", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_94)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_96))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_98)))), (elms_1 = singleton_1((elms = singleton_1(createElement("label", {
        className: "checkbox",
        children: Interop_reactApi.Children.toArray([createElement("input", {
            type: "checkbox",
            checked: state.User.IsNewsletterCheckboxOn,
            onClick: (x_21) => {
                dispatch(new Types_Msg(13, !state.User.IsNewsletterCheckboxOn));
            },
        }), createElement("span", {
            style: {
                paddingLeft: 10,
                fontSize: 16 + "px",
            },
            children: b("newsletterCheckbox"),
        })]),
    })), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), View_agreement({
        registration: () => {
            dispatch(new Types_Msg(14, new AsyncOperationStatus$1(0)));
        },
        registrationState: state.Registration,
    }), View_renderRegistrationError(state));
}

export function Component_renderCompanyRegistrationFields() {
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(State_init, (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const matchValue = state_1.ResidentCountry;
    let pattern_matching_result, error;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return large;
        }
        case 1: {
            return View_view(state_1, patternInput[1], width);
        }
        case 2: {
            const props = ofArray([["style", {
                whiteSpace: "pre-line",
                marginTop: 10,
            }], ["className", "is-danger"], ["children", error]]);
            return createElement("div", createObj(Helpers_combineClasses("notification", props)));
        }
    }
}

