import { float64_type, float32_type, isEnum, int8_type, uint16_type, uint32_type, array_type, uint8_type, fullName, int16_type, getTupleElements, makeTuple, isTuple, getElementType, isArray, list_type, option_type, isGenericType, makeUnion, getUnionCaseFields, getUnionCases, int32_type, isUnion, getRecordElements, makeRecord, isRecord, obj_type, getGenericTypeDefinition, equals, name, getGenerics, class_type } from "../fable-library.3.6.1/Reflection.js";
import { get_UTF8 } from "../fable-library.3.6.1/Encoding.js";
import { isLittleEndian, toDouble, toSingle, toInt64, toUInt64, toInt32, toUInt32, toInt16, toUInt16 } from "../fable-library.3.6.1/BitConverter.js";
import { printf, toFail } from "../fable-library.3.6.1/String.js";
import { head, map, fill } from "../fable-library.3.6.1/Array.js";
import { Dictionary } from "../fable-library.3.6.1/MutableMap.js";
import { compare, structuralHash, equals as equals_1 } from "../fable-library.3.6.1/Util.js";
import { addToDict } from "../fable-library.3.6.1/MapUtil.js";
import { ofArray } from "../fable-library.3.6.1/Map.js";
import { FSharpSet__Add, empty } from "../fable-library.3.6.1/Set.js";
import { some } from "../fable-library.3.6.1/Option.js";
import { singleton, collect, delay, toList } from "../fable-library.3.6.1/Seq.js";
import { rangeDouble } from "../fable-library.3.6.1/Range.js";
import { fromTicks } from "../fable-library.3.6.1/Date.js";
import { toNumber, fromValue, toInt, fromInteger, fromBits, equals as equals_2 } from "../fable-library.3.6.1/Long.js";
import { fromTicks as fromTicks_1 } from "../fable-library.3.6.1/DateOffset.js";
import { fromTicks as fromTicks_2, fromMinutes } from "../fable-library.3.6.1/TimeSpan.js";
import { fromIntArray } from "../fable-library.3.6.1/Decimal.js";
import { arrayToGuid } from "../fable-library.3.6.1/Guid.js";
import { fromByteArray } from "../fable-library.3.6.1/BigInt.js";

export function interpretStringAs(typ, str) {
    return str;
}

export class Reader {
    constructor(data) {
        this.data = data;
        this.pos = 0;
    }
}

export function Reader$reflection() {
    return class_type("Fable.Remoting.MsgPack.Read.Reader", void 0, Reader);
}

export function Reader_$ctor_6C95DA22(data) {
    return new Reader(data);
}

export function Reader__ReadByte(_) {
    _.pos = ((_.pos + 1) | 0);
    return _.data[_.pos - 1];
}

export function Reader__ReadRawBin_Z524259A4(_, len) {
    _.pos = ((_.pos + len) | 0);
    return _.data.slice(_.pos - len, (_.pos - 1) + 1);
}

export function Reader__ReadString_Z524259A4(_, len) {
    _.pos = ((_.pos + len) | 0);
    return get_UTF8().getString(_.data, _.pos - len, len);
}

export function Reader__ReadUInt8(x) {
    return Reader__ReadByte(x);
}

export function Reader__ReadInt8(x) {
    const value = Reader__ReadByte(x);
    return ((value + 0x80 & 0xFF) - 0x80) | 0;
}

export function Reader__ReadUInt16(_) {
    return Reader__readInt(_, 2, (tupledArg) => toUInt16(tupledArg[0], tupledArg[1]));
}

export function Reader__ReadInt16(_) {
    return Reader__readInt(_, 2, (tupledArg) => toInt16(tupledArg[0], tupledArg[1]));
}

export function Reader__ReadUInt32(_) {
    return Reader__readInt(_, 4, (tupledArg) => toUInt32(tupledArg[0], tupledArg[1]));
}

export function Reader__ReadInt32(_) {
    return Reader__readInt(_, 4, (tupledArg) => toInt32(tupledArg[0], tupledArg[1]));
}

export function Reader__ReadUInt64(_) {
    return Reader__readInt(_, 8, (tupledArg) => toUInt64(tupledArg[0], tupledArg[1]));
}

export function Reader__ReadInt64(_) {
    return Reader__readInt(_, 8, (tupledArg) => toInt64(tupledArg[0], tupledArg[1]));
}

export function Reader__ReadFloat32(_) {
    return Reader__readInt(_, 4, (tupledArg) => toSingle(tupledArg[0], tupledArg[1]));
}

export function Reader__ReadFloat64(_) {
    return Reader__readInt(_, 8, (tupledArg) => toDouble(tupledArg[0], tupledArg[1]));
}

export function Reader__ReadMap_412036CA(x, len, t) {
    const args = getGenerics(t);
    if (args.length !== 2) {
        const arg10 = name(t);
        toFail(printf("Expecting %s, but the data contains a map."))(arg10);
    }
    let pairs;
    const arr = fill(new Array(len), 0, len, null);
    for (let i = 0; i <= (len - 1); i++) {
        arr[i] = [Reader__Read_24524716(x, args[0]), Reader__Read_24524716(x, args[1])];
    }
    pairs = arr;
    if (equals(getGenericTypeDefinition(t), class_type("System.Collections.Generic.Dictionary`2", [obj_type, obj_type]))) {
        const dict = new Dictionary([], {
            Equals: (x_1, y) => equals_1(x_1, y),
            GetHashCode: (x_1) => structuralHash(x_1),
        });
        pairs.forEach((tupledArg) => {
            addToDict(dict, tupledArg[0], tupledArg[1]);
        });
        return dict;
    }
    else {
        return ofArray(pairs);
    }
}

export function Reader__ReadSet_412036CA(x, len, t) {
    const args = getGenerics(t);
    if (args.length !== 1) {
        const arg10 = name(t);
        toFail(printf("Expecting %s, but the data contains a set."))(arg10);
    }
    let set$ = empty({
        Compare: (x_1, y) => compare(x_1, y),
    });
    for (let forLoopVar = 0; forLoopVar <= (len - 1); forLoopVar++) {
        set$ = FSharpSet__Add(set$, Reader__Read_24524716(x, args[0]));
    }
    return set$;
}

export function Reader__ReadRawArray_412036CA(x, len, elementType) {
    const arr = fill(new Array(len), 0, len, null);
    for (let i = 0; i <= (len - 1); i++) {
        arr[i] = Reader__Read_24524716(x, elementType);
    }
    return arr;
}

export function Reader__ReadArray_412036CA(x, len, t) {
    if (isRecord(t)) {
        return makeRecord(t, map((prop) => Reader__Read_24524716(x, prop[1]), getRecordElements(t)));
    }
    else if (isUnion(t, true)) {
        const tag = Reader__Read_24524716(x, int32_type);
        const case$ = getUnionCases(t, true).find((x_1) => (x_1.tag === tag));
        const fieldTypes = map((x_2) => x_2[1], getUnionCaseFields(case$));
        return makeUnion(case$, (fieldTypes.length === 1) ? [Reader__Read_24524716(x, fieldTypes[0])] : ((void Reader__ReadByte(x), map((arg00) => Reader__Read_24524716(x, arg00), fieldTypes))), true);
    }
    else if (isGenericType(t) ? equals(getGenericTypeDefinition(t), option_type(obj_type)) : false) {
        if (Reader__ReadByte(x) === 0) {
            Reader__ReadByte(x);
            return null;
        }
        else {
            return some(Reader__Read_24524716(x, head(getGenerics(t))));
        }
    }
    else if (isGenericType(t) ? equals(getGenericTypeDefinition(t), list_type(obj_type)) : false) {
        const elementType = head(getGenerics(t));
        return toList(delay(() => collect((matchValue) => singleton(Reader__Read_24524716(x, elementType)), rangeDouble(0, 1, len - 1))));
    }
    else if (isArray(t)) {
        return Reader__ReadRawArray_412036CA(x, len, getElementType(t));
    }
    else if (isTuple(t)) {
        return makeTuple(map((arg00_1) => Reader__Read_24524716(x, arg00_1), getTupleElements(t)), t);
    }
    else if (equals(t, class_type("System.DateTime"))) {
        const dateTimeTicks = Reader__Read_24524716(x, class_type("System.Int64"));
        const kindAsInt = Reader__Read_24524716(x, class_type("System.Int64"));
        return fromTicks(dateTimeTicks, equals_2(kindAsInt, fromBits(1, 0, false)) ? 1 : (equals_2(kindAsInt, fromBits(2, 0, false)) ? 2 : 0));
    }
    else if (equals(t, class_type("System.DateTimeOffset"))) {
        return fromTicks_1(Reader__Read_24524716(x, class_type("System.Int64")), fromMinutes(Reader__Read_24524716(x, int16_type)));
    }
    else if (isGenericType(t) ? equals(getGenericTypeDefinition(t), class_type("Microsoft.FSharp.Collections.FSharpSet`1", [obj_type])) : false) {
        return Reader__ReadSet_412036CA(x, len, t);
    }
    else if (equals(t, class_type("System.Decimal")) ? true : (fullName(t) === "Microsoft.FSharp.Core.decimal`1")) {
        return fromIntArray(Reader__ReadRawArray_412036CA(x, 4, int32_type));
    }
    else {
        const arg20 = x.pos | 0;
        const arg10 = name(t);
        return toFail(printf("Expecting %s at position %d, but the data contains an array."))(arg10)(arg20);
    }
}

export function Reader__ReadBin_412036CA(x, len, t) {
    if (equals(t, class_type("System.Guid"))) {
        return arrayToGuid(Reader__ReadRawBin_Z524259A4(x, len));
    }
    else if (equals(t, array_type(uint8_type))) {
        return Reader__ReadRawBin_Z524259A4(x, len);
    }
    else if (equals(t, class_type("System.Numerics.BigInteger"))) {
        return fromByteArray(Reader__ReadRawBin_Z524259A4(x, len));
    }
    else {
        const arg20 = x.pos | 0;
        const arg10 = name(t);
        return toFail(printf("Expecting %s at position %d, but the data contains bin."))(arg10)(arg20);
    }
}

export function Reader__Read_24524716(x, t) {
    const matchValue = Reader__ReadByte(x);
    if ((matchValue | 31) === 191) {
        return interpretStringAs(t, Reader__ReadString_Z524259A4(x, ~(~(matchValue & 31))));
    }
    else if (matchValue === 217) {
        return interpretStringAs(t, Reader__ReadString_Z524259A4(x, ~(~Reader__ReadByte(x))));
    }
    else if (matchValue === 218) {
        return interpretStringAs(t, Reader__ReadString_Z524259A4(x, ~(~Reader__ReadUInt16(x))));
    }
    else if (matchValue === 219) {
        return interpretStringAs(t, Reader__ReadString_Z524259A4(x, ~(~Reader__ReadUInt32(x))));
    }
    else if ((matchValue | 127) === 127) {
        const typ = t;
        const n = matchValue;
        if (equals(typ, int32_type)) {
            return ~(~n);
        }
        else if (equals(typ, class_type("System.Int64"))) {
            return fromInteger(n, false, 4);
        }
        else if (equals(typ, int16_type)) {
            return (n + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (equals(typ, uint32_type)) {
            return n;
        }
        else if (equals(typ, class_type("System.UInt64"))) {
            return fromInteger(n, true, 4);
        }
        else if (equals(typ, uint16_type)) {
            return n;
        }
        else if (equals(typ, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromInteger(n, false, 4));
        }
        else if (fullName(typ) === "Microsoft.FSharp.Core.int16`1") {
            return (n + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (fullName(typ) === "Microsoft.FSharp.Core.int32`1") {
            return ~(~n);
        }
        else if (fullName(typ) === "Microsoft.FSharp.Core.int64`1") {
            return fromInteger(n, false, 4);
        }
        else if (equals(typ, uint8_type)) {
            return n;
        }
        else if (equals(typ, int8_type)) {
            return (n + 0x80 & 0xFF) - 0x80;
        }
        else if (isEnum(typ)) {
            return n;
        }
        else {
            const arg20 = name(typ);
            return toFail(printf("Cannot interpret integer %A as %s."))(n)(arg20);
        }
    }
    else if ((matchValue | 31) === 255) {
        const typ_1 = t;
        const n_2 = ((matchValue + 0x80 & 0xFF) - 0x80) | 0;
        if (equals(typ_1, int32_type)) {
            return n_2;
        }
        else if (equals(typ_1, class_type("System.Int64"))) {
            return fromInteger(n_2, false, 0);
        }
        else if (equals(typ_1, int16_type)) {
            return n_2;
        }
        else if (equals(typ_1, uint32_type)) {
            return n_2 >>> 0;
        }
        else if (equals(typ_1, class_type("System.UInt64"))) {
            return fromInteger(n_2, true, 0);
        }
        else if (equals(typ_1, uint16_type)) {
            return n_2 & 0xFFFF;
        }
        else if (equals(typ_1, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromInteger(n_2, false, 0));
        }
        else if (fullName(typ_1) === "Microsoft.FSharp.Core.int16`1") {
            return n_2;
        }
        else if (fullName(typ_1) === "Microsoft.FSharp.Core.int32`1") {
            return n_2;
        }
        else if (fullName(typ_1) === "Microsoft.FSharp.Core.int64`1") {
            return fromInteger(n_2, false, 0);
        }
        else if (equals(typ_1, uint8_type)) {
            return n_2 & 0xFF;
        }
        else if (equals(typ_1, int8_type)) {
            return n_2;
        }
        else if (isEnum(typ_1)) {
            return n_2;
        }
        else {
            const arg20_1 = name(typ_1);
            return toFail(printf("Cannot interpret integer %A as %s."))(n_2)(arg20_1);
        }
    }
    else if (matchValue === 192) {
        return null;
    }
    else if (matchValue === 194) {
        return false;
    }
    else if (matchValue === 195) {
        return true;
    }
    else if (matchValue === 202) {
        const typ_10 = t;
        const n_20 = Reader__ReadFloat32(x);
        if (equals(typ_10, float32_type)) {
            return n_20;
        }
        else if (equals(typ_10, float64_type)) {
            return n_20;
        }
        else if (fullName(typ_10) === "Microsoft.FSharp.Core.float32`1") {
            return n_20;
        }
        else if (fullName(typ_10) === "Microsoft.FSharp.Core.float`1") {
            return n_20;
        }
        else {
            const arg20_10 = name(typ_10);
            return toFail(printf("Cannot interpret float %A as %s."))(n_20)(arg20_10);
        }
    }
    else if (matchValue === 203) {
        const typ_11 = t;
        const n_22 = Reader__ReadFloat64(x);
        if (equals(typ_11, float32_type)) {
            return n_22;
        }
        else if (equals(typ_11, float64_type)) {
            return n_22;
        }
        else if (fullName(typ_11) === "Microsoft.FSharp.Core.float32`1") {
            return n_22;
        }
        else if (fullName(typ_11) === "Microsoft.FSharp.Core.float`1") {
            return n_22;
        }
        else {
            const arg20_11 = name(typ_11);
            return toFail(printf("Cannot interpret float %A as %s."))(n_22)(arg20_11);
        }
    }
    else if (matchValue === 204) {
        const typ_6 = t;
        const n_12 = Reader__ReadUInt8(x);
        if (equals(typ_6, int32_type)) {
            return ~(~n_12);
        }
        else if (equals(typ_6, class_type("System.Int64"))) {
            return fromInteger(n_12, false, 4);
        }
        else if (equals(typ_6, int16_type)) {
            return (n_12 + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (equals(typ_6, uint32_type)) {
            return n_12;
        }
        else if (equals(typ_6, class_type("System.UInt64"))) {
            return fromInteger(n_12, true, 4);
        }
        else if (equals(typ_6, uint16_type)) {
            return n_12;
        }
        else if (equals(typ_6, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromInteger(n_12, false, 4));
        }
        else if (fullName(typ_6) === "Microsoft.FSharp.Core.int16`1") {
            return (n_12 + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (fullName(typ_6) === "Microsoft.FSharp.Core.int32`1") {
            return ~(~n_12);
        }
        else if (fullName(typ_6) === "Microsoft.FSharp.Core.int64`1") {
            return fromInteger(n_12, false, 4);
        }
        else if (equals(typ_6, uint8_type)) {
            return n_12;
        }
        else if (equals(typ_6, int8_type)) {
            return (n_12 + 0x80 & 0xFF) - 0x80;
        }
        else if (isEnum(typ_6)) {
            return n_12;
        }
        else {
            const arg20_6 = name(typ_6);
            return toFail(printf("Cannot interpret integer %A as %s."))(n_12)(arg20_6);
        }
    }
    else if (matchValue === 205) {
        const typ_7 = t;
        const n_14 = Reader__ReadUInt16(x);
        if (equals(typ_7, int32_type)) {
            return ~(~n_14);
        }
        else if (equals(typ_7, class_type("System.Int64"))) {
            return fromInteger(n_14, false, 5);
        }
        else if (equals(typ_7, int16_type)) {
            return (n_14 + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (equals(typ_7, uint32_type)) {
            return n_14;
        }
        else if (equals(typ_7, class_type("System.UInt64"))) {
            return fromInteger(n_14, true, 5);
        }
        else if (equals(typ_7, uint16_type)) {
            return n_14;
        }
        else if (equals(typ_7, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromInteger(n_14, false, 5));
        }
        else if (fullName(typ_7) === "Microsoft.FSharp.Core.int16`1") {
            return (n_14 + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (fullName(typ_7) === "Microsoft.FSharp.Core.int32`1") {
            return ~(~n_14);
        }
        else if (fullName(typ_7) === "Microsoft.FSharp.Core.int64`1") {
            return fromInteger(n_14, false, 5);
        }
        else if (equals(typ_7, uint8_type)) {
            return n_14 & 0xFF;
        }
        else if (equals(typ_7, int8_type)) {
            return (n_14 + 0x80 & 0xFF) - 0x80;
        }
        else if (isEnum(typ_7)) {
            return n_14;
        }
        else {
            const arg20_7 = name(typ_7);
            return toFail(printf("Cannot interpret integer %A as %s."))(n_14)(arg20_7);
        }
    }
    else if (matchValue === 206) {
        const typ_8 = t;
        const n_16 = Reader__ReadUInt32(x);
        if (equals(typ_8, int32_type)) {
            return ~(~n_16);
        }
        else if (equals(typ_8, class_type("System.Int64"))) {
            return fromInteger(n_16, false, 6);
        }
        else if (equals(typ_8, int16_type)) {
            return (n_16 + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (equals(typ_8, uint32_type)) {
            return n_16;
        }
        else if (equals(typ_8, class_type("System.UInt64"))) {
            return fromInteger(n_16, true, 6);
        }
        else if (equals(typ_8, uint16_type)) {
            return n_16 & 0xFFFF;
        }
        else if (equals(typ_8, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromInteger(n_16, false, 6));
        }
        else if (fullName(typ_8) === "Microsoft.FSharp.Core.int16`1") {
            return (n_16 + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (fullName(typ_8) === "Microsoft.FSharp.Core.int32`1") {
            return ~(~n_16);
        }
        else if (fullName(typ_8) === "Microsoft.FSharp.Core.int64`1") {
            return fromInteger(n_16, false, 6);
        }
        else if (equals(typ_8, uint8_type)) {
            return n_16 & 0xFF;
        }
        else if (equals(typ_8, int8_type)) {
            return (n_16 + 0x80 & 0xFF) - 0x80;
        }
        else if (isEnum(typ_8)) {
            return n_16;
        }
        else {
            const arg20_8 = name(typ_8);
            return toFail(printf("Cannot interpret integer %A as %s."))(n_16)(arg20_8);
        }
    }
    else if (matchValue === 207) {
        const typ_9 = t;
        const n_18 = Reader__ReadUInt64(x);
        if (equals(typ_9, int32_type)) {
            return ~(~toInt(n_18));
        }
        else if (equals(typ_9, class_type("System.Int64"))) {
            return fromValue(n_18, false);
        }
        else if (equals(typ_9, int16_type)) {
            return (toInt(n_18) + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (equals(typ_9, uint32_type)) {
            return toInt(n_18) >>> 0;
        }
        else if (equals(typ_9, class_type("System.UInt64"))) {
            return fromValue(n_18, true);
        }
        else if (equals(typ_9, uint16_type)) {
            return toInt(n_18) & 0xFFFF;
        }
        else if (equals(typ_9, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromValue(n_18, false));
        }
        else if (fullName(typ_9) === "Microsoft.FSharp.Core.int16`1") {
            return (toInt(n_18) + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (fullName(typ_9) === "Microsoft.FSharp.Core.int32`1") {
            return ~(~toInt(n_18));
        }
        else if (fullName(typ_9) === "Microsoft.FSharp.Core.int64`1") {
            return fromValue(n_18, false);
        }
        else if (equals(typ_9, uint8_type)) {
            return toInt(n_18) & 0xFF;
        }
        else if (equals(typ_9, int8_type)) {
            return (toInt(n_18) + 0x80 & 0xFF) - 0x80;
        }
        else if (isEnum(typ_9)) {
            return toNumber(n_18);
        }
        else {
            const arg20_9 = name(typ_9);
            return toFail(printf("Cannot interpret integer %A as %s."))(n_18)(arg20_9);
        }
    }
    else if (matchValue === 208) {
        const typ_5 = t;
        const n_10 = Reader__ReadInt8(x) | 0;
        if (equals(typ_5, int32_type)) {
            return n_10;
        }
        else if (equals(typ_5, class_type("System.Int64"))) {
            return fromInteger(n_10, false, 0);
        }
        else if (equals(typ_5, int16_type)) {
            return n_10;
        }
        else if (equals(typ_5, uint32_type)) {
            return n_10 >>> 0;
        }
        else if (equals(typ_5, class_type("System.UInt64"))) {
            return fromInteger(n_10, true, 0);
        }
        else if (equals(typ_5, uint16_type)) {
            return n_10 & 0xFFFF;
        }
        else if (equals(typ_5, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromInteger(n_10, false, 0));
        }
        else if (fullName(typ_5) === "Microsoft.FSharp.Core.int16`1") {
            return n_10;
        }
        else if (fullName(typ_5) === "Microsoft.FSharp.Core.int32`1") {
            return n_10;
        }
        else if (fullName(typ_5) === "Microsoft.FSharp.Core.int64`1") {
            return fromInteger(n_10, false, 0);
        }
        else if (equals(typ_5, uint8_type)) {
            return n_10 & 0xFF;
        }
        else if (equals(typ_5, int8_type)) {
            return n_10;
        }
        else if (isEnum(typ_5)) {
            return n_10;
        }
        else {
            const arg20_5 = name(typ_5);
            return toFail(printf("Cannot interpret integer %A as %s."))(n_10)(arg20_5);
        }
    }
    else if (matchValue === 209) {
        const typ_4 = t;
        const n_8 = Reader__ReadInt16(x) | 0;
        if (equals(typ_4, int32_type)) {
            return n_8;
        }
        else if (equals(typ_4, class_type("System.Int64"))) {
            return fromInteger(n_8, false, 1);
        }
        else if (equals(typ_4, int16_type)) {
            return n_8;
        }
        else if (equals(typ_4, uint32_type)) {
            return n_8 >>> 0;
        }
        else if (equals(typ_4, class_type("System.UInt64"))) {
            return fromInteger(n_8, true, 1);
        }
        else if (equals(typ_4, uint16_type)) {
            return n_8 & 0xFFFF;
        }
        else if (equals(typ_4, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromInteger(n_8, false, 1));
        }
        else if (fullName(typ_4) === "Microsoft.FSharp.Core.int16`1") {
            return n_8;
        }
        else if (fullName(typ_4) === "Microsoft.FSharp.Core.int32`1") {
            return n_8;
        }
        else if (fullName(typ_4) === "Microsoft.FSharp.Core.int64`1") {
            return fromInteger(n_8, false, 1);
        }
        else if (equals(typ_4, uint8_type)) {
            return n_8 & 0xFF;
        }
        else if (equals(typ_4, int8_type)) {
            return (n_8 + 0x80 & 0xFF) - 0x80;
        }
        else if (isEnum(typ_4)) {
            return n_8;
        }
        else {
            const arg20_4 = name(typ_4);
            return toFail(printf("Cannot interpret integer %A as %s."))(n_8)(arg20_4);
        }
    }
    else if (matchValue === 210) {
        const typ_3 = t;
        const n_6 = Reader__ReadInt32(x) | 0;
        if (equals(typ_3, int32_type)) {
            return n_6;
        }
        else if (equals(typ_3, class_type("System.Int64"))) {
            return fromInteger(n_6, false, 2);
        }
        else if (equals(typ_3, int16_type)) {
            return (n_6 + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (equals(typ_3, uint32_type)) {
            return n_6 >>> 0;
        }
        else if (equals(typ_3, class_type("System.UInt64"))) {
            return fromInteger(n_6, true, 2);
        }
        else if (equals(typ_3, uint16_type)) {
            return n_6 & 0xFFFF;
        }
        else if (equals(typ_3, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromInteger(n_6, false, 2));
        }
        else if (fullName(typ_3) === "Microsoft.FSharp.Core.int16`1") {
            return (n_6 + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (fullName(typ_3) === "Microsoft.FSharp.Core.int32`1") {
            return n_6;
        }
        else if (fullName(typ_3) === "Microsoft.FSharp.Core.int64`1") {
            return fromInteger(n_6, false, 2);
        }
        else if (equals(typ_3, uint8_type)) {
            return n_6 & 0xFF;
        }
        else if (equals(typ_3, int8_type)) {
            return (n_6 + 0x80 & 0xFF) - 0x80;
        }
        else if (isEnum(typ_3)) {
            return n_6;
        }
        else {
            const arg20_3 = name(typ_3);
            return toFail(printf("Cannot interpret integer %A as %s."))(n_6)(arg20_3);
        }
    }
    else if (matchValue === 211) {
        const typ_2 = t;
        const n_4 = Reader__ReadInt64(x);
        if (equals(typ_2, int32_type)) {
            return ~(~toInt(n_4));
        }
        else if (equals(typ_2, class_type("System.Int64"))) {
            return fromValue(n_4, false);
        }
        else if (equals(typ_2, int16_type)) {
            return (toInt(n_4) + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (equals(typ_2, uint32_type)) {
            return toInt(n_4) >>> 0;
        }
        else if (equals(typ_2, class_type("System.UInt64"))) {
            return fromValue(n_4, true);
        }
        else if (equals(typ_2, uint16_type)) {
            return toInt(n_4) & 0xFFFF;
        }
        else if (equals(typ_2, class_type("System.TimeSpan"))) {
            return fromTicks_2(fromValue(n_4, false));
        }
        else if (fullName(typ_2) === "Microsoft.FSharp.Core.int16`1") {
            return (toInt(n_4) + 0x8000 & 0xFFFF) - 0x8000;
        }
        else if (fullName(typ_2) === "Microsoft.FSharp.Core.int32`1") {
            return ~(~toInt(n_4));
        }
        else if (fullName(typ_2) === "Microsoft.FSharp.Core.int64`1") {
            return fromValue(n_4, false);
        }
        else if (equals(typ_2, uint8_type)) {
            return toInt(n_4) & 0xFF;
        }
        else if (equals(typ_2, int8_type)) {
            return (toInt(n_4) + 0x80 & 0xFF) - 0x80;
        }
        else if (isEnum(typ_2)) {
            return toNumber(n_4);
        }
        else {
            const arg20_2 = name(typ_2);
            return toFail(printf("Cannot interpret integer %A as %s."))(n_4)(arg20_2);
        }
    }
    else if ((matchValue | 15) === 159) {
        return Reader__ReadArray_412036CA(x, ~(~(matchValue & 15)), t);
    }
    else if (matchValue === 220) {
        return Reader__ReadArray_412036CA(x, ~(~Reader__ReadUInt16(x)), t);
    }
    else if (matchValue === 221) {
        return Reader__ReadArray_412036CA(x, ~(~Reader__ReadUInt32(x)), t);
    }
    else if ((matchValue | 15) === 143) {
        return Reader__ReadMap_412036CA(x, ~(~(matchValue & 15)), t);
    }
    else {
        switch (matchValue) {
            case 196: {
                return Reader__ReadBin_412036CA(x, ~(~Reader__ReadByte(x)), t);
            }
            case 197: {
                return Reader__ReadBin_412036CA(x, ~(~Reader__ReadUInt16(x)), t);
            }
            case 198: {
                return Reader__ReadBin_412036CA(x, ~(~Reader__ReadUInt32(x)), t);
            }
            case 222: {
                return Reader__ReadMap_412036CA(x, ~(~Reader__ReadUInt16(x)), t);
            }
            case 223: {
                return Reader__ReadMap_412036CA(x, ~(~Reader__ReadUInt32(x)), t);
            }
            default: {
                const arg30 = name(t);
                const arg10_12 = x.pos | 0;
                return toFail(printf("Position %d, byte %d, expected type %s."))(arg10_12)(matchValue)(arg30);
            }
        }
    }
}

function Reader__readInt(this$, len, m) {
    if (isLittleEndian()) {
        const arr = new Uint8Array(len);
        for (let i = 0; i <= (len - 1); i++) {
            arr[i] = this$.data[((this$.pos + len) - 1) - i];
        }
        this$.pos = ((this$.pos + len) | 0);
        return m([arr, 0]);
    }
    else {
        this$.pos = ((this$.pos + len) | 0);
        return m([this$.data, this$.pos - len]);
    }
}

