import { useScriptLoader as useScriptLoader_1 } from "use-script-loader";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { empty, singleton, append, delay, toList, item } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "../Communication.js";

const Internal_useScriptLoader = useScriptLoader_1;

export function useuseScriptLoader(source, attributes, isSkip) {
    const result = Internal_useScriptLoader(source, createObj(attributes), void 0, isSkip);
    return [item(0, result), item(1, result), item(2, result)];
}

function FreshChatInternal(settings) {
    useuseScriptLoader(settings.Source, toList(delay(() => append(singleton(["chat", true]), delay(() => {
        const matchValue = settings.WidgetId;
        if (matchValue == null) {
            return empty();
        }
        else {
            return singleton(["widgetId", matchValue]);
        }
    })))), false);
    return react.createElement(react.Fragment, {});
}

export function FreshChat() {
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getFreshChatSettings(), []);
    let pattern_matching_result, settings_1;
    if (data.tag === 2) {
        if (data.fields[0].Enabled) {
            pattern_matching_result = 0;
            settings_1 = data.fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return createElement(FreshChatInternal, settings_1);
        }
        case 1: {
            switch (data.tag) {
                case 1: {
                    return null;
                }
                case 3: {
                    return null;
                }
                case 2: {
                    return null;
                }
                default: {
                    return null;
                }
            }
        }
    }
}

