import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { lambda_type, anonRecord_type, tuple_type, string_type, list_type, option_type, bool_type, union_type, record_type, class_type, int32_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { exists, tryHead, sortBy, indexed, map, empty } from "../fable_modules/fable-library.3.6.1/List.js";
import { op_Division, toNumber, equals, fromBits } from "../fable_modules/fable-library.3.6.1/Long.js";
import { UserIdModule_fromInt, LotIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { ofSeq, map as map_2, find, ofList } from "../fable_modules/fable-library.3.6.1/Map.js";
import { List_distinct } from "../fable_modules/fable-library.3.6.1/Seq2.js";
import { fastStructuralHash, compareArrays } from "../fable_modules/fable-library.3.6.1/Util.js";
import { defaultArg, map as map_1 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { Bid$reflection, Image$reflection, Dimensions$reflection } from "./Shared.js";
import { FSharpResult$2, FSharpChoice$6 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import DateOffset from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { Settings$reflection } from "../bidflow/src/infrastructure/Bidding.js";

export class Lot_Bid extends Record {
    constructor(Limit, Date$) {
        super();
        this.Limit = (Limit | 0);
        this.Date = Date$;
    }
}

export function Lot_Bid$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.Lot.Bid", [], Lot_Bid, () => [["Limit", int32_type], ["Date", class_type("System.DateTimeOffset")]]);
}

export class Lot_BidKind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Manual", "Auto"];
    }
}

export function Lot_BidKind$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.Lot.BidKind", [], Lot_BidKind, () => [[], []]);
}

export class Lot_HistoryBid extends Record {
    constructor(Bid, UserId, Date$, Kind, ReservedPriceMet, IsMyBid) {
        super();
        this.Bid = (Bid | 0);
        this.UserId = UserId;
        this.Date = Date$;
        this.Kind = Kind;
        this.ReservedPriceMet = ReservedPriceMet;
        this.IsMyBid = IsMyBid;
    }
}

export function Lot_HistoryBid$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.Lot.HistoryBid", [], Lot_HistoryBid, () => [["Bid", int32_type], ["UserId", class_type("System.Int64")], ["Date", class_type("System.DateTimeOffset")], ["Kind", Lot_BidKind$reflection()], ["ReservedPriceMet", bool_type], ["IsMyBid", bool_type]]);
}

export class Lot_LotStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "Bidding", "ActiveBidding", "Finished"];
    }
}

export function Lot_LotStatus$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.Lot.LotStatus", [], Lot_LotStatus, () => [[], [], [], []]);
}

export class Lot_Lot extends Record {
    constructor(Status, HasReservedPrice, CurrentUserIsWinner, CurrentUserIsOwner, MyLastAcceptedBid, History, Version, Id) {
        super();
        this.Status = Status;
        this.HasReservedPrice = HasReservedPrice;
        this.CurrentUserIsWinner = CurrentUserIsWinner;
        this.CurrentUserIsOwner = CurrentUserIsOwner;
        this.MyLastAcceptedBid = MyLastAcceptedBid;
        this.History = History;
        this.Version = Version;
        this.Id = Id;
    }
}

export function Lot_Lot$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.Lot.Lot", [], Lot_Lot, () => [["Status", Lot_LotStatus$reflection()], ["HasReservedPrice", bool_type], ["CurrentUserIsWinner", bool_type], ["CurrentUserIsOwner", bool_type], ["MyLastAcceptedBid", option_type(Lot_Bid$reflection())], ["History", list_type(Lot_HistoryBid$reflection())], ["Version", class_type("System.Int64")], ["Id", class_type("System.Int64")]]);
}

export function Lot_Lot_get_Zero() {
    return new Lot_Lot(new Lot_LotStatus(1), false, false, false, void 0, empty(), fromBits(0, 0, false), LotIdModule_empty);
}

export function Lot_HistoryBids_anonymize(history) {
    const userIds = ofList(map((tupledArg) => [tupledArg[1], UserIdModule_fromInt(tupledArg[0] + 1)], indexed(List_distinct(map((x_2) => x_2.UserId, sortBy((x) => [x.Bid, x.Kind], history, {
        Compare: (x_1, y) => compareArrays(x_1, y),
    })), {
        Equals: (x_3, y_1) => equals(x_3, y_1),
        GetHashCode: (x_3) => fastStructuralHash(x_3),
    }))));
    return map((x_5) => (new Lot_HistoryBid(x_5.Bid, find(x_5.UserId, userIds), x_5.Date, x_5.Kind, x_5.ReservedPriceMet, x_5.IsMyBid)), history);
}

export function Lot_HistoryBids_tryCurrent(history) {
    return tryHead(history);
}

export function Lot_HistoryBids_tryCurrentValue(history) {
    return map_1((x) => x.Bid, Lot_HistoryBids_tryCurrent(history));
}

export function Lot_HistoryBids_isCurrentBidMetReservedPrice(history) {
    return defaultArg(map_1((x) => x.ReservedPriceMet, Lot_HistoryBids_tryCurrent(history)), false);
}

export function Lot_HistoryBids_isReservedPriceMet(history) {
    return exists((x) => x.ReservedPriceMet, history);
}

export class LiveAuction_LotDescription extends Record {
    constructor(Name, Description, Condition, Size, Royalty) {
        super();
        this.Name = Name;
        this.Description = Description;
        this.Condition = Condition;
        this.Size = Size;
        this.Royalty = Royalty;
    }
}

export function LiveAuction_LotDescription$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.LiveAuction.LotDescription", [], LiveAuction_LotDescription, () => [["Name", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["Description", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["Condition", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["Size", Dimensions$reflection()], ["Royalty", bool_type]]);
}

export class LiveAuction_CurrentLotInfo extends Record {
    constructor(Id, AuctionId, OrderNumber, DisplayId, ItemId, CurrentBid, Estimate, Image, HasReservedPrice, Description, IsBiddingFinished, CurrentUserIsWinner, Priority) {
        super();
        this.Id = Id;
        this.AuctionId = AuctionId;
        this.OrderNumber = (OrderNumber | 0);
        this.DisplayId = DisplayId;
        this.ItemId = ItemId;
        this.CurrentBid = (CurrentBid | 0);
        this.Estimate = Estimate;
        this.Image = Image;
        this.HasReservedPrice = HasReservedPrice;
        this.Description = Description;
        this.IsBiddingFinished = IsBiddingFinished;
        this.CurrentUserIsWinner = CurrentUserIsWinner;
        this.Priority = (Priority | 0);
    }
}

export function LiveAuction_CurrentLotInfo$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.LiveAuction.CurrentLotInfo", [], LiveAuction_CurrentLotInfo, () => [["Id", class_type("System.Int64")], ["AuctionId", class_type("System.Int64")], ["OrderNumber", int32_type], ["DisplayId", string_type], ["ItemId", class_type("System.Int64")], ["CurrentBid", int32_type], ["Estimate", option_type(class_type("System.Decimal"))], ["Image", option_type(Image$reflection())], ["HasReservedPrice", bool_type], ["Description", LiveAuction_LotDescription$reflection()], ["IsBiddingFinished", bool_type], ["CurrentUserIsWinner", bool_type], ["Priority", int32_type]]);
}

export class LiveAuction_StateInfo extends Record {
    constructor(SecondsElapsed, Lot, UpcomingLots) {
        super();
        this.SecondsElapsed = (SecondsElapsed | 0);
        this.Lot = Lot;
        this.UpcomingLots = UpcomingLots;
    }
}

export function LiveAuction_StateInfo$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.LiveAuction.StateInfo", [], LiveAuction_StateInfo, () => [["SecondsElapsed", int32_type], ["Lot", LiveAuction_CurrentLotInfo$reflection()], ["UpcomingLots", list_type(tuple_type(Image$reflection(), int32_type, string_type))]]);
}

export class LiveAuction_State extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotStarted", "InProgress", "Finished"];
    }
}

export function LiveAuction_State$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.LiveAuction.State", [], LiveAuction_State, () => [[], [["Item", LiveAuction_StateInfo$reflection()]], []]);
}

export function LiveAuction_StateModule_tryInProgress(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return _arg1.fields[0];
        }
        case 2: {
            return void 0;
        }
        default: {
            return void 0;
        }
    }
}

export const LiveAuction_StateModule_trySecondsElapsed = (arg) => map_1((x) => x.SecondsElapsed, LiveAuction_StateModule_tryInProgress(arg));

export function LiveAuction_StateModule_$007CAuctionNotStarted$007CAuctionStarted$007CAuctionFinished$007CCurrentLotBidChanged$007CCurrentLotBiddingFinished$007CNextLotBiddingStarted$007C(_arg1_0, _arg1_1) {
    const _arg1 = [_arg1_0, _arg1_1];
    let pattern_matching_result;
    if (_arg1[0].tag === 0) {
        if (_arg1[1].tag === 1) {
            pattern_matching_result = 1;
        }
        else if (_arg1[1].tag === 2) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else if (_arg1[1].tag === 2) {
        pattern_matching_result = 2;
    }
    else if (_arg1[1].tag === 1) {
        if (_arg1[1].fields[0].Lot.IsBiddingFinished) {
            pattern_matching_result = 3;
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else {
        pattern_matching_result = 4;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpChoice$6(0, void 0);
        }
        case 1: {
            return new FSharpChoice$6(1, void 0);
        }
        case 2: {
            return new FSharpChoice$6(2, void 0);
        }
        case 3: {
            return new FSharpChoice$6(4, void 0);
        }
        case 4: {
            let pattern_matching_result_1;
            if (_arg1[0].tag === 1) {
                if (_arg1[1].tag === 1) {
                    if (equals(_arg1[0].fields[0].Lot.Id, _arg1[1].fields[0].Lot.Id)) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return new FSharpChoice$6(3, void 0);
                }
                case 1: {
                    return new FSharpChoice$6(5, void 0);
                }
            }
        }
    }
}

export class CatalogStream_LotStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Bidding", "LiveBidding", "Withdrawn", "Sold", "Unsold"];
    }
}

export function CatalogStream_LotStatus$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.CatalogStream.LotStatus", [], CatalogStream_LotStatus, () => [[], [], [], [], []]);
}

export class CatalogStream_Event extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BidChanged", "LotWithdrawn", "LiveBiddingStarted", "LotFinished", "ScheduleChanged"];
    }
}

export function CatalogStream_Event$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.CatalogStream.Event", [], CatalogStream_Event, () => [[["Item", anonRecord_type(["Bid", int32_type], ["LotId", class_type("System.Int64")], ["PreviousBid", int32_type])]], [["Item", anonRecord_type(["LotId", class_type("System.Int64")])]], [["Item", anonRecord_type(["LotId", class_type("System.Int64")])]], [["Item", anonRecord_type(["HasSold", bool_type], ["LotId", class_type("System.Int64")])]], [["Item", list_type(tuple_type(class_type("System.Int64"), class_type("System.Int64")))]]]);
}

export function CatalogStream_ScheduleChanged_toDates(offsets) {
    return map_2((_arg1, start) => DateOffset(toNumber(op_Division(start, fromBits(1, 0, false))) * 1000, 0), ofSeq(offsets));
}

export class Action extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Dummy"];
    }
}

export function Action$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.Action", [], Action, () => [[]]);
}

export class Response extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Dummy"];
    }
}

export function Response$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.Response", [], Response, () => [[]]);
}

export class StreamFrom_SubscribeToLiveAuctionArgs extends Record {
    constructor(UserId, Language) {
        super();
        this.UserId = UserId;
        this.Language = Language;
    }
}

export function StreamFrom_SubscribeToLiveAuctionArgs$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.StreamFrom.SubscribeToLiveAuctionArgs", [], StreamFrom_SubscribeToLiveAuctionArgs, () => [["UserId", option_type(class_type("System.Int64"))], ["Language", string_type]]);
}

export class StreamFrom_SubscribeToLotEventsArgs extends Record {
    constructor(LotId, UserId) {
        super();
        this.LotId = LotId;
        this.UserId = UserId;
    }
}

export function StreamFrom_SubscribeToLotEventsArgs$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.StreamFrom.SubscribeToLotEventsArgs", [], StreamFrom_SubscribeToLotEventsArgs, () => [["LotId", class_type("System.Int64")], ["UserId", option_type(class_type("System.Int64"))]]);
}

export class StreamFrom_SubscribeToCatalogEventsArgs extends Record {
    constructor(LotIds, UserId) {
        super();
        this.LotIds = LotIds;
        this.UserId = UserId;
    }
}

export function StreamFrom_SubscribeToCatalogEventsArgs$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.StreamFrom.SubscribeToCatalogEventsArgs", [], StreamFrom_SubscribeToCatalogEventsArgs, () => [["LotIds", list_type(class_type("System.Int64"))], ["UserId", option_type(class_type("System.Int64"))]]);
}

export class StreamFrom_Action extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SubscribeToLotEvents", "SubscribeToLiveAuction", "SubscribeToCatalogEvents"];
    }
}

export function StreamFrom_Action$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.StreamFrom.Action", [], StreamFrom_Action, () => [[["Item", StreamFrom_SubscribeToLotEventsArgs$reflection()]], [["Item", StreamFrom_SubscribeToLiveAuctionArgs$reflection()]], [["Item", StreamFrom_SubscribeToCatalogEventsArgs$reflection()]]]);
}

export class StreamFrom_Response extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BiddingSettings", "LotState", "LiveAuctionState", "CatalogStream"];
    }
}

export function StreamFrom_Response$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.StreamFrom.Response", [], StreamFrom_Response, () => [[["Item", Settings$reflection()]], [["Item1", class_type("System.Int64")], ["Item2", Lot_Lot$reflection()]], [["Item", LiveAuction_State$reflection()]], [["Item", CatalogStream_Event$reflection()]]]);
}

export class MakeBid_Request extends Record {
    constructor(LotId, Bid) {
        super();
        this.LotId = LotId;
        this.Bid = (Bid | 0);
    }
}

export function MakeBid_Request$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.MakeBid.Request", [], MakeBid_Request, () => [["LotId", class_type("System.Int64")], ["Bid", int32_type]]);
}

export class MakeBid_PlaceBidErrorReason extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BidLowerThanNextBidRequested", "InvalidBidValue", "BidLowerThanLastLimitedBidRequested", "BidAfterFinishRequested", "PanicError"];
    }
}

export function MakeBid_PlaceBidErrorReason$reflection() {
    return union_type("Bidflow.FO.Shared.Bidding.MakeBid.PlaceBidErrorReason", [], MakeBid_PlaceBidErrorReason, () => [[["Item", Bid$reflection()]], [["Item", Bid$reflection()]], [["Item", Bid$reflection()]], [["Item", Bid$reflection()]], [["Item", string_type]]]);
}

export class IBiddingApi extends Record {
    constructor(getLot) {
        super();
        this.getLot = getLot;
    }
}

export function IBiddingApi$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.IBiddingApi", [], IBiddingApi, () => [["getLot", lambda_type(tuple_type(class_type("System.Int64"), option_type(class_type("System.Int64"))), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Lot_Lot$reflection()]))]]);
}

export class IBiddingAuthorizedApi extends Record {
    constructor(makeBid) {
        super();
        this.makeBid = makeBid;
    }
}

export function IBiddingAuthorizedApi$reflection() {
    return record_type("Bidflow.FO.Shared.Bidding.IBiddingAuthorizedApi", [], IBiddingAuthorizedApi, () => [["makeBid", lambda_type(MakeBid_Request$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [Lot_Lot$reflection(), MakeBid_PlaceBidErrorReason$reflection()], FSharpResult$2, () => [[["ResultValue", Lot_Lot$reflection()]], [["ErrorValue", MakeBid_PlaceBidErrorReason$reflection()]]])]))]]);
}

