import { createElement } from "react";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { append, empty, singleton, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { ImageUrlModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { Img_originalImageUrlInPng } from "./Css.js";
import { ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Localized_translate } from "./Localization.js";
import { LocalizedMap_getValueOrEmpty } from "./bidflow/src/infrastructure/Localization.js";
import { HomeInfo_ContentField } from "./Shared/Shared.js";

export function promotion(promo) {
    let props_1;
    const content = createElement("div", {
        className: join(" ", toList(delay(() => ((promo.Link.length !== 0) ? singleton("homePromoLink") : empty())))),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let matchValue, i, props_5;
            return append((matchValue = promo.Image, (matchValue != null) ? ((i = matchValue, singleton((props_5 = ofArray([["style", {
                marginBottom: 20,
            }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
                className: "previewImageCenterWithoutRatio",
                src: ImageUrlModule_toString(Img_originalImageUrlInPng(i)),
                alt: "Placeholder image",
            })])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_5))))))) : singleton(null)), delay(() => {
                let props_11, props_9;
                return singleton((props_11 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", join(" ", ["paddingLeftRight-mobile45", "is-9-tablet", "is-9-desktop"])], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    className: join(" ", ["home-info-white-space-title", "homeTitleAuction"]),
                    children: Localized_translate(LocalizedMap_getValueOrEmpty(new HomeInfo_ContentField(0), promo.Content)),
                }), createElement("p", {
                    className: join(" ", ["home-info-white-space-subtitle"]),
                    children: Localized_translate(LocalizedMap_getValueOrEmpty(new HomeInfo_ContentField(1), promo.Content)),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_11)))));
            }));
        })))),
    });
    return createElement("div", {
        className: join(" ", ["margin-bottom-desktop60-mobile20"]),
        children: Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", join(" ", ["home-description-box-padding-top"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((promo.Link.length !== 0) ? singleton(createElement("a", {
            href: promo.Link,
            target: "_blank",
            children: Interop_reactApi.Children.toArray([content]),
        })) : singleton(content))))))]]), createElement("div", createObj(Helpers_combineClasses("container", props_1))))]),
    });
}

