import { Localized_formatDate, Localized_translate, t } from "../Localization.js";
import { Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { HomeInfo_CatalogImages$reflection, SpecialOffersText$reflection, AuctionPreviewInfo$reflection } from "../Shared/Shared.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "../fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "../fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { MediaQuery_customBreakpoints } from "../Common.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Route, toPath } from "../Router.js";
import { ImageUrlModule_toString } from "../bidflow/src/infrastructure/Auction.js";
import { Img_originalImageUrlInPng } from "../Css.js";
import { View } from "../Users/AppointmentBooking.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { homeApi, lotsApi } from "../Communication.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { mediumInline } from "../Components/Loader.js";

export function p(key) {
    return t("catalogue." + key);
}

export function l(key) {
    return t("lotDetails." + key);
}

export function h(key) {
    return t("home." + key);
}

export class State extends Record {
    constructor(AuctionId, AuctionInfo, SpecialOffersText, Images) {
        super();
        this.AuctionId = AuctionId;
        this.AuctionInfo = AuctionInfo;
        this.SpecialOffersText = SpecialOffersText;
        this.Images = Images;
    }
}

export function State$reflection() {
    return record_type("PreviewCatalog.State", [], State, () => [["AuctionId", class_type("System.Int64")], ["AuctionInfo", AuctionPreviewInfo$reflection()], ["SpecialOffersText", SpecialOffersText$reflection()], ["Images", HomeInfo_CatalogImages$reflection()]]);
}

export function Navbar(state) {
    let props_5, props, props_3, props_10;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput[1];
    const isActive = patternInput[0];
    const navRef = useReact_useRef_1505(void 0);
    const elemRef = navRef;
    const callback = (_arg1) => {
        setActive(false);
    };
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = matchValue, copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            document.addEventListener(eventType, fn, addOptions);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                document.removeEventListener(eventType, fn, removeOptions);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = matchValue_1, copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_7 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_7), [options_7]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_7), [options_7]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            document.addEventListener(eventType_1, fn_1, addOptions_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                document.removeEventListener(eventType_1, fn_1, removeOptions_1);
            }
        });
    }));
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const minHeight = 45;
    const props_12 = ofArray([["className", "is-white"], ["style", createObj(toList(delay(() => append(singleton(["minHeight", minHeight]), delay(() => append(singleton(["marginBottom", 0]), delay(() => ((width.tag === 0) ? singleton(["top", 58]) : ((width.tag === 1) ? singleton(["top", 58]) : append(singleton(["top", 0]), delay(() => singleton(["marginTop", 0]))))))))))))], ["className", join(" ", ["navbar-catalogue"])], ["ref", navRef], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["style", createObj(toList(delay(() => append(singleton(["minHeight", minHeight]), delay(() => append(singleton(["maxHeight", minHeight]), delay(() => {
        switch (width.tag) {
            case 1:
            case 2: {
                return singleton(["width", 40 + "%"]);
            }
            default: {
                return empty();
            }
        }
    })))))))], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "py-0"], ["className", join(" ", ["navbar-catalogue-item", "is-size-6-mobile", "overflowHidden", "navbarAuctionTitle"])], ["className", "is-uppercase"], ["children", (Localized_translate(state.AuctionInfo.HeadInfo.Head) + " ") + Localized_formatDate("d MMMM", "d MMMM", state.AuctionInfo.Date)]]), createElement("div", createObj(Helpers_combineClasses("navbar-item", props)))), (props_3 = ofArray([["style", {
        height: minHeight,
        color: "#cdcdcd",
        padding: ((((((6 + "px ") + 24) + "px ") + 8) + "px ") + 24) + "px",
    }], ["className", "has-text-grey-light"], ["onClick", (_arg1_1) => {
        setActive(!isActive);
    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-align-right", "fa-lg", "catalogMenuMobile"]),
    })])]]), createElement("a", createObj(Helpers_combineClasses("navbar-burger", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("navbar-brand", props_5)))), (props_10 = toList(delay(() => append(isActive ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", join(" ", ["navbar-menu-white", "navbar-menu-mobile"])]), delay(() => append(singleton(["style", {
        minHeight: minHeight,
    }]), delay(() => {
        let elms, props_7;
        return singleton(["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_7 = toList(delay(() => append(singleton(["className", join(" ", ["hovered-level-item"])]), delay(() => append(singleton(["style", {
            color: "#444444",
            textAlign: "center",
        }]), delay(() => append(singleton(["className", "is-uppercase"]), delay(() => {
            const matchValue_2 = state.AuctionInfo.Status;
            switch (matchValue_2.tag) {
                case 1: {
                    return append(singleton(["children", p("auction.active")]), delay(() => singleton(["href", toPath(new Route(2, state.AuctionId))])));
                }
                case 2: {
                    return append(singleton(["children", p("auction.active")]), delay(() => singleton(["href", toPath(new Route(2, state.AuctionId))])));
                }
                case 3: {
                    return append(singleton(["children", p("auction.live")]), delay(() => singleton(["href", toPath(new Route(15))])));
                }
                default: {
                    return append(singleton(["children", p("auction.closed")]), delay(() => singleton(["href", toPath(new Route(15))])));
                }
            }
        })))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_7))))), createElement("div", {
            className: "navbar-start",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]);
    })))))))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_10))))])]]);
    return createElement("nav", createObj(Helpers_combineClasses("navbar", props_12)));
}

export function renderPreviewImage(img) {
    if (img != null) {
        const i = img;
        const props_1 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("img", {
            className: "previewImageCenter",
            src: ImageUrlModule_toString(Img_originalImageUrlInPng(i)),
            alt: "Placeholder image",
        })])]);
        return createElement("figure", createObj(Helpers_combineClasses("image", props_1)));
    }
    else {
        return null;
    }
}

export function renderHeaderWithImage(title, subtitle, image) {
    return createElement("div", {
        className: join(" ", toList(delay(() => (((title !== "") ? true : (subtitle !== "")) ? singleton("catalog-priority-title-box") : singleton("catalog-priority-image-box"))))),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(renderPreviewImage(image)), delay(() => {
            let props;
            return append((title !== "") ? singleton((props = ofArray([["style", {
                whiteSpace: "pre-line",
            }], ["className", join(" ", ["catalog-text-h1"])], ["children", title]]), createElement("p", createObj(Helpers_combineClasses("", props))))) : empty(), delay(() => {
                let props_2;
                return (subtitle !== "") ? singleton((props_2 = ofArray([["style", {
                    whiteSpace: "pre-line",
                }], ["className", join(" ", ["catalog-text-h2"])], ["children", subtitle]]), createElement("p", createObj(Helpers_combineClasses("", props_2))))) : empty();
            }));
        })))))),
    });
}

export function renderContentForAuctionPreview(state) {
    const children = toList(delay(() => {
        const titleMain = Localized_translate(state.SpecialOffersText.TitleMain);
        const subtitleMain = Localized_translate(state.SpecialOffersText.SubtitleMain);
        const titleLow = Localized_translate(state.SpecialOffersText.TitleLow);
        const subtitleLow = Localized_translate(state.SpecialOffersText.SubtitleLow);
        return append((((titleMain !== "") ? true : (subtitleMain !== "")) ? true : (!equals(state.Images.CatalogPriorityImage, void 0))) ? append(singleton(createElement("div", {
            className: join(" ", ["catalog-space-between-priorities"]),
        })), delay(() => singleton(renderHeaderWithImage(titleMain, subtitleMain, state.Images.CatalogPriorityImage)))) : empty(), delay(() => ((((titleLow !== "") ? true : (subtitleLow !== "")) ? true : (!equals(state.Images.LowPriorityImage, void 0))) ? append(singleton(createElement("div", {
            className: join(" ", ["catalog-space-between-priorities-before-market"]),
        })), delay(() => singleton(renderHeaderWithImage(titleLow, subtitleLow, state.Images.LowPriorityImage)))) : empty())));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function renderFirstPriorityForAuctionPreview(state) {
    const titleHigh = Localized_translate(state.SpecialOffersText.TitleHigh);
    const subtitleHigh = Localized_translate(state.SpecialOffersText.SubtitleHigh);
    return createElement("div", {
        className: join(" ", toList(delay(() => ((titleHigh === "") ? singleton("catalog-priority-image-box") : singleton("catalog-priority-title-box"))))),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(renderPreviewImage(state.Images.FirstPriorityImage)), delay(() => {
            let props;
            return append((titleHigh !== "") ? singleton((props = ofArray([["style", {
                whiteSpace: "pre-line",
            }], ["className", join(" ", ["catalog-text-h1"])], ["children", titleHigh]]), createElement("p", createObj(Helpers_combineClasses("", props))))) : empty(), delay(() => {
                let props_2;
                return (subtitleHigh !== "") ? singleton((props_2 = ofArray([["style", {
                    whiteSpace: "pre-line",
                }], ["className", join(" ", ["catalog-text-h2"])], ["children", subtitleHigh]]), createElement("p", createObj(Helpers_combineClasses("", props_2))))) : empty();
            }));
        })))))),
    });
}

export function render(user, state, width) {
    return createElement("div", {
        className: join(" ", ["background-container", "padding_inherit"]),
        children: Interop_reactApi.Children.toArray([createElement(Navbar, state), renderFirstPriorityForAuctionPreview(state), createElement("div", {
            className: join(" ", ["is-hidden-mobile"]),
            children: Interop_reactApi.Children.toArray([renderContentForAuctionPreview(state)]),
        }), createElement("div", {
            className: join(" ", ["is-hidden-tablet", "catalog-box"]),
            children: Interop_reactApi.Children.toArray([renderContentForAuctionPreview(state)]),
        }), createElement("div", {
            style: createObj(toList(delay(() => ((width.tag === 1) ? singleton(["width", 80 + "%"]) : ((width.tag === 0) ? singleton(["width", 80 + "%"]) : ((width.tag === 2) ? singleton(["width", 80 + "%"]) : singleton(["width", 50 + "%"]))))))),
            className: join(" ", ["container-marginLeftRight-auto"]),
            children: Interop_reactApi.Children.toArray([createElement("fieldset", {
                style: {
                    marginTop: 40,
                },
                children: Interop_reactApi.Children.toArray([createElement(View, {
                    user: user,
                    isSeparatePage: false,
                })]),
            })]),
        })]),
    });
}

export function RenderCatalog(renderCatalogInputProps) {
    let auctionId;
    const user = renderCatalogInputProps.user;
    const info = renderCatalogInputProps.info;
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const auctionPreviewInfo = useFeliz_React__React_useDeferred_Static_2344FC52((auctionId = info.Id, singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(lotsApi().getTextForAuctionId(auctionId), (_arg1) => singleton_2.Bind(Remoting_handleNonAuth(homeApi().getCatalogImages(auctionId)), (_arg2) => singleton_2.Return(new FSharpResult$2(0, {
        Images: _arg2,
        SpecialOffersText: _arg1,
    }))))), (_arg3) => singleton_2.Return(new FSharpResult$2(1, _arg3.message))))), [info.Id]);
    let pattern_matching_result, error, res, ex_1;
    if (auctionPreviewInfo.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (auctionPreviewInfo.tag === 3) {
        pattern_matching_result = 1;
        error = auctionPreviewInfo.fields[0];
    }
    else if (auctionPreviewInfo.tag === 2) {
        if (auctionPreviewInfo.fields[0].tag === 1) {
            pattern_matching_result = 3;
            ex_1 = auctionPreviewInfo.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
            res = auctionPreviewInfo.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = singleton_1(["children", Interop_reactApi.Children.toArray([mediumInline(h("loading"))])]);
            return createElement("section", createObj(Helpers_combineClasses("section", props)));
        }
        case 1: {
            const value_1 = error.message;
            return createElement("div", {
                children: [value_1],
            });
        }
        case 2: {
            return render(user, new State(info.Id, info, res.SpecialOffersText, res.Images), width);
        }
        case 3: {
            const value_2 = `${ex_1}`;
            return createElement("div", {
                children: [value_2],
            });
        }
    }
}

