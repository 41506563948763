import { Localized_formatDate, tWithArgs, t } from "../Localization.js";
import { createElement } from "react";
import * as react from "react";
import { interpolate, printf, toText, join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Lot_HistoryBids_tryCurrentValue, Lot_HistoryBids_isCurrentBidMetReservedPrice, MakeBid_Request, Lot_BidKind, Lot_HistoryBids_tryCurrent } from "../Shared/Bidding.js";
import { tryFind, truncate, tryLast, map as map_1, head, partition, isEmpty, sortByDescending, cons, ofArray, empty as empty_1, tail, item, tryHead, length, singleton } from "../fable_modules/fable-library.3.6.1/List.js";
import { Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { rgba } from "../fable_modules/Feliz.1.45.0/Colors.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { compareArrays, partialApply, equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { bind, map, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { Url_getCurrentUrlPage, Currency_format, Currency_currencySymbol } from "../Common.js";
import { AnimatedNumberType, View } from "../Components/AnimatedNumber.js";
import { CurrencyModule_fromInt, UserIdModule_toString, CurrencyModule_noneWhenZero } from "../bidflow/src/infrastructure/Auction.js";
import { useReact_useCallback_93353E, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { toString, equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { useFeliz_React__React_useLotBidding_Static_6DD843DB, Types_State__get_NextBid, Types_Msg } from "../Components/Bidding.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { biddingAuthApi } from "../Communication.js";
import { RenderBiddingButton, BiddingButtonProps, BiddingButtonState } from "../Components/Buttons.js";
import { Route, toPath } from "../Router.js";

export function p(key) {
    return t("bidding." + key);
}

export function ldt(key) {
    return t("lotDetails." + key);
}

export function delimiter(color) {
    return createElement("p", {
        style: {
            borderTop: (((1 + "px ") + "dashed") + " ") + color,
        },
    });
}

export const greyDelimiter = delimiter("#676565");

export function reservedPriceUnmet() {
    let value_1;
    return createElement("p", {
        className: join(" ", ["is-uppercase", "has-text-danger", "has-text-weight-bold", "is-size-6", "py-2"]),
        children: Interop_reactApi.Children.toArray([(value_1 = p("price.unmet"), createElement("span", {
            children: [value_1],
        }))]),
    });
}

export function renderMaximumBid(state) {
    let x_6, x_7, elms, value_7, _arg1, matchValue, x, x_1, arg10, x_2, x_3, arg10_1, l, l_1, x_5, arg10_2;
    const currentBid = Lot_HistoryBids_tryCurrent(state.Lot.History);
    const backgroundColor = (currentBid != null) ? (((x_6 = currentBid, x_6.IsMyBid ? x_6.ReservedPriceMet : false)) ? ((x_7 = currentBid, "has-background-success")) : "has-background-danger") : "has-background-danger";
    const matchValue_1 = state.Lot.MyLastAcceptedBid;
    if (matchValue_1 == null) {
        return null;
    }
    else {
        const x_8 = matchValue_1;
        return createElement("div", {
            className: join(" ", [backgroundColor, "lot-lead-dontlead", "has-text-white", "is-size-6", "is-uppercase", "my-1", "is-flex", "is-justify-content-center", "is-align-items-center", "p-1"]),
            style: {
                fontWeight: 600,
            },
            children: Interop_reactApi.Children.toArray([(elms = singleton(createElement("i", {
                className: join(" ", ["fa", "fa-gavel"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (value_7 = ((_arg1 = ((x_8.Limit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")), (matchValue = [currentBid, state.Lot.MyLastAcceptedBid], (matchValue[0] != null) ? ((matchValue[1] != null) ? (((x = matchValue[0], x.IsMyBid ? (!x.ReservedPriceMet) : false)) ? ((x_1 = matchValue[0], (arg10 = tWithArgs("bidding.your.bid.doesnt.meet.reserved.price", {
                bid: x_1.Bid,
            }), toText(printf("%s"))(arg10)))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((x_2 = matchValue[0], x_2.IsMyBid ? x_2.ReservedPriceMet : false)) ? ((x_3 = matchValue[0], (arg10_1 = p("your.bid.is.highest"), toText(printf("%s"))(arg10_1)))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : ""))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((x_2 = matchValue[0], x_2.IsMyBid ? x_2.ReservedPriceMet : false)) ? ((x_3 = matchValue[0], (arg10_1 = p("your.bid.is.highest"), toText(printf("%s"))(arg10_1)))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : ""))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((x_2 = matchValue[0], x_2.IsMyBid ? x_2.ReservedPriceMet : false)) ? ((x_3 = matchValue[0], (arg10_1 = p("your.bid.is.highest"), toText(printf("%s"))(arg10_1)))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : ""))))), createElement("span", {
                children: [value_7],
            }))]),
        });
    }
}

export class LotBiddingState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Bidding", "UnSold", "Sold"];
    }
}

export function LotBiddingState$reflection() {
    return union_type("Client.Auctions.LiveBidding.View.LotBiddingState", [], LotBiddingState, () => [[], [], []]);
}

export function renderPrice(props) {
    return createElement("div", {
        style: {
            backgroundColor: rgba(0, 0, 0, 0.3),
            textAlign: "center",
            marginBottom: 3,
            marginTop: 3,
            paddingTop: 10,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["className", "card-lot-aktuelBud"]), delay(() => append(singleton_1(["style", {
            color: "#ccc",
        }]), delay(() => {
            const matchValue = props.BiddingState;
            switch (matchValue.tag) {
                case 1: {
                    return singleton_1(["children", ldt("unsold.state")]);
                }
                case 2: {
                    return singleton_1(["children", ldt("finalPrice")]);
                }
                default: {
                    return singleton_1(["children", ldt("currentBid")]);
                }
            }
        }))))))))), delay(() => append(singleton_1(createElement("div", {
            key: defaultArg(map((arg00$0040) => ((arg00$0040).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")), props.CurrentBid), "-"),
            className: join(" ", ["card-lot-currentBid"]),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let x_4, x_2;
                let patternInput;
                if (props.User != null) {
                    const matchValue_1 = [props.MyLastAcceptedBid, props.History];
                    if (matchValue_1[0] != null) {
                        const matchValue_2 = Lot_HistoryBids_tryCurrent(matchValue_1[1]);
                        let pattern_matching_result;
                        if (matchValue_2 != null) {
                            if (!matchValue_2.IsMyBid) {
                                pattern_matching_result = 0;
                            }
                            else {
                                pattern_matching_result = 1;
                            }
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                patternInput = [true, false];
                                break;
                            }
                            case 1: {
                                let pattern_matching_result_1;
                                if (matchValue_2 != null) {
                                    if ((x_2 = matchValue_2, x_2.IsMyBid ? x_2.ReservedPriceMet : false)) {
                                        pattern_matching_result_1 = 0;
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                                switch (pattern_matching_result_1) {
                                    case 0: {
                                        patternInput = [false, true];
                                        break;
                                    }
                                    case 1: {
                                        let pattern_matching_result_2;
                                        if (matchValue_2 != null) {
                                            if ((x_4 = matchValue_2, x_4.IsMyBid ? (!x_4.ReservedPriceMet) : false)) {
                                                pattern_matching_result_2 = 0;
                                            }
                                            else {
                                                pattern_matching_result_2 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_2 = 1;
                                        }
                                        switch (pattern_matching_result_2) {
                                            case 0: {
                                                patternInput = [true, false];
                                                break;
                                            }
                                            case 1: {
                                                patternInput = [false, false];
                                                break;
                                            }
                                        }
                                        break;
                                    }
                                }
                                break;
                            }
                        }
                    }
                    else {
                        patternInput = [false, false];
                    }
                }
                else {
                    patternInput = [false, false];
                }
                const bid = defaultArg(props.CurrentBid, 0);
                const oldBid = (length(props.History) > 1) ? defaultArg(map((value_26) => value_26, map((x_6) => x_6.Bid, tryHead(((equals(item(0, props.History).Kind, new Lot_BidKind(1)) ? equals(item(1, props.History).Kind, new Lot_BidKind(0)) : false) ? (item(0, props.History).Bid === item(1, props.History).Bid) : false) ? tail(tail(props.History)) : tail(props.History)))), bid) : bid;
                const symbol = createElement("span", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["card-lot-currentBid-SEK-fontSize", "card-lot-currentBid"])]), delay(() => append(singleton_1(["style", {
                    fontWeight: 300,
                    marginLeft: 10,
                }]), delay(() => ((bid === 0) ? singleton_1(["children", ""]) : singleton_1(["children", `${Currency_currencySymbol()}`]))))))))));
                return singleton_1(createElement(View, {
                    number: bid,
                    oldNumber: oldBid,
                    currencySymbol: symbol,
                    typeOfComponent: new AnimatedNumberType(1),
                    needBlinkGreen: patternInput[1],
                    needBlinkRed: patternInput[0],
                    needBlink: true,
                    isActiveBiddingComponent: true,
                }));
            })))),
        })), delay(() => {
            let matchValue_3, estimate, value_44;
            return append((matchValue_3 = bind((x_7) => CurrencyModule_noneWhenZero(x_7), props.Estimate), (matchValue_3 != null) ? ((estimate = matchValue_3, singleton_1(createElement("p", {
                className: "card-lot-aktuelBud",
                style: {
                    color: "#ccc",
                    paddingBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([(value_44 = ldt("estimate"), createElement("span", {
                    children: [value_44],
                })), createElement("strong", {
                    style: {
                        color: "#ccc",
                    },
                    children: Currency_format(estimate),
                })]),
            })))) : singleton_1(createElement("div", {
                className: join(" ", ["card-lot-estimate-zero"]),
            }))), delay(() => ((!props.IsReservedPriceMet) ? append(singleton_1(greyDelimiter), delay(() => singleton_1(reservedPriceUnmet()))) : empty())));
        })))))))),
    });
}

export function RenderBiddingHistory(renderBiddingHistoryInputProps) {
    let counts, props_16, s_2;
    const history = renderBiddingHistoryInputProps.history;
    const hasReservedPrice = renderBiddingHistoryInputProps.hasReservedPrice;
    const myLastAcceptedBid = renderBiddingHistoryInputProps.myLastAcceptedBid;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showMoreBids = patternInput[0];
    const bid_2 = (color, x_2) => {
        let x_1, props_6, props_1, s, props_4;
        const date_1 = Localized_formatDate("d MMM HH:mm", "d MMM HH.mm", toLocalTime(x_2.Date));
        let bidInfo;
        const matchValue = x_2.Kind;
        let pattern_matching_result;
        if (matchValue.tag === 0) {
            if (x_2.IsMyBid) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                bidInfo = singleton(p("bidding.you"));
                break;
            }
            case 1: {
                if (matchValue.tag === 1) {
                    if (x_2.IsMyBid) {
                        bidInfo = singleton(p("bidding.agent.you"));
                    }
                    else if (matchValue.tag === 1) {
                        bidInfo = singleton(p("bidding.agent.manual"));
                    }
                    else {
                        throw (new Error("Match failure"));
                    }
                }
                else {
                    bidInfo = empty_1();
                }
                break;
            }
        }
        return createElement("div", {
            className: join(" ", toList(delay(() => append(singleton_1("bid-history-rows"), delay(() => {
                let bid;
                return defaultArg(map((x) => equals(x, x_2), Lot_HistoryBids_tryCurrent(history)), false) ? ((bid = x_2, (myLastAcceptedBid == null) ? empty_1() : ((bid.IsMyBid ? bid.ReservedPriceMet : false) ? singleton("blink-green-to-white") : ((!bid.IsMyBid) ? singleton("blink-red-to-white") : empty_1())))) : empty();
            }))))),
            key: (x_1 = x_2, toText(interpolate("%A%P()-%A%P()-%A%P()-%A%P()", [x_1.UserId, x_1.Kind, x_1.Bid, x_1.Bid]))),
            children: Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["text-in-circle", color]),
                children: UserIdModule_toString(x_2.UserId),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (s = join(" - ", cons(date_1, bidInfo)), createElement("div", {
                className: "column",
                children: s,
            })), (props_4 = ofArray([["className", "is-narrow"], ["className", join(" ", ["has-text-right"])], ["children", (x_2.Bid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))]),
        });
    };
    const looseBid = partialApply(1, bid_2, ["has-background-danger"]);
    const winBid = partialApply(1, bid_2, ["has-background-success"]);
    const history_3 = history;
    let x_10;
    const matchValue_1 = sortByDescending((x_5) => [x_5.Date, x_5.Bid], history_3, {
        Compare: (x_6, y) => compareArrays(x_6, y),
    });
    if (isEmpty(matchValue_1)) {
        x_10 = null;
    }
    else {
        const patternInput_1 = partition((x_7) => (!x_7.ReservedPriceMet), matchValue_1);
        const bidsBelowMinPrice = patternInput_1[0];
        const bidsAboveMinPrice = patternInput_1[1];
        const historyBids = toList(delay(() => append((!isEmpty(bidsAboveMinPrice)) ? (isEmpty(tail(bidsAboveMinPrice)) ? singleton_1(winBid(head(bidsAboveMinPrice))) : append(singleton_1(winBid(head(bidsAboveMinPrice))), delay(() => map_1(looseBid, tail(bidsAboveMinPrice))))) : ((empty())), delay(() => {
            let matchValue_2, reservedPriceMetAt, reservedPriceMetAt_1, text, props_12, props_10;
            return append((matchValue_2 = tryLast(bidsAboveMinPrice), (matchValue_2 != null) ? (((reservedPriceMetAt = matchValue_2, hasReservedPrice ? (length(bidsBelowMinPrice) >= 0) : false)) ? ((reservedPriceMetAt_1 = matchValue_2, singleton_1((text = p("price.met"), createElement("div", {
                className: join(" ", ["bid-history-rows"]),
                children: Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-vcentered"], ["className", "is-gapless"], ["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                    className: "column",
                    children: text,
                }), (props_10 = ofArray([["className", "is-narrow"], ["className", join(" ", ["has-text-right"])], ["children", (reservedPriceMetAt_1.Bid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_12))))]),
            }))))) : ((empty()))) : ((empty()))), delay(() => map_1(looseBid, bidsBelowMinPrice)));
        }))));
        const children = toList(delay(() => append(showMoreBids ? historyBids : truncate(3, historyBids), delay(() => {
            let props_22;
            return (length(historyBids) > 3) ? singleton_1(createElement("div", {
                className: join(" ", ["mt-3"]),
                children: Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-fullwidth"], ["style", {
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                }], ["className", join(" ", ["has-text-white"])], ["onClick", (_arg1) => {
                    patternInput[1](!showMoreBids);
                }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    style: {
                        marginRight: 20,
                    },
                    className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => ((!showMoreBids) ? singleton_1("fa-chevron-down") : singleton_1("fa-chevron-up"))))))),
                }), createElement("span", {
                    className: join(" ", ["has-text-weight-normal", "has-text-letter-spacing-1"]),
                    children: (!showMoreBids) ? p("all.bids") : p("recent.bids"),
                }), createElement("i", {
                    style: {
                        marginLeft: 20,
                    },
                    className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => ((!showMoreBids) ? singleton_1("fa-chevron-down") : singleton_1("fa-chevron-up"))))))),
                })])]]), createElement("span", createObj(Helpers_combineClasses("button", props_22))))]),
            })) : empty();
        }))));
        x_10 = createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    const children_2 = ofArray([(counts = (length(history_3) | 0), createElement("div", {
        className: join(" ", ["bid-history-rows"]),
        style: {
            color: "#ccc",
        },
        children: Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-vcentered"], ["className", "is-gapless"], ["children", Interop_reactApi.Children.toArray([(s_2 = tWithArgs("bidding.bid.history", {
            count: counts,
        }), createElement("div", {
            className: "column",
            children: s_2,
        }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_16))))]),
    })), x_10]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function printMyLastAcceptedBid(state, userId) {
    const matchValue = [state.Lot.MyLastAcceptedBid, state.Lot.History];
    if (matchValue[0] == null) {
        if (isEmpty(matchValue[1])) {
            return "";
        }
        else {
            const matchValue_1 = tryFind((x_1) => equals_1(x_1.UserId, userId), matchValue[1]);
            if (matchValue_1 == null) {
                return "";
            }
            else {
                const x_2 = matchValue_1;
                return tWithArgs("bidding.last.bid", {
                    sum: (x_2.Bid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    time: Localized_formatDate("d MMM yyyy KK:mm:ss", "d MMM yyyy KK.mm.ss", toLocalTime(x_2.Date)),
                });
            }
        }
    }
    else {
        const x = matchValue[0];
        return tWithArgs("bidding.last.bid", {
            sum: (x.Limit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            time: Localized_formatDate("d MMM yyyy KK:mm:ss", "d MMM yyyy KK.mm.ss", toLocalTime(x.Date)),
        });
    }
}

export function printNotAllowedToBidDueToUserIsOwner(state) {
    if (state.Lot.CurrentUserIsOwner) {
        const props = ofArray([["className", join(" ", ["has-text-danger", "is-size-6", "pb-1"])], ["children", p("biddingNotAllowedOnOwnLot")]]);
        return createElement("div", createObj(Helpers_combineClasses("field", props)));
    }
    else {
        return null;
    }
}

export function RenderBidForm(renderBidFormInputProps) {
    let elms, props_4;
    const dispatch = renderBidFormInputProps.dispatch;
    const state = renderBidFormInputProps.state;
    const userId = renderBidFormInputProps.userId;
    const lotId = renderBidFormInputProps.lotId;
    useReact_useEffect_Z101E1A95(() => {
        dispatch(new Types_Msg(6, false));
    }, [Types_State__get_NextBid(state)]);
    const onClickCallback = useReact_useCallback_93353E((arg) => {
        let tupledArg;
        startImmediate((tupledArg = arg, singleton_2.Delay(() => {
            dispatch(new Types_Msg(1));
            return singleton_2.Bind(Remoting_handleNonAuth(biddingAuthApi().makeBid(new MakeBid_Request(tupledArg[0], tupledArg[1]))), (_arg1) => {
                const response = _arg1;
                return singleton_2.Combine((response.tag === 1) ? ((dispatch(new Types_Msg(3, response.fields[0])), singleton_2.Zero())) : ((dispatch(new Types_Msg(2, response.fields[0])), singleton_2.Zero())), singleton_2.Delay(() => {
                    dispatch(new Types_Msg(6, false));
                    return singleton_2.Zero();
                }));
            });
        })));
    });
    const onClick = () => {
        const matchValue = Types_State__get_NextBid(state);
        if (matchValue == null) {
        }
        else if (state.Confirm) {
            onClickCallback([lotId, matchValue]);
        }
        else {
            dispatch(new Types_Msg(6, true));
        }
    };
    const onChange = state.Confirm ? ((value) => {
    }) : ((arg_2) => {
        dispatch(new Types_Msg(0, arg_2));
    });
    let bidButtonProps;
    const matchValue_1 = state.Lot.Status;
    switch (matchValue_1.tag) {
        case 3: {
            bidButtonProps = (new BiddingButtonProps((value_5) => {
            }, state.Bid, () => {
            }, t("auctionClosed.tag"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            break;
        }
        case 1: {
            if (state.Lot.CurrentUserIsOwner) {
                bidButtonProps = (new BiddingButtonProps((value_7) => {
                }, state.Bid, () => {
                }, p("button.blocked"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            }
            else {
                const State_3 = state.Confirm ? (new BiddingButtonState(2)) : (new BiddingButtonState(1));
                bidButtonProps = (new BiddingButtonProps(onChange, state.Bid, onClick, state.Confirm ? p("button.bidding") : p("button.unlock"), State_3, state.Confirm, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            }
            break;
        }
        case 2: {
            if (state.Lot.CurrentUserIsWinner ? true : state.Lot.CurrentUserIsOwner) {
                bidButtonProps = (new BiddingButtonProps((value_9) => {
                }, state.Bid, () => {
                }, p("button.blocked"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            }
            else {
                const State_5 = state.Confirm ? (new BiddingButtonState(2)) : (new BiddingButtonState(1));
                bidButtonProps = (new BiddingButtonProps(onChange, state.Bid, onClick, state.Confirm ? p("button.bidding") : p("button.bid"), State_5, state.Confirm, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            }
            break;
        }
        default: {
            bidButtonProps = (new BiddingButtonProps((value_3) => {
            }, state.Bid, () => {
            }, p("button.blocked"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
        }
    }
    return createElement("fieldset", {
        className: "mb-0",
        className: "is-uppercase",
        style: {
            paddingTop: 30,
        },
        children: Interop_reactApi.Children.toArray([(elms = toList(delay(() => append(singleton_1(createElement(RenderBiddingButton, (($value) => { $value.key = $value.Key; return $value; })(bidButtonProps))), delay(() => append((state.Error === "") ? singleton_1(null) : singleton_1(createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "my-1"], ["className", "py-1"], ["className", "px-1"], ["className", "is-size-6"], ["className", "has-text-centered"], ["className", "has-text-white"], ["className", "has-background-danger"], ["children", state.Error]]))))), delay(() => singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["style", {
            fontSize: 14 + "px",
            marginTop: 10,
        }]), delay(() => {
            let matchValue_3;
            return singleton_1(["children", tWithArgs("bidding.minimum.bid", {
                sum: (matchValue_3 = Types_State__get_NextBid(state), (matchValue_3 == null) ? "-" : Currency_format(CurrencyModule_fromInt(matchValue_3))),
            })]);
        }))))))))))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_4 = ofArray([["className", join(" ", ["has-text-grey", "is-size-6"])], ["children", printMyLastAcceptedBid(state, userId)]]), createElement("div", createObj(Helpers_combineClasses("field", props_4)))), printNotAllowedToBidDueToUserIsOwner(state)]),
    });
}

export function Render(renderInputProps) {
    let elms_1;
    const isTablet = renderInputProps.isTablet;
    const hasReservedPrice = renderInputProps.hasReservedPrice;
    const userId = renderInputProps.userId;
    const estimate = renderInputProps.estimate;
    const lotId = renderInputProps.lotId;
    const patternInput = useFeliz_React__React_useLotBidding_Static_6DD843DB(lotId, userId);
    const state = patternInput[0];
    let renderBidFormLock;
    let bidButtonProps;
    const matchValue = state.Lot.Status;
    switch (matchValue.tag) {
        case 3: {
            bidButtonProps = (new BiddingButtonProps((value_3) => {
            }, state.Bid, () => {
            }, t("orders.filter.finished"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            break;
        }
        case 1:
        case 2: {
            bidButtonProps = (new BiddingButtonProps((value_4) => {
            }, state.Bid, () => {
                window.location.href = toPath(new Route(6, Url_getCurrentUrlPage()));
            }, t("changePassword.button.login"), new BiddingButtonState(1), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            break;
        }
        default: {
            bidButtonProps = (new BiddingButtonProps((value_1) => {
            }, state.Bid, () => {
            }, p("button.unlock"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
        }
    }
    renderBidFormLock = createElement("div", {
        style: {
            textTransform: "uppercase",
            paddingTop: 30,
        },
        children: Interop_reactApi.Children.toArray([(elms_1 = toList(delay(() => {
            let elms;
            return append(singleton_1((elms = singleton(createElement(RenderBiddingButton, (($value) => { $value.key = $value.Key; return $value; })(bidButtonProps))), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => append((state.Error === "") ? singleton_1(null) : singleton_1(createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "my-1"], ["className", "py-1"], ["className", "px-1"], ["className", "is-size-6"], ["className", "has-text-centered"], ["className", "has-text-white"], ["className", "has-background-danger"], ["children", state.Error]]))))), delay(() => singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["style", {
                fontSize: 14 + "px",
            }]), delay(() => {
                let matchValue_2;
                return singleton_1(["children", tWithArgs("bidding.minimum.bid", {
                    sum: (matchValue_2 = Types_State__get_NextBid(state), (matchValue_2 == null) ? "-" : Currency_format(CurrencyModule_fromInt(matchValue_2))),
                })]);
            })))))))))));
        })), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]),
    });
    const isReservedPriceMet = isEmpty(state.Lot.History) ? true : Lot_HistoryBids_isCurrentBidMetReservedPrice(state.Lot.History);
    return react.createElement(react.Fragment, {}, createElement("div", createObj(toList(delay(() => append(singleton_1(["className", "live-auction-card-content"]), delay(() => append(isTablet ? singleton_1(["style", {
        paddingLeft: 10,
    }]) : empty(), delay(() => {
        let matchValue_3, props_6;
        return singleton_1(["children", Interop_reactApi.Children.toArray([renderMaximumBid(state), renderPrice({
            BiddingState: (matchValue_3 = state.Lot.Status, (matchValue_3.tag === 1) ? (new LotBiddingState(0)) : ((matchValue_3.tag === 2) ? (new LotBiddingState(0)) : ((matchValue_3.tag === 3) ? (isReservedPriceMet ? (new LotBiddingState(2)) : ((matchValue_3.tag === 3) ? (new LotBiddingState(1)) : (() => {
                throw (new Error("Match failure"));
            })())) : (new LotBiddingState(1))))),
            CurrentBid: Lot_HistoryBids_tryCurrentValue(state.Lot.History),
            Estimate: estimate,
            History: state.Lot.History,
            IsReservedPriceMet: isReservedPriceMet,
            LotId: lotId,
            MyLastAcceptedBid: state.Lot.MyLastAcceptedBid,
            User: userId,
        }), (props_6 = ofArray([["className", "margin-live-auction-before-history"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            if (userId == null) {
                return singleton_1(renderBidFormLock);
            }
            else {
                const userId_1 = userId;
                return singleton_1(createElement(RenderBidForm, {
                    lotId: lotId,
                    userId: userId_1,
                    state: state,
                    dispatch: patternInput[1],
                }));
            }
        }))))]]), createElement("div", createObj(Helpers_combineClasses("block", props_6)))), createElement(RenderBiddingHistory, {
            myLastAcceptedBid: state.Lot.MyLastAcceptedBid,
            hasReservedPrice: hasReservedPrice,
            history: state.Lot.History,
        })])]);
    })))))))));
}

