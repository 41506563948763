import { Record, Union } from "../fable-library.3.6.1/Types.js";
import { record_type, int32_type, union_type } from "../fable-library.3.6.1/Reflection.js";
import { React_createDisposable_3A5B6456, useReact_useEffect_Z5234A374, useReact_useState_FCFD9EF } from "../Feliz.1.45.0/React.fs.js";
import { defaultArg } from "../fable-library.3.6.1/Option.js";

export class ScreenSize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Mobile", "MobileLandscape", "Tablet", "Desktop", "WideScreen"];
    }
}

export function ScreenSize$reflection() {
    return union_type("Feliz.UseMediaQuery.ScreenSize", [], ScreenSize, () => [[], [], [], [], []]);
}

export class Breakpoints extends Record {
    constructor(MobileLandscape, Tablet, Desktop, WideScreen) {
        super();
        this.MobileLandscape = (MobileLandscape | 0);
        this.Tablet = (Tablet | 0);
        this.Desktop = (Desktop | 0);
        this.WideScreen = (WideScreen | 0);
    }
}

export function Breakpoints$reflection() {
    return record_type("Feliz.UseMediaQuery.Breakpoints", [], Breakpoints, () => [["MobileLandscape", int32_type], ["Tablet", int32_type], ["Desktop", int32_type], ["WideScreen", int32_type]]);
}

export const BreakpointsModule_defaults = new Breakpoints(576, 768, 1024, 1216);

function makeQueries(breakpoints) {
    return [("(max-width: " + breakpoints.MobileLandscape) + "px)", ("(max-width: " + breakpoints.Tablet) + "px)", ("(max-width: " + breakpoints.Desktop) + "px)", ("(max-width: " + breakpoints.WideScreen) + "px)"];
}

export function useFeliz_React__React_useMediaQuery_Static_Z721C83C5(mediaQuery) {
    const patternInput = useReact_useState_FCFD9EF(() => (window.matchMedia(mediaQuery)).matches);
    useReact_useEffect_Z5234A374(() => {
        const mediaQueryList = window.matchMedia(mediaQuery);
        const handler = () => {
            patternInput[1](mediaQueryList.matches);
        };
        handler();
        (mediaQueryList).addListener(handler);
        return React_createDisposable_3A5B6456(() => {
            (mediaQueryList).removeListener(handler);
        });
    }, [mediaQuery]);
    return patternInput[0];
}

export function useFeliz_React__React_useResponsive_Static_Z1648B8FF(breakpoints) {
    const patternInput = makeQueries(defaultArg(breakpoints, BreakpointsModule_defaults));
    const matchValue = [useFeliz_React__React_useMediaQuery_Static_Z721C83C5(patternInput[0]), useFeliz_React__React_useMediaQuery_Static_Z721C83C5(patternInput[1]), useFeliz_React__React_useMediaQuery_Static_Z721C83C5(patternInput[2]), useFeliz_React__React_useMediaQuery_Static_Z721C83C5(patternInput[3])];
    if (matchValue[0]) {
        return new ScreenSize(0);
    }
    else if (matchValue[1]) {
        return new ScreenSize(1);
    }
    else if (matchValue[2]) {
        return new ScreenSize(2);
    }
    else if (matchValue[3]) {
        return new ScreenSize(3);
    }
    else {
        return new ScreenSize(4);
    }
}

