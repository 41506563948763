import { t } from "../Localization.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { append, singleton, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function p(key) {
    return t("auctionClosed." + key);
}

export function lAF(key) {
    return t("lotDetails." + key);
}

export function page(isConnected) {
    return createElement("div", {
        className: join(" ", ["home-box", "auctionClosed-container"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_4, props_1, s;
            return (!isConnected) ? singleton((props_4 = ofArray([["className", "mt-3"], ["className", "is-centered"], ["className", "has-text-centered"], ["className", "has-text-white"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "pr-3"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-unlink"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_1)))), (s = lAF("noConnection"), createElement("span", {
                className: "",
                children: s,
            }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_4))))) : append(singleton(createElement("p", {
                className: "auctionClosed-title",
                children: p("title.1"),
            })), delay(() => singleton(createElement("p", {
                className: "auctionClosed-subtitle",
                children: p("subtitle"),
            }))));
        })))),
    });
}

