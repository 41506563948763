import React, { Component, useState, useEffect  } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const ZoomPanPinch = ({ src }) => {
    const { height, width } = useWindowDimensions();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      });
    const buttonWrapper = {
      position: "fixed",
      bottom:40,
      left:20,
      zIndex: 100
    };
    const buttonStyle = {
        width: 30,
        height: 30,
        marginRight: 5,
        fontSize: 15,
        cursor: 'pointer'
    };
    const customStyle = {
        height:'100%',
        overflow: 'visible'
    };
    if (windowWidth<768) {
        return (
            <TransformWrapper
              initialScale={0.75}
              initialPositionX={width*0.125} 
              initialPositionY={height*0.25}
              minScale={0.75}
              centerZoomedOut={true}  
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <div className="tools" style = {buttonWrapper}>
                    <button style={buttonStyle} onClick={() => zoomIn()}><i class="fas fa-search-plus"></i></button>
                    <button style={buttonStyle} onClick={() => zoomOut()}><i class="fas fa-search-minus"></i></button>
                    <button style={buttonStyle} onClick={() => resetTransform()}><i class="fas fa-compress"></i></button>
                  </div>
                  <TransformComponent wrapperStyle={customStyle}>
                    <img src={src} alt="test" />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
        );
    }
    return (
        <TransformWrapper
          initialScale={0.75}
          initialPositionX={width*0.125} 
          initialPositionY={0}
          minScale={0.75}
          centerZoomedOut={true}   
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div className="tools" style = {buttonWrapper}>
                <button style={buttonStyle} onClick={() => zoomIn()}><i class="fas fa-search-plus"></i></button>
                <button style={buttonStyle} onClick={() => zoomOut()}><i class="fas fa-search-minus"></i></button>
                <button style={buttonStyle} onClick={() => resetTransform()}><i class="fas fa-compress"></i></button>
              </div>
              <TransformComponent wrapperStyle={customStyle}>
                <img src={src} alt="test" />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
    );
};