import { t } from "./Localization.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { Users_AccountType } from "./Shared/Shared.js";
import { createElement } from "react";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Component_renderCompanyRegistrationFields } from "./RegisterCompanyAccount.js";
import { Component_renderPersonalRegistrationFields } from "./RegisterPersonalAccount.js";

export function p(key) {
    return t("createAccount." + key);
}

export function b(key) {
    return t("profileSettings." + key);
}

export function CreateAccount() {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Users_AccountType(0));
    const setAccountType = patternInput[1];
    const accountType = patternInput[0];
    return createElement("div", {
        className: join(" ", ["background-container-login-forgot-create", "create-account-column"]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", ["create-account-box"]),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("p", {
                className: "create-account-title",
                children: p("title"),
            })), delay(() => {
                let props_8, children, props_2, props_5;
                return append(singleton((props_8 = ofArray([["className", "is-toggle"], ["className", "is-medium"], ["className", "is-centered"], ["className", "is-fullwidth"], ["className", "my-5"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_2 = toList(delay(() => append(equals(accountType, new Users_AccountType(0)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    children: p("account.personal"),
                    onClick: (_arg1) => {
                        setAccountType(new Users_AccountType(0));
                    },
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_2)))), (props_5 = toList(delay(() => append(equals(accountType, new Users_AccountType(1)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    children: p("account.company"),
                    onClick: (x) => {
                        x.preventDefault();
                        setAccountType(new Users_AccountType(1));
                    },
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_5))))]), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_8))))), delay(() => ((accountType.tag === 1) ? singleton(createElement(Component_renderCompanyRegistrationFields, null)) : singleton(createElement(Component_renderPersonalRegistrationFields, null)))));
            })))))),
        })]),
    });
}

