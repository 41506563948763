import { Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, bool_type, string_type, option_type, int32_type, lambda_type, unit_type, union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { parse } from "../fable_modules/fable-library.3.6.1/Int32.js";

export class BiddingButtonState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Disabled", "Blocked", "Active"];
    }
}

export function BiddingButtonState$reflection() {
    return union_type("Components.ButtonsInCatalog.BiddingButtonState", [], BiddingButtonState, () => [[], [], []]);
}

export class BiddingButtonInCatalogProps extends Record {
    constructor(Cancel, OnChange, Value, OnClick, ButtonText, State, Confirm, Key, IsLoading) {
        super();
        this.Cancel = Cancel;
        this.OnChange = OnChange;
        this.Value = Value;
        this.OnClick = OnClick;
        this.ButtonText = ButtonText;
        this.State = State;
        this.Confirm = Confirm;
        this.Key = Key;
        this.IsLoading = IsLoading;
    }
}

export function BiddingButtonInCatalogProps$reflection() {
    return record_type("Components.ButtonsInCatalog.BiddingButtonInCatalogProps", [], BiddingButtonInCatalogProps, () => [["Cancel", lambda_type(unit_type, unit_type)], ["OnChange", lambda_type(int32_type, unit_type)], ["Value", option_type(int32_type)], ["OnClick", lambda_type(unit_type, unit_type)], ["ButtonText", string_type], ["State", BiddingButtonState$reflection()], ["Confirm", bool_type], ["Key", string_type], ["IsLoading", bool_type]]);
}

export function RenderBiddingButtonInCatalog(props) {
    let props_10, props_8;
    const props_12 = ofArray([["key", props.Key], ["children", Interop_reactApi.Children.toArray([(props_10 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", join(" ", ["has-icons-left"])], ["style", {
        display: "flex",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_3, props_1;
        return append(props.Confirm ? singleton((props_3 = ofArray([["className", "cancelButtonInInputLotDetails"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", join(" ", ["is-vcentered"])], ["style", {
            verticalAlign: "baseline",
        }], ["onClick", (_arg1) => {
            props.Cancel();
        }]]), createElement("button", createObj(Helpers_combineClasses("delete", props_1))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3))))) : singleton(null), delay(() => append(singleton(createElement("input", createObj(toList(delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("input"), delay(() => append(singleton("card-lot-bid"), delay(() => {
            if (props.Value != null) {
                const matchValue_2 = props.State;
                switch (matchValue_2.tag) {
                    case 1:
                    case 2: {
                        return props.Confirm ? singleton("inputInCatalogActiveConfirmed") : singleton("inputInCatalogActiveNonConfirmed");
                    }
                    default: {
                        return singleton("inputInCatalogDisable");
                    }
                }
            }
            else {
                return singleton("inputInCatalogDisable");
            }
        })))))))]), delay(() => append(singleton(["type", "number"]), delay(() => {
            let matchValue_4;
            return append((matchValue_4 = props.State, (matchValue_4.tag === 1) ? (props.Confirm ? singleton(["readOnly", true]) : singleton(["onChange", (ev) => {
                props.OnChange(parse(ev.target.value, 511, false, 32));
            }])) : ((matchValue_4.tag === 2) ? (props.Confirm ? singleton(["readOnly", true]) : singleton(["onChange", (ev) => {
                props.OnChange(parse(ev.target.value, 511, false, 32));
            }])) : singleton(["readOnly", true]))), delay(() => {
                let value_21;
                const matchValue_6 = props.Value;
                if (matchValue_6 == null) {
                    return empty();
                }
                else {
                    return singleton((value_21 = (matchValue_6 | 0), ["ref", (e) => {
                        if ((!(e == null)) ? (!equals(e.value, value_21)) : false) {
                            e.value = (value_21 | 0);
                        }
                    }]));
                }
            }));
        }))))))))), delay(() => singleton(createElement("button", createObj(toList(delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("search-button"), delay(() => append(singleton("home-box"), delay(() => append(singleton("button-in-lot-details"), delay(() => {
            if (props.Value != null) {
                const matchValue_8 = props.State;
                switch (matchValue_8.tag) {
                    case 1:
                    case 2: {
                        return singleton("buttonInLotDetailsActive");
                    }
                    default: {
                        return singleton("buttonInLotDetailsDisable");
                    }
                }
            }
            else {
                return singleton("buttonInLotDetailsDisable");
            }
        })))))))))]), delay(() => append(props.IsLoading ? append(singleton(["disabled", true]), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: "has-text-white",
            className: join(" ", ["fa", "fa-circle-notch", "fa-pulse"]),
        })])]))) : singleton(["children", props.ButtonText]), delay(() => singleton(["onClick", (_arg2) => {
            props.OnClick();
        }]))))))))))))));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_10))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("control", props_12)));
}

