import { getCurrentLanguage, t as t_4 } from "./Localization.js";
import { Record, Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { record_type, option_type, int32_type, union_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { LiveAuction_StateModule_trySecondsElapsed, LiveAuction_StateModule_$007CAuctionNotStarted$007CAuctionStarted$007CAuctionFinished$007CCurrentLotBidChanged$007CCurrentLotBiddingFinished$007CNextLotBiddingStarted$007C, LiveAuction_State, StreamFrom_Response$reflection, LiveAuction_State$reflection } from "./Shared/Bidding.js";
import { Remoting_LiveBidding_notifyLotBidding, Remoting_ordersChanged } from "./Extensions.js";
import { ofArray, singleton } from "./fable_modules/fable-library.3.6.1/List.js";
import { Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { bind, map, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Img_onImageLoadFail, Img_lotMinimumImagePath } from "./Css.js";
import { CurrencyModule_toInt, CurrencyModule_noneWhenZero, ImageUrlModule_toString, ImageUrlModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { equals, int32ToString, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { State__Elapsed, update as update_1, init as init_1, State__ElapsedSeconds } from "./Components/Timer.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7, useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { op_Subtraction, addSeconds, now } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { totalSeconds, seconds as seconds_1 } from "./fable_modules/fable-library.3.6.1/TimeSpan.js";
import { Route, toPath } from "./Router.js";
import { singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { Users_User, Users_UserModule_userId, StatusAuction, Resource$1 } from "./Shared/Shared.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { singleton as singleton_2 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { homeApi } from "./Communication.js";
import { Currency_currencySymbol, LotPrefix_getPrefix } from "./Common.js";
import { Feliz_React__React_useLiveAuctionStream_Static_Z2812504D } from "./Components/SignalR.js";
import { getUser } from "./LocalStorage.js";

export function lAF(key) {
    return t_4("lotDetails." + key);
}

export function p(key) {
    return t_4("auctionClosed." + key);
}

export function h(key) {
    return t_4("home." + key);
}

export class Types_Visibility extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Hidden", "Visible"];
    }
}

export function Types_Visibility$reflection() {
    return union_type("LiveAuctionFooter.Types.Visibility", [], Types_Visibility, () => [[], []]);
}

export class Types_State extends Record {
    constructor(VisibleState, Auction, TimeElapsed) {
        super();
        this.VisibleState = VisibleState;
        this.Auction = Auction;
        this.TimeElapsed = TimeElapsed;
    }
}

export function Types_State$reflection() {
    return record_type("LiveAuctionFooter.Types.State", [], Types_State, () => [["VisibleState", Types_Visibility$reflection()], ["Auction", LiveAuction_State$reflection()], ["TimeElapsed", option_type(int32_type)]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FromServer"];
    }
}

export function Types_Msg$reflection() {
    return union_type("LiveAuctionFooter.Types.Msg", [], Types_Msg, () => [[["Item", StreamFrom_Response$reflection()]]]);
}

export function Types_Cmd_notifyOrderChanged(_arg1) {
    let pattern_matching_result, s_1;
    if (_arg1.tag === 1) {
        if (_arg1.fields[0].Lot.CurrentUserIsWinner) {
            pattern_matching_result = 0;
            s_1 = _arg1.fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return singleton((_arg1_1) => {
                Remoting_ordersChanged(s_1.Lot.AuctionId, s_1.Lot.Id);
            });
        }
        case 1: {
            return singleton((value) => {
            });
        }
    }
}

export function Types_Cmd_notifyCurrentLotBidding(_arg1) {
    switch (_arg1.tag) {
        case 2:
        case 0: {
            return singleton((value) => {
            });
        }
        default: {
            return singleton((_arg1_1) => {
                Remoting_LiveBidding_notifyLotBidding(_arg1.fields[0].Lot.Id);
            });
        }
    }
}

export function State_init() {
    return [new Types_State(new Types_Visibility(0), new LiveAuction_State(0), void 0), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.fields[0].tag === 2) {
        const next = msg.fields[0].fields[0];
        let patternInput;
        const matchValue = [state.Auction, next];
        const activePatternResult26705 = LiveAuction_StateModule_$007CAuctionNotStarted$007CAuctionStarted$007CAuctionFinished$007CCurrentLotBidChanged$007CCurrentLotBiddingFinished$007CNextLotBiddingStarted$007C(matchValue[0], matchValue[1]);
        patternInput = ((activePatternResult26705.tag === 1) ? [new Types_State(new Types_Visibility(1), next, LiveAuction_StateModule_trySecondsElapsed(next)), Cmd_none()] : ((activePatternResult26705.tag === 2) ? [new Types_State(new Types_Visibility(0), next, LiveAuction_StateModule_trySecondsElapsed(next)), Cmd_none()] : ((activePatternResult26705.tag === 3) ? [new Types_State(state.VisibleState, next, LiveAuction_StateModule_trySecondsElapsed(next)), Cmd_none()] : ((activePatternResult26705.tag === 5) ? [new Types_State(new Types_Visibility(1), next, LiveAuction_StateModule_trySecondsElapsed(next)), Cmd_none()] : ((activePatternResult26705.tag === 4) ? [new Types_State(new Types_Visibility(0), next, LiveAuction_StateModule_trySecondsElapsed(next)), Types_Cmd_notifyOrderChanged(next)] : [state, Cmd_none()])))));
        return [patternInput[0], Cmd_batch(ofArray([patternInput[1], Types_Cmd_notifyCurrentLotBidding(next)]))];
    }
    else {
        return [state, Cmd_none()];
    }
}

export function View_renderLotImage(image) {
    let props_1;
    const url = defaultArg(map(Img_lotMinimumImagePath, image), ImageUrlModule_empty);
    const elms = singleton((props_1 = ofArray([["className", "footer-live-auction-image"], ["style", {
        verticalAlign: "middle",
    }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
        src: ImageUrlModule_toString(url),
        onError: (x_1) => {
            Img_onImageLoadFail(x_1);
        },
    })])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_1)))));
    return createElement("div", {
        className: "card-image",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function View_timer(view_timerInputProps) {
    let copyOfStruct, t_2, props;
    const dependencies = view_timerInputProps.dependencies;
    const time = view_timerInputProps.time;
    let elapsed;
    const matchValue = State__ElapsedSeconds(useFeliz_React__React_useElmish_Static_17DC4F1D(init_1((copyOfStruct = now(), addSeconds(copyOfStruct, time)), 1000), (msg, state) => update_1(msg, state), dependencies)[0]) | 0;
    if (time <= 0) {
        elapsed = lAF("end");
    }
    else if (matchValue >= 60) {
        const t_1 = matchValue | 0;
        const minutes = (~(~(t_1 / 60))) | 0;
        const sec = (t_1 - (minutes * 60)) | 0;
        const hideZero = (sec >= 10) ? "" : "0";
        elapsed = (`${minutes} : ${hideZero}${sec}`);
    }
    else {
        elapsed = (((t_2 = (matchValue | 0), (t_2 < 60) ? (t_2 >= 1) : false)) ? int32ToString(matchValue) : "1");
    }
    const children = ofArray([(props = singleton(["className", join(" ", ["far", "fa-clock"])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("span", {
        style: {
            paddingLeft: 8,
        },
        children: elapsed,
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function View_timerBefore(view_timerBeforeInputProps) {
    let copyOfStruct, copyOfStruct_1, copyOfStruct_2, copyOfStruct_3, copyOfStruct_4, props_22, props_8, props_11, props_20, props_18, props_16, value_43, elms;
    const isConnected = view_timerBeforeInputProps.isConnected;
    const time = view_timerBeforeInputProps.time;
    const state_1 = useFeliz_React__React_useElmish_Static_17DC4F1D(init_1((copyOfStruct = now(), addSeconds(copyOfStruct, time)), 1000), (msg, state) => update_1(msg, state), [time])[0];
    if ((State__ElapsedSeconds(state_1) < 0) ? true : (State__ElapsedSeconds(state_1) > 60)) {
        return null;
    }
    else {
        const seconds = (((copyOfStruct_1 = State__Elapsed(state_1), seconds_1(copyOfStruct_1))) >= 0) ? ((((copyOfStruct_2 = State__Elapsed(state_1), seconds_1(copyOfStruct_2))) >= 10) ? (`${((copyOfStruct_3 = State__Elapsed(state_1), seconds_1(copyOfStruct_3)))}`) : (`0${((copyOfStruct_4 = State__Elapsed(state_1), seconds_1(copyOfStruct_4)))}`)) : "";
        return createElement("a", {
            href: toPath(new Route(15)),
            className: "footer-live-auction",
            children: Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-vcentered"], ["className", join(" ", ["is-mobile", "footer-live-auction-box"])], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", join(" ", ["is-2-desktop", "is-2-mobile", "is-3-tablet"])], ["style", {
                paddingLeft: 0,
                paddingRight: 0,
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props, props_4, value_31;
                return isConnected ? append(singleton_1((props = singleton(["className", join(" ", ["far", "fa-clock", "live_auction_not_started_timer"])]), createElement("span", createObj(Helpers_combineClasses("icon", props))))), delay(() => singleton_1(createElement("span", {
                    className: "live_auction_not_started_text",
                    children: `${seconds}`,
                })))) : singleton_1(createElement("div", {
                    className: "has-background-danger",
                    style: {
                        borderRadius: 3,
                        color: "#FFFFFF",
                        width: 100 + "%",
                        textTransform: "none",
                    },
                    children: Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "pr-3"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-unlink"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_4)))), (value_31 = lAF("noConnection"), createElement("span", {
                        children: [value_31],
                    }))]),
                }));
            }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_11 = ofArray([["className", join(" ", ["is-3-desktop", "is-5-mobile", "is-5-tablet", "live_auction_not_started_title"])], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                children: p("title.2"),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_11)))), (props_20 = ofArray([["className", join(" ", ["is-2-desktop", "is-5-mobile", "is-4-tablet"])], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(value_43 = h("button.auctionOn"), createElement("span", {
                children: [value_43],
            })), (elms = singleton(createElement("i", {
                className: join(" ", ["fa", "fa-angle-right"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_22))))]),
        });
    }
}

export function View_liveAuctionHasNotStartedYet(view_liveAuctionHasNotStartedYetInputProps) {
    let copyOfStruct;
    const unitVar1 = view_liveAuctionHasNotStartedYetInputProps.unitVar1;
    const isConnected = view_liveAuctionHasNotStartedYetInputProps.isConnected;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Resource$1(0));
    const lastAuction = patternInput[0];
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton_2.Delay(() => singleton_2.Bind(homeApi().getNextOrLastFinishedAuction(), (_arg1) => {
            patternInput[1](new Resource$1(1, _arg1));
            return singleton_2.Return();
        })));
    });
    if (lastAuction.tag === 1) {
        const x = lastAuction.fields[0];
        if (equals(x.Status, new StatusAuction(1))) {
            return createElement(View_timerBefore, {
                time: ~(~((copyOfStruct = op_Subtraction(x.Date, now()), totalSeconds(copyOfStruct)))),
                isConnected: isConnected,
            });
        }
        else {
            return null;
        }
    }
    else {
        return null;
    }
}

export function View_liveAuctionFooter(state, time, isConnected) {
    let props_37, props_2, props_5, props_16, props_26, props_35, props_33, props_31, value_97, elms;
    return createElement("a", {
        href: toPath(new Route(15)),
        className: "footer-live-auction",
        children: Interop_reactApi.Children.toArray([(props_37 = ofArray([["className", join(" ", ["is-mobile", "footer-live-auction-box"])], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", join(" ", ["is-1-desktop", "is-2-mobile", "is-1-tablet"])], ["children", Interop_reactApi.Children.toArray([createElement("p", {
            className: join(" ", ["is-size-7-mobile", "footer-live-auction-text-small"]),
            children: lAF("lot.no"),
        }), createElement("p", createObj(toList(delay(() => append(singleton_1(["className", "footer-live-auction-text-medium"]), delay(() => singleton_1(["children", LotPrefix_getPrefix(state.Lot.Priority) + state.Lot.DisplayId])))))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_5 = ofArray([["className", join(" ", ["is-narrow"])], ["children", Interop_reactApi.Children.toArray([createElement("div", {
            children: Interop_reactApi.Children.toArray([View_renderLotImage(state.Lot.Image)]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_16 = ofArray([["style", {
            alignSelf: "center",
            paddingLeft: 5,
            paddingRight: 5,
        }], ["className", join(" ", ["is-3-desktop", "is-2-widescreen", "is-3-mobile", "is-2-tablet", "is-vcentered"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", {
            className: join(" ", ["is-size-7-mobile", "footer-live-auction-text-small"]),
            children: lAF("currentBid"),
        })), delay(() => append(singleton_1(createElement("p", {
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("span", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["is-size-5-mobile", "is-size-4-tablet", "is-size-3"])]), delay(() => append(singleton_1(["style", {
                fontWeight: "bold",
            }]), delay(() => ((state.Lot.CurrentBid === 0) ? singleton_1(["children", "-"]) : singleton_1(["children", `${((state.Lot.CurrentBid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))}`]))))))))))), delay(() => ((state.Lot.CurrentBid === 0) ? singleton_1(createElement("span", {
                children: [""],
            })) : singleton_1(createElement("span", {
                className: join(" ", ["is-size-5-mobile", "is-size-4-tablet", "is-size-3"]),
                style: {
                    fontWeight: 300,
                },
                children: ` ${Currency_currencySymbol()}`,
            }))))))))),
        })), delay(() => {
            let value_45, value_47, arg00$0040_1;
            const matchValue = bind((x) => CurrencyModule_noneWhenZero(x), state.Lot.Estimate);
            if (matchValue != null) {
                const estimate = matchValue;
                return singleton_1(createElement("p", {
                    className: join(" ", ["is-size-7-mobile", "is-size-7-tablet", "footer-live-auction-text-small", "is-hidden-mobile"]),
                    style: {
                        fontWeight: 400,
                    },
                    children: Interop_reactApi.Children.toArray([(value_45 = lAF("estimate"), createElement("span", {
                        children: [value_45],
                    })), (value_47 = (`${((arg00$0040_1 = (CurrencyModule_toInt(estimate) | 0), (arg00$0040_1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")))} ${Currency_currencySymbol()}`), createElement("strong", {
                        children: [value_47],
                    }))]),
                }));
            }
            else {
                return singleton_1(createElement("div", {
                    className: join(" ", ["estimate-in-catalog-zero"]),
                }));
            }
        }))))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_26 = ofArray([["style", {
            alignSelf: "center",
        }], ["className", join(" ", ["is-2-desktop", "is-2-tablet"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", {
            className: join(" ", ["is-size-7-mobile", "footer-live-auction-text-small"]),
            style: {
                paddingBottom: 8,
            },
            children: lAF("auction.ongoing"),
        })), delay(() => {
            let props_22, value_84;
            return isConnected ? singleton_1(createElement("div", {
                style: {
                    backgroundColor: "#ffba40",
                    borderRadius: 3,
                    color: "#000000",
                    width: 100 + "%",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    if (time == null) {
                        return singleton_1(createElement("span", {
                            style: {
                                textTransform: "inherit",
                            },
                            children: lAF("end"),
                        }));
                    }
                    else {
                        const x_1 = time | 0;
                        return singleton_1(createElement(View_timer, {
                            time: x_1,
                            dependencies: [x_1, state.Lot.CurrentBid],
                        }));
                    }
                })))),
            })) : singleton_1(createElement("div", {
                className: "has-background-danger",
                style: {
                    borderRadius: 3,
                    color: "#FFFFFF",
                    width: 100 + "%",
                    textTransform: "none",
                },
                children: Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "pr-3"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-unlink"]),
                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_22)))), (value_84 = lAF("noConnection"), createElement("span", {
                    children: [value_84],
                }))]),
            }));
        }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_26)))), (props_35 = ofArray([["style", {
            alignSelf: "center",
        }], ["className", "is-hidden-mobile"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_33 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "is-light"], ["children", Interop_reactApi.Children.toArray([(value_97 = h("button.auctionOn"), createElement("span", {
            children: [value_97],
        })), (elms = singleton(createElement("i", {
            className: join(" ", ["fa", "fa-angle-right"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_33))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_35))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_37))))]),
    });
}

export function Component_ReactLAFooter(component_ReactLAFooterInputProps) {
    const dependencies = component_ReactLAFooterInputProps.dependencies;
    const user = component_ReactLAFooterInputProps.user;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(State_init, (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const isConnected = Feliz_React__React_useLiveAuctionStream_Static_Z2812504D((arg) => {
        patternInput[1](new Types_Msg(0, arg));
    }, Users_UserModule_userId(user), getCurrentLanguage())[1];
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: createObj(toList(delay(() => append(singleton_1(["transitionDuration", 1000 + "ms"]), delay(() => append(singleton_1(["transitionProperty", join(",", ["opacity"])]), delay(() => ((state_1.VisibleState.tag === 0) ? singleton_1(["opacity", 1]) : singleton_1(["opacity", 1]))))))))),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const matchValue_1 = state_1.Auction;
                switch (matchValue_1.tag) {
                    case 1: {
                        return singleton_1(View_liveAuctionFooter(matchValue_1.fields[0], state_1.TimeElapsed, isConnected));
                    }
                    case 2: {
                        return singleton_1(null);
                    }
                    default: {
                        return singleton_1(createElement(View_liveAuctionHasNotStartedYet, {
                            isConnected: isConnected,
                        }));
                    }
                }
            })))),
        })]),
    });
}

export function Component_renderLiveAuctionFooter(key) {
    const user = defaultArg(map((arg0) => (new Users_User(1, arg0)), getUser()), new Users_User(0));
    return createElement(Component_ReactLAFooter, {
        user: user,
        dependencies: [key],
    });
}

