import { Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "../fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { MediaQuery_customBreakpoints } from "../Common.js";
import { createElement } from "react";
import { printf, toText, join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { comparePrimitives, max, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { collect, reverse, empty, append, singleton, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { tail, reverse as reverse_1, last, zip, head, chunkBySize, singleton as singleton_1, append as append_1, length, concat, ofArray, map } from "../fable_modules/fable-library.3.6.1/List.js";
import { rangeDouble } from "../fable_modules/fable-library.3.6.1/Range.js";

export class AnimatedNumberType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InCatalog", "InLotDetails", "InFavourites"];
    }
}

export function AnimatedNumberType$reflection() {
    return union_type("Client.Components.AnimatedNumber.AnimatedNumberType", [], AnimatedNumberType, () => [[], [], []]);
}

export const spaceChar = "s";

export const zeroChar = "0";

export function NumberColumn(numberColumnInputProps) {
    const digit = numberColumnInputProps.digit;
    const oldDigit = numberColumnInputProps.oldDigit;
    const needRoll = numberColumnInputProps.needRoll;
    const typeOfComponent = numberColumnInputProps.typeOfComponent;
    const screenWidth = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const height = ((typeOfComponent.tag === 1) ? 60 : ((typeOfComponent.tag === 2) ? ((screenWidth.tag === 0) ? 29 : ((screenWidth.tag === 1) ? 29 : 45)) : 30)) | 0;
    const w = ((typeOfComponent.tag === 1) ? ((screenWidth.tag === 0) ? 20 : ((screenWidth.tag === 1) ? 20 : 28)) : ((typeOfComponent.tag === 2) ? ((screenWidth.tag === 0) ? 12 : ((screenWidth.tag === 1) ? 12 : 18)) : ((screenWidth.tag === 0) ? 8 : ((screenWidth.tag === 1) ? 8 : 11)))) | 0;
    const from = `${((oldDigit.charCodeAt(0) * height) - (48 * height))}px`;
    const toNumber = `${((digit.charCodeAt(0) * height) - (48 * height))}px`;
    return createElement("div", {
        className: join(" ", ["ticker_column_container"]),
        style: {
            height: height,
            width: w,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            key: `${from}-${toNumber}`,
            style: createObj(toList(delay(() => ((from === toNumber) ? singleton(["transform", ("translateY(" + ((digit.charCodeAt(0) * height) - (48 * height))) + "px)"]) : append(singleton(["--fromNumber", from]), delay(() => append(singleton(["--toNumber", toNumber]), delay(() => singleton(["transform", ("translateY(" + ((digit.charCodeAt(0) * height) - (48 * height))) + "px)"]))))))))),
            className: join(" ", toList(delay(() => append(singleton("ticker_column"), delay(() => (((oldDigit !== digit) ? needRoll : false) ? singleton("animateRoll") : empty())))))),
            children: Interop_reactApi.Children.toArray(Array.from(map((x) => createElement("div", {
                key: x,
                className: "ticker_digit",
                children: x,
            }), ofArray([9, 8, 7, 6, 5, 4, 3, 2, 1, 0])))),
        })]),
    });
}

export function View(viewInputProps) {
    const isActiveBiddingComponent = viewInputProps.isActiveBiddingComponent;
    const needBlink = viewInputProps.needBlink;
    const needBlinkRed = viewInputProps.needBlinkRed;
    const needBlinkGreen = viewInputProps.needBlinkGreen;
    const typeOfComponent = viewInputProps.typeOfComponent;
    const currencySymbol = viewInputProps.currencySymbol;
    const oldNumber = viewInputProps.oldNumber;
    const number = viewInputProps.number;
    const screenWidth = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const needRoll = (typeOfComponent.tag === 1) ? needBlink : ((typeOfComponent.tag === 2) ? true : true);
    const formatForDisplay = (number_1) => {
        const number$0027 = max((x, y) => comparePrimitives(x, y), number_1, 0);
        return toList(reverse(toText(printf("%.0f"))(number$0027).split("")));
    };
    const addSpaceThousand = (list) => concat(map((x_1) => {
        if (length(x_1) === 3) {
            return append_1(x_1, singleton_1(spaceChar));
        }
        else {
            return x_1;
        }
    }, chunkBySize(3, list)));
    const numList = formatForDisplay(number);
    const oldNumList = formatForDisplay(oldNumber);
    const oldNumList_1 = (length(numList) > length(oldNumList)) ? append_1(oldNumList, toList(delay(() => collect((matchValue) => singleton(zeroChar), rangeDouble(1, 1, length(numList) - length(oldNumList)))))) : oldNumList;
    return createElement("div", createObj(toList(delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("ticker_view"), delay(() => append(needBlinkGreen ? (isActiveBiddingComponent ? singleton("blink-green-to-white") : singleton("blink-green")) : empty(), delay(() => (needBlinkRed ? (isActiveBiddingComponent ? singleton("blink-red-to-white") : singleton("blink-red")) : empty()))))))))]), delay(() => append((typeOfComponent.tag === 0) ? singleton(["style", {
        height: 30,
        justifyContent: "flex-end",
    }]) : ((typeOfComponent.tag === 2) ? ((screenWidth.tag === 0) ? singleton(["style", {
        height: 29,
        justifyContent: "center",
    }]) : ((screenWidth.tag === 1) ? singleton(["style", {
        height: 29,
        justifyContent: "center",
    }]) : singleton(["style", {
        height: 45,
        justifyContent: "center",
    }]))) : singleton(["style", {
        height: 60,
        justifyContent: "center",
    }])), delay(() => {
        let charList, list2_1;
        return singleton(["children", Interop_reactApi.Children.toArray(Array.from(append_1(singleton_1(currencySymbol), ((length(numList) === 1) ? (head(numList) === zeroChar) : false) ? singleton_1(createElement("div", {
            key: "-",
            children: "-",
        })) : ((charList = ((list2_1 = addSpaceThousand(numList), zip(addSpaceThousand(oldNumList_1), list2_1))), map((x_2) => {
            if (x_2[0] === spaceChar) {
                return createElement("div", createObj(toList(delay(() => append(singleton(["children", ""]), delay(() => ((typeOfComponent.tag === 0) ? singleton(["style", {
                    width: 4,
                }]) : ((typeOfComponent.tag === 2) ? singleton(["style", {
                    width: 8,
                }]) : singleton(["style", {
                    width: 10,
                }])))))))));
            }
            else {
                return createElement(NumberColumn, {
                    typeOfComponent: typeOfComponent,
                    needRoll: needRoll,
                    oldDigit: x_2[0],
                    digit: x_2[1],
                });
            }
        }, (last(charList)[0] === spaceChar) ? reverse_1(tail(reverse_1(charList))) : charList))))))]);
    }))))))));
}

