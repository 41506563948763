import { Record } from "../fable-library.3.6.1/Types.js";
import { unit_type, lambda_type, option_type, record_type, class_type, int32_type } from "../fable-library.3.6.1/Reflection.js";

export class RetryContext extends Record {
    constructor(previousRetryCount, elapsedMilliseconds, retryReason) {
        super();
        this.previousRetryCount = (previousRetryCount | 0);
        this.elapsedMilliseconds = (elapsedMilliseconds | 0);
        this.retryReason = retryReason;
    }
}

export function RetryContext$reflection() {
    return record_type("Fable.SignalR.RetryContext", [], RetryContext, () => [["previousRetryCount", int32_type], ["elapsedMilliseconds", int32_type], ["retryReason", class_type("System.Exception")]]);
}

export class RetryPolicy extends Record {
    constructor(nextRetryDelayInMilliseconds) {
        super();
        this.nextRetryDelayInMilliseconds = nextRetryDelayInMilliseconds;
    }
}

export function RetryPolicy$reflection() {
    return record_type("Fable.SignalR.RetryPolicy", [], RetryPolicy, () => [["nextRetryDelayInMilliseconds", lambda_type(RetryContext$reflection(), option_type(int32_type))]]);
}

export class StreamSubscriber$1 extends Record {
    constructor(next, error, complete) {
        super();
        this.next = next;
        this.error = error;
        this.complete = complete;
    }
}

export function StreamSubscriber$1$reflection(gen0) {
    return record_type("Fable.SignalR.StreamSubscriber`1", [gen0], StreamSubscriber$1, () => [["next", lambda_type(gen0, unit_type)], ["error", lambda_type(option_type(class_type("System.Exception")), unit_type)], ["complete", lambda_type(unit_type, unit_type)]]);
}

