import { fromNumber, tryParse as tryParse_1, fromInt, equals, fromInteger, fromValue, toInt, fromBits, parse, toString } from "../../../fable_modules/fable-library.3.6.1/Long.js";
import { stringHash, int32ToString } from "../../../fable_modules/fable-library.3.6.1/Util.js";
import { tryParse, parse as parse_1 } from "../../../fable_modules/fable-library.3.6.1/Int32.js";
import { Record, toString as toString_2, FSharpRef } from "../../../fable_modules/fable-library.3.6.1/Types.js";
import { toString as toString_1, newGuid } from "../../../fable_modules/fable-library.3.6.1/Guid.js";
import { ofNullable, map, defaultArg } from "../../../fable_modules/fable-library.3.6.1/Option.js";
import { FSharpChoice$2, FSharpChoice$3, FSharpChoice$5, FSharpChoice$4, FSharpResult$2 } from "../../../fable_modules/fable-library.3.6.1/Choice.js";
import { compare, pow, abs, round, equals as equals_1, op_UnaryNegation, op_Addition, fromParts, op_Division, op_Multiply, toString as toString_3, truncate, op_Subtraction, toNumber } from "../../../fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "../../../fable_modules/fable-library.3.6.1/Decimal.js";
import { empty, tail, cons, head, isEmpty, contains, ofArray, map as map_1 } from "../../../fable_modules/fable-library.3.6.1/List.js";
import { class_type, record_type, list_type, string_type, int32_type } from "../../../fable_modules/fable-library.3.6.1/Reflection.js";
import { join, padLeft, substring } from "../../../fable_modules/fable-library.3.6.1/String.js";

export function AgreementIdModule_toString(x) {
    return toString(x);
}

export function AgreementIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function AgreementIdModule_fromInt64(x) {
    return x;
}

export const AgreementIdModule_empty = fromBits(4294967295, 4294967295, false);

export function CategoryIdModule_toString(x) {
    return int32ToString(x);
}

export function CategoryIdModule_fromString(x) {
    return parse_1(x, 511, false, 32);
}

export function CategoryIdModule_tryFromString(x) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(x, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function CategoryIdModule_fromInt(x) {
    return x;
}

export function CategoryIdModule_next(x) {
    return x + 1;
}

export const CategoryIdModule_empty = -1;

export function WarehouseCategoryIdModule_toString(x) {
    return int32ToString(x);
}

export function WarehouseCategoryIdModule_fromString(x) {
    return parse_1(x, 511, false, 32);
}

export function WarehouseCategoryIdModule_fromInt(x) {
    return x;
}

export function WarehouseCategoryIdModule_next(x) {
    return x + 1;
}

export const WarehouseCategoryIdModule_empty = -1;

export function UserIdModule_toString(x) {
    return toString(x);
}

export function UserIdModule_toInt(x) {
    return ~(~toInt(x));
}

export function UserIdModule_toInt64(x) {
    return fromValue(x, false);
}

export function UserIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function UserIdModule_fromInt64(x) {
    return x;
}

export function UserIdModule_fromInt(x) {
    return fromInteger(x, false, 2);
}

export const UserIdModule_empty = fromBits(4294967295, 4294967295, false);

export const UserIdModule_systemRobot = fromBits(0, 0, false);

export const UserIdModule_systemRobotName = "System Robot";

export function ItemIdModule_toString(x) {
    return toString(x);
}

export function ItemIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function ItemIdModule_fromInt64(x) {
    return x;
}

export function ItemIdModule_fromInt(x) {
    return ItemIdModule_fromInt64(fromInteger(x, false, 2));
}

export const ItemIdModule_empty = fromBits(4294967295, 4294967295, false);

export function SubmissionIdModule_toString(x) {
    return toString(x);
}

export function SubmissionIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function SubmissionIdModule_fromInt64(x) {
    return x;
}

export function SubmissionIdModule_toInt64(x) {
    return x;
}

export const SubmissionIdModule_empty = fromBits(4294967295, 4294967295, false);

export function SubmissionIdModule_isEmpty(x) {
    return equals(x, SubmissionIdModule_empty);
}

export function SubmissionIdModule_tryParse(x) {
    let matchValue;
    let outArg = fromInt(0);
    matchValue = [tryParse_1(x, 511, false, 64, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function FreightClassModule_toString(x) {
    return int32ToString(x);
}

export function FreightClassModule_fromString(x) {
    return parse_1(x, 511, false, 32);
}

export function FreightClassModule_fromInt(x) {
    return x;
}

export function FreightClassModule_toInt(x) {
    return x;
}

export const FreightClassModule_empty = 0;

export const FreightClassModule_one = 1;

export const FreightClassModule_two = 2;

export const FreightClassModule_three = 3;

export const FreightClassModule_four = 4;

export const FreightClassModule_five = 5;

export const FreightClassModule_six = 6;

export const FreightClassModule_seven = 7;

export const FreightClassModule_eight = 8;

export const FreightClassModule_nine = 9;

export const FreightClassModule_ten = 10;

export const FreightClassModule_eleven = 11;

export const FreightClassModule_twelve = 12;

export function OrderIdModule_toString(x) {
    return toString(x);
}

export function OrderIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function OrderIdModule_fromInt64(x) {
    return x;
}

export const OrderIdModule_empty = fromBits(4294967295, 4294967295, false);

export function PayOutIdModule_toString(x) {
    return toString(x);
}

export function PayOutIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function PayOutIdModule_fromInt64(x) {
    return x;
}

export function PayOutIdModule_fromInt(x) {
    return PayOutIdModule_fromInt64(fromInteger(x, false, 2));
}

export const PayOutIdModule_empty = fromBits(4294967295, 4294967295, false);

export function PresaleIdModule_toString(x) {
    return toString(x);
}

export function PresaleIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function PresaleIdModule_fromInt64(x) {
    return x;
}

export function PresaleIdModule_fromInt(x) {
    return PresaleIdModule_fromInt64(fromInteger(x, false, 2));
}

export const PresaleIdModule_empty = fromBits(4294967295, 4294967295, false);

export function PaymentIdModule_toString(x) {
    return toString(x);
}

export function PaymentIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function PaymentIdModule_fromInt64(x) {
    return x;
}

export function PaymentIdModule_toInt(x) {
    return ~(~toInt(x));
}

export const PaymentIdModule_empty = fromBits(4294967295, 4294967295, false);

export function NetsPaymentIdModule_toString(x) {
    return x;
}

export function NetsPaymentIdModule_fromString(x) {
    return x;
}

export function NetsPaymentIdModule_tryFromString(x) {
    try {
        return NetsPaymentIdModule_fromString(x);
    }
    catch (ex) {
        return void 0;
    }
}

export const NetsPaymentIdModule_empty = "";

export function NetsPaymentIdModule_newId() {
    let copyOfStruct;
    return (copyOfStruct = newGuid(), toString_1(copyOfStruct, "N"));
}

export function SveaPaymentIdModule_toString(x) {
    return toString(x);
}

export function SveaPaymentIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function SveaPaymentIdModule_tryFromString(x) {
    try {
        return SveaPaymentIdModule_fromString(x);
    }
    catch (ex) {
        return void 0;
    }
}

export function SveaPaymentIdModule_fromInt64(x) {
    return x;
}

export function SveaPaymentIdModule_fromInt(x) {
    return fromInteger(x, false, 2);
}

export const SveaPaymentIdModule_empty = fromInteger(-1, false, 2);

export function OrderNumberModule_toString(x) {
    return int32ToString(x);
}

export function OrderNumberModule_fromString(x) {
    return parse_1(x, 511, false, 32);
}

export function OrderNumberModule_tryFromString(x) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(x, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function OrderNumberModule_toInt(x) {
    return x;
}

export function OrderNumberModule_fromInt(x) {
    return x;
}

export const OrderNumberModule_one = 1;

export const OrderNumberModule_empty = 0;

export function OrderNumberModule_inc(x) {
    return x + 1;
}

export function OrderNumberModule_dec(x) {
    return x - 1;
}

export function LotNumberReservationIdModule_toString(x) {
    return x;
}

export function LotNumberReservationIdModule_fromString(x) {
    return x;
}

export function LotNumberReservationIdModule_newId() {
    let copyOfStruct;
    return LotNumberReservationIdModule_fromString((copyOfStruct = newGuid(), toString_1(copyOfStruct, "N")));
}

export const LotNumberReservationIdModule_empty = "";

export function LotIdModule_toString(x) {
    return toString(x);
}

export function LotIdModule_tryFromString(x) {
    let matchValue;
    let outArg = fromInt(0);
    matchValue = [tryParse_1(x, 511, false, 64, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function LotIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function LotIdModule_fromInt64(x) {
    return x;
}

export function LotIdModule_fromInt(x) {
    return LotIdModule_fromInt64(fromInteger(x, false, 2));
}

export function LotIdModule_toInt(x) {
    return ~(~toInt(x));
}

export const LotIdModule_empty = fromBits(4294967295, 4294967295, false);

export function IntegratedLotIdModule_toString(x) {
    return toString(x);
}

export function IntegratedLotIdModule_tryFromString(x) {
    let matchValue;
    let outArg = fromInt(0);
    matchValue = [tryParse_1(x, 511, false, 64, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function IntegratedLotIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function IntegratedLotIdModule_fromInt64(x) {
    return x;
}

export function IntegratedLotIdModule_fromInt(x) {
    return IntegratedLotIdModule_fromInt64(fromInteger(x, false, 2));
}

export function IntegratedLotIdModule_toInt(x) {
    return ~(~toInt(x));
}

export const IntegratedLotIdModule_empty = fromBits(4294967295, 4294967295, false);

export function ReservedPriceModule_isZero(x) {
    return x === 0;
}

export function ReservedPriceModule_isMet(amount, x) {
    return defaultArg(map((x_1) => (amount >= x_1), x), true);
}

export function ReservedPriceModule_toInt(x) {
    return x;
}

export function ReservedPriceModule_fromInt(x) {
    return x;
}

export function ReservedPriceModule_toString(x) {
    return int32ToString(x);
}

export function ReservedPriceModule_tryFromString(x) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(x, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function ReservedPriceModule_fromString(x) {
    return parse_1(x, 511, false, 32);
}

export function AuctionIdModule_toString(x) {
    return toString(x);
}

export function AuctionIdModule_toInt(x) {
    return ~(~toInt(x));
}

export function AuctionIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function AuctionIdModule_tryFromString(x) {
    let matchValue;
    let outArg = fromInt(0);
    matchValue = [tryParse_1(x, 511, false, 64, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function AuctionIdModule_parse(x) {
    try {
        return new FSharpResult$2(0, parse(x, 511, false, 64));
    }
    catch (ex) {
        return new FSharpResult$2(1, ex.message);
    }
}

export function AuctionIdModule_fromInt64(x) {
    return x;
}

export function AuctionIdModule_fromInt(x) {
    return fromInteger(x, false, 2);
}

export const AuctionIdModule_empty = fromBits(4294967295, 4294967295, false);

export const AuctionIdModule_first = AuctionIdModule_fromInt64(fromBits(1, 0, false));

export const AuctionIdModule_zero = AuctionIdModule_fromInt64(fromBits(0, 0, false));

export function IntegratedAuctionIdModule_toString(x) {
    return toString(x);
}

export function IntegratedAuctionIdModule_toInt(x) {
    return ~(~toInt(x));
}

export function IntegratedAuctionIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function IntegratedAuctionIdModule_parse(x) {
    try {
        return new FSharpResult$2(0, parse(x, 511, false, 64));
    }
    catch (ex) {
        return new FSharpResult$2(1, ex.message);
    }
}

export function IntegratedAuctionIdModule_fromInt64(x) {
    return x;
}

export function IntegratedAuctionIdModule_fromInt(x) {
    return fromInteger(x, false, 2);
}

export const IntegratedAuctionIdModule_empty = fromBits(4294967295, 4294967295, false);

export const IntegratedAuctionIdModule_first = IntegratedAuctionIdModule_fromInt64(fromBits(1, 0, false));

export const IntegratedAuctionIdModule_zero = IntegratedAuctionIdModule_fromInt64(fromBits(0, 0, false));

export function ImageFileNameModule_toString(x) {
    return x;
}

export function ImageFileNameModule_fromString(x) {
    return x;
}

export const ImageFileNameModule_empty = ImageFileNameModule_fromString("");

export function ImageIdModule_toString(x) {
    return x;
}

export function ImageIdModule_fromString(x) {
    return x;
}

export const ImageIdModule_empty = ImageIdModule_fromString("");

export function ImageIdModule_newId() {
    let copyOfStruct;
    return ImageIdModule_fromString((copyOfStruct = newGuid(), toString_1(copyOfStruct, "N")));
}

export function ImageUrlModule_toString(x) {
    return x;
}

export function ImageUrlModule_fromString(x) {
    return x;
}

export function ImageUrlModule_ofUri(x) {
    return toString_2(x);
}

export const ImageUrlModule_empty = ImageUrlModule_fromString("");

export function WidthModule_toInt(x) {
    return x;
}

export function WidthModule_ofInt(x) {
    return x;
}

export function HeightModule_toInt(x) {
    return x;
}

export function HeightModule_ofInt(x) {
    return x;
}

export function EmailModule_toString(value) {
    return value;
}

export function EmailModule_ofString(s) {
    return defaultArg(map((x) => x.trim(), ofNullable(s)), EmailModule_empty);
}

export function EmailModule_map(f, value) {
    return f(EmailModule_toString(value));
}

export const EmailModule_normalize = (value) => EmailModule_map((x) => x.trim().toUpperCase(), value);

export const EmailModule_empty = EmailModule_ofString("");

export function PhoneModule_toString(value) {
    return value;
}

export function PhoneModule_ofString(s) {
    return s;
}

export const PhoneModule_empty = PhoneModule_ofString("");

export function PasswordHashModule_toString(value) {
    return value;
}

export function PasswordHashModule_ofString(x) {
    return x;
}

export const PasswordHashModule_empty = PasswordHashModule_ofString("");

export function ResetPasswordCodeModule_toString(value) {
    return value;
}

export function ResetPasswordCodeModule_ofString(x) {
    return x;
}

export const ResetPasswordCodeModule_empty = ResetPasswordCodeModule_ofString("");

export function ResetPasswordCodeModule_mkNew() {
    let copyOfStruct;
    return ResetPasswordCodeModule_ofString((copyOfStruct = newGuid(), toString_1(copyOfStruct, "N")));
}

export function NotificationIdModule_toString(x) {
    return x;
}

export function NotificationIdModule_fromString(x) {
    return x;
}

export const NotificationIdModule_empty = "";

export function NotificationIdModule_newId() {
    let copyOfStruct;
    return NotificationIdModule_fromString((copyOfStruct = newGuid(), toString_1(copyOfStruct, "N")));
}

export function PaymentReminderIdModule_toString(x) {
    return x;
}

export function PaymentReminderIdModule_fromString(x) {
    return x;
}

export const PaymentReminderIdModule_empty = "";

export function PaymentReminderIdModule_newId() {
    let copyOfStruct;
    return PaymentReminderIdModule_fromString((copyOfStruct = newGuid(), toString_1(copyOfStruct, "N")));
}

export function SequenceIdModule_toString(value) {
    return value;
}

export const SequenceIdModule_itemId = "itemId";

export const SequenceIdModule_freightId = "freightId";

export const SequenceIdModule_lotId = "lotId";

export const SequenceIdModule_userId = "userId";

export const SequenceIdModule_auctionId = "auctionId";

export const SequenceIdModule_agreementId = "agreementId";

export const SequenceIdModule_submissionId = "submissionId";

export const SequenceIdModule_orderId = "orderId";

export const SequenceIdModule_paymentId = "paymentId";

export const SequenceIdModule_payoutId = "payoutId";

export const SequenceIdModule_fortnoxInvoiceId = "fortnoxInvoiceId";

export function SequenceIdModule_invoiceId(userId, year) {
    return `invoice_${userId}_${year}`;
}

export function PercentModule_fromDecimal(value) {
    return value;
}

export function PercentModule_fromInt(value) {
    return new Decimal(value);
}

export function PercentModule_toDecimal(value) {
    return value;
}

export function PercentModule_toInt(value) {
    return ~(~toNumber(value));
}

export const PercentModule_$00325$0020$0025 = PercentModule_fromInt(25);

export const PercentModule_zero = PercentModule_fromInt(0);

export function FileIdModule_toString(x) {
    return x;
}

export function FileIdModule_fromString(x) {
    return x;
}

export const FileIdModule_empty = FileIdModule_fromString("");

export function FileIdModule_newId() {
    let copyOfStruct;
    return FileIdModule_fromString((copyOfStruct = newGuid(), toString_1(copyOfStruct, "N")));
}

export function ScanFileNameModule_toString(x) {
    return x;
}

export function ScanFileNameModule_fromString(x) {
    return x;
}

export const ScanFileNameModule_empty = ScanFileNameModule_fromString("");

export function DeliveryCostZoneIdModule_toString(value) {
    return value;
}

export function DeliveryCostZoneIdModule_fromString(x) {
    return x;
}

export const DeliveryCostZoneIdModule_zone1 = "zone1";

export const DeliveryCostZoneIdModule_zone2 = "zone2";

export const DeliveryCostZoneIdModule_zone3 = "zone3";

export const DeliveryCostZoneIdModule_artMove = "artMove";

export const DeliveryCostZoneIdModule_dbSchenker = "dbSchenker";

export const DeliveryCostZoneIdModule_postnord = "postnord";

export const DeliveryCostZoneIdModule_localFreight = "localFreight";

export const DeliveryCostZoneIdModule_empty = "";

export function DeliveryCostZoneIdModule_$007CDbSchenker$007CPostnord$007CLocalFreight$007COther$007C(x) {
    if (x === DeliveryCostZoneIdModule_dbSchenker) {
        return new FSharpChoice$4(0, void 0);
    }
    else if (x === DeliveryCostZoneIdModule_postnord) {
        return new FSharpChoice$4(1, void 0);
    }
    else if (x === DeliveryCostZoneIdModule_localFreight) {
        return new FSharpChoice$4(2, void 0);
    }
    else {
        return new FSharpChoice$4(3, x);
    }
}

export function PickPointIdModule_toString(value) {
    return value;
}

export function PickPointIdModule_fromString(x) {
    return x;
}

export const PickPointIdModule_empty = "";

export const PickPointIdModule_ArtMove_gothenburg = "Gothenburg";

export const PickPointIdModule_ArtMove_stockholm = "Stockholm";

export const PickPointIdModule_ArtMove_malmo = "Malmo";

export const PickPointIdModule_ArtMove_helsinki = "Helsinki";

export function PickPointIdModule_ArtMove_$007CGothenburg$007CStockholm$007CMalmo$007CHelsinki$007CUnknown$007C(x) {
    if (x === PickPointIdModule_ArtMove_gothenburg) {
        return new FSharpChoice$5(0, void 0);
    }
    else if (x === PickPointIdModule_ArtMove_stockholm) {
        return new FSharpChoice$5(1, void 0);
    }
    else if (x === PickPointIdModule_ArtMove_malmo) {
        return new FSharpChoice$5(2, void 0);
    }
    else if (x === PickPointIdModule_ArtMove_helsinki) {
        return new FSharpChoice$5(3, void 0);
    }
    else {
        return new FSharpChoice$5(4, void 0);
    }
}

export function CountryCodeModule_toString(x) {
    return x.toLowerCase();
}

export function CountryCodeModule_fromString(x) {
    return x.toLowerCase();
}

export const CountryCodeModule_empty = CountryCodeModule_fromString("");

export const CountryCodeModule_sweden = CountryCodeModule_fromString("swe");

export const CountryCodeModule_norway = CountryCodeModule_fromString("nor");

export const CountryCodeModule_other = CountryCodeModule_fromString("other");

export const CountryCodeModule_europe = map_1((x) => CountryCodeModule_fromString(x), ofArray(["aut", "bel", "bgr", "hrv", "cyp", "cze", "dnk", "est", "fin", "fra", "deu", "grc", "hun", "irl", "ita", "lva", "ltu", "lux", "mlt", "nld", "pol", "prt", "rou", "svk", "svn", "esp", "swe"]));

export function CountryCodeModule_$007CSweden$007CNorway$007COther$007C(code) {
    if (code === CountryCodeModule_sweden) {
        return new FSharpChoice$3(0, void 0);
    }
    else if (code === CountryCodeModule_norway) {
        return new FSharpChoice$3(1, void 0);
    }
    else {
        return new FSharpChoice$3(2, void 0);
    }
}

export function CountryCodeModule_$007CEU$007CNonEU$007C(code) {
    if (contains(code, CountryCodeModule_europe, {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })) {
        return new FSharpChoice$2(0, code);
    }
    else {
        return new FSharpChoice$2(1, code);
    }
}

export function CountryCodeModule_isEU(code) {
    return contains(code, CountryCodeModule_europe, {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    });
}

export function MD5HashModule_ofString(s) {
    return s;
}

export function CompanyIdModule_toString(x) {
    return toString(x);
}

export function CompanyIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function CompanyIdModule_fromInt64(x) {
    return x;
}

export const CompanyIdModule_empty = fromBits(4294967295, 4294967295, false);

export const CompanyIdModule_first = CompanyIdModule_fromInt64(fromBits(1, 0, false));

export const CompanyIdModule_zero = CompanyIdModule_fromInt64(fromBits(0, 0, false));

export function InvoiceIdModule_toInt(x) {
    return x;
}

export function InvoiceIdModule_fromInt(x) {
    return x;
}

export const InvoiceIdModule_zero = InvoiceIdModule_fromInt(0);

export function InvoiceDraftIdModule_toInt(x) {
    return x;
}

export function InvoiceDraftIdModule_fromInt(x) {
    return x;
}

export const InvoiceDraftIdModule_zero = InvoiceDraftIdModule_fromInt(0);

export function CurrencyCodeModule_toString(value) {
    return value;
}

export function CurrencyCodeModule_fromString(value) {
    return value.toLowerCase();
}

export const CurrencyCodeModule_sek = CurrencyCodeModule_fromString("SEK");

export const CurrencyCodeModule_eur = CurrencyCodeModule_fromString("EUR");

export function CurrencyCodeModule_tryParse(code) {
    const matchValue = code.toUpperCase();
    switch (matchValue) {
        case "EUR": {
            return CurrencyCodeModule_eur;
        }
        case "SEK": {
            return CurrencyCodeModule_sek;
        }
        default: {
            return void 0;
        }
    }
}

export function Decimal_fractional(x) {
    return op_Subtraction(x, truncate(x));
}

export function CurrencyModule_toString(value) {
    return toString_3(value);
}

export function CurrencyModule_toDecimal(value) {
    return value;
}

export function CurrencyModule_fromDecimal(value) {
    return value;
}

export function CurrencyModule_fromFloat(value) {
    return new Decimal(value);
}

export function CurrencyModule_fromInt(value) {
    return new Decimal(value);
}

export function CurrencyModule_fromString(value) {
    return new Decimal(parse_1(value, 511, false, 32));
}

export function CurrencyModule_tryFromString(value) {
    try {
        return CurrencyModule_fromString(value);
    }
    catch (matchValue) {
        return void 0;
    }
}

export function CurrencyModule_toInt(value) {
    return ~(~toNumber(CurrencyModule_toDecimal(value)));
}

export function CurrencyModule_percent(percent, value) {
    return op_Multiply(value, op_Division(percent, fromParts(100, 0, 0, false, 0)));
}

export function CurrencyModule_percentReverse(percent, value) {
    return op_Multiply(op_Division(value, op_Addition(fromParts(100, 0, 0, false, 0), percent)), percent);
}

export const CurrencyModule_zero = fromParts(0, 0, 0, false, 0);

export function CurrencyModule_negate(x) {
    return op_UnaryNegation(x);
}

export function CurrencyModule_noneWhenZero(x) {
    if (equals_1(x, CurrencyModule_zero)) {
        return void 0;
    }
    else {
        return x;
    }
}

export function CurrencyModule_round(decimals, value) {
    return CurrencyModule_fromDecimal(round(CurrencyModule_toDecimal(value), decimals, 1));
}

export function CurrencyModule_financialRound(value) {
    return CurrencyModule_round(0, value);
}

export function CurrencyModule_fromReservedPrice(x) {
    return CurrencyModule_fromInt(ReservedPriceModule_toInt(x));
}

export function CurrencyModule_fromOptReservedPrice(x) {
    return defaultArg(map((arg) => CurrencyModule_fromInt(ReservedPriceModule_toInt(arg)), x), CurrencyModule_zero);
}

export class CurrencyModule_FormatSettings extends Record {
    constructor(DecimalDigits, CurrencySymbol, DecimalSeparator, GroupSeparator, GroupSizes, NegativePattern, PositivePattern) {
        super();
        this.DecimalDigits = (DecimalDigits | 0);
        this.CurrencySymbol = CurrencySymbol;
        this.DecimalSeparator = DecimalSeparator;
        this.GroupSeparator = GroupSeparator;
        this.GroupSizes = GroupSizes;
        this.NegativePattern = (NegativePattern | 0);
        this.PositivePattern = (PositivePattern | 0);
    }
}

export function CurrencyModule_FormatSettings$reflection() {
    return record_type("Bidflow.Infrastructure.Auction.Types.CurrencyModule.FormatSettings", [], CurrencyModule_FormatSettings, () => [["DecimalDigits", int32_type], ["CurrencySymbol", string_type], ["DecimalSeparator", string_type], ["GroupSeparator", string_type], ["GroupSizes", list_type(int32_type)], ["NegativePattern", int32_type], ["PositivePattern", int32_type]]);
}

export class CurrencyModule_FormattedCurrency extends Record {
    constructor(Origin, Integrals, Decimals, FormatSettings) {
        super();
        this.Origin = Origin;
        this.Integrals = Integrals;
        this.Decimals = Decimals;
        this.FormatSettings = FormatSettings;
    }
}

export function CurrencyModule_FormattedCurrency$reflection() {
    return record_type("Bidflow.Infrastructure.Auction.Types.CurrencyModule.FormattedCurrency", [], CurrencyModule_FormattedCurrency, () => [["Origin", class_type("System.Decimal")], ["Integrals", list_type(string_type)], ["Decimals", string_type], ["FormatSettings", CurrencyModule_FormatSettings$reflection()]]);
}

export function CurrencyModule_FormattedCurrencyModule_splitIntegrals(aggregate_mut, sizes_mut, x_mut) {
    CurrencyModule_FormattedCurrencyModule_splitIntegrals:
    while (true) {
        const aggregate = aggregate_mut, sizes = sizes_mut, x = x_mut;
        if (!isEmpty(sizes)) {
            const s = head(sizes) | 0;
            if (x === "") {
                return aggregate;
            }
            else if (x.length > s) {
                const x_2 = x;
                aggregate_mut = cons(substring(x_2, x_2.length - s, s), aggregate);
                sizes_mut = tail(sizes);
                x_mut = substring(x_2, 0, x_2.length - s);
                continue CurrencyModule_FormattedCurrencyModule_splitIntegrals;
            }
            else {
                return cons(x, aggregate);
            }
        }
        else {
            return cons(x, aggregate);
        }
        break;
    }
}

export function CurrencyModule_FormattedCurrencyModule_evaluateDecimals(decimalDigits, x) {
    const fractional = toString(fromNumber(toNumber(op_Multiply(Decimal_fractional(abs(x)), pow(fromParts(10, 0, 0, false, 0), decimalDigits))), false));
    if (decimalDigits < fractional.length) {
        return substring(fractional, 0, decimalDigits);
    }
    else if (decimalDigits > fractional.length) {
        return padLeft(fractional, decimalDigits, "0");
    }
    else {
        return fractional;
    }
}

export function CurrencyModule_FormattedCurrencyModule_create(s, input) {
    const x = abs(CurrencyModule_toDecimal(CurrencyModule_round(s.DecimalDigits, input)));
    return new CurrencyModule_FormattedCurrency(input, CurrencyModule_FormattedCurrencyModule_splitIntegrals(empty(), s.GroupSizes, toString_3(truncate(x))), CurrencyModule_FormattedCurrencyModule_evaluateDecimals(s.DecimalDigits, x), s);
}

export function CurrencyModule_FormattedCurrencyModule_numberIgnoreSign(x) {
    const integral = join(x.FormatSettings.GroupSeparator, x.Integrals);
    if ((x.FormatSettings.DecimalDigits === 0) ? true : (x.Decimals === "0")) {
        return integral;
    }
    else {
        return `${integral}${x.FormatSettings.DecimalSeparator}${x.Decimals}`;
    }
}

export function CurrencyModule_FormattedCurrencyModule_toStringN(x) {
    const number = CurrencyModule_FormattedCurrencyModule_numberIgnoreSign(x);
    if (compare(x.Origin, CurrencyModule_zero) < 0) {
        return `-${number}`;
    }
    else {
        return number;
    }
}

export function CurrencyModule_FormattedCurrencyModule_toString(x) {
    return ((compare(x.Origin, CurrencyModule_zero) >= 0) ? ((currencySymbol) => ((number) => {
        const matchValue = x.FormatSettings.PositivePattern | 0;
        switch (matchValue) {
            case 1: {
                return `${number}${currencySymbol}`;
            }
            case 2: {
                return `${currencySymbol} ${number}`;
            }
            case 3: {
                return `${number} ${currencySymbol}`;
            }
            default: {
                return `${currencySymbol}${number}`;
            }
        }
    })) : ((currencySymbol_1) => ((number_1) => {
        const matchValue_1 = x.FormatSettings.NegativePattern | 0;
        switch (matchValue_1) {
            case 1: {
                return `-${number_1}${currencySymbol_1}`;
            }
            case 2: {
                return `${currencySymbol_1}-${number_1}`;
            }
            case 3: {
                return `${currencySymbol_1}${number_1}-`;
            }
            case 4: {
                return `(${number_1}${currencySymbol_1})`;
            }
            case 5: {
                return `-${number_1}${currencySymbol_1}`;
            }
            case 6: {
                return `${number_1}-${currencySymbol_1}`;
            }
            case 7: {
                return `${number_1}${currencySymbol_1}-`;
            }
            case 8: {
                return `-${number_1} ${currencySymbol_1}`;
            }
            case 9: {
                return `-${currencySymbol_1} ${number_1}`;
            }
            case 10: {
                return `${number_1} ${currencySymbol_1}-`;
            }
            case 11: {
                return `${currencySymbol_1} ${number_1}-`;
            }
            case 12: {
                return `${currencySymbol_1} -${number_1}`;
            }
            case 13: {
                return `${number_1}- ${currencySymbol_1}`;
            }
            case 14: {
                return `(${currencySymbol_1} ${number_1})`;
            }
            case 15: {
                return `(${number_1} ${currencySymbol_1})`;
            }
            case 16: {
                return `${currencySymbol_1}- ${number_1}`;
            }
            default: {
                return `(${currencySymbol_1}${number_1})`;
            }
        }
    })))(x.FormatSettings.CurrencySymbol)(CurrencyModule_FormattedCurrencyModule_numberIgnoreSign(x));
}

export function CurrencyModule_format(s, x) {
    return CurrencyModule_FormattedCurrencyModule_toString(CurrencyModule_FormattedCurrencyModule_create(s, x));
}

export function CurrencyModule_formatN(s, x) {
    return CurrencyModule_FormattedCurrencyModule_toStringN(CurrencyModule_FormattedCurrencyModule_create(s, x));
}

export function CurrencyModule_formatOpt(s, defaultValue, x) {
    return defaultArg(map((x_1) => CurrencyModule_format(s, x_1), x), defaultValue);
}

export function CurrencyModule_formatNOpt(s, defaultValue, x) {
    return defaultArg(map((x_1) => CurrencyModule_formatN(s, x_1), x), defaultValue);
}

export function CurrencyModule_createFormatter(s) {
    return {
        Format(x) {
            return CurrencyModule_format(s, x);
        },
        FormatNumber(x_1) {
            return CurrencyModule_formatN(s, x_1);
        },
        FormatOpt(defaultValue, x_2) {
            return CurrencyModule_formatOpt(s, defaultValue, x_2);
        },
        FormatNumberOpt(defaultValue_1, x_3) {
            return CurrencyModule_formatNOpt(s, defaultValue_1, x_3);
        },
        CurrencySymbol: s.CurrencySymbol,
    };
}

export function FortnoxInvoiceIdModule_toString(x) {
    return toString(x);
}

export function FortnoxInvoiceIdModule_fromString(x) {
    return parse(x, 511, false, 64);
}

export function FortnoxInvoiceIdModule_fromInt64(x) {
    return x;
}

export const FortnoxInvoiceIdModule_empty = fromBits(4294967295, 4294967295, false);

export const FortnoxInvoiceIdModule_first = FortnoxInvoiceIdModule_fromInt64(fromBits(1, 0, false));

export const FileNameModule_empty = "";

export function FileNameModule_ofString(s) {
    return s;
}

export function FileNameModule_toString(x) {
    return x;
}

