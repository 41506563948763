import { Localized_formatDate, Localized_translate, t } from "../Localization.js";
import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { tuple_type, list_type, unit_type, option_type, bool_type, union_type, string_type, class_type, record_type, int32_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Users_UserModule_userId, SearchCriteria, Users_User, LotStartTimeRequest, Lots_LotToFavouritesRequest, Users_User$reflection, Lot$reflection } from "../Shared/Shared.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_favouritesChanged, Cmd_fromAsync, Remoting_LiveBidding_subscribeCurrentLotBiddingEvent, Deferred$1, Remoting_handleNonAuth, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { tail, concat, tryHead, map, last as last_1 } from "../fable_modules/fable-library.3.6.1/Array.js";
import { isNullOrEmpty, join, split } from "../fable_modules/fable-library.3.6.1/String.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { lotsAuthApi, lotsApi } from "../Communication.js";
import { ofList } from "../fable_modules/fable-library.3.6.1/Map.js";
import { item, toArray, append as append_1, contains, ofArray, isEmpty, singleton as singleton_1 } from "../fable_modules/fable-library.3.6.1/List.js";
import { ItemIdModule_toString, ImageUrlModule_toString, AuctionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { map as map_1, defaultArg, value as value_138 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { empty, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { AuctionState, toPath, navigate, Route, navigateTo } from "../Router.js";
import { equals } from "../fable_modules/fable-library.3.6.1/Long.js";
import { createObj, equals as equals_1, fastStructuralHash } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF, ScreenSize } from "../fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { createElement } from "react";
import * as react from "react";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505, React_functionComponent_2F9D7239 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "../fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { LotPrefix_getPrefix, LotPrefix_isPriority, MediaQuery_customBreakpoints } from "../Common.js";
import { now, toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { Img_originalImagePathContains, Img_videoThumbnail, Img_Url_medium, Img_Url_mediumContains1800 } from "../Css.js";
import { Media } from "../Components/ImageGallery.js";
import react_image_gallery from "react-image-gallery";
import { View } from "../Components/ZoomComponent.js";
import { Render } from "./Bidding.js";
import { mediumInline } from "../Components/Loader.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export function p(key) {
    return t("lotDetails." + key);
}

export class Types_Buyer extends Record {
    constructor(BidId, LotId) {
        super();
        this.BidId = (BidId | 0);
        this.LotId = (LotId | 0);
    }
}

export function Types_Buyer$reflection() {
    return record_type("LotDetails.Types.Buyer", [], Types_Buyer, () => [["BidId", int32_type], ["LotId", int32_type]]);
}

export class Types_State extends Record {
    constructor(AuctionId, Lot, BidsAreShown, IsLiked, User, EstimatedTimeInfo, IsShowFullScreen) {
        super();
        this.AuctionId = AuctionId;
        this.Lot = Lot;
        this.BidsAreShown = BidsAreShown;
        this.IsLiked = IsLiked;
        this.User = User;
        this.EstimatedTimeInfo = EstimatedTimeInfo;
        this.IsShowFullScreen = IsShowFullScreen;
    }
}

export function Types_State$reflection() {
    return record_type("LotDetails.Types.State", [], Types_State, () => [["AuctionId", class_type("System.Int64")], ["Lot", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Lot$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Lot$reflection()]], [["ErrorValue", string_type]]]))], ["BidsAreShown", bool_type], ["IsLiked", bool_type], ["User", Users_User$reflection()], ["EstimatedTimeInfo", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.DateTimeOffset"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.DateTimeOffset")]], [["ErrorValue", string_type]]]))], ["IsShowFullScreen", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Clicked", "LoadLot", "LoadTimeInfo", "ShowBids", "ChangeLikeStatus", "RenderLikedInHeader", "GotLikedInfo", "LiveAuctionBiddingOn", "ShowFullScreen"];
    }
}

export function Types_Msg$reflection() {
    return union_type("LotDetails.Types.Msg", [], Types_Msg, () => [[], [["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(Lot$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", option_type(Lot$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.DateTimeOffset"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.DateTimeOffset")]], [["ErrorValue", string_type]]]))]], [["Item", bool_type]], [["Item1", class_type("System.Int64")], ["Item2", bool_type], ["Item3", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(class_type("System.Int64")), bool_type), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(class_type("System.Int64")), bool_type)]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [bool_type, string_type], FSharpResult$2, () => [[["ResultValue", bool_type]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.Int64")]], [["Item", bool_type]]]);
}

export function Cmd_addEmbedToLink(s) {
    const last = last_1(split(s, ["/"], null, 0));
    return `https://www.youtube.com/embed/${last}`;
}

export function Cmd_getLot(lotId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(lotsApi().lotInfo(lotId), (_arg1) => singleton.Return(new Types_Msg(1, lotId, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(1, lotId, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_getTimeInfo(payload) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(lotsApi().getLotStartTime(payload), (_arg1) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_addToFavourites(lotId, isLiked, auctionId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => (isLiked ? singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().addToFavourites(new Lots_LotToFavouritesRequest(lotId, auctionId))), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(4, lotId, isLiked, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg1.fields[0])))) : singleton.Return(new Types_Msg(4, lotId, isLiked, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))) : singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().deleteFromFavourites(ofList(singleton_1([auctionId, singleton_1(lotId)])))), (_arg2) => ((_arg2.tag === 1) ? singleton.Return(new Types_Msg(4, lotId, isLiked, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.fields[0])))) : singleton.Return(new Types_Msg(4, lotId, isLiked, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))))), (_arg3) => singleton.Return(new Types_Msg(4, lotId, isLiked, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg3.message))))));
}

export function Cmd_getIsLikedInfo(lotId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getIsLikedInfo(lotId)), (_arg1) => singleton.Return(new Types_Msg(6, lotId, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(6, lotId, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_getCurrentAuctionIds(isLiked) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getCurrentAuctionIds()), (_arg1) => {
        const auctionIds = _arg1;
        return isEmpty(auctionIds) ? singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Current auctions were not found")))) : singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(0, [auctionIds, isLiked]))));
    })), (_arg2) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(lotId, user) {
    return [new Types_State(AuctionIdModule_empty, new Deferred$1(0), false, false, user, new Deferred$1(0), false), Cmd_batch(ofArray([Cmd_OfFunc_result(new Types_Msg(1, lotId, new AsyncOperationStatus$1(0))), Remoting_LiveBidding_subscribeCurrentLotBiddingEvent((arg0) => (new Types_Msg(7, arg0)))]))];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new Types_State(state.AuctionId, new Deferred$1(2, new FSharpResult$2(1, msg.fields[1].fields[0].fields[0])), state.BidsAreShown, state.IsLiked, state.User, state.EstimatedTimeInfo, state.IsShowFullScreen), Cmd_none()];
            }
            else if (msg.fields[1].fields[0].fields[0] != null) {
                const l = msg.fields[1].fields[0].fields[0];
                return [new Types_State((l.Auction != null) ? value_138(l.Auction).Id : AuctionIdModule_empty, new Deferred$1(2, new FSharpResult$2(0, l)), state.BidsAreShown, state.IsLiked, state.User, state.EstimatedTimeInfo, state.IsShowFullScreen), Cmd_batch(toList(delay(() => append(singleton_2(Cmd_OfFunc_result(new Types_Msg(2, new AsyncOperationStatus$1(0)))), delay(() => append(singleton_2(Cmd_OfFunc_result(new Types_Msg(6, msg.fields[0], new AsyncOperationStatus$1(0)))), delay(() => ((l.BidState.tag === 2) ? singleton_2(Cmd_OfFunc_result(new Types_Msg(7, msg.fields[0]))) : singleton_2(Cmd_none())))))))))];
            }
            else {
                return [state, navigateTo(new Route(0))];
            }
        }
        else {
            return [new Types_State(state.AuctionId, new Deferred$1(1), state.BidsAreShown, state.IsLiked, state.User, state.EstimatedTimeInfo, state.IsShowFullScreen), Cmd_fromAsync(Cmd_getLot(msg.fields[0]))];
        }
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.AuctionId, state.Lot, state.BidsAreShown, state.IsLiked, state.User, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsShowFullScreen), Cmd_none()];
            }
            else {
                return [new Types_State(state.AuctionId, state.Lot, state.BidsAreShown, state.IsLiked, state.User, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.IsShowFullScreen), Cmd_none()];
            }
        }
        else {
            const matchValue_1 = state.Lot;
            let pattern_matching_result, l_1;
            if (matchValue_1.tag === 2) {
                if (matchValue_1.fields[0].tag === 0) {
                    pattern_matching_result = 0;
                    l_1 = matchValue_1.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return [new Types_State(state.AuctionId, state.Lot, state.BidsAreShown, state.IsLiked, state.User, new Deferred$1(1), state.IsShowFullScreen), Cmd_fromAsync(Cmd_getTimeInfo(new LotStartTimeRequest(state.AuctionId, l_1.Id)))];
                }
                case 1: {
                    return [state, Cmd_none()];
                }
            }
        }
    }
    else if (msg.tag === 3) {
        return [new Types_State(state.AuctionId, state.Lot, msg.fields[0], state.IsLiked, state.User, state.EstimatedTimeInfo, state.IsShowFullScreen), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[2].tag === 1) {
            if (msg.fields[2].fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_fromAsync(Cmd_getCurrentAuctionIds(msg.fields[1]))];
            }
        }
        else if (state.User.tag === 0) {
            return [state, Cmd_none()];
        }
        else {
            return [state, Cmd_fromAsync(Cmd_addToFavourites(msg.fields[0], msg.fields[1], state.AuctionId))];
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                if (contains(state.AuctionId, msg.fields[0].fields[0].fields[0][0], {
                    Equals: (x_1, y) => equals(x_1, y),
                    GetHashCode: (x_1) => fastStructuralHash(x_1),
                })) {
                    if (msg.fields[0].fields[0].fields[0][1]) {
                        Remoting_favouritesChanged(1);
                    }
                    else {
                        Remoting_favouritesChanged(-1);
                    }
                }
                return [new Types_State(state.AuctionId, state.Lot, state.BidsAreShown, msg.fields[0].fields[0].fields[0][1], state.User, state.EstimatedTimeInfo, state.IsShowFullScreen), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 6) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new Types_State(state.AuctionId, state.Lot, state.BidsAreShown, false, state.User, state.EstimatedTimeInfo, state.IsShowFullScreen), Cmd_none()];
            }
            else {
                return [new Types_State(state.AuctionId, state.Lot, state.BidsAreShown, msg.fields[1].fields[0].fields[0], state.User, state.EstimatedTimeInfo, state.IsShowFullScreen), Cmd_none()];
            }
        }
        else if (equals_1(state.User, new Users_User(0))) {
            return [state, Cmd_none()];
        }
        else {
            return [state, Cmd_fromAsync(Cmd_getIsLikedInfo(msg.fields[0]))];
        }
    }
    else if (msg.tag === 7) {
        const matchValue_3 = state.Lot;
        let pattern_matching_result_1;
        if (matchValue_3.tag === 2) {
            if (matchValue_3.fields[0].tag === 0) {
                if (equals(matchValue_3.fields[0].fields[0].Id, msg.fields[0])) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return [state, navigateTo(new Route(15))];
            }
            case 1: {
                return [state, Cmd_none()];
            }
        }
    }
    else if (msg.tag === 8) {
        return [new Types_State(state.AuctionId, state.Lot, state.BidsAreShown, state.IsLiked, state.User, state.EstimatedTimeInfo, msg.fields[0]), Cmd_none()];
    }
    else {
        return [state, Cmd_none()];
    }
}

export function View_RenderGoToButton(width, lotIdOption) {
    if (lotIdOption != null) {
        const lotId_2 = lotIdOption;
        const props_13 = toList(delay(() => append((width.tag === 0) ? singleton_2(["className", "pr-2"]) : ((width.tag === 1) ? singleton_2(["className", "pr-2"]) : ((width.tag === 2) ? singleton_2(["className", "pr-2"]) : singleton_2(["className", "pr-6"]))), delay(() => {
            let props_11;
            return singleton_2(["children", Interop_reactApi.Children.toArray([(props_11 = toList(delay(() => append(equals_1(width, new ScreenSize(0)) ? singleton_2(["style", {
                display: "flex",
                justifyContent: "space-around",
            }]) : empty(), delay(() => {
                let elms, props_2, s, props_9, props_7, s_1;
                return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_2 = ofArray([["onClick", (_arg1_3) => {
                    startImmediate(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsApi().getPreviousLotLink(lotId_2)), (_arg1) => {
                        navigate(toPath(new Route(4, _arg1)));
                        return singleton.Zero();
                    })));
                }], ["className", join(" ", ["hovered-level-item", "navbar-catalogue-item"])], ["style", {
                    color: "#444444",
                    textAlign: "center",
                }], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    style: {
                        paddingRight: 10,
                    },
                    className: join(" ", ["fa", "fa-arrow-left"]),
                }), (s = p("previousLot"), createElement("span", {
                    className: "",
                    children: s,
                }))])]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_2))))), createElement("div", {
                    className: "level-left",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (props_9 = ofArray([["className", "mt-0"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["onClick", (_arg2_1) => {
                    startImmediate(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsApi().getNextLotLink(lotId_2)), (_arg2) => {
                        navigate(toPath(new Route(4, _arg2)));
                        return singleton.Zero();
                    })));
                }], ["className", join(" ", ["hovered-level-item"])], ["style", {
                    color: "#444444",
                    textAlign: "center",
                }], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([(s_1 = p("nextLot"), createElement("span", {
                    className: "",
                    children: s_1,
                })), createElement("i", {
                    style: {
                        paddingLeft: 10,
                    },
                    className: join(" ", ["fa", "fa-arrow-right"]),
                })])]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("level-right", props_9))))])]);
            })))), createElement("nav", createObj(Helpers_combineClasses("level", props_11))))])]);
        }))));
        return createElement("div", createObj(Helpers_combineClasses("navbar-end", props_13)));
    }
    else {
        return null;
    }
}

export const View_navbar = React_functionComponent_2F9D7239((props) => {
    let props_6, props_1, props_4, props_14;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput[1];
    const isActive = patternInput[0];
    const navRef = useReact_useRef_1505(void 0);
    const elemRef = navRef;
    const callback = (_arg1) => {
        setActive(false);
    };
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = matchValue, copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            document.addEventListener(eventType, fn, addOptions);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                document.removeEventListener(eventType, fn, removeOptions);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = matchValue_1, copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_7 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_7), [options_7]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_7), [options_7]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            document.addEventListener(eventType_1, fn_1, addOptions_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                document.removeEventListener(eventType_1, fn_1, removeOptions_1);
            }
        });
    }));
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    let info;
    const matchValue_2 = props.state.Lot;
    let pattern_matching_result_2, lotDetails;
    if (matchValue_2.tag === 1) {
        pattern_matching_result_2 = 0;
    }
    else if (matchValue_2.tag === 2) {
        if (matchValue_2.fields[0].tag === 1) {
            pattern_matching_result_2 = 2;
        }
        else {
            pattern_matching_result_2 = 1;
            lotDetails = matchValue_2.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result_2 = 0;
    }
    switch (pattern_matching_result_2) {
        case 0: {
            info = (void 0);
            break;
        }
        case 1: {
            info = lotDetails.Auction;
            break;
        }
        case 2: {
            info = (void 0);
            break;
        }
    }
    let lotId;
    const matchValue_3 = props.state.Lot;
    let pattern_matching_result_3, lotDetails_1;
    if (matchValue_3.tag === 1) {
        pattern_matching_result_3 = 0;
    }
    else if (matchValue_3.tag === 2) {
        if (matchValue_3.fields[0].tag === 1) {
            pattern_matching_result_3 = 2;
        }
        else {
            pattern_matching_result_3 = 1;
            lotDetails_1 = matchValue_3.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result_3 = 0;
    }
    switch (pattern_matching_result_3) {
        case 0: {
            lotId = (void 0);
            break;
        }
        case 1: {
            lotId = lotDetails_1.Id;
            break;
        }
        case 2: {
            lotId = (void 0);
            break;
        }
    }
    const minHeight = 45;
    const props_16 = ofArray([["className", "is-white"], ["style", createObj(toList(delay(() => append(singleton_2(["minHeight", minHeight]), delay(() => {
        if (props.state.User.tag === 0) {
            switch (width.tag) {
                case 0:
                case 1: {
                    return singleton_2(["top", 58]);
                }
                default: {
                    return singleton_2(["top", 0]);
                }
            }
        }
        else {
            switch (width.tag) {
                case 0:
                case 1: {
                    return singleton_2(["top", 58]);
                }
                default: {
                    return empty();
                }
            }
        }
    })))))], ["className", join(" ", ["navbar-catalogue"])], ["ref", navRef], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["style", {
        minHeight: minHeight,
        maxHeight: minHeight,
    }], ["children", Interop_reactApi.Children.toArray([(props_1 = toList(delay(() => append(singleton_2(["className", join(" ", ["py-0", "navbar-catalogue-item", "is-size-6-mobile", "overflowHidden", "navbarAuctionTitle"])]), delay(() => append(singleton_2(["className", "is-uppercase"]), delay(() => ((info != null) ? singleton_2(["children", (Localized_translate(value_138(info).Name) + " ") + Localized_formatDate("d MMMM", "d MMMM", value_138(info).Date)]) : empty()))))))), createElement("div", createObj(Helpers_combineClasses("navbar-item", props_1)))), (props_4 = ofArray([["style", {
        height: minHeight,
        color: "#cdcdcd",
        padding: ((((((6 + "px ") + 24) + "px ") + 8) + "px ") + 24) + "px",
    }], ["className", "has-text-grey-light"], ["onClick", (_arg1_1) => {
        setActive(!isActive);
    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-align-right", "fa-lg", "is-centered", "catalogMenuMobile"]),
        ["aria-hidden"]: true,
    })])]]), createElement("a", createObj(Helpers_combineClasses("navbar-burger", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("navbar-brand", props_6)))), (props_14 = toList(delay(() => append(isActive ? singleton_2(["className", "is-active"]) : empty(), delay(() => append(singleton_2(["className", join(" ", ["navbar-menu-white", "navbar-menu-mobile"])]), delay(() => append(singleton_2(["style", {
        minHeight: minHeight,
    }]), delay(() => {
        let elms, props_8, props_11;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_8 = toList(delay(() => append(singleton_2(["className", join(" ", ["hovered-level-item", "is-size-6-mobile"])]), delay(() => append(singleton_2(["style", {
            color: "#444444",
            textAlign: "center",
        }]), delay(() => append(singleton_2(["className", "is-uppercase"]), delay(() => {
            if (info != null) {
                const matchValue_5 = value_138(info).Status;
                switch (matchValue_5.tag) {
                    case 1: {
                        return append(singleton_2(["children", p("auction.active")]), delay(() => singleton_2(["href", toPath(new Route(2, props.state.AuctionId))])));
                    }
                    case 2: {
                        return append(singleton_2(["children", p("auction.active")]), delay(() => singleton_2(["href", toPath(new Route(2, props.state.AuctionId))])));
                    }
                    case 3: {
                        return append(singleton_2(["children", p("auction.active")]), delay(() => singleton_2(["href", toPath(new Route(2, props.state.AuctionId))])));
                    }
                    default: {
                        return append(singleton_2(["children", p("auction.closed")]), delay(() => singleton_2(["href", toPath(new Route(15))])));
                    }
                }
            }
            else {
                return empty();
            }
        })))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_8)))), (props_11 = toList(delay(() => append(singleton_2(["className", join(" ", ["is-hoverable", "hovered-level-item", "is-size-6-mobile"])]), delay(() => append(singleton_2(["style", {
            textAlign: "center",
            color: "#444444",
            display: "grid",
        }]), delay(() => append(singleton_2(["children", Interop_reactApi.Children.toArray([createElement("span", {
            className: "is-uppercase",
            children: p("navbar.text3"),
        })])]), delay(() => ((info != null) ? singleton_2(["href", toPath(new Route(3, new AuctionState(value_138(info).Id, 1, new SearchCriteria(0))))]) : empty()))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_11))))]), createElement("div", {
            className: "navbar-start",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), View_RenderGoToButton(width, lotId)])]);
    })))))))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_14))))])]]);
    return createElement("nav", createObj(Helpers_combineClasses("navbar", props_16)));
});

export function View_row(date, bidPrice, buyer, isCurrent) {
    let props_2, value_43, props_4;
    const props_6 = ofArray([["style", {
        marginBottom: 0,
    }], ["className", "is-gapless"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", join(" ", ["has-text-left"])], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(toList(delay(() => append(isCurrent ? singleton_2(["style", {
        color: "#FFFFFF",
        borderRadius: 15,
        fontSize: 10 + "px",
        fontWeight: 400,
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 7,
        paddingLeft: 6,
        borderRadius: 15,
        marginRight: 20,
        backgroundColor: "#5BAF63",
    }]) : singleton_2(["style", {
        color: "#FFFFFF",
        borderRadius: 15,
        fontSize: 10 + "px",
        fontWeight: 400,
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 7,
        paddingLeft: 6,
        marginRight: 20,
        backgroundColor: "#D66363",
    }]), delay(() => singleton_2(["children", buyer.BidId]))))))), (value_43 = Localized_formatDate("d MMM yyyy", "d MMM yyyy", toLocalTime(date)), createElement("span", {
        children: [value_43],
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", join(" ", ["has-text-right"])], ["children", bidPrice]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_6)));
}

export function View_getItemForImageGallery(image) {
    return new Media(ImageUrlModule_toString(Img_Url_mediumContains1800(image)), ImageUrlModule_toString(Img_Url_medium(image)), void 0, void 0);
}

export function View_renderVideo(media) {
    const matchValue = media.embedUrl;
    if (matchValue == null) {
        return null;
    }
    else {
        const link = matchValue;
        return createElement("div", {
            className: "video-wrapper",
            children: Interop_reactApi.Children.toArray([createElement("iframe", {
                src: link,
                className: "lot-details-video",
                title: "YouTube video player",
                allow: "accelerometer",
                allow: "autoplay",
                allow: "encrypted-media",
                allow: "gyroscope",
                allow: "picture-in-picture",
                allow: "fullscreen",
            })]),
        });
    }
}

export class View_IsVisible extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnDesktop", "OnTablet", "OnMobile"];
    }
}

export function View_IsVisible$reflection() {
    return union_type("LotDetails.View.IsVisible", [], View_IsVisible, () => [[], [], []]);
}

export function View_renderAuctionLocationInfo(isVisibleParams, auction) {
    if (auction == null) {
        return null;
    }
    else {
        const auction_1 = auction;
        const showing = Localized_translate(auction_1.Location.Showing);
        const isShowingEmpty = isNullOrEmpty(showing);
        const contact = Localized_translate(auction_1.Location.Contact);
        const isContactEmpty = isNullOrEmpty(contact);
        const payment = Localized_translate(auction_1.Location.Payment);
        const isPaymentEmpty = isNullOrEmpty(payment);
        const extradition = Localized_translate(auction_1.Location.Extradition);
        const isExtraditionEmpty = isNullOrEmpty(extradition);
        if (auction_1.Location.DisplayOnLot ? (!((((isShowingEmpty ? isNullOrEmpty(auction_1.Location.SetUpAddress) : false) ? isContactEmpty : false) ? isPaymentEmpty : false) ? isExtraditionEmpty : false)) : false) {
            const props_16 = toList(delay(() => append(singleton_2(["className", join(" ", append_1(singleton_1("lot-card-location"), (isVisibleParams.tag === 1) ? ofArray(["is-hidden-desktop", "is-hidden-mobile"]) : ((isVisibleParams.tag === 2) ? ofArray(["is-hidden-desktop", "is-hidden-tablet-only"]) : ofArray(["is-hidden-mobile", "is-hidden-tablet-only"]))))]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("p", {
                className: join(" ", ["card-lot-title"]),
                children: p("locationInfo"),
            })), delay(() => {
                let value_7;
                return append((!isShowingEmpty) ? singleton_2(createElement("p", {
                    className: join(" ", ["card-lot-subtitle"]),
                    style: {
                        whiteSpace: "pre-wrap",
                    },
                    children: Interop_reactApi.Children.toArray([(value_7 = p("locationShowing"), createElement("strong", {
                        children: [value_7],
                    })), createElement("span", {
                        children: [showing],
                    })]),
                })) : empty(), delay(() => {
                    let value_13;
                    return append((!isNullOrEmpty(auction_1.Location.SetUpAddress)) ? singleton_2(createElement("p", {
                        className: join(" ", ["card-lot-subtitle"]),
                        style: {
                            whiteSpace: "pre-wrap",
                        },
                        children: Interop_reactApi.Children.toArray([(value_13 = p("locationSetUpAddress"), createElement("strong", {
                            children: [value_13],
                        })), createElement("span", {
                            children: [auction_1.Location.SetUpAddress],
                        })]),
                    })) : empty(), delay(() => {
                        let value_19;
                        return append((!isContactEmpty) ? singleton_2(createElement("p", {
                            className: join(" ", ["card-lot-subtitle"]),
                            style: {
                                whiteSpace: "pre-wrap",
                            },
                            children: Interop_reactApi.Children.toArray([(value_19 = p("locationContact"), createElement("strong", {
                                children: [value_19],
                            })), createElement("span", {
                                children: [contact],
                            })]),
                        })) : empty(), delay(() => {
                            let value_25;
                            return append((!isPaymentEmpty) ? singleton_2(createElement("p", {
                                className: join(" ", ["card-lot-subtitle"]),
                                style: {
                                    whiteSpace: "pre-wrap",
                                },
                                children: Interop_reactApi.Children.toArray([(value_25 = p("locationPayment"), createElement("strong", {
                                    children: [value_25],
                                })), createElement("span", {
                                    children: [payment],
                                })]),
                            })) : empty(), delay(() => {
                                let value_31;
                                return (!isExtraditionEmpty) ? singleton_2(createElement("p", {
                                    className: join(" ", ["card-lot-subtitle"]),
                                    style: {
                                        whiteSpace: "pre-wrap",
                                    },
                                    children: Interop_reactApi.Children.toArray([(value_31 = p("locationExtradition"), createElement("strong", {
                                        children: [value_31],
                                    })), createElement("span", {
                                        children: [extradition],
                                    })]),
                                })) : empty();
                            }));
                        }));
                    }));
                }));
            }))))))])))));
            return createElement("div", createObj(Helpers_combineClasses("card", props_16)));
        }
        else {
            return null;
        }
    }
}

function View_renderDimension(title, number, info) {
    const str = Localized_translate(info);
    if ((number != null) ? true : (str !== "")) {
        const props_3 = ofArray([["className", "is-narrow"], ["className", "pl-0"], ["className", "lot-details-size-container"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("span", {
            className: "is-uppercase",
            children: title,
        })), delay(() => {
            let value_10, value_11;
            return (number != null) ? singleton_2((value_10 = (`${value_138(number)} cm`), createElement("strong", {
                children: [value_10],
            }))) : singleton_2((value_11 = (`${str}`), createElement("strong", {
                children: [value_11],
            })));
        }))))))]]);
        return createElement("div", createObj(Helpers_combineClasses("column", props_3)));
    }
    else {
        return createElement("div", {});
    }
}

export function View_renderContentForDesktopAndMobile(view_renderContentForDesktopAndMobileInputProps) {
    let children_1, elms, props_9, props_7, props_39, props_37, props_21, props_12, value_69, props_35;
    const date = view_renderContentForDesktopAndMobileInputProps.date;
    const dispatch = view_renderContentForDesktopAndMobileInputProps.dispatch;
    const lot = view_renderContentForDesktopAndMobileInputProps.lot;
    const state = view_renderContentForDesktopAndMobileInputProps.state;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const index = patternInput[0] | 0;
    let info;
    const matchValue = state.Lot;
    let pattern_matching_result, lotDetails;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            lotDetails = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            info = (void 0);
            break;
        }
        case 1: {
            info = lotDetails.Auction;
            break;
        }
        case 2: {
            info = (void 0);
            break;
        }
    }
    const ref = useReact_useRef_1505(void 0);
    let renderExpandIcon;
    const props_1 = ofArray([["className", join(" ", ["thumbnail-number-item-fullScreen-lot-details"])], ["onClick", (_arg1) => {
        dispatch(new Types_Msg(8, true));
    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-expand", "fa-lg"]),
    })])]]);
    renderExpandIcon = createElement("span", createObj(Helpers_combineClasses("icon", props_1)));
    const props_43 = ofArray([["className", join(" ", ["is-hidden-tablet-only"])], ["className", "is-fluid"], ["id", "container-lot"], ["children", Interop_reactApi.Children.toArray([(children_1 = singleton_1((elms = ofArray([(props_9 = ofArray([["className", "is-7"], ["style", {
        marginRight: "auto",
    }], ["id", "column-lot"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["style", {
        borderRadius: 0,
    }], ["className", join(" ", ["lot-card-image"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(Interop_reactApi.createElement(react_image_gallery, createObj(toList(delay(() => append(singleton_2(["ref", ref]), delay(() => {
        let matchValue_1;
        const items = map((image) => View_getItemForImageGallery(image), toArray(lot.Images));
        const video = map_1((link) => [new Media(Img_videoThumbnail, Img_videoThumbnail, Cmd_addEmbedToLink(link), (media) => View_renderVideo(media))], lot.VideoLink);
        return append(singleton_2(["items", (matchValue_1 = tryHead(items), (matchValue_1 == null) ? defaultArg(video, []) : ((video != null) ? concat([[matchValue_1], value_138(video), tail(items)]) : items))]), delay(() => append((lot.VideoLink != null) ? singleton_2(["showVideo", value_138(lot.VideoLink)]) : empty(), delay(() => append(singleton_2(["showPlayButton", false]), delay(() => append(singleton_2(["onSlide", patternInput[1]]), delay(() => append(singleton_2(["showFullscreenButton", false]), delay(() => singleton_2(["slideDuration", 250])))))))))));
    }))))))), delay(() => {
        let props_5;
        return append(LotPrefix_isPriority(lot.Priority) ? singleton_2((props_5 = ofArray([["className", join(" ", ["thumbnail-number-item-star-lot-details"])], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-star"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_5))))) : empty(), delay(() => {
            const matchValue_2 = lot.VideoLink;
            let pattern_matching_result_3;
            if (matchValue_2 != null) {
                if (index === 1) {
                    pattern_matching_result_3 = 0;
                }
                else {
                    pattern_matching_result_3 = 1;
                }
            }
            else {
                pattern_matching_result_3 = 1;
            }
            switch (pattern_matching_result_3) {
                case 0: {
                    return singleton_2(null);
                }
                case 1: {
                    let pattern_matching_result_4;
                    if (matchValue_2 != null) {
                        if (index > 1) {
                            pattern_matching_result_4 = 0;
                        }
                        else {
                            pattern_matching_result_4 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_4 = 1;
                    }
                    switch (pattern_matching_result_4) {
                        case 0: {
                            return append(singleton_2(renderExpandIcon), delay(() => (state.IsShowFullScreen ? singleton_2(createElement(View, {
                                isVisible: state.IsShowFullScreen,
                                setVisible: (_arg2) => {
                                    dispatch(new Types_Msg(8, !state.IsShowFullScreen));
                                },
                                link: ImageUrlModule_toString(Img_originalImagePathContains(item(index - 1, lot.Images))),
                            })) : empty())));
                        }
                        case 1: {
                            return append(singleton_2(renderExpandIcon), delay(() => (state.IsShowFullScreen ? singleton_2(createElement(View, {
                                isVisible: state.IsShowFullScreen,
                                setVisible: (_arg3) => {
                                    dispatch(new Types_Msg(8, !state.IsShowFullScreen));
                                },
                                link: ImageUrlModule_toString(Img_originalImagePathContains(item(index, lot.Images))),
                            })) : empty())));
                        }
                    }
                }
            }
        }));
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("card", props_7)))), View_renderAuctionLocationInfo(new View_IsVisible(0), lot.Auction)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9)))), (props_39 = ofArray([["className", join(" ", ["lot-info-card"])], ["children", Interop_reactApi.Children.toArray([(props_37 = ofArray([["style", {
        borderRadius: 0,
    }], ["id", "lot-card"], ["children", Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "lot-details-card-lot-number-container"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        className: "tagInCatalogAndLotDetails",
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["colorBlack", "far", "fa-tag"]),
            style: {
                marginRight: 10,
            },
        }), (props_12 = singleton_1(["children", ItemIdModule_toString(lot.ItemId)]), createElement("span", createObj(Helpers_combineClasses("", props_12))))]),
    }), createElement("p", {
        style: {
            padding: 0,
        },
        className: join(" ", ["card-header-title"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((state.User.tag === 0) ? singleton_2(null) : singleton_2(createElement("i", createObj(toList(delay(() => append(singleton_2(["className", join(" ", toList(delay(() => append(singleton_2("fa-heart"), delay(() => append(singleton_2("heard-icon"), delay(() => (state.IsLiked ? append(singleton_2("colorPrimary"), delay(() => singleton_2("fa"))) : append(singleton_2("colorBlack"), delay(() => singleton_2("far")))))))))))]), delay(() => append(singleton_2(["style", {
            marginLeft: 20,
            cursor: "pointer",
        }]), delay(() => {
            if (info != null) {
                const matchValue_4 = value_138(info).Status;
                switch (matchValue_4.tag) {
                    default: {
                        return state.IsLiked ? singleton_2(["onClick", (x_3) => {
                            x_3.preventDefault();
                            dispatch(new Types_Msg(4, lot.Id, false, new AsyncOperationStatus$1(0)));
                        }]) : singleton_2(["onClick", (x_4) => {
                            x_4.preventDefault();
                            dispatch(new Types_Msg(4, lot.Id, true, new AsyncOperationStatus$1(0)));
                        }]);
                    }
                }
            }
            else {
                return empty();
            }
        })))))))))))))),
    }), createElement("a", {
        style: {
            backgroundColor: "transparent",
            borderStyle: "none",
            padding: 0,
        },
        className: join(" ", ["card-header-icon"]),
        children: Interop_reactApi.Children.toArray([createElement("button", {
            className: join(" ", ["button-back-catalog"]),
            children: Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-chevron-left"]),
                style: {
                    marginRight: 7,
                    fontSize: 14 + "px",
                },
            }), (value_69 = p("button.back"), createElement("span", {
                children: [value_69],
            }))]),
            onClick: (x_5) => {
                x_5.preventDefault();
                history.back();
            },
        })]),
    })])]]), createElement("header", createObj(Helpers_combineClasses("card-header", props_21)))), (props_35 = ofArray([["style", {
        padding: ((0 + "px ") + 20) + "px",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("p", createObj(toList(delay(() => append(singleton_2(["className", join(" ", ["card-lot-title"])]), delay(() => {
        const name_32 = Localized_translate(lot.Name);
        const prefix = LotPrefix_getPrefix(lot.Priority);
        const displayId = (lot.DisplayId === "") ? "" : (`${prefix}${lot.DisplayId}. `);
        return singleton_2(["children", (name_32 === "") ? "-" : (`${displayId}${name_32}`)]);
    }))))))), delay(() => {
        const description = Localized_translate(lot.Description);
        return append((description !== "") ? singleton_2(createElement("p", {
            className: join(" ", ["card-lot-subtitle"]),
            style: {
                whiteSpace: "pre-wrap",
            },
            children: description,
        })) : empty(), delay(() => {
            let value_85;
            const condition = Localized_translate(lot.Condition);
            return append((condition !== "") ? singleton_2(createElement("p", {
                className: join(" ", ["card-lot-subtitle"]),
                children: Interop_reactApi.Children.toArray([(value_85 = p("condition"), createElement("strong", {
                    children: [value_85],
                })), createElement("span", {
                    children: [condition],
                })]),
            })) : empty(), delay(() => {
                let value_89;
                return append(lot.Royalties ? singleton_2(createElement("p", {
                    className: join(" ", ["card-lot-subtitle"]),
                    children: Interop_reactApi.Children.toArray([(value_89 = p("royalty"), createElement("strong", {
                        children: [value_89],
                    }))]),
                })) : empty(), delay(() => {
                    let props_30;
                    return append(singleton_2((props_30 = ofArray([["className", "is-multiline"], ["className", "is-mobile"], ["className", "fontSize-desktop12-mobile10"], ["style", {
                        marginBottom: 0,
                        paddingTop: 10,
                        fontWeight: 400,
                    }], ["children", Interop_reactApi.Children.toArray([View_renderDimension(p("height"), lot.Size.Height, lot.Size.HeightInfo), View_renderDimension(p("length"), lot.Size.Length, lot.Size.LengthInfo), View_renderDimension(p("width"), lot.Size.Width, lot.Size.WidthInfo), View_renderDimension(p("depth"), lot.Size.Depth, lot.Size.DepthInfo), View_renderDimension(p("diameter"), lot.Size.Diameter, lot.Size.DiameterInfo)])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_30))))), delay(() => {
                        let value_105;
                        return append(((lot.GpsPosition.Address != null) ? (lot.GpsPosition.MapLink != null) : false) ? singleton_2(createElement("p", {
                            className: join(" ", ["card-lot-subtitle"]),
                            children: Interop_reactApi.Children.toArray([(value_105 = p("gpsPosition"), createElement("strong", {
                                children: [value_105],
                            })), createElement("a", {
                                target: "_blank",
                                className: "link",
                                style: {
                                    color: "#4a4a4a",
                                },
                                href: value_138(lot.GpsPosition.MapLink),
                                children: value_138(lot.GpsPosition.Address),
                            })]),
                        })) : empty(), delay(() => singleton_2(createElement(Render, {
                            lot: lot,
                            userId: Users_UserModule_userId(state.User),
                            date: date,
                        }))));
                    }));
                }));
            }));
        }));
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_35))))])]]), createElement("div", createObj(Helpers_combineClasses("card", props_37)))), View_renderAuctionLocationInfo(new View_IsVisible(2), lot.Auction)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_39))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_43)));
}

export function View_renderContentForTabletOnly(view_renderContentForTabletOnlyInputProps) {
    let children_1, props_46, props_9, props_7, props_44, props_42, props_21, props_12, value_73, elms, props_37, props_35, props_39;
    const date = view_renderContentForTabletOnlyInputProps.date;
    const dispatch = view_renderContentForTabletOnlyInputProps.dispatch;
    const lot = view_renderContentForTabletOnlyInputProps.lot;
    const state = view_renderContentForTabletOnlyInputProps.state;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const index = patternInput[0] | 0;
    let info;
    const matchValue = state.Lot;
    let pattern_matching_result, lotDetails;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            lotDetails = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            info = (void 0);
            break;
        }
        case 1: {
            info = lotDetails.Auction;
            break;
        }
        case 2: {
            info = (void 0);
            break;
        }
    }
    const ref = useReact_useRef_1505(void 0);
    let renderExpandIcon;
    const props_1 = ofArray([["className", join(" ", ["thumbnail-number-item-fullScreen-lot-details"])], ["onClick", (_arg1) => {
        dispatch(new Types_Msg(8, true));
    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-expand", "fa-lg"]),
    })])]]);
    renderExpandIcon = createElement("span", createObj(Helpers_combineClasses("icon", props_1)));
    const props_49 = ofArray([["className", join(" ", ["is-hidden-desktop", "is-hidden-mobile", "lot-details-container-tablet"])], ["id", "container-lot"], ["children", Interop_reactApi.Children.toArray([(children_1 = singleton_1((props_46 = ofArray([["className", "is-desktop"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-12"], ["style", {
        marginRight: "auto",
        padding: ((((((12 + "px ") + 0) + "px ") + 0) + "px ") + 0) + "px",
    }], ["id", "column-lot"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["style", {
        borderRadius: 0,
    }], ["className", join(" ", ["lot-card-image"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(Interop_reactApi.createElement(react_image_gallery, createObj(toList(delay(() => {
        const items = map((image) => View_getItemForImageGallery(image), toArray(lot.Images));
        const video = map_1((link) => [new Media(Img_videoThumbnail, Img_videoThumbnail, Cmd_addEmbedToLink(link), (media) => View_renderVideo(media))], lot.VideoLink);
        let media_1;
        const matchValue_1 = tryHead(items);
        media_1 = ((matchValue_1 == null) ? defaultArg(video, []) : ((video != null) ? concat([[matchValue_1], value_138(video), tail(items)]) : items));
        return append(singleton_2(["ref", ref]), delay(() => append(singleton_2(["items", media_1]), delay(() => append((lot.VideoLink != null) ? singleton_2(["showVideo", value_138(lot.VideoLink)]) : empty(), delay(() => append(singleton_2(["showPlayButton", false]), delay(() => append(singleton_2(["onSlide", patternInput[1]]), delay(() => append(singleton_2(["showFullscreenButton", false]), delay(() => append(singleton_2(["swipingTransitionDuration", 1000]), delay(() => singleton_2(["slideDuration", 250])))))))))))))));
    }))))), delay(() => {
        let props_5;
        return append(LotPrefix_isPriority(lot.Priority) ? singleton_2((props_5 = ofArray([["className", join(" ", ["thumbnail-number-item-star-lot-details"])], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-star"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_5))))) : empty(), delay(() => {
            const matchValue_2 = lot.VideoLink;
            let pattern_matching_result_1;
            if (matchValue_2 != null) {
                if (index === 1) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return singleton_2(null);
                }
                case 1: {
                    let pattern_matching_result_2;
                    if (matchValue_2 != null) {
                        if (index > 1) {
                            pattern_matching_result_2 = 0;
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return append(singleton_2(renderExpandIcon), delay(() => (state.IsShowFullScreen ? singleton_2(createElement(View, {
                                isVisible: state.IsShowFullScreen,
                                setVisible: (_arg2) => {
                                    dispatch(new Types_Msg(8, !state.IsShowFullScreen));
                                },
                                link: ImageUrlModule_toString(Img_originalImagePathContains(item(index - 1, lot.Images))),
                            })) : empty())));
                        }
                        case 1: {
                            return append(singleton_2(renderExpandIcon), delay(() => (state.IsShowFullScreen ? singleton_2(createElement(View, {
                                isVisible: state.IsShowFullScreen,
                                setVisible: (_arg3) => {
                                    dispatch(new Types_Msg(8, !state.IsShowFullScreen));
                                },
                                link: ImageUrlModule_toString(Img_originalImagePathContains(item(index, lot.Images))),
                            })) : empty())));
                        }
                    }
                }
            }
        }));
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("card", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9)))), (props_44 = ofArray([["className", "is-12"], ["className", join(" ", ["lot-info-card"])], ["children", Interop_reactApi.Children.toArray([(props_42 = ofArray([["style", {
        borderRadius: 0,
    }], ["id", "lot-card"], ["children", Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "lot-details-card-lot-number-container"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        className: "tagInCatalogAndLotDetails",
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["colorBlack", "far", "fa-tag"]),
            style: {
                marginRight: 10,
            },
        }), (props_12 = singleton_1(["children", ItemIdModule_toString(lot.ItemId)]), createElement("span", createObj(Helpers_combineClasses("", props_12))))]),
    }), createElement("span", {
        style: {
            padding: 0,
        },
        className: join(" ", ["card-header-title"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((state.User.tag === 0) ? singleton_2(null) : singleton_2(createElement("i", createObj(toList(delay(() => append(singleton_2(["className", join(" ", toList(delay(() => append(singleton_2("fa-heart"), delay(() => append(singleton_2("heard-icon"), delay(() => (state.IsLiked ? append(singleton_2("fa"), delay(() => singleton_2("colorPrimary"))) : append(singleton_2("colorBlack"), delay(() => singleton_2("far")))))))))))]), delay(() => append(singleton_2(["style", {
            marginLeft: 20,
            cursor: "pointer",
        }]), delay(() => {
            if (info != null) {
                const matchValue_4 = value_138(info).Status;
                switch (matchValue_4.tag) {
                    default: {
                        return state.IsLiked ? singleton_2(["onClick", (x_3) => {
                            x_3.preventDefault();
                            dispatch(new Types_Msg(4, lot.Id, false, new AsyncOperationStatus$1(0)));
                        }]) : singleton_2(["onClick", (x_4) => {
                            x_4.preventDefault();
                            dispatch(new Types_Msg(4, lot.Id, true, new AsyncOperationStatus$1(0)));
                        }]);
                    }
                }
            }
            else {
                return empty();
            }
        })))))))))))))),
    }), createElement("a", {
        style: {
            backgroundColor: "transparent",
            borderStyle: "none",
            padding: 0,
        },
        className: join(" ", ["card-header-icon"]),
        children: Interop_reactApi.Children.toArray([createElement("button", {
            className: join(" ", ["button-back-catalog"]),
            children: Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-chevron-left"]),
                style: {
                    marginRight: 7,
                    fontSize: 14 + "px",
                },
            }), (value_73 = p("button.back"), createElement("span", {
                children: [value_73],
            }))]),
            onClick: (x_5) => {
                x_5.preventDefault();
                history.back();
            },
        })]),
    })])]]), createElement("header", createObj(Helpers_combineClasses("card-header", props_21)))), (elms = ofArray([(props_37 = ofArray([["className", "is-6"], ["style", {
        paddingLeft: 0,
    }], ["children", Interop_reactApi.Children.toArray([(props_35 = ofArray([["style", {
        padding: ((0 + "px ") + 20) + "px",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("p", createObj(toList(delay(() => append(singleton_2(["className", join(" ", ["card-lot-title"])]), delay(() => {
        const name_32 = Localized_translate(lot.Name);
        const prefix = LotPrefix_getPrefix(lot.Priority);
        const displayId = (lot.DisplayId === "") ? "" : (`${lot.DisplayId}. `);
        return singleton_2(["children", (name_32 === "") ? "-" : (`${prefix}${displayId}${name_32}`)]);
    }))))))), delay(() => {
        const description = Localized_translate(lot.Description);
        return append((description !== "") ? singleton_2(createElement("p", {
            className: join(" ", ["card-lot-subtitle"]),
            children: description,
        })) : empty(), delay(() => {
            let value_92;
            const condition = Localized_translate(lot.Condition);
            return append((condition !== "") ? singleton_2(createElement("p", {
                className: join(" ", ["card-lot-subtitle"]),
                children: Interop_reactApi.Children.toArray([(value_92 = p("condition"), createElement("strong", {
                    children: [value_92],
                })), createElement("span", {
                    children: [condition],
                })]),
            })) : empty(), delay(() => {
                let value_96;
                return append(lot.Royalties ? singleton_2(createElement("p", {
                    className: join(" ", ["card-lot-subtitle"]),
                    children: Interop_reactApi.Children.toArray([(value_96 = p("royalty"), createElement("strong", {
                        children: [value_96],
                    }))]),
                })) : empty(), delay(() => {
                    let props_30;
                    return append(singleton_2((props_30 = ofArray([["className", "is-multiline"], ["className", "is-mobile"], ["className", "fontSize-desktop12-mobile10"], ["style", {
                        marginBottom: 0,
                        paddingTop: 10,
                        fontWeight: 400,
                    }], ["children", Interop_reactApi.Children.toArray([View_renderDimension(p("height"), lot.Size.Height, lot.Size.HeightInfo), View_renderDimension(p("length"), lot.Size.Length, lot.Size.LengthInfo), View_renderDimension(p("width"), lot.Size.Width, lot.Size.WidthInfo), View_renderDimension(p("depth"), lot.Size.Depth, lot.Size.DepthInfo), View_renderDimension(p("diameter"), lot.Size.Diameter, lot.Size.DiameterInfo)])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_30))))), delay(() => {
                        let value_112;
                        return ((lot.GpsPosition.Address != null) ? (lot.GpsPosition.MapLink != null) : false) ? singleton_2(createElement("p", {
                            className: join(" ", ["card-lot-subtitle"]),
                            children: Interop_reactApi.Children.toArray([(value_112 = p("gpsPosition"), createElement("strong", {
                                children: [value_112],
                            })), createElement("a", {
                                className: "link",
                                target: "_blank",
                                style: {
                                    color: "#4a4a4a",
                                },
                                href: value_138(lot.GpsPosition.MapLink),
                                children: value_138(lot.GpsPosition.Address),
                            })]),
                        })) : empty();
                    }));
                }));
            }));
        }));
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_35))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_37)))), (props_39 = ofArray([["className", "is-6"], ["style", {
        paddingRight: 20,
    }], ["children", Interop_reactApi.Children.toArray([createElement(Render, {
        lot: lot,
        userId: Users_UserModule_userId(state.User),
        date: date,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_39))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("card", props_42)))), View_renderAuctionLocationInfo(new View_IsVisible(1), lot.Auction)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_44))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_46))))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_49)));
}

export function View_RenderContent(view_RenderContentInputProps) {
    const dispatch = view_RenderContentInputProps.dispatch;
    const state = view_RenderContentInputProps.state;
    const lot = view_RenderContentInputProps.lot;
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const matchValue = state.EstimatedTimeInfo;
    let pattern_matching_result, date;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            date = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = singleton_1(["children", Interop_reactApi.Children.toArray([mediumInline(p("loading.lot"))])]);
            return createElement("section", createObj(Helpers_combineClasses("section", props)));
        }
        case 1: {
            return react.createElement(react.Fragment, {}, ...toList(delay(() => ((width.tag === 2) ? singleton_2(createElement(View_renderContentForTabletOnly, {
                state: state,
                lot: lot,
                dispatch: dispatch,
                date: date,
            })) : singleton_2(createElement(View_renderContentForDesktopAndMobile, {
                state: state,
                lot: lot,
                dispatch: dispatch,
                date: date,
            }))))));
        }
        case 2: {
            return react.createElement(react.Fragment, {}, ...toList(delay(() => ((width.tag === 2) ? singleton_2(createElement(View_renderContentForTabletOnly, {
                state: state,
                lot: lot,
                dispatch: dispatch,
                date: now(),
            })) : singleton_2(createElement(View_renderContentForDesktopAndMobile, {
                state: state,
                lot: lot,
                dispatch: dispatch,
                date: now(),
            }))))));
        }
    }
}

export function View_render(state, dispatch) {
    return createElement("div", {
        className: join(" ", ["background-container", "padding_inherit"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(View_navbar({
            dispatch: dispatch,
            state: state,
        })), delay(() => {
            let props, props_2;
            const matchValue = state.Lot;
            let pattern_matching_result, er, lot;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 2;
                    lot = matchValue.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    er = matchValue.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2((props = singleton_1(["children", Interop_reactApi.Children.toArray([mediumInline(p("loading.lot"))])]), createElement("section", createObj(Helpers_combineClasses("section", props)))));
                }
                case 1: {
                    return singleton_2((props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([`Something was wrong - ${er}`])]), createElement("div", createObj(Helpers_combineClasses("notification", props_2)))));
                }
                case 2: {
                    return singleton_2(createElement(View_RenderContent, {
                        lot: lot,
                        state: state,
                        dispatch: dispatch,
                    }));
                }
            }
        })))))),
    });
}

export function Component_renderLot(component_renderLotInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(component_renderLotInputProps.lotId, component_renderLotInputProps.user), (msg, state) => State_update(msg, state), []);
    return View_render(patternInput[0], patternInput[1]);
}

