import { post, receive, start } from "../fable_modules/fable-library.3.6.1/MailboxProcessor.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { toString as toString_1, equals, fromBits, compare } from "../fable_modules/fable-library.3.6.1/Long.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { empty, add, tryFind } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Sounds_finishBidding, Sounds_overBid } from "../Css.js";
import { toString, Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { StreamFrom_Response$reflection, MakeBid_PlaceBidErrorReason$reflection, Lot_Lot_get_Zero, Lot_Lot$reflection } from "../Shared/Bidding.js";
import { union_type, class_type, record_type, bool_type, string_type, option_type, int32_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { next, Settings$reflection } from "../bidflow/src/infrastructure/Bidding.js";
import { ofArray, map as map_1, empty as empty_1, singleton as singleton_1, append, sortDescending, tryHead } from "../fable_modules/fable-library.3.6.1/List.js";
import { comparePrimitives } from "../fable_modules/fable-library.3.6.1/Util.js";
import { tWithArgs, t } from "../Localization.js";
import { Cmd_batch, Cmd_none, Cmd_OfFunc_attempt } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Remoting_bidsChanged } from "../Extensions.js";
import { append as append_1 } from "../fable_modules/fable-library.3.6.1/Array.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Feliz_React__React_useLotStream_Static_75765E85 } from "./SignalR.js";

export function MusicBox_createMusicBox() {
    return start((inbox) => {
        const messageLoop = (history) => singleton.Delay(() => singleton.Bind(receive(inbox), (_arg1) => {
            const version = _arg1[2];
            const lotId = _arg1[1];
            return singleton.ReturnFrom(messageLoop((compare(version, defaultArg(tryFind(lotId, history), fromBits(0, 0, false))) > 0) ? (((new Audio(_arg1[0])).play(), add(lotId, version, history))) : history));
        }));
        return messageLoop(empty());
    });
}

export const MusicBox_musicBox = MusicBox_createMusicBox();

export function MusicBox_playOverbid(lotId, version) {
    post(MusicBox_musicBox, [Sounds_overBid, lotId, version]);
}

export function MusicBox_playFinished(lotId, version) {
    post(MusicBox_musicBox, [Sounds_finishBidding, lotId, version]);
}

export class Types_State extends Record {
    constructor(Lot, Bid, Error$, IsRequestToBidProcessing, NeedBlink, Settings, Confirm) {
        super();
        this.Lot = Lot;
        this.Bid = Bid;
        this.Error = Error$;
        this.IsRequestToBidProcessing = IsRequestToBidProcessing;
        this.NeedBlink = NeedBlink;
        this.Settings = Settings;
        this.Confirm = Confirm;
    }
}

export function Types_State$reflection() {
    return record_type("Bidflow.FO.Client.Components.Bidding.Types.State", [], Types_State, () => [["Lot", Lot_Lot$reflection()], ["Bid", option_type(int32_type)], ["Error", string_type], ["IsRequestToBidProcessing", bool_type], ["NeedBlink", bool_type], ["Settings", option_type(Settings$reflection())], ["Confirm", bool_type]]);
}

export function Types_State_get_Zero() {
    return new Types_State(Lot_Lot_get_Zero(), void 0, "", false, false, void 0, false);
}

export function Types_State__get_NextBid(this$) {
    const lastBid = defaultArg(tryHead(sortDescending(append(defaultArg(map((x) => singleton_1(x.Limit), this$.Lot.MyLastAcceptedBid), empty_1()), map_1((x_1) => x_1.Bid, this$.Lot.History)), {
        Compare: (x_2, y) => comparePrimitives(x_2, y),
    })), 0) | 0;
    return map((settings) => next(settings.Intervals)(lastBid), this$.Settings);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SetBid", "BidRequestIsAboutToSend", "BidAccepted", "BidRejected", "FromServer", "FailedNotifyBidsChanged", "SetConfirm"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Bidflow.FO.Client.Components.Bidding.Types.Msg", [], Types_Msg, () => [[["Item", int32_type]], [], [["Item", Lot_Lot$reflection()]], [["Item", MakeBid_PlaceBidErrorReason$reflection()]], [["Item", StreamFrom_Response$reflection()]], [["Item", class_type("System.Exception")]], [["Item", bool_type]]]);
}

export function State_p(key) {
    return t("bidding." + key);
}

export function State_Cmd_notifyBidsChanged(state) {
    if (state.Lot.MyLastAcceptedBid != null) {
        return Cmd_OfFunc_attempt((lotId) => {
            Remoting_bidsChanged(lotId);
        }, state.Lot.Id, (arg0) => (new Types_Msg(5, arg0)));
    }
    else {
        return Cmd_none();
    }
}

export function State_Cmd_playSound(state) {
    const matchValue = state.Lot.Status;
    switch (matchValue.tag) {
        case 1:
        case 2: {
            return singleton_1((_arg1) => {
                MusicBox_playOverbid(state.Lot.Id, state.Lot.Version);
            });
        }
        case 3: {
            return singleton_1((_arg2) => {
                MusicBox_playFinished(state.Lot.Id, state.Lot.Version);
            });
        }
        default: {
            return singleton_1((_arg1) => {
                MusicBox_playOverbid(state.Lot.Id, state.Lot.Version);
            });
        }
    }
}

export function State_init() {
    return [Types_State_get_Zero(), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.tag === 6) {
        return [new Types_State(state.Lot, (!state.Confirm) ? state.Bid : Types_State__get_NextBid(state), state.Error, state.IsRequestToBidProcessing, state.NeedBlink, state.Settings, msg.fields[0]), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new Types_State(state.Lot, state.Bid, "", true, state.NeedBlink, state.Settings, state.Confirm), Cmd_none()];
    }
    else if (msg.tag === 2) {
        const newState = new Types_State(msg.fields[0], state.Bid, state.Error, state.IsRequestToBidProcessing, state.NeedBlink, state.Settings, state.Confirm);
        return [new Types_State(newState.Lot, Types_State__get_NextBid(newState), newState.Error, false, newState.NeedBlink, newState.Settings, newState.Confirm), Cmd_batch(ofArray([State_Cmd_notifyBidsChanged(newState), State_Cmd_playSound(newState)]))];
    }
    else if (msg.tag === 3) {
        return [new Types_State(state.Lot, state.Bid, (msg.fields[0].tag === 1) ? tWithArgs("bidding.invalid.bid.value", {
            y: (msg.fields[0].fields[0].Price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        }) : ((msg.fields[0].tag === 2) ? tWithArgs("bidding.your.bid.greater", {
            y: (msg.fields[0].fields[0].Price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        }) : ((msg.fields[0].tag === 3) ? State_p("failed.to.bid") : ((msg.fields[0].tag === 4) ? msg.fields[0].fields[0] : tWithArgs("bidding.your.bid.lower", {
            y: (msg.fields[0].fields[0].Price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        })))), false, state.NeedBlink, state.Settings, state.Confirm), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            if (compare(msg.fields[0].fields[1].Version, state.Lot.Version) > 0) {
                const newState_1 = new Types_State(msg.fields[0].fields[1], state.Bid, state.Error, state.IsRequestToBidProcessing, state.NeedBlink, state.Settings, state.Confirm);
                return [new Types_State(newState_1.Lot, state.Confirm ? state.Bid : Types_State__get_NextBid(newState_1), newState_1.Error, newState_1.IsRequestToBidProcessing, !equals(msg.fields[0].fields[0], fromBits(0, 0, false)), newState_1.Settings, newState_1.Confirm), equals(msg.fields[0].fields[0], fromBits(0, 0, false)) ? Cmd_none() : State_Cmd_playSound(newState_1)];
            }
            else {
                return [new Types_State(state.Lot, state.Bid, state.Error, state.IsRequestToBidProcessing, true, state.Settings, state.Confirm), Cmd_none()];
            }
        }
        else if (msg.fields[0].tag === 2) {
            return [state, Cmd_none()];
        }
        else if (msg.fields[0].tag === 3) {
            return [state, Cmd_none()];
        }
        else {
            return [new Types_State(state.Lot, state.Bid, state.Error, state.IsRequestToBidProcessing, state.NeedBlink, msg.fields[0].fields[0], state.Confirm), Cmd_none()];
        }
    }
    else if (msg.tag === 5) {
        console.error(some(`Failed to dispatch custom event 'Bids.Changed' for lotId = ${state.Lot.Id}.`), msg.fields[0]);
        return [state, Cmd_none()];
    }
    else {
        return [new Types_State(state.Lot, msg.fields[0], state.Error, state.IsRequestToBidProcessing, state.NeedBlink, state.Settings, state.Confirm), Cmd_none()];
    }
}

export function useFeliz_React__React_useLotBidding_Static_6DD843DB(lotId, userId, dependencies) {
    let dependencies_1;
    const array2 = [toString(userId), toString_1(lotId)];
    dependencies_1 = append_1(defaultArg(dependencies, []), array2);
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(State_init, (msg, state) => State_update(msg, state), dependencies_1);
    const dispatch = patternInput[1];
    return [patternInput[0], dispatch, Feliz_React__React_useLotStream_Static_75765E85((arg) => {
        dispatch(new Types_Msg(4, arg));
    }, lotId, userId, dependencies_1)[1]];
}

