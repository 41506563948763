import { t } from "./Localization.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { record_type, bool_type, class_type, list_type, union_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi } from "./Communication.js";
import { EmailModule_ofString, EmailModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Url_getCurrentUrlPage, Form_getFieldError, Form_validationForgotPassword } from "./Common.js";
import { value as value_119 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { printf, toText, join } from "./fable_modules/fable-library.3.6.1/String.js";
import { singleton as singleton_2, fold, cons, empty as empty_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty as empty_2, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Route, toPath } from "./Router.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";

export function p(key) {
    return t("forgotPassword." + key);
}

export class Types_State extends Record {
    constructor(Email, Recovery, FormErrors, FormValidation) {
        super();
        this.Email = Email;
        this.Recovery = Recovery;
        this.FormErrors = FormErrors;
        this.FormValidation = FormValidation;
    }
}

export function Types_State$reflection() {
    return record_type("ForgotPassword.Types.State", [], Types_State, () => [["Email", string_type], ["Recovery", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["FormValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailChanged", "RecoverAccount", "UserSaved"];
    }
}

export function Types_Msg$reflection() {
    return union_type("ForgotPassword.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))]], []]);
}

export function Cmd_recover(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(usersApi().requestResetPassword(state.Email), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, p("userNotExist"))))) : singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.fields[0]))))))), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init() {
    return [new Types_State(EmailModule_empty, new Deferred$1(0), empty(), false), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Email, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Email, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation), Cmd_none()];
            }
        }
        else {
            const errors_1 = Form_validationForgotPassword(state.Email, (new Types_State(state.Email, state.Recovery, state.FormErrors, true)).FormValidation);
            if (value_119(errors_1).Equals(empty())) {
                return [new Types_State(state.Email, new Deferred$1(1), value_119(errors_1), true), Cmd_fromAsync(Cmd_recover(state))];
            }
            else {
                return [new Types_State(state.Email, new Deferred$1(0), value_119(errors_1), true), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 2) {
        return [state, Cmd_none()];
    }
    else {
        return [new Types_State(msg.fields[0], state.Recovery, value_119(Form_validationForgotPassword(msg.fields[0], state.FormValidation)), state.FormValidation), Cmd_none()];
    }
}

export function View_renderRequestingError(state, dispatch) {
    const matchValue = state.Recovery;
    let pattern_matching_result, error;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return null;
        }
        case 1: {
            const props_3 = ofArray([["style", {
                marginTop: 20,
            }], ["className", "is-success"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    textAlign: "left",
                },
                children: Interop_reactApi.Children.toArray([createElement("i", {
                    style: {
                        paddingLeft: 15,
                        fontSize: 25 + "px",
                    },
                    className: join(" ", ["fa", "fa-info-circle"]),
                }), createElement("p", {
                    style: {
                        fontSize: 14 + "px",
                        fontWeight: 400,
                    },
                    children: p("emailWithInstructions"),
                })]),
            })])]]);
            return createElement("div", createObj(Helpers_combineClasses("notification", props_3)));
        }
        case 2: {
            const props_8 = ofArray([["style", {
                marginTop: 80,
            }], ["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    textAlign: "left",
                },
                children: Interop_reactApi.Children.toArray([createElement("i", {
                    style: {
                        paddingLeft: 15,
                        fontSize: 25 + "px",
                    },
                    className: join(" ", ["fa", "fa-2x", "fa-info-circle"]),
                }), createElement("p", {
                    style: {
                        fontSize: 14 + "px",
                        fontWeight: 400,
                    },
                    children: error,
                })]),
            })])]]);
            return createElement("div", createObj(Helpers_combineClasses("notification", props_8)));
        }
    }
}

export function View_view(state, dispatch, width) {
    return createElement("form", createObj(toList(delay(() => append(singleton_1(["onSubmit", (x) => {
        x.preventDefault();
        dispatch(new Types_Msg(1, new AsyncOperationStatus$1(0)));
    }]), delay(() => append(singleton_1(["className", join(" ", ["background-container-login-forgot-create", "create-account-column"])]), delay(() => append((width.tag === 0) ? singleton_1(["style", {}]) : ((width.tag === 1) ? singleton_1(["style", {}]) : ((width.tag === 2) ? singleton_1(["style", {
        minHeight: 814,
    }]) : ((width.tag === 3) ? singleton_1(["style", {
        minHeight: 814,
    }]) : singleton_1(["style", {}])))), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_19;
            const matchValue = state.Recovery;
            let pattern_matching_result;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 1;
                }
                else {
                    pattern_matching_result = 0;
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_1((props_19 = ofArray([["className", "login-box"], ["style", {
                        maxHeight: 650,
                    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["create-account-title"]),
                        children: p("title"),
                    })), delay(() => {
                        let props_10, props_8;
                        return append(singleton_1((props_10 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["style", {
                            paddingBottom: 0,
                        }], ["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let props_1;
                            const errors = Form_getFieldError(state.FormErrors, "Email");
                            return append(singleton_1((props_1 = toList(delay(() => append(singleton_1(["className", "is-large"]), delay(() => append((width.tag === 0) ? singleton_1(["className", "is-medium"]) : ((width.tag === 1) ? singleton_1(["className", "is-medium"]) : ((width.tag === 2) ? singleton_1(["className", "is-medium"]) : singleton_1(["className", "is-large"]))), delay(() => append((!equals(errors, empty_1())) ? singleton_1(["style", {
                                borderColor: "#FF0000",
                                boxShadow: "none",
                            }]) : empty_2(), delay(() => append(singleton_1(["type", "email"]), delay(() => append(singleton_1(["placeholder", p("forgotPassword.email")]), delay(() => singleton_1(["onChange", (ev) => {
                                dispatch(new Types_Msg(0, EmailModule_ofString(ev.target.value)));
                            }]))))))))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_1)))))), delay(() => {
                                let props_3;
                                return append(singleton_1((props_3 = ofArray([["style", {
                                    color: "#FF0000",
                                    textAlign: "left",
                                }], ["children", fold((s_1, x_1) => toText(printf("%s %s"))(s_1)(x_1), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_3))))), delay(() => {
                                    let props_5;
                                    return append(singleton_1((props_5 = singleton_2(["className", join(" ", ["fas", "fa-envelope", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_5))))), delay(() => singleton_1(createElement("a", {
                                        style: {
                                            fontSize: 16 + "px",
                                            fontWeight: 400,
                                            color: "#008cba",
                                        },
                                        children: p("back.link"),
                                        href: toPath(new Route(6, Url_getCurrentUrlPage())),
                                    }))));
                                }));
                            }));
                        }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_10))))), delay(() => {
                            let props_12;
                            return append(singleton_1((props_12 = ofArray([["className", "is-primary"], ["className", "is-fullwidth"], ["className", join(" ", ["create-account-button"])], ["children", p("button.send")]]), createElement("button", createObj(Helpers_combineClasses("button", props_12))))), delay(() => {
                                let props_17;
                                const matchValue_1 = state.Recovery;
                                let pattern_matching_result_1, error;
                                if (matchValue_1.tag === 2) {
                                    if (matchValue_1.fields[0].tag === 1) {
                                        pattern_matching_result_1 = 0;
                                        error = matchValue_1.fields[0].fields[0];
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                                switch (pattern_matching_result_1) {
                                    case 0: {
                                        return singleton_1((props_17 = ofArray([["style", {
                                            marginTop: 80,
                                        }], ["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                                            style: {
                                                textAlign: "left",
                                            },
                                            children: Interop_reactApi.Children.toArray([createElement("i", {
                                                style: {
                                                    paddingLeft: 15,
                                                    fontSize: 25 + "px",
                                                },
                                                className: join(" ", ["fa", "fa-2x", "fa-info-circle"]),
                                            }), createElement("p", {
                                                style: {
                                                    fontSize: 14 + "px",
                                                    fontWeight: 400,
                                                },
                                                children: error,
                                            })]),
                                        })])]]), createElement("div", createObj(Helpers_combineClasses("notification", props_17)))));
                                    }
                                    case 1: {
                                        return singleton_1(null);
                                    }
                                }
                            }));
                        }));
                    }))))))]]), createElement("div", createObj(Helpers_combineClasses("box", props_19)))));
                }
                case 1: {
                    return singleton_1(createElement("div", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["background-container-login-forgot-create", "create-account-column"])]), delay(() => append((width.tag === 0) ? singleton_1(["style", {}]) : ((width.tag === 1) ? singleton_1(["style", {}]) : ((width.tag === 2) ? singleton_1(["style", {
                        minHeight: 814,
                    }]) : singleton_1(["style", {
                        minHeight: 500,
                    }]))), delay(() => {
                        let props_26, value_107;
                        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["className", "login-box"], ["style", {
                            maxHeight: 400,
                        }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                            style: {
                                padding: 20,
                                marginBottom: 20,
                                borderBottom: (((1 + "px ") + "dashed") + " ") + "#ccc",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("p", {
                                className: join(" ", ["create-account-title"]),
                                children: p("success.title"),
                            }), createElement("div", {
                                className: "buy-text",
                                children: Interop_reactApi.Children.toArray([(value_107 = p("emailWithInstructions"), createElement("p", {
                                    children: [value_107],
                                }))]),
                            })]),
                        }), createElement("a", {
                            className: join(" ", ["button", "buy-button-big"]),
                            children: p("button.login"),
                            href: toPath(new Route(0)),
                        })])]]), createElement("div", createObj(Helpers_combineClasses("box", props_26))))])]);
                    })))))))));
                }
            }
        })))),
    })])])))))))))));
}

export function Component_recoverPassword() {
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(State_init, (msg, state) => State_update(msg, state), []);
    return View_view(patternInput[0], patternInput[1], useFeliz_React__React_useResponsive_Static_Z1648B8FF());
}

