import { Record, Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { Errors_ServerError, Errors_DomainError, Auth_LoginInfo, Auth_LoggedInUser$reflection, Errors_ServerError$reflection } from "./Shared/Shared.js";
import { record_type, bool_type, class_type, list_type, union_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Result_MapError, FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { authApi } from "./Communication.js";
import { saveUser } from "./LocalStorage.js";
import { EmailModule_ofString, EmailModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_OfFunc_perform, Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { map, defaultArg, value as value_114 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Form_getFieldError, Form_validationLogin } from "./Common.js";
import { Route, toPath } from "./Router.js";
import { Navigation_newUrl } from "./fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { singleton as singleton_2, fold, cons, empty as empty_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { t } from "./Localization.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { empty as empty_2, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export class Types_ErrorType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ServerError", "Exception"];
    }
}

export function Types_ErrorType$reflection() {
    return union_type("Login.Types.ErrorType", [], Types_ErrorType, () => [[["Item", Errors_ServerError$reflection()]], [["Item", string_type]]]);
}

export class Types_ValidState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Valid", "NotValid"];
    }
}

export function Types_ValidState$reflection() {
    return union_type("Login.Types.ValidState", [], Types_ValidState, () => [[], [["Item", Types_ErrorType$reflection()]]]);
}

export class Types_State extends Record {
    constructor(Login, Password, LoginResult, isUserValid, FormErrors, FormValidation) {
        super();
        this.Login = Login;
        this.Password = Password;
        this.LoginResult = LoginResult;
        this.isUserValid = isUserValid;
        this.FormErrors = FormErrors;
        this.FormValidation = FormValidation;
    }
}

export function Types_State$reflection() {
    return record_type("Login.Types.State", [], Types_State, () => [["Login", string_type], ["Password", string_type], ["LoginResult", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Auth_LoggedInUser$reflection(), Types_ErrorType$reflection()], FSharpResult$2, () => [[["ResultValue", Auth_LoggedInUser$reflection()]], [["ErrorValue", Types_ErrorType$reflection()]]]))], ["isUserValid", Types_ValidState$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["FormValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoginChanged", "PasswordChanged", "Login", "UserSaved"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Login.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Auth_LoggedInUser$reflection(), Types_ErrorType$reflection()], FSharpResult$2, () => [[["ResultValue", Auth_LoggedInUser$reflection()]], [["ErrorValue", Types_ErrorType$reflection()]]]))]], []]);
}

export function Cmd_login(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const loginInfo = new Auth_LoginInfo(state.Login, state.Password);
        return singleton.Bind(authApi().logIn(loginInfo), (_arg1) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, Result_MapError((arg0) => (new Types_ErrorType(0, arg0)), _arg1)))));
    }), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new Types_ErrorType(1, _arg2.message)))))));
}

export function Cmd_saveUser(user) {
    saveUser(user);
}

export function State_init() {
    return [new Types_State(EmailModule_empty, "", new Deferred$1(0), new Types_ValidState(0), empty(), false), Cmd_none()];
}

export function State_update(url, signin, msg, state) {
    if (msg.tag === 1) {
        return [new Types_State(state.Login, msg.fields[0], state.LoginResult, state.isUserValid, value_114(Form_validationLogin(new Auth_LoginInfo(state.Login, msg.fields[0]), state.FormValidation)), state.FormValidation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Login, state.Password, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), new Types_ValidState(1, msg.fields[0].fields[0].fields[0]), state.FormErrors, state.FormValidation), Cmd_none()];
            }
            else {
                const url_1 = defaultArg(map((_encodedUrl) => (decodeURIComponent(_encodedUrl)), url), toPath(new Route(0)));
                return [new Types_State(state.Login, state.Password, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), new Types_ValidState(0), state.FormErrors, state.FormValidation), Cmd_batch(ofArray([Cmd_OfFunc_perform((user_1) => {
                    Cmd_saveUser(user_1);
                }, msg.fields[0].fields[0].fields[0], () => (new Types_Msg(3))), Cmd_OfFunc_perform(signin, msg.fields[0].fields[0].fields[0], (_arg2) => (new Types_Msg(3))), Navigation_newUrl(url_1)]))];
            }
        }
        else {
            const errors_2 = Form_validationLogin(new Auth_LoginInfo(state.Login, state.Password), (new Types_State(state.Login, state.Password, state.LoginResult, state.isUserValid, state.FormErrors, true)).FormValidation);
            if (value_114(errors_2).Equals(empty())) {
                return [new Types_State(state.Login, state.Password, new Deferred$1(1), state.isUserValid, value_114(errors_2), true), Cmd_fromAsync(Cmd_login(state))];
            }
            else {
                return [new Types_State(state.Login, state.Password, new Deferred$1(0), state.isUserValid, value_114(errors_2), true), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 3) {
        return [state, Cmd_none()];
    }
    else {
        return [new Types_State(msg.fields[0], state.Password, state.LoginResult, state.isUserValid, value_114(Form_validationLogin(new Auth_LoginInfo(msg.fields[0], state.Password), state.FormValidation)), state.FormValidation), Cmd_none()];
    }
}

export function View_p(key) {
    return t("login." + key);
}

export function View_view(view_viewInputProps) {
    const dispatch = view_viewInputProps.dispatch;
    const state = view_viewInputProps.state;
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF();
    return createElement("form", {
        onSubmit: (e) => {
            e.preventDefault();
            dispatch(new Types_Msg(2, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray([createElement("div", createObj(toList(delay(() => append((width.tag === 0) ? singleton_1(["style", {
            display: "block",
            flexDirection: "column",
            alignItems: "center",
        }]) : singleton_1(["style", {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }]), delay(() => append(singleton_1(["className", join(" ", ["background-container-login-forgot-create"])]), delay(() => {
            let props_37;
            return singleton_1(["children", Interop_reactApi.Children.toArray([(props_37 = ofArray([["className", join(" ", ["login-box"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let elms, props_7, elms_1, props_17, props_21;
                return append(singleton_1(createElement("div", {
                    style: {
                        paddingBottom: 20,
                        borderBottom: (((1 + "px ") + "dashed") + " ") + "#ccc",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("p", {
                        className: join(" ", ["login-container"]),
                        children: View_p("button.login"),
                    }), (elms = singleton_2((props_7 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let props_1;
                        const errors = Form_getFieldError(state.FormErrors, "Login");
                        return append(singleton_1((props_1 = toList(delay(() => append((width.tag === 0) ? singleton_1(["className", "is-medium"]) : ((width.tag === 1) ? singleton_1(["className", "is-medium"]) : ((width.tag === 2) ? singleton_1(["className", "is-medium"]) : singleton_1(["className", "is-large"]))), delay(() => append((!equals(errors, empty_1())) ? singleton_1(["style", {
                            borderColor: "#FF0000",
                            boxShadow: "none",
                        }]) : empty_2(), delay(() => append(singleton_1(["type", "email"]), delay(() => append(singleton_1(["placeholder", View_p("email.placeholder")]), delay(() => singleton_1(["onChange", (ev) => {
                            dispatch(new Types_Msg(0, EmailModule_ofString(ev.target.value)));
                        }]))))))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_1)))))), delay(() => {
                            let props_3;
                            return append(singleton_1((props_3 = ofArray([["style", {
                                color: "#FF0000",
                                textAlign: "left",
                            }], ["children", fold((s_1, x_1) => (`${s_1} ${x_1}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_3))))), delay(() => {
                                let props_5;
                                return singleton_1((props_5 = singleton_2(["className", join(" ", ["fas", "fa-envelope", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_5)))));
                            }));
                        }));
                    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_7))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    })), (elms_1 = singleton_2((props_17 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let props_10;
                        const errors_1 = Form_getFieldError(state.FormErrors, "Password");
                        return append(singleton_1((props_10 = toList(delay(() => append((width.tag === 0) ? singleton_1(["className", "is-medium"]) : ((width.tag === 1) ? singleton_1(["className", "is-medium"]) : ((width.tag === 2) ? singleton_1(["className", "is-medium"]) : singleton_1(["className", "is-large"]))), delay(() => append((!equals(errors_1, empty_1())) ? singleton_1(["style", {
                            borderColor: "#FF0000",
                            boxShadow: "none",
                        }]) : empty_2(), delay(() => append(singleton_1(["type", "password"]), delay(() => append(singleton_1(["placeholder", View_p("password.placeholder")]), delay(() => singleton_1(["onChange", (ev_1) => {
                            dispatch(new Types_Msg(1, ev_1.target.value));
                        }]))))))))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_10)))))), delay(() => {
                            let props_12;
                            return append(singleton_1((props_12 = ofArray([["style", {
                                color: "#FF0000",
                                textAlign: "left",
                            }], ["children", fold((s_2, x_3) => (`${s_2} ${x_3}`), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_12))))), delay(() => {
                                let props_14;
                                return append(singleton_1((props_14 = singleton_2(["className", join(" ", ["fas", "fa-lock", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_14))))), delay(() => singleton_1(createElement("a", {
                                    style: {
                                        fontSize: 16 + "px",
                                        fontWeight: 400,
                                        color: "#008cba",
                                    },
                                    children: View_p("title.forgotpassword"),
                                    href: toPath(new Route(8)),
                                }))));
                            }));
                        }));
                    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_17))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    })), createElement("div", {
                        style: {
                            padding: ((4 + "px ") + 0) + "px",
                        },
                    }), (props_21 = ofArray([["className", join(" ", ["is-primary", "is-fullwidth", "button-login"])], ["children", View_p("button.login")]]), createElement("button", createObj(Helpers_combineClasses("button", props_21))))]),
                })), delay(() => {
                    let children_2, value_87;
                    const matchValue = state.isUserValid;
                    if (matchValue.tag === 1) {
                        const content = (title, text1, text2, buttonText) => {
                            const children_4 = ofArray([createElement("p", {
                                className: join(" ", ["login-container"]),
                                children: title,
                            }), createElement("div", {
                                style: {
                                    fontSize: 16 + "px",
                                    fontWeight: 400,
                                    textAlign: "left",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("p", {
                                    children: [text1],
                                }), createElement("p", {
                                    children: [text2],
                                })]),
                            }), createElement("a", {
                                href: toPath(new Route(10)),
                                children: Interop_reactApi.Children.toArray([createElement("div", {
                                    className: join(" ", ["mt-5", "button", "is-primary", "button-login"]),
                                    children: buttonText,
                                })]),
                            })]);
                            return createElement("div", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                            });
                        };
                        return equals(matchValue.fields[0], new Types_ErrorType(0, new Errors_ServerError(3, new Errors_DomainError(4)))) ? singleton_1(content(View_p("verify.error.title"), View_p("verify.error.text.one"), View_p("verify.error.text.two"), View_p("button.create.account"))) : singleton_1(content(View_p("error.title"), View_p("error.text.one"), View_p("error.text.two"), View_p("button.create.account")));
                    }
                    else {
                        return singleton_1((children_2 = ofArray([createElement("p", {
                            className: join(" ", ["login-container"]),
                            children: View_p("title.login"),
                        }), createElement("div", {
                            style: {
                                fontSize: 16 + "px",
                                fontWeight: 400,
                                textAlign: "left",
                            },
                            children: Interop_reactApi.Children.toArray([(value_87 = View_p("subtitle.login"), createElement("p", {
                                children: [value_87],
                            }))]),
                        }), createElement("a", {
                            href: toPath(new Route(10)),
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: join(" ", ["mt-5", "button", "is-primary", "button-login"]),
                                children: View_p("button.create.account"),
                            })]),
                        })]), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                        })));
                    }
                }));
            }))))]]), createElement("div", createObj(Helpers_combineClasses("box", props_37))))])]);
        }))))))))]),
    });
}

export function Component_login(component_loginInputProps) {
    const url = component_loginInputProps.url;
    const loggedIn = component_loginInputProps.loggedIn;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(State_init, (msg, state) => State_update(url, loggedIn, msg, state), []);
    return createElement(View_view, {
        state: patternInput[0],
        dispatch: patternInput[1],
    });
}

