import { tWithArgs, Localized_translate, Localized_formatDate, t } from "./Localization.js";
import { toLocalTime } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { SearchCriteria, SellLotState, Lots_Queries_CurrentBids, LotStatus as LotStatus_3, Lots_Queries_CurrentBids$reflection, HomeInfo_MultipleAuction$reflection, Lots_Queries_BidHistory$reflection } from "./Shared/Shared.js";
import { record_type, class_type, union_type, string_type, list_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, Remoting_handleNonAuth, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { CatalogStream_ScheduleChanged_toDates, StreamFrom_Response$reflection } from "./Shared/Bidding.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { lotsAuthApi, homeApi } from "./Communication.js";
import { tryFind as tryFind_1, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { sortDescending, contains, sortBy, tryHead, empty as empty_1, isEmpty, filter, singleton as singleton_2, tryFind, map, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { AuctionState, toPath, Route, navigateTo } from "./Router.js";
import { equals } from "./fable_modules/fable-library.3.6.1/Long.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { empty as empty_2, map as map_1, singleton as singleton_1, append, collect, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { compareArrays, compare, arrayHash, equalArrays, fastStructuralHash, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Img_grayJean, Img_lotMedium800ImagePath, Img_imagePlaceholder, Img_onImageLoadFail } from "./Css.js";
import { CurrencyModule_fromInt, ImageUrlModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { TimerInCatalog } from "./Components/Timers.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Currency_format, LotPrefix_getPrefix } from "./Common.js";
import { AnimatedNumberType, View } from "./Components/AnimatedNumber.js";
import { List_groupBy, List_distinctBy } from "./fable_modules/fable-library.3.6.1/Seq2.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useCatalogStream_Static_5365EDEF } from "./Components/SignalR.js";
import { value as value_140 } from "./fable_modules/fable-library.3.6.1/Option.js";

export function p(key) {
    return t("biddingHistory." + key);
}

export function f(key) {
    return t("favourites." + key);
}

export function formattedDate(i) {
    return Localized_formatDate("d MMMM yyyy", "d MMMM yyyy", toLocalTime(i));
}

export function formattedDateTime(i) {
    return Localized_formatDate("d MMM. HH:mm", "d MMMM HH.mm", toLocalTime(i));
}

export class State extends Record {
    constructor(HistoryAuctions, ActiveAuctions, CurrentBids, PredictedStartLiveBiddingDates) {
        super();
        this.HistoryAuctions = HistoryAuctions;
        this.ActiveAuctions = ActiveAuctions;
        this.CurrentBids = CurrentBids;
        this.PredictedStartLiveBiddingDates = PredictedStartLiveBiddingDates;
    }
}

export function State$reflection() {
    return record_type("BiddingHistory.State", [], State, () => [["HistoryAuctions", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Lots_Queries_BidHistory$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Lots_Queries_BidHistory$reflection())]], [["ErrorValue", string_type]]]))], ["ActiveAuctions", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [HomeInfo_MultipleAuction$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", HomeInfo_MultipleAuction$reflection()]], [["ErrorValue", string_type]]]))], ["CurrentBids", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Lots_Queries_CurrentBids$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Lots_Queries_CurrentBids$reflection())]], [["ErrorValue", string_type]]]))], ["PredictedStartLiveBiddingDates", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Int64"), class_type("System.DateTimeOffset")])]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadAuction", "GetAuctionInfo", "LoadCurrentBids", "GoTo", "FromServer"];
    }
}

export function Msg$reflection() {
    return union_type("BiddingHistory.Msg", [], Msg, () => [[["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Lots_Queries_BidHistory$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Lots_Queries_BidHistory$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [HomeInfo_MultipleAuction$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", HomeInfo_MultipleAuction$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Lots_Queries_CurrentBids$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Lots_Queries_CurrentBids$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.Int64")]], [["Item", StreamFrom_Response$reflection()]]]);
}

export const getAuctionInfo = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(homeApi().getActiveAndHistoryAuctions(), (_arg1) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function getHistoryAuction(userId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getBidHistory(userId)), (_arg1) => singleton.Return(new Msg(0, userId, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(0, userId, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export const getCurrentBids = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getCurrentBids()), (_arg1) => singleton.Return(new Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function init(userId, unitVar1) {
    return [new State(new Deferred$1(0), new Deferred$1(0), new Deferred$1(0), empty()), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(0, userId, new AsyncOperationStatus$1(0))), Cmd_fromAsync(getCurrentBids), Cmd_fromAsync(getAuctionInfo)]))];
}

export function update(msg, state) {
    if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.HistoryAuctions, state.ActiveAuctions, new Deferred$1(2, msg.fields[0].fields[0]), state.PredictedStartLiveBiddingDates), Cmd_none()];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.HistoryAuctions, new Deferred$1(2, msg.fields[0].fields[0]), state.CurrentBids, state.PredictedStartLiveBiddingDates), Cmd_none()];
        }
        else {
            return [new State(state.HistoryAuctions, new Deferred$1(1), state.CurrentBids, state.PredictedStartLiveBiddingDates), Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        return [state, navigateTo(new Route(4, msg.fields[0]))];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 3) {
            const matchValue = state.CurrentBids;
            let pattern_matching_result, lots;
            if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 0;
                    lots = matchValue.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    if (msg.fields[0].fields[0].tag === 2) {
                        return [new State(state.HistoryAuctions, state.ActiveAuctions, new Deferred$1(2, new FSharpResult$2(0, map((x_5) => {
                            if (equals(x_5.LotId, msg.fields[0].fields[0].fields[0].LotId)) {
                                const lot_1 = x_5;
                                return new Lots_Queries_CurrentBids(lot_1.LotId, lot_1.OrderId, lot_1.IdAuction, lot_1.AuctionDate, lot_1.DisplayId, lot_1.Name, lot_1.Bid, lot_1.Priority, new LotStatus_3(2), lot_1.IsYourBidMaximum, lot_1.CurrentBid, lot_1.PreviousBid, lot_1.Image);
                            }
                            else {
                                return x_5;
                            }
                        }, lots))), state.PredictedStartLiveBiddingDates), Cmd_none()];
                    }
                    else if (msg.fields[0].fields[0].tag === 1) {
                        return [new State(state.HistoryAuctions, state.ActiveAuctions, new Deferred$1(2, new FSharpResult$2(0, map((x_6) => {
                            if (equals(x_6.LotId, msg.fields[0].fields[0].fields[0].LotId)) {
                                const lot_2 = x_6;
                                return new Lots_Queries_CurrentBids(lot_2.LotId, lot_2.OrderId, lot_2.IdAuction, lot_2.AuctionDate, lot_2.DisplayId, lot_2.Name, lot_2.Bid, lot_2.Priority, new LotStatus_3(3, new SellLotState(2)), lot_2.IsYourBidMaximum, lot_2.CurrentBid, lot_2.PreviousBid, lot_2.Image);
                            }
                            else {
                                return x_6;
                            }
                        }, lots))), state.PredictedStartLiveBiddingDates), Cmd_none()];
                    }
                    else if (msg.fields[0].fields[0].tag === 3) {
                        const info_3 = msg.fields[0].fields[0].fields[0];
                        const matchValue_2 = tryFind((x_7) => equals(x_7.LotId, info_3.LotId), lots);
                        if (matchValue_2 == null) {
                            return [state, Cmd_none()];
                        }
                        else {
                            return [new State(state.HistoryAuctions, state.ActiveAuctions, new Deferred$1(2, new FSharpResult$2(0, map((x_8) => {
                                if (equals(x_8.LotId, info_3.LotId)) {
                                    const lot_3 = x_8;
                                    return new Lots_Queries_CurrentBids(lot_3.LotId, lot_3.OrderId, lot_3.IdAuction, lot_3.AuctionDate, lot_3.DisplayId, lot_3.Name, lot_3.Bid, lot_3.Priority, info_3.HasSold ? (new LotStatus_3(3, new SellLotState(1))) : (new LotStatus_3(3, new SellLotState(0))), lot_3.IsYourBidMaximum, lot_3.CurrentBid, lot_3.PreviousBid, lot_3.Image);
                                }
                                else {
                                    return x_8;
                                }
                            }, lots))), state.PredictedStartLiveBiddingDates), Cmd_none()];
                        }
                    }
                    else if (msg.fields[0].fields[0].tag === 4) {
                        return [new State(state.HistoryAuctions, state.ActiveAuctions, state.CurrentBids, CatalogStream_ScheduleChanged_toDates(msg.fields[0].fields[0].fields[0])), Cmd_none()];
                    }
                    else {
                        const info = msg.fields[0].fields[0].fields[0];
                        const matchValue_1 = tryFind((x_3) => equals(x_3.LotId, info.LotId), lots);
                        if (matchValue_1 == null) {
                            return [state, Cmd_none()];
                        }
                        else {
                            return [new State(state.HistoryAuctions, state.ActiveAuctions, new Deferred$1(2, new FSharpResult$2(0, map((x_4) => {
                                if (equals(x_4.LotId, info.LotId)) {
                                    const lot = x_4;
                                    return new Lots_Queries_CurrentBids(lot.LotId, lot.OrderId, lot.IdAuction, lot.AuctionDate, lot.DisplayId, lot.Name, lot.Bid, lot.Priority, lot.LotStatus, lot.IsYourBidMaximum, info.Bid, info.PreviousBid, lot.Image);
                                }
                                else {
                                    return x_4;
                                }
                            }, lots))), state.PredictedStartLiveBiddingDates), Cmd_none()];
                        }
                    }
                }
                case 1: {
                    return [state, Cmd_none()];
                }
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[1].tag === 1) {
        return [new State(new Deferred$1(2, msg.fields[1].fields[0]), state.ActiveAuctions, state.CurrentBids, state.PredictedStartLiveBiddingDates), Cmd_none()];
    }
    else {
        return [new State(new Deferred$1(1), state.ActiveAuctions, state.CurrentBids, state.PredictedStartLiveBiddingDates), Cmd_fromAsync(getHistoryAuction(msg.fields[0]))];
    }
}

export const noBids = createElement("div", {
    style: {
        color: "#cdcdcd",
        textAlign: "center",
        width: 66 + "%",
    },
    className: "container-marginLeftRight-auto",
    children: Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-gavel", "fa-5x"]),
    }), createElement("h1", {
        style: {
            marginTop: 22,
            marginBottom: 11,
            fontSize: 40 + "px",
            fontWeight: 400,
            fontFamily: "Roboto slab, Arial",
        },
        children: p("noBids"),
    })]),
});

export function currentBidItem(dispatch, state, bidsList) {
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((matchValue) => append(singleton_1(createElement("p", {
            style: {
                fontSize: 14 + "px",
                fontWeight: 600,
                marginTop: 30,
                marginBottom: 30,
            },
            children: Localized_formatDate("EEEE, dd MMMM yyyy", "EEEE, dd MMMM yyyy", matchValue[1]),
        })), delay(() => map_1((bid) => {
            let props_27, props_13, props_11, props_7, elms_1, props_9, props_25, props_23, props_17, props_21;
            return createElement("div", {
                onClick: (_arg1) => {
                    dispatch(new Msg(3, bid.LotId));
                },
                style: {
                    marginLeft: 0,
                    marginRight: 0,
                },
                className: join(" ", ["favourites-item", "is-clickable"]),
                children: Interop_reactApi.Children.toArray([(props_27 = ofArray([["className", "is-desktop"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-vcentered"], ["className", "is-three-fifths-desktop"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-mobile"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-narrow"], ["className", "is-half-mobile"], ["style", {
                    paddingLeft: 0,
                }], ["children", Interop_reactApi.Children.toArray([(elms_1 = toList(delay(() => {
                    let elms;
                    return append(singleton_1((elms = singleton_2(createElement("img", createObj(toList(delay(() => append(singleton_1(["className", "favourites-img-size"]), delay(() => append(singleton_1(["onError", (x_2) => {
                        Img_onImageLoadFail(x_2);
                    }]), delay(() => {
                        const matchValue_1 = bid.Image;
                        return (matchValue_1 == null) ? singleton_1(["src", Img_imagePlaceholder]) : singleton_1(["src", ImageUrlModule_toString(Img_lotMedium800ImagePath(matchValue_1))]);
                    }))))))))), createElement("figure", {
                        className: "image",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))), delay(() => {
                        const matchValue_2 = bid.LotStatus;
                        let pattern_matching_result;
                        if (matchValue_2.tag === 1) {
                            pattern_matching_result = 0;
                        }
                        else if (matchValue_2.tag === 2) {
                            pattern_matching_result = 1;
                        }
                        else if (matchValue_2.tag === 3) {
                            if (matchValue_2.fields[0].tag === 1) {
                                pattern_matching_result = 3;
                            }
                            else if (matchValue_2.fields[0].tag === 0) {
                                pattern_matching_result = 3;
                            }
                            else {
                                pattern_matching_result = 2;
                            }
                        }
                        else {
                            pattern_matching_result = 0;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                const startDate = tryFind_1(bid.LotId, state.PredictedStartLiveBiddingDates);
                                return singleton_1(createElement(TimerInCatalog, {
                                    dateUntil: startDate,
                                    dependencies: [startDate],
                                }));
                            }
                            case 1: {
                                return singleton_1(createElement("span", {
                                    className: join(" ", ["thumbnail-number-item"]),
                                    children: t("home.tag.biddingNow"),
                                }));
                            }
                            case 2: {
                                return singleton_1(createElement("span", {
                                    className: join(" ", ["thumbnail-number-item"]),
                                    children: t("withdrawn.state"),
                                }));
                            }
                            case 3: {
                                return singleton_1(createElement("span", {
                                    className: join(" ", ["thumbnail-number-item"]),
                                    children: t("home.tag.completed"),
                                }));
                            }
                        }
                    }));
                })), createElement("div", {
                    className: "card-image",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7)))), (props_9 = toList(delay(() => append(singleton_1(["className", "is-half-mobile"]), delay(() => append(singleton_1(["className", join(" ", ["lot-name"])]), delay(() => {
                    const dot = (bid.DisplayId === "") ? "" : ". ";
                    const prefix = LotPrefix_getPrefix(bid.Priority);
                    return singleton_1(["children", `${(prefix + bid.DisplayId)}${dot}${Localized_translate(bid.Name)}`]);
                })))))), createElement("div", createObj(Helpers_combineClasses("column", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_25 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "is-mobile"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_17 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("p", {
                    className: join(" ", ["is-size-7-mobile", "is-size-6"]),
                    children: p("MaxBid"),
                    style: {
                        fontWeight: 400,
                        textTransform: "uppercase",
                        textAlign: "center",
                    },
                }), createElement("p", {
                    children: (bid.Bid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    className: join(" ", toList(delay(() => append(singleton_1("is-size-4-mobile"), delay(() => append(singleton_1("is-size-3"), delay(() => (bid.IsYourBidMaximum ? singleton_1("has-text-success") : singleton_1("has-text-danger-dark"))))))))),
                    style: {
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        textAlign: "center",
                    },
                })])]), createElement("div", createObj(Helpers_combineClasses("column", props_17)))), (props_21 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("p", createObj(toList(delay(() => append(singleton_1(["className", "is-uppercase"]), delay(() => append(singleton_1(["className", join(" ", ["is-size-7-mobile", "is-size-6"])]), delay(() => {
                    let matchValue_3;
                    return append((matchValue_3 = bid.LotStatus, (matchValue_3.tag === 1) ? singleton_1(["children", f("currentBid")]) : ((matchValue_3.tag === 2) ? singleton_1(["children", f("currentBid")]) : ((matchValue_3.tag === 3) ? ((matchValue_3.fields[0].tag === 1) ? singleton_1(["children", f("finalPrice")]) : ((matchValue_3.fields[0].tag === 2) ? append(singleton_1(["className", "colorRed"]), delay(() => singleton_1(["children", f("withdrawn")]))) : singleton_1(["children", f("unsold")]))) : singleton_1(["children", "created"])))), delay(() => singleton_1(["style", {
                        fontWeight: 400,
                        textTransform: "uppercase",
                        textAlign: "center",
                    }])));
                })))))))), createElement("p", {
                    className: join(" ", ["is-size-4-mobile", "is-size-3"]),
                    style: {
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        textAlign: "center",
                    },
                    children: Interop_reactApi.Children.toArray([createElement(View, {
                        number: bid.CurrentBid,
                        oldNumber: bid.PreviousBid,
                        currencySymbol: null,
                        typeOfComponent: new AnimatedNumberType(2),
                        needBlinkGreen: false,
                        needBlinkRed: false,
                        needBlink: true,
                        isActiveBiddingComponent: false,
                    })]),
                })])]), createElement("div", createObj(Helpers_combineClasses("column", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_25))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_27))))]),
            });
        }, filter((x_6) => equals(x_6.IdAuction, matchValue[0]), bidsList)))), List_distinctBy((tuple) => tuple[0], map((x) => [x.IdAuction, x.AuctionDate], bidsList), {
            Equals: (x_1, y) => equals(x_1, y),
            GetHashCode: (x_1) => fastStructuralHash(x_1),
        })))))),
    });
}

export function currentBids(dispatch, state) {
    let props_3;
    const matchValue = state.CurrentBids;
    if (matchValue.tag === 1) {
        const props = singleton_2(["className", join(" ", ["fas", "fa-spinner", "fa-pulse"])]);
        return createElement("span", createObj(Helpers_combineClasses("icon", props)));
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            if (isEmpty(matchValue.fields[0].fields[0])) {
                return noBids;
            }
            else {
                let pattern_matching_result, x_2;
                if (matchValue.tag === 2) {
                    if (matchValue.fields[0].tag === 0) {
                        pattern_matching_result = 0;
                        x_2 = matchValue.fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return currentBidItem(dispatch, state, x_2);
                    }
                    case 1: {
                        throw (new Error("Match failure: Extensions.Deferred`1"));
                    }
                }
            }
        }
        else {
            const elms = singleton_2((props_3 = ofArray([["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("h1", {
                children: [matchValue.fields[0].fields[0]],
            })])]]), createElement("div", createObj(Helpers_combineClasses("notification", props_3)))));
            return createElement("section", {
                className: "section",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            });
        }
    }
    else {
        return null;
    }
}

export function biddingHistoryItem(history) {
    const children = toList(delay(() => map_1((x) => createElement("tr", {
        className: join(" ", ["is-size-7-mobile", "is-size-6"]),
        style: {
            borderTop: (((1 + "px ") + "dashed") + " ") + "#ccc",
            fontWeight: 400,
            textAlign: "left",
        },
        children: Interop_reactApi.Children.toArray([createElement("td", {
            children: x.DisplayId,
        }), createElement("td", {
            colSpan: 2,
            children: Localized_translate(x.Name),
        }), createElement("td", createObj(toList(delay(() => append(singleton_1(["style", {
            textTransform: "uppercase",
        }]), delay(() => singleton_1(["children", formattedDateTime(x.Time)]))))))), createElement("td", {
            children: Currency_format(CurrencyModule_fromInt(x.YourBid)),
        })]),
    }), history)));
    return createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Auction(auctionInputProps) {
    const history = auctionInputProps.history;
    const auction = auctionInputProps.auction;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isActive = patternInput[0];
    const props_15 = ofArray([["className", join(" ", ["is-size-5-mobile", "is-size-5"])], ["style", {
        backgroundColor: "#f7f7f7",
        marginTop: 15,
        borderRadius: 0,
        boxShadow: "none",
        padding: 0,
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_4, props, props_2;
        return append(singleton_1((props_4 = ofArray([["className", join(" ", ["is-mobile"])], ["style", {
            color: "#5F5F5F",
            fontWeight: 500,
        }], ["children", Interop_reactApi.Children.toArray([(props = toList(delay(() => singleton_1(["children", tWithArgs("biddingHistory.auctionDate", {
            date: formattedDate(auction[1]),
        })]))), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => (isActive ? singleton_1("fa-chevron-up") : singleton_1("fa-chevron-down")))))))], ["className", join(" ", ["is-3"])], ["style", {
            paddingRight: 20,
            textAlign: "right",
            color: "#5F5F5F",
        }]]), createElement("div", createObj(Helpers_combineClasses("column", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_4))))), delay(() => {
            let elms, props_12, children;
            return isActive ? singleton_1((elms = singleton_2((props_12 = ofArray([["style", {
                tableLayout: "fixed",
            }], ["className", join(" ", ["is-fullwidth"])], ["children", Interop_reactApi.Children.toArray([createElement("thead", {
                className: join(" ", ["is-size-7-mobile", "is-size-5"]),
                style: {
                    borderTop: (((1 + "px ") + "solid") + " ") + "#ccc",
                    fontWeight: 600,
                    textAlign: "left",
                    textTransform: "uppercase",
                },
                children: Interop_reactApi.Children.toArray([(children = ofArray([createElement("th", {
                    children: p("lotNo"),
                }), createElement("th", {
                    colSpan: 2,
                    children: p("Object"),
                }), createElement("th", {
                    children: p("Time"),
                }), createElement("th", {
                    children: p("BidInSek"),
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))]),
            }), biddingHistoryItem(history)])]]), createElement("table", createObj(Helpers_combineClasses("table", props_12))))), createElement("div", {
                className: "table-container",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))) : singleton_1(null);
        }));
    }))))], ["onClick", (_arg1) => {
        patternInput[1](!isActive);
    }]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_15)));
}

export function BiddingHistory(biddingHistoryInputProps) {
    let matchValue;
    const userId = biddingHistoryInputProps.userId;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => init(userId, void 0), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    useFeliz_React__React_useCatalogStream_Static_5365EDEF((arg) => {
        dispatch(new Msg(4, arg));
    }, (matchValue = state_1.CurrentBids, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? map((x) => x.LotId, matchValue.fields[0].fields[0]) : empty_1()) : empty_1()), userId);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const isActive = patternInput_1[0];
    let activeAuctions;
    const matchValue_1 = state_1.ActiveAuctions;
    let pattern_matching_result, auctionInfo;
    if (matchValue_1.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue_1.tag === 2) {
        if (matchValue_1.fields[0].tag === 0) {
            pattern_matching_result = 1;
            auctionInfo = matchValue_1.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            activeAuctions = (void 0);
            break;
        }
        case 1: {
            activeAuctions = auctionInfo;
            break;
        }
    }
    let lastAuction;
    const matchValue_2 = state_1.ActiveAuctions;
    let pattern_matching_result_1, auctions;
    if (matchValue_2.tag === 1) {
        pattern_matching_result_1 = 0;
    }
    else if (matchValue_2.tag === 2) {
        if (matchValue_2.fields[0].tag === 0) {
            pattern_matching_result_1 = 1;
            auctions = matchValue_2.fields[0].fields[0];
        }
        else {
            pattern_matching_result_1 = 0;
        }
    }
    else {
        pattern_matching_result_1 = 0;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            lastAuction = (void 0);
            break;
        }
        case 1: {
            lastAuction = ((!isEmpty(auctions.ActiveAuctions)) ? tryHead(auctions.ActiveAuctions) : tryHead(auctions.HistoryAuctions));
            break;
        }
    }
    return createElement("div", {
        style: {
            backgroundImage: ("url(\u0027" + Img_grayJean) + "\u0027)",
            backgroundRepeat: "repeat",
            marginRight: 0,
            marginLeft: 0,
            width: 100 + "%",
            padding: ((((((0 + "px ") + 0) + "px ") + 100) + "px ") + 0) + "px",
            minHeight: 500,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_12, props_2, props_10;
            const minHeight = 45;
            return append(singleton_1((props_12 = ofArray([["className", "is-uppercase"], ["className", join(" ", ["navbar-catalogue"])], ["style", {
                minHeight: minHeight,
            }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["navbar-brand", "is-vcentered"]),
                style: {
                    minHeight: minHeight,
                },
                children: Interop_reactApi.Children.toArray([createElement("p", createObj(toList(delay(() => append((lastAuction == null) ? ((empty_2())) : singleton_1(["children", (Localized_translate(lastAuction.HeadInfo.Head) + " ") + Localized_formatDate("d MMMM", "d MMMM", value_140(lastAuction).Date)]), delay(() => append(singleton_1(["className", join(" ", ["navbar-catalogue-item", "overflowHidden"])]), delay(() => singleton_1(["style", {
                    marginRight: 12,
                    display: "block",
                }]))))))))), (props_2 = ofArray([["style", {
                    height: minHeight,
                    color: "#cdcdcd",
                    padding: ((((((6 + "px ") + 24) + "px ") + 8) + "px ") + 24) + "px",
                }], ["className", "has-text-grey-light"], ["onClick", (_arg1) => {
                    patternInput_1[1](!isActive);
                }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-align-right", "fa-lg", "catalogMenuMobile"]),
                })])]]), createElement("a", createObj(Helpers_combineClasses("navbar-burger", props_2))))]),
            }), (props_10 = toList(delay(() => append(isActive ? singleton_1(["className", "is-active"]) : empty_2(), delay(() => append(singleton_1(["className", join(" ", ["navbar-menu-white", "navbar-menu-mobile"])]), delay(() => append(singleton_1(["style", {
                minHeight: minHeight,
            }]), delay(() => {
                let elms, props_5, props_7;
                return singleton_1(["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_5 = toList(delay(() => append(singleton_1(["className", join(" ", ["hovered-level-item"])]), delay(() => append(singleton_1(["style", {
                    color: "#444444",
                    textAlign: "center",
                }]), delay(() => append(singleton_1(["className", "is-uppercase"]), delay(() => {
                    if (lastAuction != null) {
                        const matchValue_3 = value_140(lastAuction).Status;
                        switch (matchValue_3.tag) {
                            case 1: {
                                return append(singleton_1(["children", f("navbar.auction.toAuction")]), delay(() => singleton_1(["href", toPath(new Route(2, void 0))])));
                            }
                            case 2: {
                                return append(singleton_1(["children", f("navbar.auction.toAuction")]), delay(() => singleton_1(["href", toPath(new Route(2, void 0))])));
                            }
                            case 3: {
                                return append(singleton_1(["children", f("navbar.auction.live")]), delay(() => singleton_1(["href", toPath(new Route(15))])));
                            }
                            default: {
                                return append(singleton_1(["children", f("navbar.auction.closed")]), delay(() => singleton_1(["href", toPath(new Route(15))])));
                            }
                        }
                    }
                    else {
                        return empty_2();
                    }
                })))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_5)))), (props_7 = toList(delay(() => append(singleton_1(["className", join(" ", ["hovered-level-item"])]), delay(() => append(singleton_1(["children", f("navbar.catalogue")]), delay(() => append(singleton_1(["style", {
                    textAlign: "center",
                    color: "#444444",
                    display: "grid",
                }]), delay(() => ((lastAuction != null) ? singleton_1(["href", toPath(new Route(3, new AuctionState(value_140(lastAuction).Id, 1, new SearchCriteria(0))))]) : singleton_1(["href", toPath(new Route(0))])))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_7))))]), createElement("div", {
                    className: "navbar-start",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))])]);
            })))))))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_10))))])]]), createElement("nav", createObj(Helpers_combineClasses("navbar", props_12))))), delay(() => singleton_1(createElement("div", {
                className: join(" ", ["favourites-container-padding"]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let matchValue_4;
                    return append((matchValue_4 = state_1.CurrentBids, (matchValue_4.tag === 1) ? singleton_1(noBids) : ((matchValue_4.tag === 2) ? ((matchValue_4.fields[0].tag === 0) ? singleton_1(createElement("div", {
                        className: join(" ", ["bidding-history-lot-card"]),
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (isEmpty(matchValue_4.fields[0].fields[0]) ? singleton_1(noBids) : singleton_1(currentBids(dispatch, state_1))))))),
                    })) : singleton_1(noBids)) : singleton_1(noBids))), delay(() => singleton_1(createElement("div", {
                        style: {
                            padding: ((((((0 + "px ") + 5) + "px ") + 0) + "px ") + 5) + "px",
                            width: 100 + "%",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "bid-history-content",
                            style: {
                                backgroundColor: "#fff",
                                boxShadow: (((((((0 + "px ") + 0) + "px ") + 2) + "px ") + 1) + "px ") + "rgba(232,229,232,1)",
                                textAlign: "left",
                                marginTop: 30,
                            },
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", {
                                className: join(" ", ["is-mobile", "is-size-4-mobile", "is-size-4"]),
                                style: {
                                    paddingBottom: 20,
                                    paddingRight: 20,
                                    color: "#5F5F5F",
                                    borderBottom: (((1 + "px ") + "solid") + " ") + "#ccc",
                                    fontWeight: 600,
                                    marginBottom: 30,
                                },
                                children: Interop_reactApi.Children.toArray([p("bidHistory"), createElement("i", {
                                    className: join(" ", ["fa", "fa-gavel"]),
                                    style: {
                                        float: "right",
                                    },
                                })]),
                            })), delay(() => {
                                let props_20, props_18, elms_1, props_23, value_134, activeAuctionsIds;
                                const matchValue_5 = state_1.HistoryAuctions;
                                let pattern_matching_result_2, error, historyAuctions;
                                if (matchValue_5.tag === 1) {
                                    pattern_matching_result_2 = 0;
                                }
                                else if (matchValue_5.tag === 2) {
                                    if (matchValue_5.fields[0].tag === 0) {
                                        pattern_matching_result_2 = 2;
                                        historyAuctions = matchValue_5.fields[0].fields[0];
                                    }
                                    else {
                                        pattern_matching_result_2 = 1;
                                        error = matchValue_5.fields[0].fields[0];
                                    }
                                }
                                else {
                                    pattern_matching_result_2 = 0;
                                }
                                switch (pattern_matching_result_2) {
                                    case 0: {
                                        return singleton_1((props_20 = ofArray([["className", "is-large"], ["className", join(" ", ["has-text-centered"])], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-large"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                                            className: join(" ", ["fas", "fa-spinner", "fa-pulse", "fa-3x"]),
                                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_18))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_20)))));
                                    }
                                    case 1: {
                                        return singleton_1((elms_1 = singleton_2((props_23 = ofArray([["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("h1", {
                                            children: [error],
                                        })])]]), createElement("div", createObj(Helpers_combineClasses("notification", props_23))))), createElement("section", {
                                            className: "section",
                                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                                        })));
                                    }
                                    case 2: {
                                        return isEmpty(historyAuctions) ? singleton_1((value_134 = p("noBidHistory"), createElement("p", {
                                            children: [value_134],
                                        }))) : map_1((i) => createElement(Auction, {
                                            auction: i[0],
                                            history: i[1],
                                        }), (activeAuctions != null) ? ((activeAuctionsIds = map((y_2) => y_2.Id, activeAuctions.ActiveAuctions), sortBy((tuple) => tuple[1], List_groupBy((x_8) => [x_8.AuctionId, x_8.DateAuction], filter((x_6) => (!contains(x_6.AuctionId, activeAuctionsIds, {
                                            Equals: (x_7, y_3) => equals(x_7, y_3),
                                            GetHashCode: (x_7) => fastStructuralHash(x_7),
                                        })), historyAuctions), {
                                            Equals: (x_9, y_4) => equalArrays(x_9, y_4),
                                            GetHashCode: (x_9) => arrayHash(x_9),
                                        }), {
                                            Compare: (x_10, y_5) => compare(x_10, y_5),
                                        }))) : sortDescending(List_groupBy((x_3) => [x_3.AuctionId, x_3.DateAuction], historyAuctions, {
                                            Equals: (x_4, y) => equalArrays(x_4, y),
                                            GetHashCode: (x_4) => arrayHash(x_4),
                                        }), {
                                            Compare: (x_5, y_1) => compareArrays(x_5, y_1),
                                        }));
                                    }
                                }
                            })))))),
                        })]),
                    }))));
                })))),
            }))));
        })))),
    });
}

