import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { settingsApi } from "../Communication.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { ProfileSettings as ProfileSettings_1 } from "./EditAccountCompany.js";
import { ProfileSettings as ProfileSettings_2 } from "./EditPersonalAccount.js";

export function ProfileSettings(profileSettingsInputProps) {
    const userId = profileSettingsInputProps.userId;
    const account = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getAccount(userId)), [userId]);
    if (account.tag === 1) {
        return createElement("i", {
            className: join(" ", ["fa", "fa-refresh", "fa-spin", "fa-2x"]),
        });
    }
    else if (account.tag === 3) {
        const value_1 = account.fields[0].message;
        return createElement("div", {
            children: [value_1],
        });
    }
    else if (account.tag === 2) {
        if (account.fields[0].tag === 0) {
            if (account.fields[0].fields[0].tag === 1) {
                return createElement(ProfileSettings_1, account.fields[0].fields[0].fields[0]);
            }
            else {
                return createElement(ProfileSettings_2, account.fields[0].fields[0].fields[0]);
            }
        }
        else {
            const value_2 = `User with id=${account.fields[0].fields[0].fields[0]} not found`;
            return createElement("div", {
                children: [value_2],
            });
        }
    }
    else {
        return null;
    }
}

