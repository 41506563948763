import { map, defaultArg, ofNullable } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Convert_fromJson, Convert_serialize } from "./fable_modules/Fable.SimpleJson.3.20.0/Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.20.0/TypeInfo.Converter.fs.js";
import { Subscribe_SubscribeAccepting, Cookies_EnabledCookies, Cookies_CookiesAccepting, Auth_LoggedInUser$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.20.0/SimpleJson.fs.js";
import { tryParse, minValue, toUniversalTime } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { toString } from "./fable_modules/fable-library.3.6.1/Date.js";
import { FSharpRef } from "./fable_modules/fable-library.3.6.1/Types.js";

export function WebStorage_remove(key) {
    localStorage.removeItem(key);
}

export function WebStorage_save(key, value) {
    localStorage.setItem(key, value);
}

export function WebStorage_load(key) {
    return ofNullable(localStorage.getItem(key));
}

export function saveUser(user) {
    localStorage.setItem("loggedUser", Convert_serialize(user, createTypeInfo(Auth_LoggedInUser$reflection())));
}

export function removeUser() {
    localStorage.removeItem("loggedUser");
}

export function getUser() {
    let matchValue;
    let matchValue_1;
    const arg00 = localStorage.getItem("loggedUser");
    try {
        matchValue_1 = (new FSharpResult$2(0, (matchValue = SimpleJson_tryParse(arg00), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(Auth_LoggedInUser$reflection())) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })())));
    }
    catch (ex) {
        matchValue_1 = (new FSharpResult$2(1, ex.message));
    }
    if (matchValue_1.tag === 1) {
        return void 0;
    }
    else {
        return matchValue_1.fields[0];
    }
}

export function getToken() {
    return defaultArg(map((x) => x.AccessToken, getUser()), "");
}

export function Cookies_parseFilter(_arg1) {
    switch (_arg1) {
        case "unknown": {
            return new Cookies_CookiesAccepting(0);
        }
        case "acceptAll": {
            return new Cookies_CookiesAccepting(1, new Cookies_EnabledCookies(true, true));
        }
        case "acceptGA": {
            return new Cookies_CookiesAccepting(1, new Cookies_EnabledCookies(false, true));
        }
        case "acceptFP": {
            return new Cookies_CookiesAccepting(1, new Cookies_EnabledCookies(true, false));
        }
        case "acceptMain": {
            return new Cookies_CookiesAccepting(1, new Cookies_EnabledCookies(false, false));
        }
        default: {
            return new Cookies_CookiesAccepting(0);
        }
    }
}

export function Cookies_filterToString(_arg1) {
    if (_arg1.tag === 1) {
        if (_arg1.fields[0].FacebookPixel) {
            if (_arg1.fields[0].GoogleAnalytics) {
                return "acceptAll";
            }
            else {
                return "acceptFP";
            }
        }
        else if (_arg1.fields[0].GoogleAnalytics) {
            return "acceptGA";
        }
        else {
            return "acceptMain";
        }
    }
    else {
        return "unknown";
    }
}

export function Cookies_load() {
    return defaultArg(map((_arg1) => Cookies_parseFilter(_arg1), WebStorage_load("cookies")), new Cookies_CookiesAccepting(0));
}

export function Cookies_save(cookiesSet) {
    WebStorage_save("cookies", Cookies_filterToString(cookiesSet));
}

export function SubscribeToMailchimp_filterToString(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            let copyOfStruct = toUniversalTime(_arg1.fields[0]);
            return toString(copyOfStruct, "yyyy-MM-dd HH:mm:ss");
        }
        case 2: {
            return "accept";
        }
        default: {
            return "unknown";
        }
    }
}

export function SubscribeToMailchimp_parseFilter(_arg1) {
    switch (_arg1) {
        case "unknown": {
            return new Subscribe_SubscribeAccepting(0);
        }
        case "accept": {
            return new Subscribe_SubscribeAccepting(2);
        }
        default: {
            let date;
            let outArg = minValue();
            date = [tryParse(_arg1, new FSharpRef(() => outArg, (v) => {
                outArg = v;
            })), outArg];
            if (date[0]) {
                return new Subscribe_SubscribeAccepting(1, date[1]);
            }
            else {
                return new Subscribe_SubscribeAccepting(0);
            }
        }
    }
}

export function SubscribeToMailchimp_load() {
    return defaultArg(map((_arg1) => SubscribeToMailchimp_parseFilter(_arg1), WebStorage_load("mailchimp")), new Subscribe_SubscribeAccepting(0));
}

export function SubscribeToMailchimp_save(subscribeSet) {
    WebStorage_save("mailchimp", SubscribeToMailchimp_filterToString(subscribeSet));
}

