import { Parsimmon_seperateBy, Parsimmon_ofLazy, Parsimmon_optionalWhitespace, Parsimmon_between, Parsimmon_satisfy, Parsimmon_orTry, Parsimmon_oneOf, Parsimmon_stringReturn, Parsimmon_choose, Parsimmon_many, Parsimmon_seq3, Parsimmon_str, Parsimmon_seq2, Parsimmon_map, Parsimmon_digit, Parsimmon_atLeastOneOrMany, Parsimmon_concat } from "../Fable.Parsimmon.4.1.0/Parsimmon.fs.js";
import { parse } from "../fable-library.3.6.1/Double.js";
import { join } from "../fable-library.3.6.1/String.js";
import { Json } from "./Json.fs.js";
import { map, ofArray } from "../fable-library.3.6.1/List.js";
import { ofList } from "../fable-library.3.6.1/Map.js";
import { Lazy } from "../fable-library.3.6.1/Util.js";

export const digits = Parsimmon_concat(Parsimmon_atLeastOneOrMany(Parsimmon_digit));

export const jint = Parsimmon_map((value) => parse(value), digits);

export const negJint = Parsimmon_map((tupledArg) => (-tupledArg[1]), Parsimmon_seq2(Parsimmon_str("-"), jint));

export const jfloat = Parsimmon_map((tupledArg) => {
    const digitsLeft = tupledArg[0];
    return parse(join("", [(digitsLeft === "") ? "0" : digitsLeft, tupledArg[1], tupledArg[2]]));
}, Parsimmon_seq3(Parsimmon_concat(Parsimmon_many(Parsimmon_digit)), Parsimmon_str("."), Parsimmon_concat(Parsimmon_atLeastOneOrMany(Parsimmon_digit))));

export const negativeJFloat = Parsimmon_map((tupledArg) => (-tupledArg[1]), Parsimmon_seq2(Parsimmon_str("-"), jfloat));

export const jnumber = Parsimmon_map((arg0) => (new Json(0, arg0)), Parsimmon_choose(ofArray([jfloat, negativeJFloat, jint, negJint])));

export const jbool = Parsimmon_choose(ofArray([Parsimmon_stringReturn("true", new Json(2, true)), Parsimmon_stringReturn("false", new Json(2, false))]));

export const jnull = Parsimmon_stringReturn("null", new Json(3));

export const stringLiteral = (() => {
    const escape = Parsimmon_map((_arg1) => {
        switch (_arg1) {
            case "b": {
                return "\b";
            }
            case "f": {
                return "\f";
            }
            case "n": {
                return "\n";
            }
            case "r": {
                return "\r";
            }
            case "t": {
                return "\t";
            }
            default: {
                return _arg1;
            }
        }
    }, Parsimmon_oneOf("\"\\/bfnrt"));
    const anyCharSnippet = Parsimmon_concat(Parsimmon_many(Parsimmon_orTry(Parsimmon_map((tuple) => tuple[1], Parsimmon_seq2(Parsimmon_str("\\"), escape)), Parsimmon_satisfy((c_1) => ((c_1 !== "\"") ? (c_1 !== "\\") : false)))));
    return Parsimmon_between(Parsimmon_str("\""), Parsimmon_str("\""), anyCharSnippet);
})();

export const jstring = stringLiteral.map((arg0) => (new Json(1, arg0)));

export function withWhitespace(p) {
    return Parsimmon_between(Parsimmon_optionalWhitespace, Parsimmon_optionalWhitespace, p);
}

export const jvalue = Parsimmon_choose(map((p) => withWhitespace(p), ofArray([jnull, jbool, jnumber, jstring])));

export const comma = withWhitespace(Parsimmon_str(","));

function json$004099() {
    return Parsimmon_ofLazy(() => Parsimmon_choose(ofArray([jvalue, Parsimmon_map((arg) => (new Json(4, ofArray(arg))), Parsimmon_between(withWhitespace(Parsimmon_str("[")), withWhitespace(Parsimmon_str("]")), Parsimmon_seperateBy(comma, json$004099$002D1.Value))), Parsimmon_map((arg_2) => (new Json(5, ofList(ofArray(arg_2)))), Parsimmon_between(withWhitespace(Parsimmon_str("{")), withWhitespace(Parsimmon_str("}")), Parsimmon_seperateBy(comma, Parsimmon_map((tupledArg) => [tupledArg[0], tupledArg[2]], Parsimmon_seq3(withWhitespace(stringLiteral), withWhitespace(Parsimmon_str(":")), withWhitespace(json$004099$002D1.Value))))))])));
}

const json$004099$002D1 = new Lazy(json$004099);

export const json = json$004099$002D1.Value;

export const jsonParser = withWhitespace(json);

