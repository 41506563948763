import { getCurrentLanguage, Localized_translate, t as t_1 } from "../Localization.js";
import { toString, Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, class_type, option_type, int32_type, union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { LiveAuction_StateModule_trySecondsElapsed, LiveAuction_StateModule_$007CAuctionNotStarted$007CAuctionStarted$007CAuctionFinished$007CCurrentLotBidChanged$007CCurrentLotBiddingFinished$007CNextLotBiddingStarted$007C, LiveAuction_State, StreamFrom_Response$reflection, LiveAuction_State$reflection } from "../Shared/Bidding.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { sleep } from "../fable_modules/fable-library.3.6.1/Async.js";
import { op_Addition, equals, fromBits } from "../fable_modules/fable-library.3.6.1/Long.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_fromAsync } from "../Extensions.js";
import { View_timer, Types_Cmd_notifyOrderChanged } from "../LiveAuctionFooter.js";
import { indexed, singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { equals as equals_1, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { collect, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { rgba } from "../fable_modules/Feliz.1.45.0/Colors.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { ItemIdModule_toString, ImageUrlModule_toString } from "../bidflow/src/infrastructure/Auction.js";
import { Img_actionBackground, Img_Url_mediumContains1800Options, Img_onImageLoadFail, Img_lotMediumImagePathContain } from "../Css.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { MediaQuery_customBreakpoints, LotPrefix_isPriority, LotPrefix_getPrefix } from "../Common.js";
import { Render } from "./LiveBidding.js";
import { Route, AuctionState, toPath } from "../Router.js";
import { Users_UserModule_userId, SearchCriteria } from "../Shared/Shared.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Feliz_React__React_useLiveAuctionStream_Static_Z2812504D } from "../Components/SignalR.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "../fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { page as page_4 } from "./AuctionClosed.js";
import { Page } from "./AuctionNotStartedYet.js";

export function ldt(key) {
    return t_1("lotDetails." + key);
}

export function p(key) {
    return t_1("home." + key);
}

export class Types_Visibility extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Hidden", "Visible"];
    }
}

export function Types_Visibility$reflection() {
    return union_type("LiveAuction.Types.Visibility", [], Types_Visibility, () => [[], []]);
}

export class Types_State extends Record {
    constructor(VisibleState, Auction, TimeElapsed, Version) {
        super();
        this.VisibleState = VisibleState;
        this.Auction = Auction;
        this.TimeElapsed = TimeElapsed;
        this.Version = Version;
    }
}

export function Types_State$reflection() {
    return record_type("LiveAuction.Types.State", [], Types_State, () => [["VisibleState", Types_Visibility$reflection()], ["Auction", LiveAuction_State$reflection()], ["TimeElapsed", option_type(int32_type)], ["Version", class_type("System.Int64")]]);
}

export function Types_StateModule_key(state) {
    const matchValue = state.Auction;
    switch (matchValue.tag) {
        case 1: {
            const s = matchValue.fields[0];
            return `${s.Lot.Id}-${s.Lot.CurrentBid}-${s.SecondsElapsed}`;
        }
        case 2: {
            return "finished";
        }
        default: {
            return "not-started";
        }
    }
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FromServer", "DelayVisibilityChange"];
    }
}

export function Types_Msg$reflection() {
    return union_type("LiveAuction.Types.Msg", [], Types_Msg, () => [[["Item", StreamFrom_Response$reflection()]], [["Item1", Types_Visibility$reflection()], ["Item2", class_type("System.Int64")]]]);
}

export function State_delayVisibilityChange(x, visibility, version) {
    return singleton.Delay(() => singleton.Bind(sleep(x), () => singleton.Return(new Types_Msg(1, visibility, version))));
}

export function State_init() {
    return [new Types_State(new Types_Visibility(0), new LiveAuction_State(0), void 0, fromBits(0, 0, false)), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        if (!equals(state.Version, msg.fields[1])) {
            return [state, Cmd_none()];
        }
        else {
            return [new Types_State(msg.fields[0], state.Auction, state.TimeElapsed, state.Version), Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 2) {
        const newVersion = op_Addition(state.Version, fromBits(1, 0, false));
        const matchValue = [state.Auction, msg.fields[0].fields[0]];
        const activePatternResult28033 = LiveAuction_StateModule_$007CAuctionNotStarted$007CAuctionStarted$007CAuctionFinished$007CCurrentLotBidChanged$007CCurrentLotBiddingFinished$007CNextLotBiddingStarted$007C(matchValue[0], matchValue[1]);
        if (activePatternResult28033.tag === 1) {
            return [new Types_State(new Types_Visibility(1), msg.fields[0].fields[0], LiveAuction_StateModule_trySecondsElapsed(msg.fields[0].fields[0]), newVersion), Cmd_none()];
        }
        else if (activePatternResult28033.tag === 2) {
            return [new Types_State(state.VisibleState, msg.fields[0].fields[0], void 0, newVersion), Cmd_fromAsync(State_delayVisibilityChange(3000, new Types_Visibility(1), newVersion))];
        }
        else if (activePatternResult28033.tag === 3) {
            return [new Types_State(state.VisibleState, msg.fields[0].fields[0], LiveAuction_StateModule_trySecondsElapsed(msg.fields[0].fields[0]), newVersion), Cmd_none()];
        }
        else if (activePatternResult28033.tag === 4) {
            return [new Types_State(state.VisibleState, msg.fields[0].fields[0], -1, newVersion), Cmd_batch(ofArray([Types_Cmd_notifyOrderChanged(msg.fields[0].fields[0]), Cmd_fromAsync(State_delayVisibilityChange(2000, new Types_Visibility(0), newVersion))]))];
        }
        else if (activePatternResult28033.tag === 5) {
            return [new Types_State(new Types_Visibility(1), msg.fields[0].fields[0], LiveAuction_StateModule_trySecondsElapsed(msg.fields[0].fields[0]), newVersion), Cmd_none()];
        }
        else {
            return [new Types_State(new Types_Visibility(1), state.Auction, state.TimeElapsed, newVersion), Cmd_none()];
        }
    }
    else {
        return [state, Cmd_none()];
    }
}

export function View_renderNextLotImages(ind, image, priority, displayId) {
    let elms, props_1;
    return createElement("div", {
        style: createObj(toList(delay(() => append(singleton_1(["backgroundColor", rgba(0, 0, 0, 0.3)]), delay(() => ((ind === 3) ? singleton_1(["opacity", 0]) : empty())))))),
        className: join(" ", ["lot-card-image-live-auction"]),
        children: Interop_reactApi.Children.toArray([(elms = ofArray([(props_1 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("img", {
            src: ImageUrlModule_toString(Img_lotMediumImagePathContain(image)),
            onError: (x_1) => {
                Img_onImageLoadFail(x_1);
            },
        })])]), createElement("figure", createObj(Helpers_combineClasses("image", props_1)))), createElement("span", createObj(toList(delay(() => append(singleton_1(["style", {
            position: "absolute",
            top: 0,
            padding: ((((((2 + "px ") + 10) + "px ") + 2) + "px ") + 8) + "px",
            color: "#FFFFFF",
            backgroundColor: "#000000",
            fontWeight: "bold",
        }]), delay(() => append(singleton_1(["onError", (x_2) => {
            Img_onImageLoadFail(x_2);
        }]), delay(() => singleton_1(["children", LotPrefix_getPrefix(priority) + displayId])))))))))]), createElement("div", {
            className: "card-image",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]),
    });
}

export function View_renderLotImage(url, priority) {
    let props_4;
    const url_1 = ImageUrlModule_toString(url);
    const elms = singleton_2((props_4 = ofArray([["style", {
        height: "inherit",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("img", {
        key: url_1,
        src: url_1,
        onError: (x_1) => {
            Img_onImageLoadFail(x_1);
        },
    })), delay(() => {
        let props_2;
        return LotPrefix_isPriority(priority) ? singleton_1((props_2 = ofArray([["className", join(" ", ["thumbnail-number-item-star-lot-details"])], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-star"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_2))))) : empty();
    }))))))]]), createElement("figure", createObj(Helpers_combineClasses("image", props_4)))));
    return createElement("div", {
        className: "card-image",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function View_timerOption(time, dependencies) {
    if (time == null) {
        return null;
    }
    else {
        const t = time | 0;
        return createElement(View_timer, {
            time: t,
            dependencies: dependencies,
        });
    }
}

export function View_liveDescriptionLot(state) {
    return createElement("div", {
        className: "live-auction-card-content",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["style", {
            fontWeight: "bold",
            color: "#FFFFFF",
            lineHeight: 30 + "px",
            paddingTop: 0,
        }]), delay(() => append(singleton_1(["className", "card-lot-title"]), delay(() => {
            const name = Localized_translate(state.Lot.Description.Name);
            const prefix = LotPrefix_getPrefix(state.Lot.Priority);
            const displayId = (state.Lot.DisplayId === "") ? "" : (`${state.Lot.DisplayId}. `);
            return singleton_1(["children", (name === "") ? "-" : (`${prefix}${displayId}${name}`)]);
        }))))))))), delay(() => {
            const description = Localized_translate(state.Lot.Description.Description);
            return append((description !== "") ? singleton_1(createElement("p", {
                className: "card-lot-subtitle",
                style: {
                    color: "#ccc",
                },
                children: description,
            })) : empty(), delay(() => {
                const condition = Localized_translate(state.Lot.Description.Condition);
                return append((condition !== "") ? singleton_1(createElement("p", {
                    className: "card-lot-subtitle",
                    style: {
                        color: "#ccc",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            fontWeight: 700,
                        },
                        children: ldt("condition"),
                    }), createElement("span", {
                        children: [condition],
                    })]),
                })) : empty(), delay(() => (state.Lot.Description.Royalty ? singleton_1(createElement("p", {
                    className: join(" ", ["card-lot-subtitle"]),
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        children: ldt("royalty"),
                        style: {
                            fontWeight: 700,
                            color: "#FFFFFF",
                        },
                    })]),
                })) : empty())));
            }));
        })))))),
    });
}

export function View_liveBiddingComponent(state, userId, isTablet) {
    const props_7 = toList(delay(() => append((!isTablet) ? singleton_1(["style", {
        whiteSpace: "pre-line",
        paddingTop: 0,
    }]) : singleton_1(["style", {
        whiteSpace: "pre-line",
        padding: 0,
    }]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_5, props_1, props_3;
        return (!isTablet) ? append(singleton_1(View_liveDescriptionLot(state)), delay(() => singleton_1(createElement("div", {
            style: {
                paddingTop: 20,
            },
            children: Interop_reactApi.Children.toArray([createElement(Render, {
                lotId: state.Lot.Id,
                estimate: state.Lot.Estimate,
                userId: userId,
                hasReservedPrice: state.Lot.HasReservedPrice,
                isTablet: isTablet,
            })]),
        })))) : singleton_1((props_5 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["style", {
            paddingLeft: 0,
            paddingRight: 0,
        }], ["children", Interop_reactApi.Children.toArray([View_liveDescriptionLot(state)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (props_3 = ofArray([["style", {
            paddingLeft: 0,
            paddingRight: 0,
        }], ["children", Interop_reactApi.Children.toArray([createElement(Render, {
            lotId: state.Lot.Id,
            estimate: state.Lot.Estimate,
            userId: userId,
            hasReservedPrice: state.Lot.HasReservedPrice,
            isTablet: isTablet,
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_5)))));
    }))))])))));
    return createElement("div", createObj(Helpers_combineClasses("card-content", props_7)));
}

export const View_nextLotsTitle = createElement("div", {
    style: {
        margin: ((((((10 + "px ") + 0) + "px ") + 10) + "px ") + 0) + "px",
        color: "#ffba40",
        width: 100 + "%",
        textTransform: "uppercase",
        alignItems: "center",
    },
    children: Interop_reactApi.Children.toArray([createElement("span", {
        style: {
            paddingRight: 8,
        },
        children: ldt("next.lots"),
    }), (() => {
        const props_1 = singleton_2(["className", join(" ", ["far", "fa-clock"])]);
        return createElement("span", createObj(Helpers_combineClasses("icon", props_1)));
    })()]),
});

export function View_renderContent(state, timerComponent, user, width, isConnected) {
    let props_13, props_1, props_11, props_17, props_15, props_20, props_38, props_41, props_47, props_65, props_63, props_69, props_72, props_90, props_88;
    switch (width.tag) {
        case 1: {
            const elms = ofArray([(props_13 = ofArray([["className", "is-variable"], ["className", "is-gapless"], ["style", {
                marginRight: "auto",
                display: "flex",
                padding: 0,
            }], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", join(" ", ["lot-card-image-live-auction-mobile-column"])], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["lot-card-image-live-auction"]),
                children: Interop_reactApi.Children.toArray([View_renderLotImage(Img_Url_mediumContains1800Options(state.Lot.Image), state.Lot.Priority)]),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (props_11 = ofArray([["className", "is-4"], ["className", "pl-0"], ["style", {
                backgroundColor: rgba(0, 0, 0, 0.3),
                paddingTop: 10,
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props_4;
                return append(singleton_1(createElement("span", {
                    className: "live-auction-lot-number-mobile",
                    style: {
                        fontWeight: 600,
                        fontSize: 16 + "px",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["colorWhite", "far", "fa-tag"]),
                        style: {
                            marginRight: 10,
                        },
                    }), (props_4 = singleton_2(["children", ItemIdModule_toString(state.Lot.ItemId)]), createElement("span", createObj(Helpers_combineClasses("", props_4))))]),
                })), delay(() => append(singleton_1(createElement("a", {
                    className: "live-auction-button-catalog",
                    href: toPath(new Route(3, new AuctionState(state.Lot.AuctionId, 1, new SearchCriteria(0)))),
                    children: p("button.catalogue"),
                })), delay(() => {
                    let value_67;
                    return isConnected ? singleton_1(createElement("p", {
                        style: {
                            backgroundColor: "#ffba40",
                            borderRadius: 3,
                            color: "#3a3a3a",
                            textAlign: "center",
                            width: 100 + "%",
                            marginTop: 10,
                            fontSize: 18 + "px",
                            fontWeight: 700,
                            padding: 7,
                        },
                        children: Interop_reactApi.Children.toArray([timerComponent]),
                    })) : singleton_1(createElement("p", {
                        className: "has-background-danger",
                        style: {
                            fontSize: 9 + "px",
                            borderRadius: 3,
                            color: "#FFFFFF",
                            textAlign: "center",
                            width: 100 + "%",
                            marginTop: 10,
                        },
                        children: Interop_reactApi.Children.toArray([(value_67 = ldt("noConnection"), createElement("span", {
                            children: [value_67],
                        }))]),
                    }));
                }))));
            }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_17 = ofArray([["className", join(" ", ["lot-info-card"])], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["style", {
                backgroundColor: rgba(0, 0, 0, 0.3),
                borderRadius: 0,
                color: "#FFFFFF",
            }], ["id", "lot-card"], ["children", Interop_reactApi.Children.toArray([View_liveBiddingComponent(state, Users_UserModule_userId(user), false)])]]), createElement("div", createObj(Helpers_combineClasses("card", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_17)))), createElement("div", {
                style: {
                    paddingLeft: 12,
                },
                children: Interop_reactApi.Children.toArray([View_nextLotsTitle]),
            }), (props_20 = ofArray([["style", {
                display: "flex",
                justifyContent: "center",
                paddingBottom: 50,
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((matchValue) => singleton_1(View_renderNextLotImages(matchValue[0], matchValue[1][0], matchValue[1][1], matchValue[1][2])), indexed(state.UpcomingLots))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))]);
            return createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            });
        }
        case 2: {
            const children_1 = ofArray([createElement("div", {
                className: join(" ", ["lot-card-image"]),
                style: {
                    backgroundColor: rgba(0, 0, 0, 0.3),
                    flexBasis: "auto",
                },
                children: Interop_reactApi.Children.toArray([View_renderLotImage(Img_Url_mediumContains1800Options(state.Lot.Image), state.Lot.Priority)]),
            }), createElement("div", {
                className: join(" ", ["lot-info-card"]),
                style: {
                    width: 100 + "%",
                },
                children: Interop_reactApi.Children.toArray([(props_38 = ofArray([["style", {
                    backgroundColor: rgba(0, 0, 0, 0.3),
                    borderRadius: 0,
                    color: "#FFFFFF",
                }], ["id", "lot-card"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_30, props_25;
                    return append(singleton_1((props_30 = ofArray([["style", {
                        borderBottom: (((0 + "px ") + "solid") + " ") + "#FFFFFF",
                    }], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                        className: join(" ", ["card-header-title"]),
                        style: {
                            paddingLeft: 0,
                        },
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            style: {
                                color: "#FFFFFF",
                                backgroundColor: rgba(255, 255, 255, 0.1),
                                padding: ((3 + "px ") + 15) + "px",
                                fontWeight: 600,
                                fontSize: 16 + "px",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("i", {
                                className: join(" ", ["colorWhite", "far", "fa-tag"]),
                                style: {
                                    marginRight: 10,
                                },
                            }), (props_25 = singleton_2(["children", ItemIdModule_toString(state.Lot.ItemId)]), createElement("span", createObj(Helpers_combineClasses("", props_25))))]),
                        })]),
                    }), createElement("a", {
                        style: {
                            backgroundColor: "transparent",
                            borderStyle: "none",
                            paddingRight: 0,
                            textTransform: "uppercase",
                            color: "#FFFFFF",
                            alignSelf: "center",
                        },
                        href: toPath(new Route(3, new AuctionState(state.Lot.AuctionId, 1, new SearchCriteria(0)))),
                        children: p("button.catalogue"),
                    })])]]), createElement("header", createObj(Helpers_combineClasses("card-header", props_30))))), delay(() => {
                        let props_34, value_162;
                        return append(isConnected ? singleton_1(createElement("div", {
                            style: {
                                backgroundColor: "#ffba40",
                                borderRadius: 3,
                                color: "#000000",
                                textAlign: "center",
                                margin: ((((((0 + "px ") + 20) + "px ") + 0) + "px ") + 20) + "px",
                                fontSize: 20 + "px",
                            },
                            children: Interop_reactApi.Children.toArray([timerComponent]),
                        })) : singleton_1(createElement("div", {
                            className: "has-background-danger",
                            style: {
                                borderRadius: 3,
                                color: "#FFFFFF",
                                textAlign: "center",
                                margin: ((((((0 + "px ") + 20) + "px ") + 0) + "px ") + 20) + "px",
                                fontSize: 20 + "px",
                            },
                            children: Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "pr-3"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                                className: join(" ", ["fa", "fa-unlink"]),
                            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_34)))), (value_162 = ldt("noConnection"), createElement("span", {
                                children: [value_162],
                            }))]),
                        })), delay(() => singleton_1(View_liveBiddingComponent(state, Users_UserModule_userId(user), true))));
                    }));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("card", props_38))))]),
            }), createElement("div", {
                children: Interop_reactApi.Children.toArray([View_nextLotsTitle, (props_41 = ofArray([["style", {
                    display: "flex",
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((matchValue_1) => singleton_1(View_renderNextLotImages(matchValue_1[0], matchValue_1[1][0], matchValue_1[1][1], matchValue_1[1][2])), indexed(state.UpcomingLots))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_41))))]),
            })]);
            return createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            });
        }
        case 3: {
            const props_67 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_47 = ofArray([["className", "is-7"], ["style", {
                marginRight: "auto",
            }], ["id", "column-lot"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["lot-card-image"]),
                style: {
                    backgroundColor: rgba(0, 0, 0, 0.3),
                },
                children: Interop_reactApi.Children.toArray([View_renderLotImage(Img_Url_mediumContains1800Options(state.Lot.Image), state.Lot.Priority)]),
            }), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(View_nextLotsTitle), delay(() => collect((matchValue_2) => singleton_1(View_renderNextLotImages(matchValue_2[0], matchValue_2[1][0], matchValue_2[1][1], matchValue_2[1][2])), indexed(state.UpcomingLots)))))))),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_47)))), (props_65 = ofArray([["className", "is-5"], ["children", Interop_reactApi.Children.toArray([(props_63 = ofArray([["style", {
                backgroundColor: rgba(0, 0, 0, 0.3),
                borderRadius: 0,
                color: "#FFFFFF",
            }], ["id", "lot-card"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props_55, props_50;
                return append(singleton_1((props_55 = ofArray([["style", {
                    borderBottom: (((0 + "px ") + "solid") + " ") + "#FFFFFF",
                }], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    className: join(" ", ["card-header-title"]),
                    style: {
                        paddingLeft: 0,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            color: "#FFFFFF",
                            backgroundColor: rgba(255, 255, 255, 0.1),
                            padding: ((3 + "px ") + 15) + "px",
                            fontWeight: 600,
                            fontSize: 14 + "px",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["colorWhite", "far", "fa-tag"]),
                            style: {
                                marginRight: 10,
                            },
                        }), (props_50 = singleton_2(["children", ItemIdModule_toString(state.Lot.ItemId)]), createElement("span", createObj(Helpers_combineClasses("", props_50))))]),
                    })]),
                }), createElement("a", {
                    className: "live-auction-button-catalog",
                    href: toPath(new Route(3, new AuctionState(state.Lot.AuctionId, 1, new SearchCriteria(0)))),
                    children: p("button.catalogue"),
                })])]]), createElement("header", createObj(Helpers_combineClasses("card-header", props_55))))), delay(() => {
                    let props_59, value_239;
                    return append(isConnected ? singleton_1(createElement("div", {
                        style: {
                            backgroundColor: "#ffba40",
                            borderRadius: 3,
                            color: "#000000",
                            textAlign: "center",
                            margin: ((((((0 + "px ") + 20) + "px ") + 0) + "px ") + 20) + "px",
                            fontSize: 25 + "px",
                            fontWeight: 700,
                        },
                        children: Interop_reactApi.Children.toArray([timerComponent]),
                    })) : singleton_1(createElement("div", {
                        className: "has-background-danger",
                        style: {
                            borderRadius: 3,
                            color: "#FFFFFF",
                            textAlign: "center",
                            margin: ((((((0 + "px ") + 20) + "px ") + 0) + "px ") + 20) + "px",
                            fontSize: 20 + "px",
                        },
                        children: Interop_reactApi.Children.toArray([(props_59 = ofArray([["className", "pr-3"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-unlink"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_59)))), (value_239 = ldt("noConnection"), createElement("span", {
                            children: [value_239],
                        }))]),
                    })), delay(() => singleton_1(View_liveBiddingComponent(state, Users_UserModule_userId(user), false))));
                }));
            }))))]]), createElement("div", createObj(Helpers_combineClasses("card", props_63))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_65))))])]);
            return createElement("div", createObj(Helpers_combineClasses("columns", props_67)));
        }
        case 4: {
            const elms_1 = ofArray([(props_69 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(View_nextLotsTitle), delay(() => collect((matchValue_3) => singleton_1(View_renderNextLotImages(matchValue_3[0], matchValue_3[1][0], matchValue_3[1][1], matchValue_3[1][2])), indexed(state.UpcomingLots))))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_69)))), (props_72 = ofArray([["className", "is-6"], ["style", {
                marginRight: "auto",
            }], ["id", "column-lot"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["lot-card-image"]),
                style: {
                    backgroundColor: rgba(0, 0, 0, 0.3),
                },
                children: Interop_reactApi.Children.toArray([View_renderLotImage(Img_Url_mediumContains1800Options(state.Lot.Image), state.Lot.Priority)]),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_72)))), (props_90 = ofArray([["className", join(" ", ["lot-info-card"])], ["children", Interop_reactApi.Children.toArray([(props_88 = ofArray([["style", {
                backgroundColor: rgba(0, 0, 0, 0.3),
                borderRadius: 0,
                color: "#FFFFFF",
            }], ["id", "lot-card"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props_80, props_75;
                return append(singleton_1((props_80 = ofArray([["style", {
                    borderBottom: (((0 + "px ") + "solid") + " ") + "#FFFFFF",
                }], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    className: join(" ", ["card-header-title"]),
                    style: {
                        paddingLeft: 0,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            color: "#FFFFFF",
                            backgroundColor: rgba(255, 255, 255, 0.1),
                            fontWeight: 600,
                            fontSize: 14 + "px",
                            padding: ((3 + "px ") + 15) + "px",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["colorWhite", "far", "fa-tag"]),
                            style: {
                                marginRight: 10,
                            },
                        }), (props_75 = singleton_2(["children", ItemIdModule_toString(state.Lot.ItemId)]), createElement("span", createObj(Helpers_combineClasses("", props_75))))]),
                    })]),
                }), createElement("a", {
                    className: "live-auction-button-catalog",
                    href: toPath(new Route(3, new AuctionState(state.Lot.AuctionId, 1, new SearchCriteria(0)))),
                    children: p("button.catalogue"),
                })])]]), createElement("header", createObj(Helpers_combineClasses("card-header", props_80))))), delay(() => {
                    let props_84, value_314;
                    return append(isConnected ? singleton_1(createElement("div", {
                        style: {
                            backgroundColor: "#ffba40",
                            borderRadius: 3,
                            color: "#000000",
                            textAlign: "center",
                            margin: ((((((0 + "px ") + 20) + "px ") + 0) + "px ") + 20) + "px",
                            fontSize: 25 + "px",
                            fontWeight: 700,
                        },
                        children: Interop_reactApi.Children.toArray([timerComponent]),
                    })) : singleton_1(createElement("div", {
                        className: "has-background-danger",
                        style: {
                            borderRadius: 3,
                            color: "#FFFFFF",
                            textAlign: "center",
                            margin: ((((((0 + "px ") + 20) + "px ") + 0) + "px ") + 20) + "px",
                            fontSize: 20 + "px",
                        },
                        children: Interop_reactApi.Children.toArray([(props_84 = ofArray([["className", "pr-3"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-unlink"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_84)))), (value_314 = ldt("noConnection"), createElement("span", {
                            children: [value_314],
                        }))]),
                    })), delay(() => singleton_1(View_liveBiddingComponent(state, Users_UserModule_userId(user), false))));
                }));
            }))))]]), createElement("div", createObj(Helpers_combineClasses("card", props_88))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_90))))]);
            return createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            });
        }
        default: {
            const elms = ofArray([(props_13 = ofArray([["className", "is-variable"], ["className", "is-gapless"], ["style", {
                marginRight: "auto",
                display: "flex",
                padding: 0,
            }], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", join(" ", ["lot-card-image-live-auction-mobile-column"])], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["lot-card-image-live-auction"]),
                children: Interop_reactApi.Children.toArray([View_renderLotImage(Img_Url_mediumContains1800Options(state.Lot.Image), state.Lot.Priority)]),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (props_11 = ofArray([["className", "is-4"], ["className", "pl-0"], ["style", {
                backgroundColor: rgba(0, 0, 0, 0.3),
                paddingTop: 10,
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props_4;
                return append(singleton_1(createElement("span", {
                    className: "live-auction-lot-number-mobile",
                    style: {
                        fontWeight: 600,
                        fontSize: 16 + "px",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["colorWhite", "far", "fa-tag"]),
                        style: {
                            marginRight: 10,
                        },
                    }), (props_4 = singleton_2(["children", ItemIdModule_toString(state.Lot.ItemId)]), createElement("span", createObj(Helpers_combineClasses("", props_4))))]),
                })), delay(() => append(singleton_1(createElement("a", {
                    className: "live-auction-button-catalog",
                    href: toPath(new Route(3, new AuctionState(state.Lot.AuctionId, 1, new SearchCriteria(0)))),
                    children: p("button.catalogue"),
                })), delay(() => {
                    let value_67;
                    return isConnected ? singleton_1(createElement("p", {
                        style: {
                            backgroundColor: "#ffba40",
                            borderRadius: 3,
                            color: "#3a3a3a",
                            textAlign: "center",
                            width: 100 + "%",
                            marginTop: 10,
                            fontSize: 18 + "px",
                            fontWeight: 700,
                            padding: 7,
                        },
                        children: Interop_reactApi.Children.toArray([timerComponent]),
                    })) : singleton_1(createElement("p", {
                        className: "has-background-danger",
                        style: {
                            fontSize: 9 + "px",
                            borderRadius: 3,
                            color: "#FFFFFF",
                            textAlign: "center",
                            width: 100 + "%",
                            marginTop: 10,
                        },
                        children: Interop_reactApi.Children.toArray([(value_67 = ldt("noConnection"), createElement("span", {
                            children: [value_67],
                        }))]),
                    }));
                }))));
            }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_17 = ofArray([["className", join(" ", ["lot-info-card"])], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["style", {
                backgroundColor: rgba(0, 0, 0, 0.3),
                borderRadius: 0,
                color: "#FFFFFF",
            }], ["id", "lot-card"], ["children", Interop_reactApi.Children.toArray([View_liveBiddingComponent(state, Users_UserModule_userId(user), false)])]]), createElement("div", createObj(Helpers_combineClasses("card", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_17)))), createElement("div", {
                style: {
                    paddingLeft: 12,
                },
                children: Interop_reactApi.Children.toArray([View_nextLotsTitle]),
            }), (props_20 = ofArray([["style", {
                display: "flex",
                justifyContent: "center",
                paddingBottom: 50,
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((matchValue) => singleton_1(View_renderNextLotImages(matchValue[0], matchValue[1][0], matchValue[1][1], matchValue[1][2])), indexed(state.UpcomingLots))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))]);
            return createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            });
        }
    }
}

export function useComponent_useStreamLiveAuction(user, dependencies) {
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(State_init, (msg, state) => State_update(msg, state), dependencies);
    return [patternInput[0], Feliz_React__React_useLiveAuctionStream_Static_Z2812504D((arg) => {
        patternInput[1](new Types_Msg(0, arg));
    }, Users_UserModule_userId(user), getCurrentLanguage(), dependencies)[1]];
}

export function Component_renderActiveBiddingWithHub(component_renderActiveBiddingWithHubInputProps) {
    const user = component_renderActiveBiddingWithHubInputProps.user;
    const patternInput = useComponent_useStreamLiveAuction(user, [toString(Users_UserModule_userId(user))]);
    const state = patternInput[0];
    const isConnected = patternInput[1];
    const screenSize = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    return createElement("div", {
        style: {
            backgroundImage: ("url(\u0027" + Img_actionBackground) + "\u0027)",
            backgroundSize: "cover",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (equals_1(state.Auction, new LiveAuction_State(2)) ? singleton_1(page_4(isConnected)) : singleton_1(createElement("div", {
            style: createObj(toList(delay(() => append(singleton_1(["transitionDuration", 1000 + "ms"]), delay(() => append(singleton_1(["transitionProperty", join(",", ["opacity"])]), delay(() => ((state.VisibleState.tag === 1) ? singleton_1(["opacity", 1]) : singleton_1(["opacity", 0]))))))))),
            className: "live-auction-box",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const matchValue_1 = state.Auction;
                switch (matchValue_1.tag) {
                    case 1: {
                        return singleton_1(View_renderContent(matchValue_1.fields[0], View_timerOption(state.TimeElapsed, [Types_StateModule_key(state)]), user, screenSize, isConnected));
                    }
                    case 2: {
                        return singleton_1(page_4(isConnected));
                    }
                    default: {
                        return singleton_1(createElement(Page, {
                            isConnected: isConnected,
                        }));
                    }
                }
            })))),
        }))))))),
    });
}

export function Component_renderLiveAuction(user) {
    return createElement(Component_renderActiveBiddingWithHub, {
        user: user,
    });
}

