import { Localized_formatDate, Localized_translate, t } from "../Localization.js";
import { Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { HomeInfo_ContentField, HomeInfo_HistoryPageInfo, Resource$1, SearchCriteria, HomeInfo_HistoryPageInfo$reflection, AuctionPreviewInfo$reflection } from "../Shared/Shared.js";
import { record_type, list_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { CustomerSpecific, Route, AuctionState, toPath } from "../Router.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { empty as empty_1, append, singleton, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Img_lotMediumImagePath, Img_startBackground } from "../Css.js";
import { ImageUrlModule_toString } from "../bidflow/src/infrastructure/Auction.js";
import { singleton as singleton_2, isEmpty, map, empty, filter, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { homeApi } from "../Communication.js";
import { LocalizedMap_getValueOrEmpty, LocalizedMap_empty } from "../bidflow/src/infrastructure/Localization.js";
import { fullScreenMedium } from "../Components/Loader.js";

export function p(key) {
    return t("history." + key);
}

export class AuctionsInfo extends Record {
    constructor(PublishedAuctions, PreviewAuctions, HistoryAuctions, Content) {
        super();
        this.PublishedAuctions = PublishedAuctions;
        this.PreviewAuctions = PreviewAuctions;
        this.HistoryAuctions = HistoryAuctions;
        this.Content = Content;
    }
}

export function AuctionsInfo$reflection() {
    return record_type("History.AuctionsInfo", [], AuctionsInfo, () => [["PublishedAuctions", list_type(AuctionPreviewInfo$reflection())], ["PreviewAuctions", list_type(AuctionPreviewInfo$reflection())], ["HistoryAuctions", list_type(AuctionPreviewInfo$reflection())], ["Content", HomeInfo_HistoryPageInfo$reflection()]]);
}

export function auctionBox(auction) {
    let props_7, props_1, props_5;
    return createElement("div", {
        style: {
            marginBottom: 25,
        },
        children: Interop_reactApi.Children.toArray([createElement("a", {
            href: toPath(new Route(3, new AuctionState(auction.Id, 1, new SearchCriteria(0)))),
            style: {
                color: "#000000",
            },
            children: Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-mobile"], ["className", "has-background-white"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "is-4"], ["className", "px-1"], ["className", "py-1"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(toList(delay(() => {
                let imageUrl;
                const matchValue = auction.Image;
                imageUrl = ((matchValue == null) ? Img_startBackground : ImageUrlModule_toString(Img_lotMediumImagePath(matchValue)));
                return singleton(["style", {
                    backgroundImage: ("url(\u0027" + imageUrl) + "\u0027)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "scroll",
                    backgroundSize: "cover",
                    height: 100 + "%",
                }]);
            }))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (props_5 = ofArray([["className", "px-1"], ["className", "pr-1"], ["className", "pl-4"], ["style", {
                display: "inline-grid",
            }], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                style: {
                    paddingTop: 5,
                    marginBottom: 5,
                },
                className: join(" ", ["homeTitleAuction", "history-banner-title", "overflowHidden"]),
                children: Localized_translate(auction.HeadInfo.Head),
            }), createElement("p", {
                className: "has-text-grey-light",
                style: {
                    textTransform: "uppercase",
                    paddingBottom: 5,
                },
                children: Localized_formatDate("d MMM", "d MMM", toLocalTime(auction.Date)),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_7))))]),
        })]),
    });
}

export function History() {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Resource$1(0));
    const auctions = patternInput[0];
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton_1.Delay(() => singleton_1.Bind(homeApi().getActiveAndHistoryAuctions(), (_arg1) => {
            const allActiveAndHistoryAuctions = _arg1;
            return singleton_1.Bind(homeApi().getHistoryInfo(), (_arg2) => {
                patternInput[1](new Resource$1(1, new AuctionsInfo(filter((x) => (!x.IsPreviewPublished), allActiveAndHistoryAuctions.ActiveAuctions), filter((x_1) => x_1.IsPreviewPublished, allActiveAndHistoryAuctions.ActiveAuctions), allActiveAndHistoryAuctions.HistoryAuctions, _arg2)));
                return singleton_1.Return();
            });
        })));
    });
    return createElement("div", {
        className: "background-container-login-forgot-create",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_23, props_13, props_11, elms, elms_1, props_21, value_58, props_18;
            const outgoingAuction = (auctions.tag === 1) ? auctions.fields[0].PublishedAuctions : empty();
            const activeAuctions = (auctions.tag === 1) ? auctions.fields[0].PreviewAuctions : empty();
            const historyAuctions = (auctions.tag === 1) ? createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(map((auction) => auctionBox(auction), auctions.fields[0].HistoryAuctions))),
            }) : null;
            const content_1 = (auctions.tag === 1) ? auctions.fields[0].Content : (new HomeInfo_HistoryPageInfo(LocalizedMap_empty()));
            return (auctions.tag === 0) ? singleton(fullScreenMedium(t("futureAuction.loading"))) : singleton((props_23 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-6-desktop"], ["className", "is-7-tablet"], ["children", Interop_reactApi.Children.toArray([(props_11 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => {
                let props_1;
                return append(isEmpty(outgoingAuction) ? singleton(null) : append(singleton((props_1 = ofArray([["style", {
                    fontWeight: 600,
                    marginBottom: 25,
                    paddingLeft: 0.25 + "rem",
                }], ["children", p("outgoing")]]), createElement("p", createObj(Helpers_combineClasses("", props_1))))), delay(() => singleton(createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(map((auction_1) => auctionBox(auction_1), outgoingAuction))),
                })))), delay(() => {
                    let props_4;
                    return append(singleton((props_4 = ofArray([["style", createObj(toList(delay(() => append(singleton(["fontWeight", 600]), delay(() => append((!isEmpty(outgoingAuction)) ? singleton(["marginTop", 60]) : empty_1(), delay(() => append(singleton(["marginBottom", 25]), delay(() => singleton(["paddingLeft", 0.25 + "rem"]))))))))))], ["children", p("lastAuctions")]]), createElement("p", createObj(Helpers_combineClasses("", props_4))))), delay(() => singleton(historyAuctions)));
                }));
            })), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (elms_1 = toList(delay(() => {
                let props_7;
                return isEmpty(activeAuctions) ? singleton(null) : append(singleton((props_7 = ofArray([["style", {
                    fontWeight: 600,
                    marginBottom: 25,
                    paddingLeft: 0.25 + "rem",
                }], ["children", p("upcoming")]]), createElement("p", createObj(Helpers_combineClasses("", props_7))))), delay(() => singleton(createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(map((auction_2) => auctionBox(auction_2), activeAuctions))),
                }))));
            })), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_21 = ofArray([["className", "is-4"], ["className", "is-5-tablet"], ["className", "is-offset-1-desktop"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["px-6", "py-6", "has-background-white"]),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["fontSize-desktop45-mobile30", "history-green-box-title", "homeTitleAuction"]),
                    children: Interop_reactApi.Children.toArray([(value_58 = Localized_translate(LocalizedMap_getValueOrEmpty(new HomeInfo_ContentField(0), content_1.Content)), createElement("p", {
                        children: [value_58],
                    }))]),
                }), createElement("p", {
                    className: join(" ", ["fontSize-desktop18-mobile14", "margin-bottom-desktop30-mobile10"]),
                    children: Localized_translate(LocalizedMap_getValueOrEmpty(new HomeInfo_ContentField(1), content_1.Content)),
                }), (props_18 = ofArray([["className", "is-medium"], ["className", "is-fullwidth"], ["href", toPath(new Route(24, new CustomerSpecific(0)))], ["className", "is-primary"], ["children", p("box.button")]]), createElement("a", createObj(Helpers_combineClasses("button", props_18))))]),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_23)))));
        })))),
    });
}

