import { getCurrentLanguage, t } from "../Localization.js";
import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { bool_type, union_type, unit_type, record_type, list_type, string_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Settings_addressToString, Settings_GetAccountError, UploadImage_Info, UploadImage_Item, Settings_GetAccountError$reflection, Settings_Account$reflection, UploadImage_Info$reflection, UploadImage_Image$reflection } from "../Shared/Shared.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { length, fold, ofArray, filter, append, singleton as singleton_1, empty as empty_2, map, isEmpty } from "../fable_modules/fable-library.3.6.1/List.js";
import { Validator$1__IsMail, Validator$1__IsValid_Z4CF01147, Validator$1__Test, Validator$1__NotBlank_2B595, Validator$1__End_Z5E18B1E2, validateSync } from "../fable_modules/Fable.Validation.0.2.1/Validation.fs.js";
import { tryFind, FSharpMap__get_IsEmpty, forAll, ofSeq, empty as empty_1 } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Form_getFieldError, Form_testerEmail, Form_testerPhone } from "../Common.js";
import { PhoneModule_ofString, PhoneModule_toString, PhoneModule_empty, EmailModule_empty, EmailModule_toString, EmailModule_ofString } from "../bidflow/src/infrastructure/Auction.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { imagesApi, settingsApi } from "../Communication.js";
import { newGuid } from "../fable_modules/fable-library.3.6.1/Guid.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { collect, empty as empty_3, append as append_1, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { GTM_Cmd_pushSubmissionFormSent } from "../Google.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "../fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { EditImages } from "../EditImages.js";
import { Route, toPath } from "../Router.js";
import { Img_grayJean } from "../Css.js";

export function b(key) {
    return t("appointmentBooking." + key);
}

export function p(key) {
    return t("common." + key);
}

export function ps(key) {
    return t("profileSettings." + key);
}

export class Item extends Record {
    constructor(Id, Title, Description, Images) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.Description = Description;
        this.Images = Images;
    }
}

export function Item$reflection() {
    return record_type("Client.AppointmentBooking.Item", [], Item, () => [["Id", class_type("System.Guid")], ["Title", string_type], ["Description", string_type], ["Images", list_type(UploadImage_Image$reflection())]]);
}

export class State extends Record {
    constructor(Info, Items, SaveFormOperation, FormErrors, InfoErrors, NeedValidateForm, Lang) {
        super();
        this.Info = Info;
        this.Items = Items;
        this.SaveFormOperation = SaveFormOperation;
        this.FormErrors = FormErrors;
        this.InfoErrors = InfoErrors;
        this.NeedValidateForm = NeedValidateForm;
        this.Lang = Lang;
    }
}

export function State$reflection() {
    return record_type("Client.AppointmentBooking.State", [], State, () => [["Info", UploadImage_Info$reflection()], ["Items", list_type(Item$reflection())], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Guid"), class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])])], ["InfoErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedValidateForm", bool_type], ["Lang", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TitleChanged", "DescriptionChanged", "ImagesChanged", "NameChanged", "PhoneChanged", "EmailChanged", "AddressChanged", "AddItem", "DeleteItem", "SaveForm", "LoadUser", "EventSentToGTM"];
    }
}

export function Msg$reflection() {
    return union_type("Client.AppointmentBooking.Msg", [], Msg, () => [[["Item1", class_type("System.Guid")], ["Item2", string_type]], [["Item1", class_type("System.Guid")], ["Item2", string_type]], [["Item1", class_type("System.Guid")], ["Item2", list_type(UploadImage_Image$reflection())]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Settings_Account$reflection(), Settings_GetAccountError$reflection()], FSharpResult$2, () => [[["ResultValue", Settings_Account$reflection()]], [["ErrorValue", Settings_GetAccountError$reflection()]]])]], []]);
}

export function toItem(item) {
    return new UploadImage_Item(item.Title, item.Description, item.Images);
}

export function Validation_testImages(value) {
    return !isEmpty(value);
}

export function Validation_validate(item) {
    return validateSync(true, (v) => (new UploadImage_Item(Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.itemTitle"))(Validator$1__Test(v, "ItemTitle", item.Title))), Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.itemDescription"))(Validator$1__Test(v, "ItemDescription", item.Description))), Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => Validation_testImages(value))(p("error.itemImages"))(Validator$1__Test(v, "ItemsImages", item.Images))))));
}

export function Validation_validateIf(condition, item) {
    if (condition) {
        const matchValue = Validation_validate(item);
        if (matchValue.tag === 1) {
            return [item.Id, matchValue.fields[0]];
        }
        else {
            return [item.Id, empty_1()];
        }
    }
    else {
        return [item.Id, empty_1()];
    }
}

export function Validation_validateInfo(info) {
    return validateSync(true, (v) => {
        let arg10_3;
        const Name = Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.name"))(Validator$1__Test(v, "Name", info.Name)));
        const Phone = Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (phone) => Form_testerPhone(phone))(p("error.phone"))(Validator$1__Test(v, "Phone", info.Phone)));
        return new UploadImage_Info(Name, EmailModule_ofString(Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (email) => Form_testerEmail(email))(p("error.email"))((arg10_3 = Validator$1__Test(v, "Email", EmailModule_toString(info.Email)), Validator$1__IsMail(v, p("error.email"), arg10_3))))), Phone, Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.address"))(Validator$1__Test(v, "Address", info.Address))));
    });
}

export function Validation_validateInfoIf(condition, info) {
    if (condition) {
        const matchValue = Validation_validateInfo(info);
        if (matchValue.tag === 1) {
            return matchValue.fields[0];
        }
        else {
            return empty_1();
        }
    }
    else {
        return empty_1();
    }
}

export function getUser(userId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(settingsApi().getAccount(userId), (_arg1) => singleton.Return(new Msg(10, _arg1)))), (_arg2) => singleton.Return(new Msg(10, new FSharpResult$2(1, new Settings_GetAccountError(0, userId))))));
}

export function saveItems(form, info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(imagesApi().sendItemsInfo([info, map((item) => toItem(item), form)]), (_arg2) => singleton.Return(new Msg(9, new AsyncOperationStatus$1(1, _arg2))))), (_arg3) => singleton.Return(new Msg(9, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg3.message))))));
}

export function empty(id) {
    return new Item(id, "", "", empty_2());
}

export function init(user) {
    let Info, FormErrors, InfoErrors;
    const lng = getCurrentLanguage();
    let patternInput;
    if (user.tag === 0) {
        patternInput = ["", EmailModule_empty];
    }
    else {
        const x = user.fields[0];
        patternInput = [x.Name, x.Username];
    }
    const newId = newGuid();
    return [(Info = (new UploadImage_Info(patternInput[0], patternInput[1], PhoneModule_empty, "")), (FormErrors = ofSeq([[newId, empty_1()]]), (InfoErrors = empty_1(), new State(Info, singleton_1(empty(newId)), new Deferred$1(0), FormErrors, InfoErrors, false, lng)))), Cmd_batch(toList(delay(() => ((user.tag === 0) ? singleton_2(Cmd_none()) : singleton_2(Cmd_fromAsync(getUser(user.fields[0].UserId)))))))];
}

export function withForm(form, state) {
    return new State(state.Info, form, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang);
}

export function validate(state) {
    return new State(state.Info, state.Items, state.SaveFormOperation, ofSeq(map((x) => Validation_validateIf(state.NeedValidateForm, x), state.Items)), state.InfoErrors, state.NeedValidateForm, state.Lang);
}

export function validateInfo(state) {
    return new State(state.Info, state.Items, state.SaveFormOperation, state.FormErrors, Validation_validateInfoIf(state.NeedValidateForm, state.Info), state.NeedValidateForm, state.Lang);
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4;
    if (msg.tag === 1) {
        return [validate(new State(state.Info, map((item_1) => {
            if (item_1.Id === msg.fields[0]) {
                return new Item(item_1.Id, item_1.Title, msg.fields[1], item_1.Images);
            }
            else {
                return item_1;
            }
        }, state.Items), state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [validate(new State(state.Info, map((item_2) => {
            if (item_2.Id === msg.fields[0]) {
                return new Item(item_2.Id, item_2.Title, item_2.Description, msg.fields[1]);
            }
            else {
                return item_2;
            }
        }, state.Items), state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [validateInfo(new State((inputRecord = state.Info, new UploadImage_Info(inputRecord.Name, inputRecord.Email, msg.fields[0], inputRecord.Address)), state.Items, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [validateInfo(new State((inputRecord_1 = state.Info, new UploadImage_Info(msg.fields[0], inputRecord_1.Email, inputRecord_1.Phone, inputRecord_1.Address)), state.Items, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [validateInfo(new State((inputRecord_2 = state.Info, new UploadImage_Info(inputRecord_2.Name, msg.fields[0], inputRecord_2.Phone, inputRecord_2.Address)), state.Items, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [validateInfo(new State((inputRecord_3 = state.Info, new UploadImage_Info(inputRecord_3.Name, inputRecord_3.Email, inputRecord_3.Phone, msg.fields[0])), state.Items, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new State(state.Info, append(state.Items, singleton_1(empty(newGuid()))), state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [new State(state.Info, filter((item_3) => (item_3.Id !== msg.fields[0]), state.Items), state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), Cmd_none()];
    }
    else if (msg.tag === 10) {
        if (msg.fields[0].tag === 1) {
            return [state, Cmd_none()];
        }
        else {
            const account = msg.fields[0].fields[0];
            let patternInput;
            if (account.tag === 1) {
                const x_7 = account.fields[0];
                patternInput = [x_7.ContactPerson.Phone, Settings_addressToString(state.Lang, x_7.Address)];
            }
            else {
                const x_6 = account.fields[0];
                patternInput = [x_6.PersonalInfo.Phone, Settings_addressToString(state.Lang, x_6.Address)];
            }
            return [new State((inputRecord_4 = state.Info, new UploadImage_Info(inputRecord_4.Name, inputRecord_4.Email, patternInput[0], patternInput[1])), state.Items, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), Cmd_none()];
        }
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.Info, state.Items, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), GTM_Cmd_pushSubmissionFormSent(new Msg(11))];
        }
        else {
            const errorsInfo = Validation_validateInfoIf(true, state.Info);
            const errors = ofSeq(map((x_9) => Validation_validateIf(true, x_9), state.Items));
            if (forAll((_arg1, e) => FSharpMap__get_IsEmpty(e), errors)) {
                return [new State(state.Info, state.Items, new Deferred$1(1), errors, errorsInfo, true, state.Lang), Cmd_fromAsync(saveItems(state.Items, state.Info))];
            }
            else {
                return [new State(state.Info, state.Items, new Deferred$1(0), errors, errorsInfo, true, state.Lang), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 11) {
        return [state, Cmd_none()];
    }
    else {
        return [validate(new State(state.Info, map((item) => {
            if (item.Id === msg.fields[0]) {
                return new Item(item.Id, msg.fields[1], item.Description, item.Images);
            }
            else {
                return item;
            }
        }, state.Items), state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
}

export const dividedTop = ["style", {
    borderTopStyle: "solid",
    borderTopWidth: 1 + "px",
    borderTopColor: "#c4c4c4",
}];

export function View(viewInputProps) {
    const isSeparatePage = viewInputProps.isSeparatePage;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(viewInputProps.user), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF();
    const isSaving = (state_1.SaveFormOperation.tag === 1) ? true : false;
    let saveError;
    const matchValue_1 = state_1.SaveFormOperation;
    let pattern_matching_result, error;
    if (matchValue_1.tag === 2) {
        if (matchValue_1.fields[0].tag === 1) {
            pattern_matching_result = 0;
            error = matchValue_1.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = ofArray([["className", "is-danger"], ["style", {
                marginTop: 10,
            }], ["children", error]]);
            saveError = createElement("div", createObj(Helpers_combineClasses("notification", props)));
            break;
        }
        case 1: {
            saveError = null;
            break;
        }
    }
    let isSavedSuccessfully;
    const matchValue_2 = state_1.SaveFormOperation;
    let pattern_matching_result_1;
    if (matchValue_2.tag === 2) {
        if (matchValue_2.fields[0].tag === 0) {
            pattern_matching_result_1 = 0;
        }
        else {
            pattern_matching_result_1 = 1;
        }
    }
    else {
        pattern_matching_result_1 = 1;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            isSavedSuccessfully = true;
            break;
        }
        case 1: {
            isSavedSuccessfully = false;
            break;
        }
    }
    const bookingAppointment = createElement("div", {
        disabled: isSaving,
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_5, props_39, elms_7, props_50, elms_9, props_61, elms_11, props_72;
            return append_1(singleton_2(createElement("div", {
                className: join(" ", toList(delay(() => (isSeparatePage ? singleton_2("appointment-booking-box") : singleton_2("appointment-booking-box-saparate"))))),
                style: {
                    borderRadius: 10,
                },
                children: Interop_reactApi.Children.toArray([(elms_5 = singleton_1((props_39 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_33, props_31;
                    const errors_4 = Form_getFieldError(state_1.InfoErrors, "Name");
                    return append_1(singleton_2((props_33 = ofArray([["className", "has-text-left"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", ps("name")]]), createElement("label", createObj(Helpers_combineClasses("label", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_33))))), delay(() => {
                        let elms_4;
                        return append_1(singleton_2((elms_4 = singleton_1(createElement("input", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append_1((!isEmpty(errors_4)) ? singleton_2(["style", {
                            borderColor: "#FF0000",
                            boxShadow: "none",
                        }]) : empty_3(), delay(() => append_1(singleton_2(["type", "text"]), delay(() => {
                            let value_121;
                            return append_1(singleton_2((value_121 = state_1.Info.Name, ["ref", (e_2) => {
                                if ((!(e_2 == null)) ? (!equals(e_2.value, value_121)) : false) {
                                    e_2.value = value_121;
                                }
                            }])), delay(() => singleton_2(["onChange", (ev_2) => {
                                dispatch(new Msg(3, ev_2.target.value));
                            }])));
                        }))))))))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                        }))), delay(() => {
                            let props_37;
                            return singleton_2((props_37 = ofArray([["style", {
                                color: "#FF0000",
                            }], ["children", fold((s_3, x_6) => (`${s_3} ${x_6}`), "", errors_4)]]), createElement("p", createObj(Helpers_combineClasses("help", props_37)))));
                        }));
                    }));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_39))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                })), (elms_7 = singleton_1((props_50 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_44, props_42;
                    const errors_5 = Form_getFieldError(state_1.InfoErrors, "Email");
                    return append_1(singleton_2((props_44 = ofArray([["className", "has-text-left"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_42 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", ps("email.placeholder")]]), createElement("label", createObj(Helpers_combineClasses("label", props_42))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_44))))), delay(() => {
                        let elms_6;
                        return append_1(singleton_2((elms_6 = singleton_1(createElement("input", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append_1((!isEmpty(errors_5)) ? singleton_2(["style", {
                            borderColor: "#FF0000",
                            boxShadow: "none",
                        }]) : empty_3(), delay(() => append_1(singleton_2(["type", "text"]), delay(() => {
                            let value_157;
                            return append_1(singleton_2((value_157 = EmailModule_toString(state_1.Info.Email), ["ref", (e_3) => {
                                if ((!(e_3 == null)) ? (!equals(e_3.value, value_157)) : false) {
                                    e_3.value = value_157;
                                }
                            }])), delay(() => singleton_2(["onChange", (ev_3) => {
                                dispatch(new Msg(5, EmailModule_ofString(ev_3.target.value)));
                            }])));
                        }))))))))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                        }))), delay(() => {
                            let props_48;
                            return singleton_2((props_48 = ofArray([["style", {
                                color: "#FF0000",
                            }], ["children", fold((s_5, x_7) => (`${s_5} ${x_7}`), "", errors_5)]]), createElement("p", createObj(Helpers_combineClasses("help", props_48)))));
                        }));
                    }));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_50))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                })), (elms_9 = singleton_1((props_61 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_55, props_53;
                    const errors_6 = Form_getFieldError(state_1.InfoErrors, "Phone");
                    return append_1(singleton_2((props_55 = ofArray([["className", "has-text-left"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_53 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", ps("phone.placeholder")]]), createElement("label", createObj(Helpers_combineClasses("label", props_53))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_55))))), delay(() => {
                        let elms_8;
                        return append_1(singleton_2((elms_8 = singleton_1(createElement("input", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append_1((!isEmpty(errors_6)) ? singleton_2(["style", {
                            borderColor: "#FF0000",
                            boxShadow: "none",
                        }]) : empty_3(), delay(() => append_1(singleton_2(["type", "text"]), delay(() => {
                            let value_193;
                            return append_1(singleton_2((value_193 = PhoneModule_toString(state_1.Info.Phone), ["ref", (e_4) => {
                                if ((!(e_4 == null)) ? (!equals(e_4.value, value_193)) : false) {
                                    e_4.value = value_193;
                                }
                            }])), delay(() => singleton_2(["onChange", (ev_4) => {
                                dispatch(new Msg(4, PhoneModule_ofString(ev_4.target.value)));
                            }])));
                        }))))))))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                        }))), delay(() => {
                            let props_59;
                            return singleton_2((props_59 = ofArray([["style", {
                                color: "#FF0000",
                            }], ["children", fold((s_7, x_8) => (`${s_7} ${x_8}`), "", errors_6)]]), createElement("p", createObj(Helpers_combineClasses("help", props_59)))));
                        }));
                    }));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_61))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                })), (elms_11 = singleton_1((props_72 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_66, props_64;
                    const errors_7 = Form_getFieldError(state_1.InfoErrors, "Address");
                    return append_1(singleton_2((props_66 = ofArray([["className", "has-text-left"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_64 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", ps("address.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props_64))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_66))))), delay(() => {
                        let elms_10;
                        return append_1(singleton_2((elms_10 = singleton_1(createElement("input", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append_1((!isEmpty(errors_7)) ? singleton_2(["style", {
                            borderColor: "#FF0000",
                            boxShadow: "none",
                        }]) : empty_3(), delay(() => append_1(singleton_2(["type", "text"]), delay(() => {
                            let value_228;
                            return append_1(singleton_2((value_228 = state_1.Info.Address, ["ref", (e_5) => {
                                if ((!(e_5 == null)) ? (!equals(e_5.value, value_228)) : false) {
                                    e_5.value = value_228;
                                }
                            }])), delay(() => singleton_2(["onChange", (ev_5) => {
                                dispatch(new Msg(6, ev_5.target.value));
                            }])));
                        }))))))))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                        }))), delay(() => {
                            let props_70;
                            return singleton_2((props_70 = ofArray([["style", {
                                color: "#FF0000",
                            }], ["children", fold((s_8, x_9) => (`${s_8} ${x_9}`), "", errors_7)]]), createElement("p", createObj(Helpers_combineClasses("help", props_70)))));
                        }));
                    }));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_72))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                }))]),
            })), delay(() => append_1(collect((item_1) => {
                let dispatch_1, errors, itemsCount_1, item, elms_1, props_11, elms_3, props_23, props_28, props_26;
                return singleton_2((dispatch_1 = dispatch, (errors = defaultArg(tryFind(item_1.Id, state_1.FormErrors), empty_1()), (itemsCount_1 = (length(state_1.Items) | 0), (item = item_1, createElement("div", {
                    className: join(" ", toList(delay(() => (isSeparatePage ? singleton_2("appointment-booking-box") : singleton_2("appointment-booking-box-saparate"))))),
                    style: {
                        borderRadius: 10,
                    },
                    key: `${item.Id}`,
                    children: Interop_reactApi.Children.toArray([(itemsCount_1 === 1) ? null : createElement("button", {
                        style: {
                            float: "right",
                        },
                        className: "delete",
                        onClick: (_arg1) => {
                            dispatch(new Msg(8, item.Id));
                        },
                    }), (elms_1 = singleton_1((props_11 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let props_5, props_3;
                        const errors_1 = Form_getFieldError(errors, "ItemTitle");
                        return append_1(singleton_2((props_5 = ofArray([["className", "has-text-left"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", b("item.title")]]), createElement("label", createObj(Helpers_combineClasses("label", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_5))))), delay(() => {
                            let elms;
                            return append_1(singleton_2((elms = singleton_1(createElement("input", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append_1((!isEmpty(errors_1)) ? singleton_2(["style", {
                                borderColor: "#FF0000",
                                boxShadow: "none",
                            }]) : empty_3(), delay(() => append_1(singleton_2(["type", "text"]), delay(() => {
                                let value_36;
                                return append_1(singleton_2((value_36 = item.Title, ["ref", (e) => {
                                    if ((!(e == null)) ? (!equals(e.value, value_36)) : false) {
                                        e.value = value_36;
                                    }
                                }])), delay(() => singleton_2(["onChange", (ev) => {
                                    dispatch_1(new Msg(0, item.Id, ev.target.value));
                                }])));
                            }))))))))))), createElement("div", {
                                className: "field-body",
                                children: Interop_reactApi.Children.toArray(Array.from(elms)),
                            }))), delay(() => {
                                let props_9;
                                return singleton_2((props_9 = ofArray([["style", {
                                    color: "#FF0000",
                                }], ["children", fold((s, x_1) => (`${s} ${x_1}`), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_9)))));
                            }));
                        }));
                    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_11))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    })), (elms_3 = singleton_1((props_23 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let props_16, props_14;
                        const errors_2 = Form_getFieldError(errors, "ItemDescription");
                        return append_1(singleton_2((props_16 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", b("item.description")]]), createElement("label", createObj(Helpers_combineClasses("label", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_16))))), delay(() => {
                            let elms_2, props_18;
                            return append_1(singleton_2((elms_2 = singleton_1((props_18 = toList(delay(() => append_1((!isEmpty(errors_2)) ? singleton_2(["style", {
                                borderColor: "#FF0000",
                                boxShadow: "none",
                            }]) : empty_3(), delay(() => append_1(singleton_2(["className", join(" ", ["is-medium"])]), delay(() => append_1(singleton_2(["disabled", isSaving]), delay(() => {
                                let value_70;
                                return append_1(singleton_2((value_70 = item.Description, ["ref", (e_1) => {
                                    if ((!(e_1 == null)) ? (!equals(e_1.value, value_70)) : false) {
                                        e_1.value = value_70;
                                    }
                                }])), delay(() => singleton_2(["onChange", (ev_1) => {
                                    dispatch_1(new Msg(1, item.Id, ev_1.target.value));
                                }])));
                            })))))))), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_18))))), createElement("div", {
                                className: "field-body",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                            }))), delay(() => {
                                let props_21;
                                return singleton_2((props_21 = ofArray([["style", {
                                    color: "#FF0000",
                                }], ["children", fold((s_1, x_3) => (`${s_1} ${x_3}`), "", errors_2)]]), createElement("p", createObj(Helpers_combineClasses("help", props_21)))));
                            }));
                        }));
                    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_23))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                    })), createElement(EditImages, {
                        images: item.Images,
                        onImagesChanged: (x_4) => {
                            dispatch_1(new Msg(2, item.Id, x_4));
                        },
                        dependencies: [isSavedSuccessfully],
                    }), (props_28 = ofArray([["style", {
                        paddingTop: 0,
                    }], ["children", Interop_reactApi.Children.toArray([(props_26 = toList(delay(() => {
                        const errors_3 = Form_getFieldError(errors, "ItemsImages");
                        return append_1(singleton_2(["className", "has-text-left"]), delay(() => append_1(singleton_2(["style", {
                            color: "#FF0000",
                        }]), delay(() => singleton_2(["children", fold((s_2, x_5) => (`${s_2} ${x_5}`), "", errors_3)])))));
                    })), createElement("p", createObj(Helpers_combineClasses("help", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_28))))]),
                }))))));
            }, state_1.Items), delay(() => {
                let props_84, props_80, props_77, s_9, props_82;
                return append_1(singleton_2((props_84 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_80 = ofArray([["className", "is-light"], ["onClick", (x_10) => {
                    x_10.preventDefault();
                    dispatch(new Msg(7));
                }], ["children", Interop_reactApi.Children.toArray([(props_77 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-plus"]),
                })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_77)))), (s_9 = b("add.item.button"), createElement("span", {
                    className: "",
                    children: s_9,
                }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_80)))), (props_82 = toList(delay(() => append_1(isSaving ? singleton_2(["className", "is-loading"]) : empty_3(), delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(singleton_2(["onClick", (e_6) => {
                    e_6.preventDefault();
                    dispatch(new Msg(9, new AsyncOperationStatus$1(0)));
                }]), delay(() => singleton_2(["children", b("submit.button")]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_82))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_84))))), delay(() => {
                    let elms_12;
                    return singleton_2((elms_12 = singleton_1(createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray([saveError]),
                    })), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                    })));
                }));
            }))));
        })))),
    });
    const matchValue_3 = state_1.SaveFormOperation;
    let pattern_matching_result_2;
    if (matchValue_3.tag === 2) {
        if (matchValue_3.fields[0].tag === 0) {
            pattern_matching_result_2 = 0;
        }
        else {
            pattern_matching_result_2 = 1;
        }
    }
    else {
        pattern_matching_result_2 = 1;
    }
    switch (pattern_matching_result_2) {
        case 0: {
            if (isSeparatePage) {
                return createElement("div", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["background-container-login-forgot-create", "create-account-column"])]), delay(() => append_1((width.tag === 0) ? singleton_2(["style", {}]) : ((width.tag === 1) ? singleton_2(["style", {}]) : ((width.tag === 2) ? singleton_2(["style", {
                    minHeight: 814,
                }]) : singleton_2(["style", {
                    minHeight: 500,
                }]))), delay(() => {
                    let props_94, value_301;
                    return singleton_2(["children", Interop_reactApi.Children.toArray([(props_94 = ofArray([["className", "login-box"], ["style", {
                        maxHeight: 400,
                    }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                        style: {
                            padding: 20,
                            marginBottom: 20,
                            borderBottom: (((1 + "px ") + "dashed") + " ") + "#ccc",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("p", {
                            className: join(" ", ["create-account-title"]),
                            children: b("success.title"),
                        }), createElement("div", {
                            className: "buy-text",
                            children: Interop_reactApi.Children.toArray([(value_301 = b("success.notification"), createElement("p", {
                                children: [value_301],
                            }))]),
                        })]),
                    }), createElement("a", {
                        className: join(" ", ["button", "buy-button-big"]),
                        children: b("Ok"),
                        href: toPath(new Route(0)),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("box", props_94))))])]);
                }))))))));
            }
            else {
                return createElement("div", {
                    className: "has-background-success",
                    style: {
                        padding: 20,
                        marginBottom: 20,
                        color: "#FFFFFF",
                        textAlign: "center",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("p", {
                        style: {
                            fontSize: 24 + "px",
                        },
                        children: b("success.title"),
                    }), createElement("div", {
                        style: {
                            color: "#FFFFFF",
                        },
                        children: b("success.notification"),
                    })]),
                });
            }
        }
        case 1: {
            return bookingAppointment;
        }
    }
}

export function render(user) {
    let props;
    return createElement("div", {
        style: {
            backgroundImage: ("url(\u0027" + Img_grayJean) + "\u0027)",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                padding: 5,
                paddingTop: 70,
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("fieldset", {
                    className: join(" ", ["container", "appointment-booking-container"]),
                    children: Interop_reactApi.Children.toArray([(props = ofArray([["className", "homeTitleAuction"], ["style", {
                        textAlign: "center",
                    }], ["children", b("title")]]), createElement("h3", createObj(Helpers_combineClasses("title is-3", props)))), createElement(View, {
                        user: user,
                        isSeparatePage: true,
                    })]),
                })]),
            })]),
        })]),
    });
}

