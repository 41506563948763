import { ProgramModule_mkProgram, ProgramModule_withSubscription, ProgramModule_run } from "./fable_modules/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactSynchronous } from "./fable_modules/Fable.Elmish.React.3.0.1/react.fs.js";
import { ProgramModule_toNavigable } from "./fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { parsePath } from "./fable_modules/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { routeParser } from "./Router.js";
import { view, update, init, Msg, urlUpdate as urlUpdate_1 } from "./Index.js";
import { Remoting_subscribe } from "./Extensions.js";
import "../../public/icons/css/all.min.css";


ProgramModule_run(Program_withReactSynchronous("elmish-app", ProgramModule_toNavigable((location) => parsePath(routeParser, location), (nextPage, state_2) => urlUpdate_1(nextPage, state_2), ProgramModule_withSubscription((_model) => Remoting_subscribe(new Msg(2), _model), ProgramModule_mkProgram((page) => init(page), (msg, state) => update(msg, state), (state_1, dispatch) => view(state_1, dispatch))))));

