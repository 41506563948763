import { Localized_formatDate, Localized_translate, t } from "./Localization.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { Users_UserModule_userId, SearchCriteria, SellLotState, Lots_Queries_Favourite, LotStatus as LotStatus_3, AuctionPreviewInfo$reflection, Lots_Queries_Favourite$reflection, Users_User$reflection } from "./Shared/Shared.js";
import { unit_type, record_type, class_type, union_type, string_type, list_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_favouritesChanged, Cmd_fromAsync, Deferred$1, Remoting_handleNonAuth, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { CatalogStream_ScheduleChanged_toDates, StreamFrom_Response$reflection } from "./Shared/Bidding.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { lotsAuthApi, homeApi } from "./Communication.js";
import { ImageUrlModule_toString, AuctionIdModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { ofList, tryFind as tryFind_1, toSeq, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { sortBy, filter, empty as empty_2, singleton as singleton_2, tryFind, map as map_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { op_UnaryNegation_Int32 } from "./fable_modules/fable-library.3.6.1/Int32.js";
import { collect, empty as empty_1, singleton as singleton_1, append, delay, toList, map, concat, length } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { AuctionState, toPath, Route, navigateTo } from "./Router.js";
import { equals } from "./fable_modules/fable-library.3.6.1/Long.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { fastStructuralHash, equals as equals_1, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Img_onImageLoadFail, Img_imagePlaceholder, Img_lotMedium800ImagePath } from "./Css.js";
import { defaultArg, value as value_122 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { TimerInCatalog } from "./Components/Timers.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { MediaQuery_customBreakpoints, LotPrefix_getPrefix } from "./Common.js";
import { AnimatedNumberType, View } from "./Components/AnimatedNumber.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { List_groupBy, List_distinctBy } from "./fable_modules/fable-library.3.6.1/Seq2.js";
import { compare } from "./fable_modules/fable-library.3.6.1/Date.js";
import { mediumInline } from "./Components/Loader.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useCatalogStream_Static_5365EDEF } from "./Components/SignalR.js";

export function p(key) {
    return t("favourites." + key);
}

export class Types_State extends Record {
    constructor(User, Favourites, AuctionId, LastAuctionInfo, PredictedStartLiveBiddingDates) {
        super();
        this.User = User;
        this.Favourites = Favourites;
        this.AuctionId = AuctionId;
        this.LastAuctionInfo = LastAuctionInfo;
        this.PredictedStartLiveBiddingDates = PredictedStartLiveBiddingDates;
    }
}

export function Types_State$reflection() {
    return record_type("Favourites.Types.State", [], Types_State, () => [["User", Users_User$reflection()], ["Favourites", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Lots_Queries_Favourite$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Lots_Queries_Favourite$reflection())]], [["ErrorValue", string_type]]]))], ["AuctionId", class_type("System.Int64")], ["LastAuctionInfo", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionPreviewInfo$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionPreviewInfo$reflection()]], [["ErrorValue", string_type]]]))], ["PredictedStartLiveBiddingDates", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Int64"), class_type("System.DateTimeOffset")])]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetAuctionInfo", "LoadFavourites", "DeleteFromFavourites", "GoTo", "FromServer"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Favourites.Types.Msg", [], Types_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionPreviewInfo$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionPreviewInfo$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Lots_Queries_Favourite$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Lots_Queries_Favourite$reflection())]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Int64"), list_type(class_type("System.Int64"))])], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.Int64")]], [["Item", StreamFrom_Response$reflection()]]]);
}

export const Cmd_getAuctionInfo = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(homeApi().getNextOrLastFinishedAuction(), (_arg1) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export const Cmd_loadFavourites = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getFavourites()), (_arg1) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_deleteFromFavourites(info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().deleteFromFavourites(info)), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(2, info, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg1.fields[0])))) : singleton.Return(new Types_Msg(2, info, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Types_Msg(2, info, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(user) {
    return [new Types_State(user, new Deferred$1(0), AuctionIdModule_empty, new Deferred$1(0), empty()), Cmd_batch(ofArray([Cmd_fromAsync(Cmd_loadFavourites), Cmd_fromAsync(Cmd_getAuctionInfo)]))];
}

export function State_update(msg, state) {
    if (msg.tag === 0) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.User, state.Favourites, state.AuctionId, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.PredictedStartLiveBiddingDates), Cmd_none()];
            }
            else {
                return [new Types_State(state.User, state.Favourites, msg.fields[0].fields[0].fields[0].Id, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.PredictedStartLiveBiddingDates), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.User, state.Favourites, state.AuctionId, new Deferred$1(1), state.PredictedStartLiveBiddingDates), Cmd_none()];
        }
    }
    else if (msg.tag === 2) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                Remoting_favouritesChanged(op_UnaryNegation_Int32(length(concat(map((tuple) => tuple[1], toSeq(msg.fields[0]))))));
                return [state, Cmd_fromAsync(Cmd_loadFavourites)];
            }
        }
        else {
            return [state, Cmd_fromAsync(Cmd_deleteFromFavourites(msg.fields[0]))];
        }
    }
    else if (msg.tag === 3) {
        return [state, navigateTo(new Route(4, msg.fields[0]))];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 3) {
            const matchValue = state.Favourites;
            let pattern_matching_result, lots_1;
            if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 0;
                    lots_1 = matchValue.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    if (msg.fields[0].fields[0].tag === 2) {
                        return [new Types_State(state.User, new Deferred$1(2, new FSharpResult$2(0, map_1((x_2) => {
                            if (equals(x_2.LotId, msg.fields[0].fields[0].fields[0].LotId)) {
                                const lot_1 = x_2;
                                return new Lots_Queries_Favourite(lot_1.LotId, lot_1.LotIdInAuction, lot_1.DisplayId, lot_1.AuctionInfo, lot_1.Name, lot_1.Bid, lot_1.PreviousBid, new LotStatus_3(2), lot_1.CurrentBid, lot_1.Image, lot_1.Priority);
                            }
                            else {
                                return x_2;
                            }
                        }, lots_1))), state.AuctionId, state.LastAuctionInfo, state.PredictedStartLiveBiddingDates), Cmd_none()];
                    }
                    else if (msg.fields[0].fields[0].tag === 1) {
                        return [new Types_State(state.User, new Deferred$1(2, new FSharpResult$2(0, map_1((x_3) => {
                            if (equals(x_3.LotId, msg.fields[0].fields[0].fields[0].LotId)) {
                                const lot_2 = x_3;
                                return new Lots_Queries_Favourite(lot_2.LotId, lot_2.LotIdInAuction, lot_2.DisplayId, lot_2.AuctionInfo, lot_2.Name, lot_2.Bid, lot_2.PreviousBid, new LotStatus_3(3, new SellLotState(2)), lot_2.CurrentBid, lot_2.Image, lot_2.Priority);
                            }
                            else {
                                return x_3;
                            }
                        }, lots_1))), state.AuctionId, state.LastAuctionInfo, state.PredictedStartLiveBiddingDates), Cmd_none()];
                    }
                    else if (msg.fields[0].fields[0].tag === 3) {
                        const info_4 = msg.fields[0].fields[0].fields[0];
                        const matchValue_2 = tryFind((x_4) => equals(x_4.LotId, info_4.LotId), lots_1);
                        if (matchValue_2 == null) {
                            return [state, Cmd_none()];
                        }
                        else {
                            return [new Types_State(state.User, new Deferred$1(2, new FSharpResult$2(0, map_1((x_5) => {
                                if (equals(x_5.LotId, info_4.LotId)) {
                                    const lot_3 = x_5;
                                    return new Lots_Queries_Favourite(lot_3.LotId, lot_3.LotIdInAuction, lot_3.DisplayId, lot_3.AuctionInfo, lot_3.Name, lot_3.Bid, lot_3.PreviousBid, info_4.HasSold ? (new LotStatus_3(3, new SellLotState(1))) : (new LotStatus_3(3, new SellLotState(0))), lot_3.CurrentBid, lot_3.Image, lot_3.Priority);
                                }
                                else {
                                    return x_5;
                                }
                            }, lots_1))), state.AuctionId, state.LastAuctionInfo, state.PredictedStartLiveBiddingDates), Cmd_none()];
                        }
                    }
                    else if (msg.fields[0].fields[0].tag === 4) {
                        return [new Types_State(state.User, state.Favourites, state.AuctionId, state.LastAuctionInfo, CatalogStream_ScheduleChanged_toDates(msg.fields[0].fields[0].fields[0])), Cmd_none()];
                    }
                    else {
                        const info_1 = msg.fields[0].fields[0].fields[0];
                        const matchValue_1 = tryFind((x) => equals(x.LotId, info_1.LotId), lots_1);
                        if (matchValue_1 == null) {
                            return [state, Cmd_none()];
                        }
                        else {
                            return [new Types_State(state.User, new Deferred$1(2, new FSharpResult$2(0, map_1((x_1) => {
                                if (equals(x_1.LotId, info_1.LotId)) {
                                    const lot = x_1;
                                    return new Lots_Queries_Favourite(lot.LotId, lot.LotIdInAuction, lot.DisplayId, lot.AuctionInfo, lot.Name, lot.Bid, info_1.PreviousBid, lot.LotStatus, info_1.Bid, lot.Image, lot.Priority);
                                }
                                else {
                                    return x_1;
                                }
                            }, lots_1))), state.AuctionId, state.LastAuctionInfo, state.PredictedStartLiveBiddingDates), Cmd_none()];
                        }
                    }
                }
                case 1: {
                    return [state, Cmd_none()];
                }
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(state.User, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.AuctionId, state.LastAuctionInfo, state.PredictedStartLiveBiddingDates), Cmd_none()];
        }
        else {
            return [new Types_State(state.User, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.AuctionId, state.LastAuctionInfo, state.PredictedStartLiveBiddingDates), Cmd_none()];
        }
    }
    else {
        return [state, Cmd_none()];
    }
}

export const View_noFavourites = createElement("div", {
    style: {
        color: "#cdcdcd",
        textAlign: "center",
        marginLeft: 17 + "%",
        width: 66 + "%",
    },
    children: Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-heart", "fa-5x"]),
    }), createElement("h1", {
        style: {
            marginTop: 22,
            marginBottom: 11,
            fontSize: 40 + "px",
            fontWeight: 400,
            fontFamily: "Roboto slab, Arial",
        },
        children: p("noFavourites.title"),
    }), createElement("h1", {
        style: {
            marginTop: 11,
            marginBottom: 11,
            fontSize: 20 + "px",
            fontWeight: 300,
        },
        children: p("noFavourites.subtitle"),
    })]),
});

export function View_favouriteItem(delete$, dispatch, state, lot) {
    let props_29, props_12, props_10, props_6, elms_1, props_8, props_27, props_25, props_16, props_20, props_23;
    return createElement("div", {
        style: {
            marginLeft: 0,
            marginRight: 0,
        },
        className: join(" ", ["favourites-item", "is-clickable"]),
        onClick: (_arg1) => {
            dispatch(new Types_Msg(3, lot.LotId));
        },
        children: Interop_reactApi.Children.toArray([(props_29 = ofArray([["className", "is-desktop"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-vcentered"], ["className", "is-three-fifths-desktop"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-mobile"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-narrow"], ["className", "is-half-mobile"], ["style", {
            paddingLeft: 0,
        }], ["children", Interop_reactApi.Children.toArray([(elms_1 = toList(delay(() => {
            let elms;
            return append(singleton_1((elms = singleton_2(createElement("img", createObj(toList(delay(() => append((lot.Image != null) ? singleton_1(["src", ImageUrlModule_toString(Img_lotMedium800ImagePath(value_122(lot.Image)))]) : singleton_1(["src", ImageUrlModule_toString(Img_imagePlaceholder)]), delay(() => append(singleton_1(["onError", (x_2) => {
                Img_onImageLoadFail(x_2);
            }]), delay(() => singleton_1(["className", "favourites-img-size"])))))))))), createElement("figure", {
                className: "image",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => {
                const matchValue = lot.LotStatus;
                let pattern_matching_result;
                if (matchValue.tag === 1) {
                    pattern_matching_result = 0;
                }
                else if (matchValue.tag === 2) {
                    pattern_matching_result = 1;
                }
                else if (matchValue.tag === 3) {
                    if (matchValue.fields[0].tag === 1) {
                        pattern_matching_result = 3;
                    }
                    else if (matchValue.fields[0].tag === 0) {
                        pattern_matching_result = 3;
                    }
                    else {
                        pattern_matching_result = 2;
                    }
                }
                else {
                    pattern_matching_result = 0;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        const startDate = tryFind_1(lot.LotId, state.PredictedStartLiveBiddingDates);
                        return singleton_1(createElement(TimerInCatalog, {
                            dateUntil: startDate,
                            dependencies: [startDate],
                        }));
                    }
                    case 1: {
                        return singleton_1(createElement("span", {
                            className: join(" ", ["thumbnail-number-item"]),
                            children: t("home.tag.biddingNow"),
                        }));
                    }
                    case 2: {
                        return singleton_1(createElement("span", {
                            className: join(" ", ["thumbnail-number-item"]),
                            children: p("withdrawn"),
                        }));
                    }
                    case 3: {
                        return singleton_1(createElement("span", {
                            className: join(" ", ["thumbnail-number-item"]),
                            children: t("home.tag.completed"),
                        }));
                    }
                }
            }));
        })), createElement("div", {
            className: "card-image",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = toList(delay(() => append(singleton_1(["className", "is-half-mobile"]), delay(() => append(singleton_1(["className", join(" ", ["lot-name"])]), delay(() => {
            const dot = (lot.DisplayId === "") ? "" : ". ";
            const prefix = LotPrefix_getPrefix(lot.Priority);
            return singleton_1(["children", `${(prefix + lot.DisplayId)}${dot}${Localized_translate(lot.Name)}`]);
        })))))), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_27 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_25 = ofArray([["className", "is-mobile"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_16 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("p", {
            className: join(" ", ["is-size-7-mobile", "is-size-6"]),
            children: p("maxBid"),
            style: {
                fontWeight: 400,
                textTransform: "uppercase",
                textAlign: "center",
            },
        }), createElement("p", createObj(toList(delay(() => {
            let arg00$0040;
            return append((lot.Bid != null) ? singleton_1(["children", (arg00$0040 = (value_122(lot.Bid) | 0), (arg00$0040).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))]) : singleton_1(["children", "-"]), delay(() => append(singleton_1(["className", join(" ", ["is-size-4-mobile", "is-size-3"])]), delay(() => singleton_1(["style", {
                fontWeight: "bold",
                textTransform: "uppercase",
                textAlign: "center",
            }])))));
        }))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_20 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("p", createObj(toList(delay(() => append(singleton_1(["className", "is-uppercase"]), delay(() => append(singleton_1(["className", join(" ", ["is-size-7-mobile", "is-size-6"])]), delay(() => {
            let matchValue_1;
            return append((matchValue_1 = lot.LotStatus, (matchValue_1.tag === 1) ? singleton_1(["children", p("currentBid")]) : ((matchValue_1.tag === 2) ? singleton_1(["children", p("currentBid")]) : ((matchValue_1.tag === 3) ? ((matchValue_1.fields[0].tag === 1) ? singleton_1(["children", p("finalPrice")]) : ((matchValue_1.fields[0].tag === 2) ? append(singleton_1(["style", {
                color: "rgb(214, 99, 99)",
            }]), delay(() => singleton_1(["children", p("withdrawn")]))) : singleton_1(["children", p("unsold")]))) : singleton_1(["children", "created"])))), delay(() => singleton_1(["style", {
                fontWeight: 400,
                textTransform: "uppercase",
                textAlign: "center",
            }])));
        })))))))), createElement("p", createObj(toList(delay(() => {
            const bid = defaultArg(lot.CurrentBid, 0);
            const oldBid = defaultArg(lot.PreviousBid, 0);
            return append(singleton_1(["children", Interop_reactApi.Children.toArray([createElement(View, {
                number: bid,
                oldNumber: oldBid,
                currencySymbol: null,
                typeOfComponent: new AnimatedNumberType(2),
                needBlinkGreen: false,
                needBlinkRed: false,
                needBlink: true,
                isActiveBiddingComponent: false,
            })])]), delay(() => append(singleton_1(["className", join(" ", ["is-size-4-mobile", "is-size-3"])]), delay(() => singleton_1(["style", createObj(toList(delay(() => append(singleton_1(["fontWeight", "bold"]), delay(() => append(singleton_1(["textTransform", "uppercase"]), delay(() => append(singleton_1(["textAlign", "center"]), delay(() => {
                const matchValue_2 = lot.LotStatus;
                let pattern_matching_result_1;
                if (matchValue_2.tag === 3) {
                    if (matchValue_2.fields[0].tag === 2) {
                        pattern_matching_result_1 = 0;
                    }
                    else if (matchValue_2.fields[0].tag === 0) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return singleton_1(["color", "rgb(214, 99, 99)"]);
                    }
                    case 1: {
                        return singleton_1(["color", "#444"]);
                    }
                }
            })))))))))])))));
        }))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_20)))), (props_23 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: join(" ", ["fa", "fa-times", "is-medium", "is-danger", "favourites-delete-icon"]),
            style: {
                margin: ((0 + "px ") + 15) + "px",
            },
            onClick: (x_3) => {
                x_3.stopPropagation();
                x_3.preventDefault();
                delete$(ofList(singleton_2([lot.AuctionInfo.Id, singleton_2(lot.LotId)])));
            },
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_25))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_27))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_29))))]),
    });
}

export function View_Favourites(view_FavouritesInputProps) {
    const state = view_FavouritesInputProps.state;
    const dispatch = view_FavouritesInputProps.dispatch;
    const deleteFavourite = view_FavouritesInputProps.deleteFavourite;
    const lastAuction = view_FavouritesInputProps.lastAuction;
    const lots = view_FavouritesInputProps.lots;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isActive = patternInput[0];
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_12, props_2, props_10;
            const minHeight = 45;
            return append(singleton_1((props_12 = ofArray([["className", "is-uppercase"], ["className", join(" ", ["navbar-catalogue"])], ["style", {
                minHeight: minHeight,
            }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["navbar-brand"]),
                style: {
                    minHeight: minHeight,
                },
                children: Interop_reactApi.Children.toArray([createElement("p", createObj(toList(delay(() => append((lastAuction == null) ? ((empty_1())) : singleton_1(["children", (Localized_translate(lastAuction.HeadInfo.Head) + " ") + Localized_formatDate("d MMMM", "d MMMM", value_122(lastAuction).Date)]), delay(() => append(singleton_1(["className", join(" ", ["navbar-catalogue-item", "overflowHidden"])]), delay(() => singleton_1(["style", {
                    marginRight: 12,
                    display: "block",
                }]))))))))), (props_2 = ofArray([["style", {
                    height: minHeight,
                    color: "#cdcdcd",
                    padding: ((((((6 + "px ") + 24) + "px ") + 8) + "px ") + 24) + "px",
                }], ["className", "has-text-grey-light"], ["onClick", (_arg1) => {
                    patternInput[1](!isActive);
                }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-align-right", "fa-lg", "catalogMenuMobile"]),
                })])]]), createElement("a", createObj(Helpers_combineClasses("navbar-burger", props_2))))]),
            }), (props_10 = toList(delay(() => append(isActive ? singleton_1(["className", "is-active"]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["navbar-menu-white", "navbar-menu-mobile"])]), delay(() => append(singleton_1(["style", {
                minHeight: minHeight,
            }]), delay(() => {
                let elms, props_5, props_7;
                return singleton_1(["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_5 = toList(delay(() => append(singleton_1(["className", join(" ", ["hovered-level-item"])]), delay(() => append(singleton_1(["style", {
                    color: "#444444",
                    textAlign: "center",
                }]), delay(() => append(singleton_1(["className", "is-uppercase"]), delay(() => {
                    if (lastAuction != null) {
                        const matchValue = value_122(lastAuction).Status;
                        switch (matchValue.tag) {
                            case 1: {
                                return append(singleton_1(["children", p("navbar.auction.toAuction")]), delay(() => singleton_1(["href", toPath(new Route(2, void 0))])));
                            }
                            case 2: {
                                return append(singleton_1(["children", p("navbar.auction")]), delay(() => singleton_1(["href", toPath(new Route(2, void 0))])));
                            }
                            case 3: {
                                return append(singleton_1(["children", p("navbar.auction.live")]), delay(() => singleton_1(["href", toPath(new Route(15))])));
                            }
                            default: {
                                return append(singleton_1(["children", p("navbar.auction.closed")]), delay(() => singleton_1(["href", toPath(new Route(15))])));
                            }
                        }
                    }
                    else {
                        return empty_1();
                    }
                })))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_5)))), (props_7 = toList(delay(() => append(singleton_1(["className", join(" ", ["hovered-level-item"])]), delay(() => append(singleton_1(["children", p("navbar.catalogue")]), delay(() => append(singleton_1(["style", {
                    textAlign: "center",
                    color: "#444444",
                    display: "grid",
                }]), delay(() => ((lastAuction != null) ? singleton_1(["href", toPath(new Route(3, new AuctionState(value_122(lastAuction).Id, 1, new SearchCriteria(0))))]) : singleton_1(["href", toPath(new Route(0))])))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_7))))]), createElement("div", {
                    className: "navbar-start",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))])]);
            })))))))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_10))))])]]), createElement("nav", createObj(Helpers_combineClasses("navbar", props_12))))), delay(() => singleton_1(createElement("div", {
                className: join(" ", ["favourites-container-padding"]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let children_1, props_17;
                    return equals_1(lots, empty_2()) ? singleton_1(createElement("div", {
                        style: {
                            padding: ((((((50 + "px ") + 5) + "px ") + 0) + "px ") + 5) + "px",
                            width: 100 + "%",
                        },
                        children: Interop_reactApi.Children.toArray([View_noFavourites]),
                    })) : singleton_1((children_1 = ofArray([createElement("div", {
                        style: createObj(toList(delay(() => append((width.tag === 0) ? singleton_1(["padding", ((((((10 + "px ") + 5) + "px ") + 0) + "px ") + 5) + "px"]) : ((width.tag === 1) ? singleton_1(["padding", ((((((10 + "px ") + 5) + "px ") + 0) + "px ") + 5) + "px"]) : ((width.tag === 2) ? singleton_1(["padding", ((((((10 + "px ") + 5) + "px ") + 0) + "px ") + 5) + "px"]) : singleton_1(["padding", ((((((50 + "px ") + 5) + "px ") + 0) + "px ") + 5) + "px"]))), delay(() => singleton_1(["width", 100 + "%"])))))),
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((matchValue_1) => append(singleton_1(createElement("p", {
                            style: {
                                fontSize: 14 + "px",
                                fontWeight: 600,
                                marginTop: 30,
                                marginBottom: 30,
                            },
                            children: Localized_formatDate("EEEE, dd MMMM yyyy", "EEEE, dd MMMM yyyy", matchValue_1[1]),
                        })), delay(() => map_1((lot) => View_favouriteItem(deleteFavourite, dispatch, state, lot), filter((x_4) => equals(x_4.AuctionInfo.Id, matchValue_1[0]), lots)))), sortBy((tuple_1) => tuple_1[1], List_distinctBy((tuple) => tuple[0], map_1((x_1) => [x_1.AuctionInfo.Id, x_1.AuctionInfo.Date], lots), {
                            Equals: (x_2, y) => equals(x_2, y),
                            GetHashCode: (x_2) => fastStructuralHash(x_2),
                        }), {
                            Compare: (x_3, y_1) => compare(x_3, y_1),
                        })))))),
                    }), createElement("div", {
                        style: {
                            margin: 0,
                            paddingTop: 60,
                            textAlign: "center",
                        },
                        children: Interop_reactApi.Children.toArray([(props_17 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["className", "favourites-button"]), delay(() => append(singleton_1(["children", p("button.cancel")]), delay(() => {
                            const lotsId = ofList(map_1((tupledArg) => [tupledArg[0], map_1((l) => l.LotId, tupledArg[1])], List_groupBy((x_5) => x_5.AuctionInfo.Id, lots, {
                                Equals: (x_6, y_2) => equals(x_6, y_2),
                                GetHashCode: (x_6) => fastStructuralHash(x_6),
                            })));
                            return singleton_1(["onClick", (x_7) => {
                                x_7.preventDefault();
                                deleteFavourite(lotsId);
                            }]);
                        })))))))), createElement("button", createObj(Helpers_combineClasses("button", props_17))))]),
                    })]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    })));
                })))),
            }))));
        })))),
    });
}

export function View_render(state, dispatch) {
    let lastAuction;
    const matchValue = state.LastAuctionInfo;
    let pattern_matching_result, auctionInfo;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            auctionInfo = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            lastAuction = (void 0);
            break;
        }
        case 1: {
            lastAuction = auctionInfo;
            break;
        }
        case 2: {
            lastAuction = (void 0);
            break;
        }
    }
    return createElement("div", {
        className: join(" ", ["background-container"]),
        style: {
            padding: ((((((0 + "px ") + 0) + "px ") + 100) + "px ") + 0) + "px",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props;
            const matchValue_1 = state.Favourites;
            let pattern_matching_result_1, lots;
            if (matchValue_1.tag === 1) {
                pattern_matching_result_1 = 0;
            }
            else if (matchValue_1.tag === 2) {
                if (matchValue_1.fields[0].tag === 1) {
                    pattern_matching_result_1 = 2;
                }
                else {
                    pattern_matching_result_1 = 1;
                    lots = matchValue_1.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result_1 = 0;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return singleton_1((props = singleton_2(["children", Interop_reactApi.Children.toArray([mediumInline(p("loading.lot"))])]), createElement("section", createObj(Helpers_combineClasses("section", props)))));
                }
                case 1: {
                    return singleton_1(createElement(View_Favourites, {
                        lots: lots,
                        lastAuction: lastAuction,
                        deleteFavourite: (x) => {
                            dispatch(new Types_Msg(2, x, new AsyncOperationStatus$1(0)));
                        },
                        dispatch: dispatch,
                        state: state,
                    }));
                }
                case 2: {
                    return singleton_1(null);
                }
            }
        })))),
    });
}

export function Component_RenderFavourites(component_RenderFavouritesInputProps) {
    let matchValue;
    const user = component_RenderFavouritesInputProps.user;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(user), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    useFeliz_React__React_useCatalogStream_Static_5365EDEF((arg) => {
        dispatch(new Types_Msg(4, arg));
    }, (matchValue = state_1.Favourites, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? map_1((x) => x.LotId, matchValue.fields[0].fields[0]) : empty_2()) : empty_2()), Users_UserModule_userId(user));
    return View_render(state_1, dispatch);
}

