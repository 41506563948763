import { t as t_1 } from "./Localization.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "./Extensions.js";
import { lotsApi } from "./Communication.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { large } from "./Components/Loader.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Route, toPath, navigate } from "./Router.js";

export function t(key) {
    return t_1("common." + key);
}

export function Render(renderInputProps) {
    let props_3, value_2, props_7, value_6;
    const itemId = renderInputProps.itemId;
    const lot = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(lotsApi().getLotByItemId(itemId)))), [itemId]);
    if (lot.tag === 1) {
        const props = singleton_1(["children", Interop_reactApi.Children.toArray([large])]);
        return createElement("section", createObj(Helpers_combineClasses("section", props)));
    }
    else if (lot.tag === 3) {
        return createElement("div", {
            className: join(" ", ["background-container", "padding_inherit"]),
            children: Interop_reactApi.Children.toArray([(props_3 = singleton_1(["children", Interop_reactApi.Children.toArray([(value_2 = lot.fields[0].message, createElement("div", {
                children: [value_2],
            }))])]), createElement("section", createObj(Helpers_combineClasses("section", props_3))))]),
        });
    }
    else if (lot.tag === 2) {
        if (lot.fields[0] == null) {
            return createElement("div", {
                className: join(" ", ["background-container", "padding_inherit"]),
                children: Interop_reactApi.Children.toArray([(props_7 = singleton_1(["children", Interop_reactApi.Children.toArray([(value_6 = t("error.noLotFound"), createElement("div", {
                    children: [value_6],
                }))])]), createElement("section", createObj(Helpers_combineClasses("section", props_7))))]),
            });
        }
        else {
            const lot_1 = lot.fields[0];
            navigate(toPath(new Route(4, lot_1)));
            window.location.reload();
            return null;
        }
    }
    else {
        return null;
    }
}

