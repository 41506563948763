import { Record } from "../fable-library.3.6.1/Types.js";
import { lambda_type, unit_type, enum_type, record_type, bool_type, int32_type, class_type, obj_type, option_type, string_type } from "../fable-library.3.6.1/Reflection.js";
import { some } from "../fable-library.3.6.1/Option.js";
import { startAsPromise } from "../fable-library.3.6.1/Async.js";

export class RequestOptionsRecord extends Record {
    constructor(method, url, content, headers, responseType, abortSignal, timeout, withCredentials) {
        super();
        this.method = method;
        this.url = url;
        this.content = content;
        this.headers = headers;
        this.responseType = responseType;
        this.abortSignal = abortSignal;
        this.timeout = timeout;
        this.withCredentials = withCredentials;
    }
}

export function RequestOptionsRecord$reflection() {
    return record_type("Fable.SignalR.Http.RequestOptionsRecord", [], RequestOptionsRecord, () => [["method", option_type(string_type)], ["url", option_type(string_type)], ["content", option_type(obj_type)], ["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["responseType", option_type(string_type)], ["abortSignal", option_type(class_type("Fable.SignalR.AbortSignal"))], ["timeout", option_type(int32_type)], ["withCredentials", option_type(bool_type)]]);
}

export class Request {
    constructor() {
        this.state = (new RequestOptionsRecord(void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0));
    }
}

export function Request$reflection() {
    return class_type("Fable.SignalR.Http.Request", void 0, Request);
}

export function Request_$ctor() {
    return new Request();
}

export function Request__method_Z1CC4AE14(this$, value) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new RequestOptionsRecord(value, inputRecord.url, inputRecord.content, inputRecord.headers, inputRecord.responseType, inputRecord.abortSignal, inputRecord.timeout, inputRecord.withCredentials)));
    return this$;
}

export function Request__url_Z721C83C5(this$, value) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new RequestOptionsRecord(inputRecord.method, value, inputRecord.content, inputRecord.headers, inputRecord.responseType, inputRecord.abortSignal, inputRecord.timeout, inputRecord.withCredentials)));
    return this$;
}

export function Request__content_Z721C83C5(this$, body) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new RequestOptionsRecord(inputRecord.method, inputRecord.url, some(body), inputRecord.headers, inputRecord.responseType, inputRecord.abortSignal, inputRecord.timeout, inputRecord.withCredentials)));
    return this$;
}

export function Request__content_Z3077ED04(this$, body) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new RequestOptionsRecord(inputRecord.method, inputRecord.url, some(body), inputRecord.headers, inputRecord.responseType, inputRecord.abortSignal, inputRecord.timeout, inputRecord.withCredentials)));
    return this$;
}

export function Request__headers_Z305ADAA2(this$, value) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new RequestOptionsRecord(inputRecord.method, inputRecord.url, inputRecord.content, value, inputRecord.responseType, inputRecord.abortSignal, inputRecord.timeout, inputRecord.withCredentials)));
    return this$;
}

export function Request__responseType_Z36DB2048(this$, value) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new RequestOptionsRecord(inputRecord.method, inputRecord.url, inputRecord.content, inputRecord.headers, value, inputRecord.abortSignal, inputRecord.timeout, inputRecord.withCredentials)));
    return this$;
}

export function Request__abortSignal_6F935BD1(this$, signal) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new RequestOptionsRecord(inputRecord.method, inputRecord.url, inputRecord.content, inputRecord.headers, inputRecord.responseType, signal, inputRecord.timeout, inputRecord.withCredentials)));
    return this$;
}

export function Request__timeout_Z524259A4(this$, value) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new RequestOptionsRecord(inputRecord.method, inputRecord.url, inputRecord.content, inputRecord.headers, inputRecord.responseType, inputRecord.abortSignal, value, inputRecord.withCredentials)));
    return this$;
}

export function Request__withCredentials_Z1FBCCD16(this$, value) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new RequestOptionsRecord(inputRecord.method, inputRecord.url, inputRecord.content, inputRecord.headers, inputRecord.responseType, inputRecord.abortSignal, inputRecord.timeout, value)));
    return this$;
}

export function Request__build(_) {
    return _.state;
}

export class Response extends Record {
    constructor(statusCode, statusText, content) {
        super();
        this.statusCode = (statusCode | 0);
        this.statusText = statusText;
        this.content = content;
    }
}

export function Response$reflection() {
    return record_type("Fable.SignalR.Http.Response", [], Response, () => [["statusCode", int32_type], ["statusText", option_type(string_type)], ["content", obj_type]]);
}

export class ConnectionOptions extends Record {
    constructor(headers, httpClient, transport, logger, accessTokenFactory, logMessageContent, skipNegotiation, withCredentials) {
        super();
        this.headers = headers;
        this.httpClient = httpClient;
        this.transport = transport;
        this.logger = logger;
        this.accessTokenFactory = accessTokenFactory;
        this.logMessageContent = logMessageContent;
        this.skipNegotiation = skipNegotiation;
        this.withCredentials = withCredentials;
    }
}

export function ConnectionOptions$reflection() {
    return record_type("Fable.SignalR.Http.ConnectionOptions", [], ConnectionOptions, () => [["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["httpClient", option_type(class_type("Fable.SignalR.Http.Client"))], ["transport", option_type(enum_type("Fable.SignalR.TransportType", int32_type, [["None", 0], ["WebSockets", 1], ["ServerSentEvents", 2], ["LongPolling", 4]]))], ["logger", option_type(obj_type)], ["accessTokenFactory", option_type(lambda_type(unit_type, obj_type))], ["logMessageContent", option_type(bool_type)], ["skipNegotiation", option_type(bool_type)], ["withCredentials", option_type(bool_type)]]);
}

export class ConnectionBuilder {
    constructor() {
        this.state = (new ConnectionOptions(void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0));
    }
}

export function ConnectionBuilder$reflection() {
    return class_type("Fable.SignalR.Http.ConnectionBuilder", void 0, ConnectionBuilder);
}

export function ConnectionBuilder_$ctor() {
    return new ConnectionBuilder();
}

export function ConnectionBuilder__header_Z305ADAA2(this$, headers) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(headers, inputRecord.httpClient, inputRecord.transport, inputRecord.logger, inputRecord.accessTokenFactory, inputRecord.logMessageContent, inputRecord.skipNegotiation, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__httpClient_Z332A3196(this$, client) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, client, inputRecord.transport, inputRecord.logger, inputRecord.accessTokenFactory, inputRecord.logMessageContent, inputRecord.skipNegotiation, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__transport_Z6974FC42(this$, transportType) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, inputRecord.httpClient, transportType, inputRecord.logger, inputRecord.accessTokenFactory, inputRecord.logMessageContent, inputRecord.skipNegotiation, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__logger_303398F8(this$, logger) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, inputRecord.httpClient, inputRecord.transport, some(logger), inputRecord.accessTokenFactory, inputRecord.logMessageContent, inputRecord.skipNegotiation, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__logger_2D37BB17(this$, logLevel) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, inputRecord.httpClient, inputRecord.transport, some(logLevel), inputRecord.accessTokenFactory, inputRecord.logMessageContent, inputRecord.skipNegotiation, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__accessTokenFactory_5106B011(this$, factory) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, inputRecord.httpClient, inputRecord.transport, inputRecord.logger, factory, inputRecord.logMessageContent, inputRecord.skipNegotiation, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__accessTokenFactory_Z1DC7E8E7(this$, factory) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, inputRecord.httpClient, inputRecord.transport, inputRecord.logger, factory, inputRecord.logMessageContent, inputRecord.skipNegotiation, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__accessTokenFactory_Z338AE963(this$, factory) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, inputRecord.httpClient, inputRecord.transport, inputRecord.logger, () => startAsPromise(factory()), inputRecord.logMessageContent, inputRecord.skipNegotiation, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__logMessageContent_Z1FBCCD16(this$, value) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, inputRecord.httpClient, inputRecord.transport, inputRecord.logger, inputRecord.accessTokenFactory, value, inputRecord.skipNegotiation, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__skipNegotiation_Z1FBCCD16(this$, value) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, inputRecord.httpClient, inputRecord.transport, inputRecord.logger, inputRecord.accessTokenFactory, inputRecord.logMessageContent, value, inputRecord.withCredentials)));
    return this$;
}

export function ConnectionBuilder__withCredentials_Z1FBCCD16(this$, value) {
    let inputRecord;
    this$.state = ((inputRecord = this$.state, new ConnectionOptions(inputRecord.headers, inputRecord.httpClient, inputRecord.transport, inputRecord.logger, inputRecord.accessTokenFactory, inputRecord.logMessageContent, inputRecord.skipNegotiation, value)));
    return this$;
}

export function ConnectionBuilder__build(_) {
    return _.state;
}

