import { Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { lambda_type, class_type, option_type, record_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";

export class Item extends Record {
    constructor(original, thumbnail) {
        super();
        this.original = original;
        this.thumbnail = thumbnail;
    }
}

export function Item$reflection() {
    return record_type("ImageGallery.Item", [], Item, () => [["original", string_type], ["thumbnail", string_type]]);
}

export class Media extends Record {
    constructor(original, thumbnail, embedUrl, renderItem) {
        super();
        this.original = original;
        this.thumbnail = thumbnail;
        this.embedUrl = embedUrl;
        this.renderItem = renderItem;
    }
}

export function Media$reflection() {
    return record_type("ImageGallery.Media", [], Media, () => [["original", string_type], ["thumbnail", string_type], ["embedUrl", option_type(string_type)], ["renderItem", option_type(lambda_type(Media$reflection(), class_type("Fable.React.ReactElement")))]]);
}

export class ImageGallery {
    constructor() {
    }
}

export function ImageGallery$reflection() {
    return class_type("ImageGallery.ImageGallery", void 0, ImageGallery);
}

