import { tWithArgs, t } from "./Localization.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { unit_type, union_type, class_type, record_type, bool_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi, settingsApi } from "./Communication.js";
import { Users_ChangeSendNewsletterInfo } from "./Shared/Shared.js";
import { Cmd_none, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Route, toPath } from "./Router.js";
import { Url_getCurrentUrlPage } from "./Common.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { medium } from "./Components/Loader.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";

export function p(key) {
    return t("newsletterSubscription." + key);
}

export class Types_Newsletter extends Record {
    constructor(Email, SendNewsletters) {
        super();
        this.Email = Email;
        this.SendNewsletters = SendNewsletters;
    }
}

export function Types_Newsletter$reflection() {
    return record_type("NewsletterSubscription.Types.Newsletter", [], Types_Newsletter, () => [["Email", string_type], ["SendNewsletters", bool_type]]);
}

export class Types_State extends Record {
    constructor(UserId, Newsletter, Saving) {
        super();
        this.UserId = UserId;
        this.Newsletter = Newsletter;
        this.Saving = Saving;
    }
}

export function Types_State$reflection() {
    return record_type("NewsletterSubscription.Types.State", [], Types_State, () => [["UserId", class_type("System.Int64")], ["Newsletter", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Types_Newsletter$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Types_Newsletter$reflection()]], [["ErrorValue", string_type]]]))], ["Saving", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadAccount", "ChangeSubscriptionStatus", "UserSaved"];
    }
}

export function Types_Msg$reflection() {
    return union_type("NewsletterSubscription.Types.Msg", [], Types_Msg, () => [[["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Types_Newsletter$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Types_Newsletter$reflection()]], [["ErrorValue", string_type]]]))]], [["Item1", bool_type], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], []]);
}

export function Cmd_loadStatus(userId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getAccount(userId)), (_arg1) => {
        let a_1, a;
        if (_arg1.tag === 1) {
            return singleton.Return(new Types_Msg(0, userId, new AsyncOperationStatus$1(1, new FSharpResult$2(1, `User with id=${userId} not found`))));
        }
        else {
            const account = _arg1.fields[0];
            return singleton.Return(new Types_Msg(0, userId, new AsyncOperationStatus$1(1, new FSharpResult$2(0, (account.tag === 1) ? ((a_1 = account.fields[0], new Types_Newsletter(a_1.Email, a_1.SendNewsletters))) : ((a = account.fields[0], new Types_Newsletter(a.Email, a.SendNewsletters)))))));
        }
    })), (_arg2) => singleton.Return(new Types_Msg(0, userId, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_save(userId, sendNewsletters) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(usersApi().changeSendNewsletter(new Users_ChangeSendNewsletterInfo(userId, sendNewsletters)), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(1, sendNewsletters, new AsyncOperationStatus$1(1, new FSharpResult$2(1, p("userNotExist"))))) : singleton.Return(new Types_Msg(1, sendNewsletters, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Types_Msg(1, sendNewsletters, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(userId) {
    return [new Types_State(userId, new Deferred$1(0), new Deferred$1(0)), Cmd_OfFunc_result(new Types_Msg(0, userId, new AsyncOperationStatus$1(0)))];
}

export function State_update(msg, state) {
    let matchValue;
    if (msg.tag === 1) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new Types_State(state.UserId, state.Newsletter, new Deferred$1(2, new FSharpResult$2(1, msg.fields[1].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new Types_State(state.UserId, (matchValue = state.Newsletter, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? (new Deferred$1(2, new FSharpResult$2(0, new Types_Newsletter(matchValue.fields[0].fields[0].Email, msg.fields[0])))) : matchValue) : matchValue), new Deferred$1(2, new FSharpResult$2(0, void 0))), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.UserId, state.Newsletter, new Deferred$1(1)), Cmd_fromAsync(Cmd_save(state.UserId, msg.fields[0]))];
        }
    }
    else if (msg.tag === 2) {
        return [state, Cmd_none()];
    }
    else if (msg.fields[1].tag === 1) {
        if (msg.fields[1].fields[0].tag === 1) {
            return [new Types_State(state.UserId, new Deferred$1(2, new FSharpResult$2(1, msg.fields[1].fields[0].fields[0])), state.Saving), Cmd_none()];
        }
        else {
            return [new Types_State(state.UserId, new Deferred$1(2, new FSharpResult$2(0, msg.fields[1].fields[0].fields[0])), state.Saving), Cmd_none()];
        }
    }
    else {
        return [new Types_State(state.UserId, new Deferred$1(1), state.Saving), Cmd_fromAsync(Cmd_loadStatus(msg.fields[0]))];
    }
}

export function View_view(newsletter, state, dispatch, width) {
    return createElement("form", createObj(toList(delay(() => append(singleton_1(["onSubmit", (x) => {
        x.preventDefault();
        dispatch(new Types_Msg(1, !newsletter.SendNewsletters, new AsyncOperationStatus$1(0)));
    }]), delay(() => append(singleton_1(["className", join(" ", ["background-container-login-forgot-create", "create-account-column"])]), delay(() => append((width.tag === 0) ? singleton_1(["style", {}]) : ((width.tag === 1) ? singleton_1(["style", {}]) : ((width.tag === 2) ? singleton_1(["style", {
        minHeight: 814,
    }]) : ((width.tag === 3) ? singleton_1(["style", {
        minHeight: 814,
    }]) : singleton_1(["style", {}])))), delay(() => {
        let props_10;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "login-box"], ["style", {
                maxHeight: 650,
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", {
                className: join(" ", ["create-account-title"]),
                children: p("title"),
            })), delay(() => {
                let value_26;
                return append(singleton_1(createElement("div", {
                    style: {
                        fontSize: 16 + "px",
                        fontWeight: 400,
                        textAlign: "left",
                        paddingBottom: 20,
                    },
                    children: Interop_reactApi.Children.toArray([(value_26 = tWithArgs("newsletterSubscription.textInfo", {
                        email: `${newsletter.Email}`,
                        subscriptionStatus: newsletter.SendNewsletters ? p("subscribedStatus") : p("unSubscribedStatus"),
                    }), createElement("p", {
                        children: [value_26],
                    }))]),
                })), delay(() => {
                    let props_3;
                    return append(singleton_1((props_3 = ofArray([["className", "is-primary"], ["className", "is-fullwidth"], ["className", join(" ", ["create-account-button"])], ["children", newsletter.SendNewsletters ? p("buttonUnsubscribe") : p("buttonSubscribe")]]), createElement("button", createObj(Helpers_combineClasses("button", props_3))))), delay(() => {
                        let props_8;
                        const matchValue = state.Saving;
                        let pattern_matching_result, error;
                        if (matchValue.tag === 1) {
                            pattern_matching_result = 0;
                        }
                        else if (matchValue.tag === 2) {
                            if (matchValue.fields[0].tag === 1) {
                                pattern_matching_result = 1;
                                error = matchValue.fields[0].fields[0];
                            }
                            else {
                                pattern_matching_result = 0;
                            }
                        }
                        else {
                            pattern_matching_result = 0;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                return singleton_1(null);
                            }
                            case 1: {
                                return singleton_1((props_8 = ofArray([["style", {
                                    marginTop: 80,
                                }], ["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                                    style: {
                                        textAlign: "left",
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("i", {
                                        style: {
                                            paddingLeft: 15,
                                            fontSize: 25 + "px",
                                        },
                                        className: join(" ", ["fa", "fa-2x", "fa-info-circle"]),
                                    }), createElement("p", {
                                        style: {
                                            fontSize: 14 + "px",
                                            fontWeight: 400,
                                        },
                                        children: error,
                                    })]),
                                })])]]), createElement("div", createObj(Helpers_combineClasses("notification", props_8)))));
                            }
                        }
                    }));
                }));
            }))))))]]), createElement("div", createObj(Helpers_combineClasses("box", props_10))))]),
        })])]);
    }))))))))));
}

export function View_nonAuthorizedInfo(width) {
    return createElement("form", createObj(toList(delay(() => append(singleton_1(["onSubmit", (x) => {
        x.preventDefault();
        window.location.href = toPath(new Route(6, Url_getCurrentUrlPage()));
    }]), delay(() => append(singleton_1(["className", join(" ", ["background-container-login-forgot-create", "create-account-column"])]), delay(() => append((width.tag === 0) ? singleton_1(["style", {}]) : ((width.tag === 1) ? singleton_1(["style", {}]) : ((width.tag === 2) ? singleton_1(["style", {
        minHeight: 814,
    }]) : ((width.tag === 3) ? singleton_1(["style", {
        minHeight: 814,
    }]) : singleton_1(["style", {}])))), delay(() => {
        let props_5, value_26, props_3;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "login-box"], ["style", {
                maxHeight: 650,
            }], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                className: join(" ", ["create-account-title"]),
                children: p("title"),
            }), createElement("div", {
                style: {
                    fontSize: 16 + "px",
                    fontWeight: 400,
                    textAlign: "left",
                    paddingBottom: 20,
                },
                children: Interop_reactApi.Children.toArray([(value_26 = p("nonAuthorizedInfo"), createElement("p", {
                    children: [value_26],
                }))]),
            }), (props_3 = ofArray([["className", "is-primary"], ["className", "is-fullwidth"], ["className", join(" ", ["create-account-button"])], ["children", t("login.button.login")]]), createElement("button", createObj(Helpers_combineClasses("button", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_5))))]),
        })])]);
    }))))))))));
}

export function Component_RenderNewsletterPage(component_RenderNewsletterPageInputProps) {
    const width = component_RenderNewsletterPageInputProps.width;
    const userId = component_RenderNewsletterPageInputProps.userId;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(userId), (msg, state) => State_update(msg, state), [userId]);
    const state_1 = patternInput[0];
    const matchValue = state_1.Newsletter;
    if (matchValue.tag === 1) {
        return medium("Loading");
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            return View_view(matchValue.fields[0].fields[0], state_1, patternInput[1], width);
        }
        else {
            return createElement("div", {
                children: [matchValue.fields[0].fields[0]],
            });
        }
    }
    else {
        return null;
    }
}

export function Component_Render(component_RenderInputProps) {
    const user = component_RenderInputProps.user;
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF();
    if (user.tag === 1) {
        return createElement(Component_RenderNewsletterPage, {
            userId: user.fields[0].UserId,
            width: width,
        });
    }
    else {
        return View_nonAuthorizedInfo(width);
    }
}

