import { Localized_formatDate, Localized_translate, getCurrentLanguage, t } from "../Localization.js";
import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { SearchCriteria, Lots_Category, Users_User, Errors_ServerError, Lots_LotToFavouritesRequest, Errors_ServerError$reflection, Users_User$reflection, Image$reflection, AuctionPreviewInfo$reflection, AuctionForWeeklySpecials$reflection, Lots_Queries_Lot$reflection } from "../Shared/Shared.js";
import { bool_type, tuple_type, record_type, option_type, class_type, unit_type, union_type, string_type, list_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_favouritesChanged, Cmd_fromAsync, Deferred$1, Remoting_handleNonAuth, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { lotsAuthApi, homeApi, lotsApi } from "../Communication.js";
import { sortBy, head, map, cons, filter, contains, forAll, tryFind, empty, ofArray, singleton as singleton_1, isEmpty } from "../fable_modules/fable-library.3.6.1/List.js";
import { ofList } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createObj, fastStructuralHash, equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { AuctionState, toPath, Route, navigateTo } from "../Router.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { empty as empty_1, collect, append, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { useReact_useEffectOnce_3A5B6456, React_functionComponent_2F9D7239, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { CurrencyModule_noneWhenZero, CurrencyModule_fromInt, ImageUrlModule_toString, CategoryIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { LocalizedStringModule_from } from "../bidflow/src/infrastructure/Localization.js";
import { createElement } from "react";
import * as react from "react";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "../fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { Currency_formatOption, LotPrefix_isPriority, LotPrefix_getPrefix, MediaQuery_customBreakpoints } from "../Common.js";
import { bind, map as map_1, value as value_128 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { Img_onImageLoadFail, Img_lotMedium800ImagePath, Img_imagePlaceholder } from "../Css.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { View_renderPreviewImage as View_renderPreviewImage_1 } from "./Catalog.js";
import { compare } from "../fable_modules/fable-library.3.6.1/Date.js";
import { toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { mediumInline } from "../Components/Loader.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export function p(key) {
    return t("catalogue." + key);
}

export function l(key) {
    return t("lotDetails." + key);
}

export function f(key) {
    return t("favourites." + key);
}

export class Types_State extends Record {
    constructor(LoadedLots, Auctions, AuctionInfo, AddToFavourites, IsLiked, Image, User) {
        super();
        this.LoadedLots = LoadedLots;
        this.Auctions = Auctions;
        this.AuctionInfo = AuctionInfo;
        this.AddToFavourites = AddToFavourites;
        this.IsLiked = IsLiked;
        this.Image = Image;
        this.User = User;
    }
}

export function Types_State$reflection() {
    return record_type("WeeklySpecialsCatalog.Types.State", [], Types_State, () => [["LoadedLots", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Lots_Queries_Lot$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Lots_Queries_Lot$reflection())]], [["ErrorValue", string_type]]]))], ["Auctions", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AuctionForWeeklySpecials$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(AuctionForWeeklySpecials$reflection())]], [["ErrorValue", string_type]]]))], ["AuctionInfo", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionPreviewInfo$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionPreviewInfo$reflection()]], [["ErrorValue", string_type]]]))], ["AddToFavourites", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["IsLiked", list_type(class_type("System.Int64"))], ["Image", option_type(Image$reflection())], ["User", Users_User$reflection()]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GoToLot", "LoadLots", "GetAuctionInfo", "ChangeLikeStatus", "RenderLikedInHeader", "LoadLikedLots", "LoadImages"];
    }
}

export function Types_Msg$reflection() {
    return union_type("WeeklySpecialsCatalog.Types.Msg", [], Types_Msg, () => [[["Item", class_type("System.Int64")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(Lots_Queries_Lot$reflection()), list_type(AuctionForWeeklySpecials$reflection())), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(Lots_Queries_Lot$reflection()), list_type(AuctionForWeeklySpecials$reflection()))]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionPreviewInfo$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionPreviewInfo$reflection()]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Int64")], ["Item2", class_type("System.Int64")], ["Item3", bool_type], ["Item4", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(class_type("System.Int64")), class_type("System.Int64")), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(class_type("System.Int64")), class_type("System.Int64"))]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(class_type("System.Int64")), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(class_type("System.Int64"))]], [["ErrorValue", Errors_ServerError$reflection()]]]))]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(Image$reflection()), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(Image$reflection())]], [["ErrorValue", Errors_ServerError$reflection()]]])]]]);
}

export const Cmd_getWeeklySpecialsLots = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(lotsApi().getLotsWeeklySpecials(), (_arg1) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export const Cmd_getAuctionInfo = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(homeApi().getNextOrLastFinishedAuction(), (_arg1) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_getCurrentAuctionId(lotId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getCurrentAuctionIds()), (_arg1) => {
        const auctionIds = _arg1;
        return isEmpty(auctionIds) ? singleton.Return(new Types_Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Current auction was not found")))) : singleton.Return(new Types_Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(0, [auctionIds, lotId]))));
    })), (_arg2) => singleton.Return(new Types_Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_changeLikeStatus(lotId, isToAdd, auctionId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => (isToAdd ? singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().addToFavourites(new Lots_LotToFavouritesRequest(lotId, auctionId))), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(3, lotId, auctionId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg1.fields[0])))) : singleton.Return(new Types_Msg(3, lotId, auctionId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))) : singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().deleteFromFavourites(ofList(singleton_1([auctionId, singleton_1(lotId)])))), (_arg2) => ((_arg2.tag === 1) ? singleton.Return(new Types_Msg(3, lotId, auctionId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.fields[0])))) : singleton.Return(new Types_Msg(3, lotId, auctionId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))))), (_arg3) => singleton.Return(new Types_Msg(3, lotId, auctionId, isToAdd, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg3.message))))));
}

export function Cmd_getLikedLots(auctionIds) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getLikedLots(auctionIds)), (_arg1) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(0, ofArray(_arg1))))))), (_arg2) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message)))))));
}

export function Cmd_loadImages(auctionId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(homeApi().getCatalogImages(auctionId)), (_arg1) => singleton.Return(new Types_Msg(6, new FSharpResult$2(0, _arg1.FirstPriorityImage))))), (_arg2) => singleton.Return(new Types_Msg(6, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message))))));
}

export function State_init(user) {
    return [new Types_State(new Deferred$1(1), new Deferred$1(1), new Deferred$1(0), new Deferred$1(0), empty(), void 0, user), Cmd_batch(ofArray([Cmd_fromAsync(Cmd_getAuctionInfo), Cmd_OfFunc_result(new Types_Msg(1, new AsyncOperationStatus$1(0)))]))];
}

export function State_update(msg, state) {
    let matchValue_1;
    if (msg.tag === 0) {
        if (equals(state.AddToFavourites, new Deferred$1(1))) {
            return [state, Cmd_none()];
        }
        else {
            return [state, navigateTo(new Route(4, msg.fields[0]))];
        }
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.LoadedLots, state.Auctions, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.AddToFavourites, state.IsLiked, state.Image, state.User), Cmd_none()];
            }
            else {
                return [new Types_State(state.LoadedLots, state.Auctions, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.AddToFavourites, state.IsLiked, state.Image, state.User), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.LoadedLots, state.Auctions, new Deferred$1(1), state.AddToFavourites, state.IsLiked, state.Image, state.User), Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        if (msg.fields[3].tag === 1) {
            if (msg.fields[3].fields[0].tag === 1) {
                return [new Types_State(state.LoadedLots, state.Auctions, state.AuctionInfo, new Deferred$1(2, new FSharpResult$2(1, msg.fields[3].fields[0].fields[0])), state.IsLiked, state.Image, state.User), Cmd_none()];
            }
            else {
                return [state, Cmd_fromAsync(Cmd_getCurrentAuctionId(msg.fields[0]))];
            }
        }
        else if (state.User.tag === 0) {
            return [state, Cmd_none()];
        }
        else {
            return [new Types_State(state.LoadedLots, state.Auctions, state.AuctionInfo, new Deferred$1(1), state.IsLiked, state.Image, state.User), Cmd_fromAsync(Cmd_changeLikeStatus(msg.fields[0], msg.fields[2], msg.fields[1]))];
        }
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.LoadedLots, state.Auctions, state.AuctionInfo, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsLiked, state.Image, state.User), Cmd_none()];
            }
            else {
                const lot = tryFind((lotId) => equals_1(lotId, msg.fields[0].fields[0].fields[0][1]), state.IsLiked);
                return [new Types_State(state.LoadedLots, state.Auctions, state.AuctionInfo, new Deferred$1(2, new FSharpResult$2(0, void 0)), ((matchValue_1 = state.Auctions, (matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? forAll((x_1) => contains(x_1.Id, msg.fields[0].fields[0].fields[0][0], {
                    Equals: (x_2, y) => equals_1(x_2, y),
                    GetHashCode: (x_2) => fastStructuralHash(x_2),
                }), matchValue_1.fields[0].fields[0]) : false) : false)) ? ((lot != null) ? ((Remoting_favouritesChanged(-1), filter((x_3) => (!equals_1(x_3, msg.fields[0].fields[0].fields[0][1])), state.IsLiked))) : ((Remoting_favouritesChanged(1), cons(msg.fields[0].fields[0].fields[0][1], state.IsLiked)))) : ((lot != null) ? filter((x_4) => (!equals_1(x_4, msg.fields[0].fields[0].fields[0][1])), state.IsLiked) : cons(msg.fields[0].fields[0].fields[0][1], state.IsLiked)), state.Image, state.User), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.LoadedLots, state.Auctions, state.AuctionInfo, state.AddToFavourites, empty(), state.Image, state.User), Cmd_none()];
            }
            else {
                return [new Types_State(state.LoadedLots, state.Auctions, state.AuctionInfo, state.AddToFavourites, msg.fields[0].fields[0].fields[0], state.Image, state.User), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            return [state, Cmd_none()];
        }
        else {
            return [new Types_State(state.LoadedLots, state.Auctions, state.AuctionInfo, state.AddToFavourites, state.IsLiked, msg.fields[0].fields[0], state.User), Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.Auctions, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.Image, state.User), Cmd_none()];
        }
        else {
            return [new Types_State(new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0][0])), new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0][1])), state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.Image, state.User), Cmd_batch(toList(delay(() => {
                const auctionIds = map((x) => x.Id, msg.fields[0].fields[0].fields[0][1]);
                return equals(state.User, new Users_User(0)) ? singleton_2(Cmd_none()) : singleton_2(Cmd_fromAsync(Cmd_getLikedLots(auctionIds)));
            })))];
        }
    }
    else {
        return [new Types_State(new Deferred$1(1), state.Auctions, state.AuctionInfo, state.AddToFavourites, state.IsLiked, state.Image, state.User), Cmd_fromAsync(Cmd_getWeeklySpecialsLots)];
    }
}

export function View_SelectCategory(view_SelectCategoryInputProps) {
    const width = view_SelectCategoryInputProps.width;
    const onSelect = view_SelectCategoryInputProps.onSelect;
    const auctionId = view_SelectCategoryInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisibility = patternInput[1];
    const isVisible = patternInput[0];
    const categories = useFeliz_React__React_useDeferred_Static_2344FC52(lotsApi().categories(auctionId), [auctionId]);
    switch (categories.tag) {
        case 3:
        case 1: {
            return null;
        }
        case 2: {
            const categories_2 = cons(new Lots_Category(CategoryIdModule_empty, LocalizedStringModule_from(singleton_1([getCurrentLanguage(), t("catalogue.all.categories")]))), categories.fields[0]);
            return react.createElement(react.Fragment, {}, ...toList(delay(() => {
                let props_5, props_12;
                return (width.tag === 0) ? singleton_2((props_5 = ofArray([["className", join(" ", ["has-dropdown", "is-size-6-mobile", "is-hoverable", "hovered-level-item"])], ["style", {
                    textAlign: "center",
                    marginLeft: 0,
                    color: "#444444",
                }], ["onClick", (_arg1) => {
                    setVisibility(!isVisible);
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("span", {
                    className: "is-uppercase",
                    children: p("navbar.categories"),
                })), delay(() => append(singleton_2(createElement("i", {
                    className: join(" ", ["fa", "fa-chevron-down"]),
                    style: {
                        paddingLeft: 6,
                    },
                })), delay(() => {
                    let elms;
                    return isVisible ? singleton_2((elms = toList(delay(() => collect((category) => {
                        let props_2;
                        const categoryId = (category.Id === CategoryIdModule_empty) ? (void 0) : category.Id;
                        return singleton_2((props_2 = ofArray([["className", join(" ", ["navbar-catalogue-categories-mobile"])], ["children", Localized_translate(category.Name)], ["onClick", (_arg2) => {
                            onSelect(categoryId);
                            setVisibility(false);
                        }]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_2)))));
                    }, categories_2))), createElement("div", {
                        className: "navbar-dropdown",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))) : empty_1();
                }))))))))]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_5))))) : ((width.tag === 1) ? singleton_2((props_5 = ofArray([["className", join(" ", ["has-dropdown", "is-size-6-mobile", "is-hoverable", "hovered-level-item"])], ["style", {
                    textAlign: "center",
                    marginLeft: 0,
                    color: "#444444",
                }], ["onClick", (_arg1) => {
                    setVisibility(!isVisible);
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("span", {
                    className: "is-uppercase",
                    children: p("navbar.categories"),
                })), delay(() => append(singleton_2(createElement("i", {
                    className: join(" ", ["fa", "fa-chevron-down"]),
                    style: {
                        paddingLeft: 6,
                    },
                })), delay(() => {
                    let elms;
                    return isVisible ? singleton_2((elms = toList(delay(() => collect((category) => {
                        let props_2;
                        const categoryId = (category.Id === CategoryIdModule_empty) ? (void 0) : category.Id;
                        return singleton_2((props_2 = ofArray([["className", join(" ", ["navbar-catalogue-categories-mobile"])], ["children", Localized_translate(category.Name)], ["onClick", (_arg2) => {
                            onSelect(categoryId);
                            setVisibility(false);
                        }]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_2)))));
                    }, categories_2))), createElement("div", {
                        className: "navbar-dropdown",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))) : empty_1();
                }))))))))]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_5))))) : singleton_2((props_12 = ofArray([["className", join(" ", ["has-dropdown", "hovered-level-item", "is-hoverable"])], ["style", {
                    textAlign: "center",
                    color: "#444444",
                    display: "grid",
                }], ["onClick", (_arg3) => {
                    setVisibility(!isVisible);
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_7;
                    return append(singleton_2((props_7 = ofArray([["className", "is-uppercase"], ["style", {
                        padding: ((((((0 + "px ") + 35) + "px ") + 0) + "px ") + 0) + "px",
                        color: "#444444",
                    }], ["id", "catalog-navbar-dropdown-arrow"], ["children", p("navbar.categories")]]), createElement("a", createObj(Helpers_combineClasses("navbar-link", props_7))))), delay(() => {
                        let elms_1;
                        return isVisible ? singleton_2((elms_1 = toList(delay(() => collect((category_1) => {
                            let props_9;
                            const categoryId_1 = (category_1.Id === CategoryIdModule_empty) ? (void 0) : category_1.Id;
                            return singleton_2((props_9 = ofArray([["className", join(" ", ["navbar-catalogue-categories"])], ["children", Localized_translate(category_1.Name)], ["onClick", (_arg4) => {
                                onSelect(categoryId_1);
                            }]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_9)))));
                        }, categories_2))), createElement("div", {
                            className: "navbar-dropdown",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        }))) : empty_1();
                    }));
                }))))]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_12))))));
            })));
        }
        default: {
            return null;
        }
    }
}

export function View_Navbar(state) {
    let props_2, props_10;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isActive = patternInput[0];
    const minHeight = 45;
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    let info;
    const matchValue = state.AuctionInfo;
    let pattern_matching_result, auctionInfo;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            auctionInfo = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            info = (void 0);
            break;
        }
        case 1: {
            info = auctionInfo;
            break;
        }
        case 2: {
            info = (void 0);
            break;
        }
    }
    const props_12 = ofArray([["className", "is-uppercase"], ["className", join(" ", ["navbar-catalogue"])], ["style", createObj(toList(delay(() => append(singleton_2(["minHeight", minHeight]), delay(() => append(singleton_2(["marginBottom", 0]), delay(() => {
        if (state.User.tag === 0) {
            switch (width.tag) {
                case 0:
                case 1: {
                    return singleton_2(["top", 58]);
                }
                default: {
                    return singleton_2(["top", 0]);
                }
            }
        }
        else {
            switch (width.tag) {
                case 0:
                case 1: {
                    return singleton_2(["top", 58]);
                }
                default: {
                    return empty_1();
                }
            }
        }
    })))))))], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: join(" ", ["navbar-brand"]),
        style: {
            minHeight: minHeight,
        },
        children: Interop_reactApi.Children.toArray([createElement("p", createObj(toList(delay(() => {
            let matchValue_2;
            return append((info != null) ? singleton_2(["children", ((matchValue_2 = state.AuctionInfo, (matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? Localized_translate(matchValue_2.fields[0].fields[0].HeadInfo.Head) : p("navbar.title")) : p("navbar.title"))) + Localized_formatDate("d MMMM", "d MMMM", value_128(info).Date)]) : empty_1(), delay(() => singleton_2(["className", join(" ", ["navbar-catalogue-item", "overflowHidden", "navbarAuctionTitle"])])));
        })))), (props_2 = ofArray([["style", {
            height: minHeight,
            color: "#cdcdcd",
            padding: ((((((6 + "px ") + 24) + "px ") + 8) + "px ") + 24) + "px",
        }], ["className", "has-text-grey-light"], ["onClick", (_arg1) => {
            patternInput[1](!isActive);
        }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-align-right", "fa-lg", "catalogMenuMobile"]),
        })])]]), createElement("a", createObj(Helpers_combineClasses("navbar-burger", props_2))))]),
    }), (props_10 = toList(delay(() => append(isActive ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["navbar-menu-white", "navbar-menu-mobile"])]), delay(() => append(singleton_2(["style", {
        minHeight: minHeight,
    }]), delay(() => {
        let elms, props_5, props_7;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_5 = toList(delay(() => append(singleton_2(["className", join(" ", ["hovered-level-item"])]), delay(() => append(singleton_2(["style", {
            color: "#444444",
            textAlign: "center",
        }]), delay(() => append(singleton_2(["className", "is-uppercase"]), delay(() => {
            if (info != null) {
                const matchValue_3 = value_128(info).Status;
                switch (matchValue_3.tag) {
                    case 1: {
                        return append(singleton_2(["children", f("navbar.auction.toAuction")]), delay(() => singleton_2(["href", toPath(new Route(2, value_128(info).Id))])));
                    }
                    case 2: {
                        return append(singleton_2(["children", f("navbar.auction.toAuction")]), delay(() => singleton_2(["href", toPath(new Route(2, value_128(info).Id))])));
                    }
                    case 3: {
                        return append(singleton_2(["children", f("navbar.auction.live")]), delay(() => singleton_2(["href", toPath(new Route(15))])));
                    }
                    default: {
                        return append(singleton_2(["children", f("navbar.auction.closed")]), delay(() => singleton_2(["href", toPath(new Route(15))])));
                    }
                }
            }
            else {
                return empty_1();
            }
        })))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_5)))), (props_7 = toList(delay(() => append(singleton_2(["className", join(" ", ["hovered-level-item"])]), delay(() => append(singleton_2(["children", f("navbar.catalogue")]), delay(() => append(singleton_2(["style", {
            textAlign: "center",
            color: "#444444",
            display: "grid",
        }]), delay(() => ((info != null) ? singleton_2(["href", toPath(new Route(3, new AuctionState(value_128(info).Id, 1, new SearchCriteria(0))))]) : singleton_2(["href", toPath(new Route(0))])))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_7))))]), createElement("div", {
            className: "navbar-start",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]);
    })))))))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_10))))])]]);
    return createElement("nav", createObj(Helpers_combineClasses("navbar", props_12)));
}

export const View_RenderLots = React_functionComponent_2F9D7239((props) => {
    const renderCard = (lot, state, addToFavourites, removeFromFavourites) => {
        let elms, props_17, children_1, value_66;
        const props_19 = ofArray([["className", "catalog-lot-card"], ["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => {
            let images, image, props_2;
            return append(singleton_2((images = lot.Images, (image = (isEmpty(images) ? Img_imagePlaceholder : Img_lotMedium800ImagePath(head(images))), (props_2 = ofArray([["id", "catalog-card-image"], ["style", {
                cursor: "pointer",
                marginLeft: 0,
                marginRight: 0,
                textAlign: "center",
                marginBottom: 0,
            }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
                id: "catalog-lot-img",
                src: ImageUrlModule_toString(image),
                onError: (x_2) => {
                    Img_onImageLoadFail(x_2);
                },
            })])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_2))))))), delay(() => append(singleton_2(createElement("span", createObj(toList(delay(() => append(singleton_2(["className", join(" ", ["thumbnail-number-item"])]), delay(() => singleton_2(["children", LotPrefix_getPrefix(lot.Priority) + lot.DisplayId])))))))), delay(() => {
                let props_6;
                return LotPrefix_isPriority(lot.Priority) ? singleton_2((props_6 = ofArray([["className", join(" ", ["thumbnail-number-item-star"])], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-star"]),
                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_6))))) : empty_1();
            }))));
        })), createElement("div", {
            className: "card-image",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_17 = ofArray([["id", "catalog-card-content"], ["children", Interop_reactApi.Children.toArray([createElement("h2", createObj(toList(delay(() => {
            const name_12 = Localized_translate(lot.Name);
            return append(singleton_2(["children", (name_12 === "") ? "-" : name_12]), delay(() => singleton_2(["className", join(" ", ["name-lot-in-catalog"])])));
        })))), createElement("p", createObj(toList(delay(() => append(singleton_2(["className", join(" ", ["estimate-in-catalog"])]), delay(() => {
            const matchValue = lot.BidStatus;
            let pattern_matching_result;
            if (matchValue.tag === 1) {
                pattern_matching_result = 1;
            }
            else if (matchValue.tag === 2) {
                pattern_matching_result = 1;
            }
            else if (matchValue.tag === 3) {
                if (matchValue.fields[0].tag === 1) {
                    pattern_matching_result = 3;
                }
                else if (matchValue.fields[0].tag === 2) {
                    pattern_matching_result = 4;
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(["children", "created"]);
                }
                case 1: {
                    return singleton_2(["children", p("currentBid.state")]);
                }
                case 2: {
                    return singleton_2(["children", p("unsold.state")]);
                }
                case 3: {
                    return singleton_2(["children", p("finalPrice.state")]);
                }
                case 4: {
                    return append(singleton_2(["style", {
                        color: "rgb(214, 99, 99)",
                    }]), delay(() => singleton_2(["children", p("withdrawn.state")])));
                }
            }
        })))))), (children_1 = singleton_1(createElement("h3", {
            className: join(" ", ["actual-bid-in-catalog"]),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("span", createObj(toList(delay(() => {
                let matchValue_1;
                return append((matchValue_1 = lot.BidStatus, (matchValue_1.tag === 3) ? ((matchValue_1.fields[0].tag === 2) ? singleton_2(["style", {
                    color: "rgb(214, 99, 99)",
                }]) : ((matchValue_1.fields[0].tag === 0) ? singleton_2(["style", {
                    color: "rgb(214, 99, 99)",
                }]) : ((empty_1())))) : ((empty_1()))), delay(() => singleton_2(["children", Currency_formatOption("-", map_1((value_17) => CurrencyModule_fromInt(value_17), lot.CurrentBid))])));
            }))))), delay(() => ((state.User.tag === 0) ? singleton_2(null) : singleton_2(createElement("i", createObj(toList(delay(() => append(singleton_2(["className", join(" ", ["fa", "fa-heart", "catalog-heart-icon"])]), delay(() => {
                const isLiked = tryFind((x_8) => equals_1(x_8, lot.LotId), state.IsLiked);
                return append(singleton_2(["style", createObj(toList(delay(() => append(singleton_2(["float", "right"]), delay(() => append((isLiked != null) ? singleton_2(["color", "#51A351"]) : singleton_2(["color", "#cdcdcd"]), delay(() => singleton_2(["padding", 10]))))))))]), delay(() => {
                    if (isLiked == null) {
                        const matchValue_4 = lot.AuctionId;
                        if (matchValue_4 == null) {
                            return singleton_2(["onClick", (x_12) => {
                                x_12.preventDefault();
                            }]);
                        }
                        else {
                            const a_1 = matchValue_4;
                            return singleton_2(["onClick", (x_11) => {
                                x_11.preventDefault();
                                addToFavourites([lot.LotId, a_1]);
                            }]);
                        }
                    }
                    else {
                        const l_1 = isLiked;
                        const matchValue_3 = lot.AuctionId;
                        if (matchValue_3 == null) {
                            return singleton_2(["onClick", (x_10) => {
                                x_10.preventDefault();
                            }]);
                        }
                        else {
                            const a = matchValue_3;
                            return singleton_2(["onClick", (x_9) => {
                                x_9.preventDefault();
                                removeFromFavourites([l_1, a]);
                            }]);
                        }
                    }
                }));
            })))))))))))))),
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_1)),
        })), createElement("p", {
            className: join(" ", ["estimate-in-catalog"]),
            children: Interop_reactApi.Children.toArray([p("lot.estimate"), (value_66 = Currency_formatOption("-", bind((x_6) => CurrencyModule_noneWhenZero(x_6), lot.Estimate)), createElement("strong", {
                children: [value_66],
            }))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_17))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("card", props_19)));
    };
    const props_27 = ofArray([["className", "is-multiline"], ["id", "last-catalog-column"], ["className", "catalog-lots-container"], ["style", {
        flexWrap: "wrap",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((file) => {
        let props_21;
        return append(singleton_2((props_21 = ofArray([["className", join(" ", ["is-hidden-mobile", "is-hidden-tablet-only"])], ["className", "is-3"], ["id", "catalog-card"], ["style", {
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0,
            paddingBottom: 0,
            cursor: "pointer",
        }], ["children", Interop_reactApi.Children.toArray([renderCard(file, props.state, props.addToFavourites, props.removeFromFavourites)])], ["onClick", (x_13) => {
            x_13.preventDefault();
            props.goToLot(file.LotId);
        }]]), createElement("div", createObj(Helpers_combineClasses("column", props_21))))), delay(() => {
            let props_23;
            return append(singleton_2((props_23 = ofArray([["className", join(" ", ["is-hidden-mobile", "is-hidden-desktop"])], ["className", "is-4"], ["id", "catalog-card"], ["style", {
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 0,
                paddingBottom: 0,
                cursor: "pointer",
            }], ["children", Interop_reactApi.Children.toArray([renderCard(file, props.state, props.addToFavourites, props.removeFromFavourites)])], ["onClick", (x_14) => {
                x_14.preventDefault();
                props.goToLot(file.LotId);
            }]]), createElement("div", createObj(Helpers_combineClasses("column", props_23))))), delay(() => {
                let props_25;
                return singleton_2((props_25 = ofArray([["className", join(" ", ["is-hidden-tablet"])], ["id", "catalog-card"], ["style", {
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 0,
                    paddingBottom: 0,
                    cursor: "pointer",
                }], ["children", Interop_reactApi.Children.toArray([renderCard(file, props.state, props.addToFavourites, props.removeFromFavourites)])], ["onClick", (x_15) => {
                    x_15.preventDefault();
                    props.goToLot(file.LotId);
                }]]), createElement("div", createObj(Helpers_combineClasses("column", props_25)))));
            }));
        }));
    }, props.lot)))))]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_27)));
});

export function View_renderPreviewImage(view_renderPreviewImageInputProps) {
    const auctionId = view_renderPreviewImageInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(homeApi().getCatalogImages(auctionId), (_arg1) => {
            patternInput[1](_arg1.FirstPriorityImage);
            return singleton.Return();
        })));
    });
    return View_renderPreviewImage_1(patternInput[0]);
}

export function View_renderContent(lots, state, dispatch) {
    let auctions;
    const matchValue = state.Auctions;
    let pattern_matching_result, a;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            a = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            auctions = empty();
            break;
        }
        case 1: {
            auctions = sortBy((x) => x.Date, a, {
                Compare: (x_1, y) => compare(x_1, y),
            });
            break;
        }
        case 2: {
            auctions = empty();
            break;
        }
    }
    const children = toList(delay(() => collect((auction) => {
        const list_3 = filter((x_3) => equals(x_3.AuctionId, auction.Id), lots);
        return (!isEmpty(list_3)) ? singleton_2(createElement("div", {
            style: {
                marginBottom: 50,
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["catalog-priority-title-box"]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement(View_renderPreviewImage, {
                    auctionId: auction.Id,
                })), delay(() => {
                    let props;
                    return append(singleton_2((props = toList(delay(() => append(singleton_2(["style", {
                        whiteSpace: "pre-line",
                    }]), delay(() => append(singleton_2(["className", join(" ", ["catalog-text-h1"])]), delay(() => {
                        const date_1 = Localized_formatDate("d MMMM", "d MMMM", toLocalTime(auction.Date));
                        return singleton_2(["children", `${auction.WeeklySpecialsTitle} - ${date_1}`]);
                    })))))), createElement("p", createObj(Helpers_combineClasses("", props))))), delay(() => {
                        let props_2;
                        const wsSubtitle = Localized_translate(auction.WeeklySpecialsSubTitle);
                        return (wsSubtitle !== "") ? singleton_2((props_2 = ofArray([["style", {
                            whiteSpace: "pre-line",
                        }], ["className", join(" ", ["catalog-text-h2"])], ["children", wsSubtitle]]), createElement("p", createObj(Helpers_combineClasses("", props_2))))) : empty_1();
                    }));
                })))))),
            }), createElement("div", {
                className: "catalog-box",
                children: Interop_reactApi.Children.toArray([View_RenderLots({
                    addToFavourites: (tupledArg) => {
                        dispatch(new Types_Msg(3, tupledArg[0], tupledArg[1], true, new AsyncOperationStatus$1(0)));
                    },
                    goToLot: (x_2) => {
                        dispatch(new Types_Msg(0, x_2));
                    },
                    lot: list_3,
                    removeFromFavourites: (tupledArg_1) => {
                        dispatch(new Types_Msg(3, tupledArg_1[0], tupledArg_1[1], false, new AsyncOperationStatus$1(0)));
                    },
                    state: state,
                })]),
            })]),
        })) : empty_1();
    }, auctions)));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function View_render(state, dispatch) {
    return createElement("div", {
        className: join(" ", ["background-container", "padding_inherit"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement(View_Navbar, state)), delay(() => {
            let props, props_6, props_2;
            const matchValue = state.LoadedLots;
            let pattern_matching_result, lots;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 1) {
                    pattern_matching_result = 2;
                }
                else {
                    pattern_matching_result = 1;
                    lots = matchValue.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2((props = singleton_1(["children", Interop_reactApi.Children.toArray([mediumInline(l("loading.lot"))])]), createElement("section", createObj(Helpers_combineClasses("section", props)))));
                }
                case 1: {
                    return isEmpty(lots) ? singleton_2((props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", {
                        style: {
                            color: "#cdcdcd",
                            textAlign: "center",
                            marginLeft: 17 + "%",
                            padding: ((((((40 + "px ") + 5) + "px ") + 100) + "px ") + 5) + "px",
                            width: 66 + "%",
                        },
                        children: Interop_reactApi.Children.toArray([(props_2 = singleton_1(["className", join(" ", ["fa", "fa-chair", "fa-5x"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_2)))), createElement("h1", {
                            style: {
                                marginTop: 22,
                                marginBottom: 11,
                                fontSize: 40 + "px",
                                fontWeight: 400,
                                fontFamily: "Roboto slab, Arial",
                            },
                            children: p("noLotsInSpecialOffers"),
                        })]),
                    })])]), createElement("div", createObj(Helpers_combineClasses("container", props_6))))) : append(singleton_2(createElement("div", {
                        className: join(" ", ["is-hidden-mobile"]),
                        children: Interop_reactApi.Children.toArray([View_renderContent(lots, state, dispatch)]),
                    })), delay(() => singleton_2(createElement("div", {
                        className: join(" ", ["is-hidden-tablet"]),
                        children: Interop_reactApi.Children.toArray([View_renderContent(lots, state, dispatch)]),
                    }))));
                }
                case 2: {
                    return singleton_2(null);
                }
            }
        })))))),
    });
}

export function View_RenderCatalog(view_RenderCatalogInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(view_RenderCatalogInputProps.user), (msg, state) => State_update(msg, state), []);
    return View_render(patternInput[0], patternInput[1]);
}

