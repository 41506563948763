import { Localized_formatDate, Localized_translate, tWithArgs, t } from "../Localization.js";
import { createElement } from "react";
import * as react from "react";
import { interpolate, printf, toText, join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { tryFind, truncate, tryLast, map as map_2, head, partition, sortByDescending, cons, empty as empty_1, ofArray, isEmpty, tail, item, tryHead, length, singleton } from "../fable_modules/fable-library.3.6.1/List.js";
import { Lot_HistoryBids_tryCurrentValue, Lot_Lot_get_Zero, CatalogStream_ScheduleChanged_toDates, MakeBid_Request, Lot_BidKind, Lot_HistoryBids_isReservedPriceMet, Lot_HistoryBids_tryCurrent } from "../Shared/Bidding.js";
import { iterate, map as map_1, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { compareArrays, partialApply, equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { toArray, bind, map, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { Url_getCurrentUrlPage, Currency_format, Currency_formatNOption, Currency_formatOption, Currency_formatN, Currency_currencySymbol } from "../Common.js";
import { AnimatedNumberType, View } from "../Components/AnimatedNumber.js";
import { fromParts, op_Multiply, equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Decimal.js";
import { UserIdModule_toString, DeliveryCostZoneIdModule_dbSchenker, CurrencyModule_noneWhenZero, CurrencyModule_fromInt, CurrencyModule_zero } from "../bidflow/src/infrastructure/Auction.js";
import { useReact_useState_FCFD9EF, useReact_useCallback_93353E, React_functionComponent_2F9D7239, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { toString, equals as equals_2 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { useFeliz_React__React_useLotBidding_Static_6DD843DB, Types_State__get_NextBid, Types_Msg } from "../Components/Bidding.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { biddingApi, homeApi, nonAuthSettingsApi, lotsApi, lotsAuthApi, biddingAuthApi } from "../Communication.js";
import { prev } from "../bidflow/src/infrastructure/Bidding.js";
import { RenderBiddingButtonInCatalog, BiddingButtonInCatalogProps, BiddingButtonState } from "../Components/ButtonsInCatalog.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { mediumInline, small } from "../Components/Loader.js";
import { useFeliz_React__React_useCatalogStream_Static_5365EDEF } from "../Components/SignalR.js";
import { tryFind as tryFind_1 } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Route, toPath } from "../Router.js";
import { TimerInLotDetails } from "../Components/Timers.js";
import { LotStatus, SellLotState, Lot as Lot_1 } from "../Shared/Shared.js";

export function p(key) {
    return t("bidding." + key);
}

export function ldt(key) {
    return t("lotDetails." + key);
}

export function d(key) {
    return t("home." + key);
}

export function delimiter(color) {
    return createElement("p", {
        style: {
            borderTop: (((1 + "px ") + "dashed") + " ") + color,
        },
    });
}

export const greyDelimiter = delimiter("#676565");

export function reservedPriceUnmet() {
    let value_1;
    return createElement("p", {
        className: join(" ", ["is-uppercase", "has-text-danger", "has-text-weight-bold", "lot-details-reserved-price"]),
        children: Interop_reactApi.Children.toArray([(value_1 = p("price.unmet"), createElement("span", {
            children: [value_1],
        }))]),
    });
}

function renderMaximumBid$0027(currentBid, limitedBid) {
    let x_6, x_7, elms, value_11, _arg1, matchValue, x, x_1, arg10, x_2, x_3, arg10_1, l, l_1, x_5, arg10_2;
    const backgroundColor = (currentBid != null) ? (((x_6 = currentBid, x_6.IsMyBid ? x_6.ReservedPriceMet : false)) ? ((x_7 = currentBid, "has-background-success")) : "has-background-danger") : "has-background-danger";
    if (limitedBid == null) {
        return createElement("div", {
            className: "py-3",
        });
    }
    else {
        const x_8 = limitedBid;
        return createElement("div", {
            className: join(" ", [backgroundColor, "lot-lead-dontlead", "has-text-white", "is-size-6", "is-uppercase", "is-flex", "is-justify-content-center", "is-align-items-center"]),
            style: {
                padding: 3,
                marginBottom: 3,
                fontWeight: 600,
            },
            children: Interop_reactApi.Children.toArray([(elms = singleton(createElement("i", {
                className: join(" ", ["fa", "fa-gavel", "lot-details-icon"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (value_11 = ((_arg1 = ((x_8.Limit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")), (matchValue = [currentBid, limitedBid], (matchValue[0] != null) ? ((matchValue[1] != null) ? (((x = matchValue[0], x.IsMyBid ? (!x.ReservedPriceMet) : false)) ? ((x_1 = matchValue[0], (arg10 = tWithArgs("bidding.your.bid.doesnt.meet.reserved.price", {
                bid: x_1.Bid,
            }), toText(printf("%s"))(arg10)))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((x_2 = matchValue[0], x_2.IsMyBid ? x_2.ReservedPriceMet : false)) ? ((x_3 = matchValue[0], (arg10_1 = p("your.bid.is.highest"), toText(printf("%s"))(arg10_1)))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : ""))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((x_2 = matchValue[0], x_2.IsMyBid ? x_2.ReservedPriceMet : false)) ? ((x_3 = matchValue[0], (arg10_1 = p("your.bid.is.highest"), toText(printf("%s"))(arg10_1)))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : ""))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((x_2 = matchValue[0], x_2.IsMyBid ? x_2.ReservedPriceMet : false)) ? ((x_3 = matchValue[0], (arg10_1 = p("your.bid.is.highest"), toText(printf("%s"))(arg10_1)))) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : "")) : ((matchValue[0] != null) ? ((matchValue[1] != null) ? (((l = matchValue[1], !matchValue[0].IsMyBid)) ? ((l_1 = matchValue[1], (x_5 = matchValue[0], (arg10_2 = tWithArgs("bidding.your.bid.is.not.highest", {
                bid: l_1.Limit,
            }), toText(printf("%s"))(arg10_2))))) : "") : "") : ""))))), createElement("span", {
                children: [value_11],
            }))]),
        });
    }
}

export function renderMaximumBid2(state) {
    return renderMaximumBid$0027(Lot_HistoryBids_tryCurrent(state.History), state.MyLastAcceptedBid);
}

export function renderMaximumBid(state) {
    return renderMaximumBid$0027(Lot_HistoryBids_tryCurrent(state.Lot.History), state.Lot.MyLastAcceptedBid);
}

export function renderPriceActive(state, props) {
    const isReservedPriceMet = Lot_HistoryBids_isReservedPriceMet(props.History);
    return createElement("div", {
        style: {
            backgroundColor: "#f1f1f1",
            textAlign: "center",
            marginBottom: 3,
            marginTop: 3,
            paddingTop: 10,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["card-lot-aktuelBud"])]), delay(() => {
            const matchValue = props.Lot.BidState;
            let pattern_matching_result;
            if (matchValue.tag === 1) {
                pattern_matching_result = 1;
            }
            else if (matchValue.tag === 2) {
                pattern_matching_result = 1;
            }
            else if (matchValue.tag === 3) {
                if (matchValue.fields[0].tag === 1) {
                    pattern_matching_result = 3;
                }
                else if (matchValue.fields[0].tag === 2) {
                    pattern_matching_result = 4;
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_1(["children", "created"]);
                }
                case 1: {
                    return singleton_1(["children", ldt("currentBid")]);
                }
                case 2: {
                    return singleton_1(["children", ldt("unsold.state")]);
                }
                case 3: {
                    return singleton_1(["children", ldt("finalPrice")]);
                }
                case 4: {
                    return singleton_1(["children", t("catalogue.withdrawn.state")]);
                }
            }
        }))))))), delay(() => append(singleton_1(createElement("div", {
            key: defaultArg(map((arg00$0040) => ((arg00$0040).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")), props.Lot.CurrentBid), "-"),
            className: join(" ", ["card-lot-currentBid"]),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let x_4, x_2;
                let patternInput;
                if (props.User != null) {
                    const matchValue_1 = [state.Lot.MyLastAcceptedBid, state.Lot.History];
                    if (matchValue_1[0] != null) {
                        const matchValue_2 = Lot_HistoryBids_tryCurrent(matchValue_1[1]);
                        let pattern_matching_result_1;
                        if (matchValue_2 != null) {
                            if (!matchValue_2.IsMyBid) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                        switch (pattern_matching_result_1) {
                            case 0: {
                                patternInput = [state.NeedBlink, false];
                                break;
                            }
                            case 1: {
                                let pattern_matching_result_2;
                                if (matchValue_2 != null) {
                                    if ((x_2 = matchValue_2, x_2.IsMyBid ? x_2.ReservedPriceMet : false)) {
                                        pattern_matching_result_2 = 0;
                                    }
                                    else {
                                        pattern_matching_result_2 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_2 = 1;
                                }
                                switch (pattern_matching_result_2) {
                                    case 0: {
                                        patternInput = [false, state.NeedBlink];
                                        break;
                                    }
                                    case 1: {
                                        let pattern_matching_result_3;
                                        if (matchValue_2 != null) {
                                            if ((x_4 = matchValue_2, x_4.IsMyBid ? (!x_4.ReservedPriceMet) : false)) {
                                                pattern_matching_result_3 = 0;
                                            }
                                            else {
                                                pattern_matching_result_3 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_3 = 1;
                                        }
                                        switch (pattern_matching_result_3) {
                                            case 0: {
                                                patternInput = [state.NeedBlink, false];
                                                break;
                                            }
                                            case 1: {
                                                patternInput = [false, false];
                                                break;
                                            }
                                        }
                                        break;
                                    }
                                }
                                break;
                            }
                        }
                    }
                    else {
                        patternInput = [false, false];
                    }
                }
                else {
                    patternInput = [false, false];
                }
                const bid = defaultArg(props.Lot.CurrentBid, 0);
                const oldBid = (length(state.Lot.History) > 1) ? defaultArg(map((value_27) => value_27, map((x_6) => x_6.Bid, tryHead(((equals(item(0, state.Lot.History).Kind, new Lot_BidKind(1)) ? equals(item(1, state.Lot.History).Kind, new Lot_BidKind(0)) : false) ? (item(0, state.Lot.History).Bid === item(1, state.Lot.History).Bid) : false) ? tail(tail(state.Lot.History)) : tail(state.Lot.History)))), bid) : bid;
                const symbol = createElement("span", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["card-lot-currentBid-SEK-fontSize", "card-lot-currentBid"])]), delay(() => append(singleton_1(["style", {
                    fontWeight: 300,
                    marginLeft: 10,
                }]), delay(() => ((bid === 0) ? singleton_1(["children", ""]) : singleton_1(["children", `${Currency_currencySymbol()}`]))))))))));
                return singleton_1(createElement(View, {
                    number: bid,
                    oldNumber: oldBid,
                    currencySymbol: symbol,
                    typeOfComponent: new AnimatedNumberType(1),
                    needBlinkGreen: patternInput[1],
                    needBlinkRed: patternInput[0],
                    needBlink: state.NeedBlink,
                    isActiveBiddingComponent: false,
                }));
            })))),
        })), delay(() => {
            let matchValue_3, estimate_1, estimate_2, value_43;
            return append((matchValue_3 = props.Lot.Estimate, (matchValue_3 != null) ? (equals_1(matchValue_3, CurrencyModule_zero) ? ((estimate_1 = matchValue_3, singleton_1(createElement("div", {
                className: "card-lot-estimate-zero",
            })))) : ((matchValue_3 != null) ? ((estimate_2 = matchValue_3, singleton_1(createElement("p", {
                className: join(" ", ["card-lot-estimate"]),
                children: Interop_reactApi.Children.toArray([(value_43 = ldt("estimate"), createElement("span", {
                    children: [value_43],
                })), createElement("strong", {
                    children: `${Currency_formatN(estimate_2)} ${Currency_currencySymbol()}`,
                })]),
            })))) : (() => {
                throw (new Error("Match failure"));
            })())) : singleton_1(createElement("div", {
                className: "card-lot-estimate-zero",
            }))), delay(() => (((!isEmpty(props.History)) ? (!isReservedPriceMet) : false) ? append(singleton_1(greyDelimiter), delay(() => singleton_1(reservedPriceUnmet()))) : empty())));
        })))))))),
    });
}

export function renderPriceFinished(props) {
    const isReservedPriceMet = Lot_HistoryBids_isReservedPriceMet(props.History);
    return createElement("div", {
        style: {
            backgroundColor: "#f1f1f1",
            textAlign: "center",
            marginBottom: 3,
            paddingTop: 10,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["card-lot-aktuelBud"])]), delay(() => {
            const matchValue = props.Lot.BidState;
            let pattern_matching_result;
            if (matchValue.tag === 1) {
                pattern_matching_result = 1;
            }
            else if (matchValue.tag === 2) {
                pattern_matching_result = 1;
            }
            else if (matchValue.tag === 3) {
                if (matchValue.fields[0].tag === 1) {
                    pattern_matching_result = 3;
                }
                else if (matchValue.fields[0].tag === 2) {
                    pattern_matching_result = 4;
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_1(["children", "created"]);
                }
                case 1: {
                    return singleton_1(["children", ldt("currentBid")]);
                }
                case 2: {
                    return singleton_1(["children", ldt("unsold.state")]);
                }
                case 3: {
                    return singleton_1(["children", ldt("finalPrice")]);
                }
                case 4: {
                    return append(singleton_1(["children", t("catalogue.withdrawn.state")]), delay(() => singleton_1(["style", {
                        color: "rgb(214, 99, 99)",
                    }])));
                }
            }
        }))))))), delay(() => append(singleton_1(createElement("div", {
            className: join(" ", ["card-lot-currentBid-finished"]),
            children: Currency_formatOption("-", map((value_21) => CurrencyModule_fromInt(value_21), props.Lot.CurrentBid)),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontWeight: "bold",
                },
                children: Currency_formatNOption("-", map((value_26) => CurrencyModule_fromInt(value_26), props.Lot.CurrentBid)),
            }), createElement("span", createObj(toList(delay(() => append(singleton_1(["className", "card-lot-currentBid-SEK-fontSize"]), delay(() => append(singleton_1(["style", {
                fontWeight: 300,
            }]), delay(() => ((props.Lot.CurrentBid != null) ? singleton_1(["children", ` ${Currency_currencySymbol()}`]) : singleton_1(["children", ""]))))))))))]),
        })), delay(() => {
            let matchValue_2, estimate, value_40, value_41;
            return append((matchValue_2 = bind((x_2) => CurrencyModule_noneWhenZero(x_2), props.Lot.Estimate), (matchValue_2 != null) ? ((estimate = matchValue_2, singleton_1(createElement("p", {
                className: join(" ", ["card-lot-estimate"]),
                children: Interop_reactApi.Children.toArray([(value_40 = ldt("estimate"), createElement("span", {
                    children: [value_40],
                })), (value_41 = Currency_format(estimate), createElement("strong", {
                    children: [value_41],
                }))]),
            })))) : singleton_1(createElement("div", {
                className: join(" ", ["card-lot-estimate-zero"]),
            }))), delay(() => (((!isEmpty(props.History)) ? (!isReservedPriceMet) : false) ? append(singleton_1(greyDelimiter), delay(() => singleton_1(reservedPriceUnmet()))) : empty())));
        })))))))),
    });
}

export function RenderShipping(renderShippingInputProps) {
    let props_22, props_15, elms, props_13, props_20, props_18, options;
    const deliveryOptionCosts = renderShippingInputProps.deliveryOptionCosts;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isVisible = patternInput[0];
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", ["card-lot-shipping"]),
            children: Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-mobile"], ["style", {
                marginTop: 3,
            }], ["className", "collapse-height-column"], ["className", "mb-0"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "lot-details-delivery-options"], ["style", {
                paddingTop: 0,
            }], ["children", Interop_reactApi.Children.toArray([(elms = singleton(createElement("i", {
                className: join(" ", ["fa", "fa-shipping-fast", "lot-details-icon"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (props_13 = ofArray([["className", join(" ", ["card-lot-estimate"])], ["className", "has-text-centered"], ["children", ldt("shipping.alternatives")]]), createElement("span", createObj(Helpers_combineClasses("", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15)))), (props_20 = ofArray([["className", "lot-details-delivery-options"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                style: {
                    fontSize: 12 + "px",
                    color: "#9E9E9E",
                    marginRight: 10,
                },
                className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => ((!isVisible) ? singleton_1("fa-chevron-down") : singleton_1("fa-chevron-up"))))))),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))])], ["onClick", (x) => {
                x.preventDefault();
                patternInput[1](!isVisible);
            }]]), createElement("div", createObj(Helpers_combineClasses("columns", props_22)))), createElement("div", {
                className: join(" ", ["collapse-ex"]),
                style: createObj(toList(delay(() => (isVisible ? append(singleton_1(["overflow", "visible"]), delay(() => append(singleton_1(["height", ~(~document.getElementById("collapse-desktop-mobile-shipping").clientHeight)]), delay(() => singleton_1(["overflow", "hidden"]))))) : empty())))),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    id: "collapse-desktop-mobile-shipping",
                    className: join(" ", ["growUp"]),
                    style: {
                        backgroundColor: "#F1F1F1",
                    },
                    children: Interop_reactApi.Children.toArray([(options = toList(delay(() => map_1((option) => [option.DeliveryService, Localized_translate(option.Name), Localized_translate(option.Description), option.CostOneItem], deliveryOptionCosts))), react.createElement(react.Fragment, {}, ...toList(delay(() => map_1((option_1) => {
                        const tupledArg = option_1;
                        const cost = tupledArg[3];
                        return createElement("div", {
                            style: {
                                padding: ((((((5 + "px ") + 15) + "px ") + 15) + "px ") + 15) + "px",
                                margin: 20,
                                textAlign: "left",
                                backgroundColor: "#ececec",
                                borderRadius: 5,
                                textTransform: "none",
                            },
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("span", {
                                style: {
                                    fontSize: 17 + "px",
                                    fontWeight: 400,
                                    margin: 0,
                                },
                                children: tupledArg[1],
                            })), delay(() => {
                                let children;
                                return append((tupledArg[0] === DeliveryCostZoneIdModule_dbSchenker) ? append(singleton_1((children = ofArray([createElement("span", {
                                    style: {
                                        fontSize: 13 + "px",
                                        fontWeight: 400,
                                        margin: 0,
                                    },
                                    children: "Sweden:",
                                }), createElement("span", {
                                    style: {
                                        backgroundColor: "#5baf62",
                                        fontWeight: 600,
                                        fontSize: 12 + "px",
                                        color: "#FFFFFF",
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                        borderRadius: 4,
                                        marginLeft: 10,
                                    },
                                    children: Currency_format(cost),
                                })]), createElement("p", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                                }))), delay(() => {
                                    let children_2;
                                    return singleton_1((children_2 = ofArray([createElement("span", {
                                        style: {
                                            fontSize: 13 + "px",
                                            fontWeight: 400,
                                            margin: 0,
                                        },
                                        children: "Europe:",
                                    }), createElement("span", {
                                        style: {
                                            backgroundColor: "#5baf62",
                                            fontWeight: 600,
                                            fontSize: 12 + "px",
                                            color: "#FFFFFF",
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                            borderRadius: 4,
                                            marginLeft: 10,
                                        },
                                        children: Currency_format(op_Multiply(cost, fromParts(2, 0, 0, false, 0))),
                                    })]), createElement("p", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                                    })));
                                })) : singleton_1(createElement("span", {
                                    style: {
                                        backgroundColor: "#5baf62",
                                        fontWeight: 600,
                                        fontSize: 12 + "px",
                                        color: "#FFFFFF",
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                        borderRadius: 4,
                                        marginLeft: 10,
                                    },
                                    children: Currency_format(cost),
                                })), delay(() => singleton_1(createElement("p", {
                                    style: {
                                        marginTop: 10,
                                        fontSize: 13 + "px",
                                        textTransform: "none",
                                        whiteSpace: "pre-line",
                                    },
                                    children: tupledArg[2],
                                }))));
                            })))))),
                        });
                    }, options)))))]),
                })]),
            })]),
        })]),
    });
}

export const renderBiddingHistory = React_functionComponent_2F9D7239((input) => {
    let counts, props_16, s_2;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showMoreBids = patternInput[0];
    const bid_2 = (color, x_2) => {
        let x_1, props_6, props_1, s, props_4;
        const date_1 = Localized_formatDate("d MMM HH:mm", "d MMM HH.mm", toLocalTime(x_2.Date));
        let bidInfo;
        const matchValue = x_2.Kind;
        let pattern_matching_result;
        if (matchValue.tag === 0) {
            if (x_2.IsMyBid) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                bidInfo = singleton(p("bidding.you"));
                break;
            }
            case 1: {
                if (matchValue.tag === 1) {
                    if (x_2.IsMyBid) {
                        bidInfo = singleton(p("bidding.agent.you"));
                    }
                    else if (matchValue.tag === 1) {
                        bidInfo = singleton(p("bidding.agent.manual"));
                    }
                    else {
                        throw (new Error("Match failure"));
                    }
                }
                else {
                    bidInfo = empty_1();
                }
                break;
            }
        }
        return createElement("div", {
            className: join(" ", toList(delay(() => append(singleton_1("bid-history-rows"), delay(() => {
                let bid;
                return defaultArg(map((x) => equals(x, x_2), Lot_HistoryBids_tryCurrent(input.History)), false) ? ((bid = x_2, (bid.IsMyBid ? bid.ReservedPriceMet : false) ? singleton("blink-green") : ((!bid.IsMyBid) ? singleton("blink-red") : empty_1()))) : empty();
            }))))),
            key: (x_1 = x_2, toText(interpolate("%A%P()-%A%P()-%A%P()-%A%P()", [x_1.UserId, x_1.Kind, x_1.Bid, x_1.Bid]))),
            children: Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["text-in-circle", color]),
                children: UserIdModule_toString(x_2.UserId),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (s = join(" - ", cons(date_1, bidInfo)), createElement("div", {
                className: "column",
                children: s,
            })), (props_4 = ofArray([["className", "is-narrow"], ["className", join(" ", ["has-text-right"])], ["children", (x_2.Bid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))]),
        });
    };
    const looseBid = partialApply(1, bid_2, ["has-background-danger"]);
    const winBid = partialApply(1, bid_2, ["has-background-success"]);
    const history_2 = input.History;
    let x_10;
    const matchValue_1 = sortByDescending((x_5) => [x_5.Date, x_5.Bid], history_2, {
        Compare: (x_6, y) => compareArrays(x_6, y),
    });
    if (isEmpty(matchValue_1)) {
        x_10 = null;
    }
    else {
        const patternInput_1 = partition((x_7) => (!x_7.ReservedPriceMet), matchValue_1);
        const bidsBelowMinPrice = patternInput_1[0];
        const bidsAboveMinPrice = patternInput_1[1];
        const historyBids = toList(delay(() => append((!isEmpty(bidsAboveMinPrice)) ? (isEmpty(tail(bidsAboveMinPrice)) ? singleton_1(winBid(head(bidsAboveMinPrice))) : append(singleton_1(winBid(head(bidsAboveMinPrice))), delay(() => map_2(looseBid, tail(bidsAboveMinPrice))))) : ((empty())), delay(() => {
            let matchValue_2, reservedPriceMetAt, reservedPriceMetAt_1, text, props_12, props_10;
            return append((matchValue_2 = tryLast(bidsAboveMinPrice), (matchValue_2 != null) ? (((reservedPriceMetAt = matchValue_2, input.HasReservedPrice ? (length(bidsBelowMinPrice) >= 0) : false)) ? ((reservedPriceMetAt_1 = matchValue_2, singleton_1((text = p("price.met"), createElement("div", {
                className: join(" ", ["bid-history-rows"]),
                children: Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-vcentered"], ["className", "is-gapless"], ["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                    className: "column",
                    children: text,
                }), (props_10 = ofArray([["className", "is-narrow"], ["className", join(" ", ["has-text-right"])], ["children", (reservedPriceMetAt_1.Bid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_12))))]),
            }))))) : ((empty()))) : ((empty()))), delay(() => map_2(looseBid, bidsBelowMinPrice)));
        }))));
        const children = toList(delay(() => append(showMoreBids ? historyBids : truncate(3, historyBids), delay(() => {
            let props_22;
            return (length(historyBids) > 3) ? singleton_1(createElement("div", {
                className: join(" ", ["mt-3"]),
                children: Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-fullwidth"], ["className", "is-white"], ["className", join(" ", ["bid-history-show-more-container"])], ["onClick", (_arg1) => {
                    patternInput[1](!showMoreBids);
                }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    style: {
                        marginRight: 20,
                    },
                    className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => ((!showMoreBids) ? singleton_1("fa-chevron-down") : singleton_1("fa-chevron-up"))))))),
                }), createElement("span", {
                    className: join(" ", ["has-text-weight-normal", "has-text-letter-spacing-1"]),
                    children: (!showMoreBids) ? p("all.bids") : p("recent.bids"),
                }), createElement("i", {
                    style: {
                        marginLeft: 20,
                    },
                    className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => ((!showMoreBids) ? singleton_1("fa-chevron-down") : singleton_1("fa-chevron-up"))))))),
                })])]]), createElement("span", createObj(Helpers_combineClasses("button", props_22))))]),
            })) : empty();
        }))));
        x_10 = createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    const children_2 = ofArray([(counts = (length(history_2) | 0), createElement("div", {
        className: join(" ", ["bid-history-row-header"]),
        children: Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-vcentered"], ["className", "is-gapless"], ["children", Interop_reactApi.Children.toArray([(s_2 = tWithArgs("bidding.bid.history", {
            count: counts,
        }), createElement("div", {
            className: "column",
            children: s_2,
        }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_16))))]),
    })), x_10]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
});

export function printNotAllowedToBidDueToUserIsOwner(state) {
    if (state.Lot.CurrentUserIsOwner) {
        const props = ofArray([["className", join(" ", ["has-text-danger", "is-size-6", "pb-1"])], ["children", p("biddingNotAllowedOnOwnLot")]]);
        return createElement("div", createObj(Helpers_combineClasses("field", props)));
    }
    else {
        return null;
    }
}

export function printMyLastAcceptedBid(state, userId) {
    let text;
    const matchValue = [state.Lot.MyLastAcceptedBid, state.Lot.History];
    if (matchValue[0] == null) {
        if (isEmpty(matchValue[1])) {
            text = "";
        }
        else {
            const matchValue_1 = tryFind((x_1) => equals_2(x_1.UserId, userId), matchValue[1]);
            if (matchValue_1 == null) {
                text = "";
            }
            else {
                const x_2 = matchValue_1;
                text = tWithArgs("bidding.last.bid", {
                    sum: (x_2.Bid).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    time: Localized_formatDate("d MMM yyyy KK:mm:ss", "d MMM yyyy KK.mm.ss", toLocalTime(x_2.Date)),
                });
            }
        }
    }
    else {
        const x = matchValue[0];
        text = tWithArgs("bidding.last.bid", {
            sum: (x.Limit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            time: Localized_formatDate("d MMM yyyy KK:mm:ss", "d MMM yyyy KK.mm.ss", toLocalTime(x.Date)),
        });
    }
    if (text === "") {
        return null;
    }
    else {
        const props = ofArray([["className", join(" ", ["has-text-grey", "is-size-6", "pb-1"])], ["children", text]]);
        return createElement("div", createObj(Helpers_combineClasses("field", props)));
    }
}

export function RenderBidForm(renderBidFormInputProps) {
    let elms;
    const dispatch = renderBidFormInputProps.dispatch;
    const state = renderBidFormInputProps.state;
    const userId = renderBidFormInputProps.userId;
    const lotId = renderBidFormInputProps.lotId;
    const onClickCallback = useReact_useCallback_93353E((arg) => {
        let tupledArg;
        startImmediate((tupledArg = arg, singleton_2.Delay(() => {
            dispatch(new Types_Msg(1));
            return singleton_2.Bind(Remoting_handleNonAuth(biddingAuthApi().makeBid(new MakeBid_Request(tupledArg[0], tupledArg[1]))), (_arg1) => {
                const response = _arg1;
                return singleton_2.Combine((response.tag === 1) ? ((dispatch(new Types_Msg(3, response.fields[0])), singleton_2.Zero())) : ((dispatch(new Types_Msg(2, response.fields[0])), singleton_2.Zero())), singleton_2.Delay(() => {
                    dispatch(new Types_Msg(6, false));
                    return singleton_2.Zero();
                }));
            });
        })));
    });
    const onClick = () => {
        let bid_1;
        const matchValue = [state.Bid, state.Settings];
        let pattern_matching_result, bid_2;
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                if ((bid_1 = (matchValue[0] | 0), state.Confirm)) {
                    pattern_matching_result = 0;
                    bid_2 = matchValue[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                onClickCallback([lotId, bid_2]);
                break;
            }
            case 1: {
                let pattern_matching_result_1, bid_3, settings;
                if (matchValue[0] != null) {
                    if (matchValue[1] != null) {
                        pattern_matching_result_1 = 0;
                        bid_3 = matchValue[0];
                        settings = matchValue[1];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        dispatch(new Types_Msg(0, prev(settings.Intervals)(bid_3)));
                        dispatch(new Types_Msg(6, true));
                        break;
                    }
                    case 1: {
                        break;
                    }
                }
                break;
            }
        }
    };
    const onCancel = () => {
        if (state.Confirm) {
            dispatch(new Types_Msg(6, false));
        }
    };
    const onChange = state.Confirm ? ((value) => {
    }) : ((arg_2) => {
        dispatch(new Types_Msg(0, arg_2));
    });
    let bidButtonProps;
    const matchValue_1 = state.Lot.Status;
    switch (matchValue_1.tag) {
        case 3: {
            bidButtonProps = (new BiddingButtonInCatalogProps(() => {
            }, (value_3) => {
            }, state.Bid, () => {
            }, p("button.blocked"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            break;
        }
        case 1: {
            if (state.Lot.CurrentUserIsOwner) {
                bidButtonProps = (new BiddingButtonInCatalogProps(() => {
                }, (value_6) => {
                }, state.Bid, () => {
                }, p("button.blocked"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            }
            else {
                const State_2 = state.Confirm ? (new BiddingButtonState(2)) : (new BiddingButtonState(1));
                bidButtonProps = (new BiddingButtonInCatalogProps(onCancel, onChange, state.Bid, onClick, state.Confirm ? p("confirm") : p("button.bid"), State_2, state.Confirm, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            }
            break;
        }
        case 2: {
            if (state.Lot.CurrentUserIsWinner ? true : state.Lot.CurrentUserIsOwner) {
                bidButtonProps = (new BiddingButtonInCatalogProps(() => {
                }, (value_9) => {
                }, state.Bid, () => {
                }, p("button.blocked"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            }
            else {
                const State_4 = state.Confirm ? (new BiddingButtonState(2)) : (new BiddingButtonState(1));
                bidButtonProps = (new BiddingButtonInCatalogProps(onCancel, onChange, state.Bid, onClick, state.Confirm ? p("confirm") : p("button.bid"), State_4, state.Confirm, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            }
            break;
        }
        default: {
            bidButtonProps = (new BiddingButtonInCatalogProps(() => {
            }, (value_3) => {
            }, state.Bid, () => {
            }, p("button.blocked"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
        }
    }
    return createElement("fieldset", {
        className: "mb-0",
        className: "is-uppercase",
        children: Interop_reactApi.Children.toArray([(elms = toList(delay(() => append(singleton_1(createElement("p", {
            className: join(" ", ["card-lot-subtitle"]),
            children: p("maximum.bid"),
        })), delay(() => append(singleton_1(createElement(RenderBiddingButtonInCatalog, (($value) => { $value.key = $value.Key; return $value; })(bidButtonProps))), delay(() => append((state.Error === "") ? singleton_1(null) : singleton_1(createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "my-1"], ["className", "py-1"], ["className", "px-1"], ["className", "is-size-6"], ["className", "has-text-centered"], ["className", "has-text-white"], ["className", "has-background-danger"], ["children", state.Error]]))))), delay(() => singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["style", {
            marginTop: 10,
        }]), delay(() => append(singleton_1(["className", "fontSize-desktop14-mobile12"]), delay(() => {
            let matchValue_3;
            return singleton_1(["children", tWithArgs("bidding.minimum.bid", {
                sum: (matchValue_3 = Types_State__get_NextBid(state), (matchValue_3 == null) ? "-" : Currency_format(CurrencyModule_fromInt(matchValue_3))),
            })]);
        }))))))))))))))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), printNotAllowedToBidDueToUserIsOwner(state), printMyLastAcceptedBid(state, userId)]),
    });
}

export function loadInfo(userId, lotId, newBid, height, setHeight, isVisible) {
    return singleton_2.Delay(() => singleton_2.Bind((userId != null) ? lotsAuthApi().getProvisions([lotId, newBid]) : lotsApi().getProvisions([lotId, newBid]), (_arg1) => singleton_2.Bind(nonAuthSettingsApi().getCountryVAT(), (_arg2) => singleton_2.Return({
        Provision: _arg1,
        VAT: _arg2,
    }))));
}

export function CommissionsBoxDetails(commissionsBoxDetailsInputProps) {
    const isVisible = commissionsBoxDetailsInputProps.isVisible;
    const setHeight = commissionsBoxDetailsInputProps.setHeight;
    const newBid = commissionsBoxDetailsInputProps.newBid;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(loadInfo(commissionsBoxDetailsInputProps.userId, commissionsBoxDetailsInputProps.lotId, newBid, commissionsBoxDetailsInputProps.height, setHeight, isVisible), [newBid]);
    switch (data.tag) {
        case 1: {
            const props = ofArray([["className", "is-size-6"], ["children", t("home.loading")]]);
            return createElement("span", createObj(Helpers_combineClasses("", props)));
        }
        case 3: {
            return null;
        }
        case 2: {
            const info = data.fields[0];
            setHeight(isVisible ? document.getElementById("collapse-mobile-provision").clientHeight : 0);
            return createElement("div", {
                className: join(" ", ["bid-history-rows"]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_6, props_2, props_4;
                    return append(singleton_1((props_6 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "px-4"], ["className", join(" ", ["bid-history-rows"])], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["style", {
                        textAlign: "left",
                    }], ["children", t("shoppingCart.receipt.bid")]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", join(" ", ["is-3", "has-text-right"])], ["children", Currency_formatN(info.Provision.Price)]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))), delay(() => {
                        let props_12, props_8, props_10;
                        return append((!equals_1(info.Provision.CorporateTax, CurrencyModule_zero)) ? singleton_1((props_12 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "px-4"], ["className", join(" ", ["bid-history-rows"])], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["style", {
                            textAlign: "left",
                        }], ["children", t("shoppingCart.receipt.corporateTax")]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", join(" ", ["is-3", "has-text-right"])], ["children", Currency_formatN(info.Provision.CorporateTax)]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_12))))) : empty(), delay(() => {
                            let props_18, props_14, props_16;
                            return append(singleton_1((props_18 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "px-4"], ["className", join(" ", ["bid-history-rows"])], ["children", Interop_reactApi.Children.toArray([(props_14 = toList(delay(() => append(singleton_1(["style", {
                                textAlign: "left",
                            }]), delay(() => {
                                const percent = ` ${info.Provision.BuyerCommissionPercent}`;
                                return singleton_1(["children", (t("shoppingCart.receipt.commission") + percent) + "%"]);
                            })))), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", join(" ", ["is-3", "has-text-right"])], ["children", Currency_formatN(info.Provision.BuyerCommission)]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))), delay(() => {
                                let props_24, props_20, props_22;
                                return append((!equals_1(info.Provision.HammerFee, CurrencyModule_zero)) ? singleton_1((props_24 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "px-4"], ["className", join(" ", ["bid-history-rows"])], ["children", Interop_reactApi.Children.toArray([(props_20 = ofArray([["style", {
                                    textAlign: "left",
                                }], ["children", t("shoppingCart.receipt.hammer")]]), createElement("div", createObj(Helpers_combineClasses("column", props_20)))), (props_22 = ofArray([["className", join(" ", ["is-3", "has-text-right"])], ["children", Currency_formatN(info.Provision.HammerFee)]]), createElement("div", createObj(Helpers_combineClasses("column", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_24))))) : empty(), delay(() => {
                                    let props_30, props_26, props_28;
                                    return append((!equals_1(info.Provision.Royalty, CurrencyModule_zero)) ? singleton_1((props_30 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "px-4"], ["className", join(" ", ["bid-history-rows"])], ["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["style", {
                                        textAlign: "left",
                                    }], ["children", t("shoppingCart.receipt.royalties")]]), createElement("div", createObj(Helpers_combineClasses("column", props_26)))), (props_28 = ofArray([["className", join(" ", ["is-3", "has-text-right"])], ["children", Currency_formatN(info.Provision.Royalty)]]), createElement("div", createObj(Helpers_combineClasses("column", props_28))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_30))))) : empty(), delay(() => {
                                        let props_36, props_32, props_34;
                                        return append(singleton_1((props_36 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "px-4"], ["className", join(" ", ["bid-history-rows"])], ["children", Interop_reactApi.Children.toArray([(props_32 = ofArray([["style", {
                                            textAlign: "left",
                                        }], ["children", t("shoppingCart.receipt.tax") + (` ${info.VAT}%`)]]), createElement("div", createObj(Helpers_combineClasses("column", props_32)))), (props_34 = ofArray([["className", join(" ", ["is-2", "has-text-right"])], ["children", Currency_formatN(info.Provision.Vat)]]), createElement("div", createObj(Helpers_combineClasses("column", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_36))))), delay(() => {
                                            let props_42, props_38, props_40;
                                            return append((!equals_1(info.Provision.DeductVat, CurrencyModule_zero)) ? singleton_1((props_42 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "px-4"], ["className", join(" ", ["bid-history-rows"])], ["children", Interop_reactApi.Children.toArray([(props_38 = ofArray([["style", {
                                                textAlign: "left",
                                            }], ["children", t("shoppingCart.receipt.taxFree")]]), createElement("div", createObj(Helpers_combineClasses("column", props_38)))), (props_40 = ofArray([["className", join(" ", ["is-3", "has-text-right"])], ["children", Currency_formatN(info.Provision.DeductVat)]]), createElement("div", createObj(Helpers_combineClasses("column", props_40))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_42))))) : empty(), delay(() => {
                                                let props_48, props_44, props_46;
                                                return singleton_1((props_48 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "has-background-grey-lighter"], ["className", "px-4"], ["className", join(" ", ["bid-history-rows"])], ["children", Interop_reactApi.Children.toArray([(props_44 = ofArray([["style", {
                                                    textAlign: "left",
                                                }], ["children", t("lotDetails.finalPrice")]]), createElement("div", createObj(Helpers_combineClasses("column", props_44)))), (props_46 = ofArray([["className", join(" ", ["is-3", "has-text-right"])], ["children", Currency_formatN(info.Provision.Total)]]), createElement("div", createObj(Helpers_combineClasses("column", props_46))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_48)))));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                })))),
            });
        }
        default: {
            const props = ofArray([["className", "is-size-6"], ["children", t("home.loading")]]);
            return createElement("span", createObj(Helpers_combineClasses("", props)));
        }
    }
}

export function RenderDisplayProvision(renderDisplayProvisionInputProps) {
    let props, props_15, props_8, elms, props_6, props_13, props_11;
    const userId = renderDisplayProvisionInputProps.userId;
    const newBid = renderDisplayProvisionInputProps.newBid;
    const lotId = renderDisplayProvisionInputProps.lotId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isVisible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const height = patternInput_1[0];
    const showComponent = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getIsShowCommissionBox(), []);
    switch (showComponent.tag) {
        case 1: {
            return createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["card-lot-shipping"]),
                    children: Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-mobile"], ["style", {
                        marginTop: 3,
                    }], ["className", "collapse-height-column"], ["className", "mb-0"], ["children", Interop_reactApi.Children.toArray([small("")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props))))]),
                })]),
            });
        }
        case 3: {
            return null;
        }
        case 2: {
            if (showComponent.fields[0]) {
                return createElement("div", {
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", ["card-lot-shipping"]),
                        children: Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-mobile"], ["style", {
                            marginTop: 3,
                        }], ["className", "collapse-height-column"], ["className", "mb-0"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "lot-details-delivery-options"], ["style", {
                            paddingTop: 0,
                        }], ["children", Interop_reactApi.Children.toArray([(elms = singleton(createElement("i", {
                            className: join(" ", ["far", "fa-credit-card", "lot-details-icon"]),
                        })), createElement("span", {
                            className: "icon",
                            children: Interop_reactApi.Children.toArray(Array.from(elms)),
                        })), (props_6 = ofArray([["className", join(" ", ["card-lot-estimate"])], ["className", "has-text-centered"], ["children", ldt("provision.titleButton")]]), createElement("span", createObj(Helpers_combineClasses("", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_13 = ofArray([["className", "lot-details-delivery-options"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            style: {
                                fontSize: 12 + "px",
                                color: "#9E9E9E",
                                marginRight: 10,
                            },
                            className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => (isVisible ? singleton_1("fa-chevron-up") : singleton_1("fa-chevron-down"))))))),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13))))])], ["onClick", (x) => {
                            x.preventDefault();
                            patternInput[1](!isVisible);
                        }]]), createElement("div", createObj(Helpers_combineClasses("columns", props_15)))), createElement("div", {
                            className: join(" ", ["collapse-ex"]),
                            style: createObj(toList(delay(() => (isVisible ? append(singleton_1(["overflow", "visible"]), delay(() => append(singleton_1(["height", ~(~height)]), delay(() => singleton_1(["overflow", "hidden"]))))) : empty())))),
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                id: "collapse-mobile-provision",
                                className: join(" ", ["growUp"]),
                                style: {
                                    backgroundColor: "#F1F1F1",
                                },
                                children: Interop_reactApi.Children.toArray([createElement(CommissionsBoxDetails, {
                                    userId: userId,
                                    lotId: lotId,
                                    newBid: newBid,
                                    height: height,
                                    setHeight: patternInput_1[1],
                                    isVisible: isVisible,
                                })]),
                            })]),
                        })]),
                    })]),
                });
            }
            else {
                return null;
            }
        }
        default: {
            return createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["card-lot-shipping"]),
                    children: Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-mobile"], ["style", {
                        marginTop: 3,
                    }], ["className", "collapse-height-column"], ["className", "mb-0"], ["children", Interop_reactApi.Children.toArray([small("")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props))))]),
                })]),
            });
        }
    }
}

export function Display(displayInputProps) {
    let elms_1;
    const date = displayInputProps.date;
    const hasReservedPrice = displayInputProps.hasReservedPrice;
    const userId = displayInputProps.userId;
    const lot = displayInputProps.lot;
    const patternInput = useFeliz_React__React_useLotBidding_Static_6DD843DB(lot.Id, userId);
    const state = patternInput[0];
    const patternInput_1 = useReact_useState_FCFD9EF(() => date);
    useFeliz_React__React_useCatalogStream_Static_5365EDEF((_arg1) => {
        let pattern_matching_result, schedule;
        if (_arg1.tag === 3) {
            if (_arg1.fields[0].tag === 4) {
                pattern_matching_result = 0;
                schedule = _arg1.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                iterate(patternInput_1[1], toArray(tryFind_1(lot.Id, CatalogStream_ScheduleChanged_toDates(schedule))));
                break;
            }
            case 1: {
                break;
            }
        }
    }, singleton(lot.Id), userId);
    let renderBidFormLock;
    let bidButtonProps;
    const matchValue = state.Lot.Status;
    switch (matchValue.tag) {
        case 3: {
            bidButtonProps = (new BiddingButtonInCatalogProps(() => {
            }, (value_1) => {
            }, state.Bid, () => {
            }, p("button.unlock"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            break;
        }
        case 1:
        case 2: {
            bidButtonProps = (new BiddingButtonInCatalogProps(() => {
            }, (value_3) => {
            }, state.Bid, () => {
                window.location.href = toPath(new Route(6, Url_getCurrentUrlPage()));
            }, t("changePassword.button.login"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
            break;
        }
        default: {
            bidButtonProps = (new BiddingButtonInCatalogProps(() => {
            }, (value_1) => {
            }, state.Bid, () => {
            }, p("button.unlock"), new BiddingButtonState(0), false, toString(state.Lot.Version), state.IsRequestToBidProcessing));
        }
    }
    renderBidFormLock = createElement("div", {
        style: {
            textTransform: "uppercase",
        },
        children: Interop_reactApi.Children.toArray([(elms_1 = toList(delay(() => append(singleton_1(createElement("p", {
            style: {
                fontSize: 14 + "px",
                padding: ((8 + "px ") + 0) + "px",
            },
            children: p("maximum.bid"),
        })), delay(() => {
            let elms;
            return append(singleton_1((elms = singleton(createElement(RenderBiddingButtonInCatalog, (($value) => { $value.key = $value.Key; return $value; })(bidButtonProps))), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => append((state.Error === "") ? singleton_1(null) : singleton_1(createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "my-1"], ["className", "py-1"], ["className", "px-1"], ["className", "is-size-6"], ["className", "has-text-centered"], ["className", "has-text-white"], ["className", "has-background-danger"], ["children", state.Error]]))))), delay(() => singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["style", {
                fontSize: 14 + "px",
            }]), delay(() => {
                let matchValue_2;
                return singleton_1(["children", tWithArgs("bidding.minimum.bid", {
                    sum: (matchValue_2 = Types_State__get_NextBid(state), (matchValue_2 == null) ? "-" : Currency_format(CurrencyModule_fromInt(matchValue_2))),
                })]);
            })))))))))));
        })))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]),
    });
    const connectionStatus = patternInput[2] ? null : createElement("div", createObj(Helpers_combineClasses("content", ofArray([["className", "is-danger"], ["className", "has-text-centered"], ["className", "has-text-danger"], ["children", "No connection. Restoring ..."]]))));
    return createElement("div", {
        className: "bid-history-container-padding",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_2;
            return equals(state.Lot, Lot_Lot_get_Zero()) ? singleton_1((elms_2 = singleton(mediumInline(d("loading"))), createElement("section", {
                className: "section",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))) : append(singleton_1(renderMaximumBid(state)), delay(() => append(singleton_1(createElement(TimerInLotDetails, {
                time: patternInput_1[0],
            })), delay(() => append(singleton_1(renderPriceActive(state, {
                History: state.Lot.History,
                Lot: new Lot_1(lot.Id, lot.IdInAuction, lot.DisplayId, lot.ItemId, lot.Auction, lot.Name, lot.BidState, Lot_HistoryBids_tryCurrentValue(state.Lot.History), lot.Estimate, lot.Category, lot.Images, lot.VideoLink, lot.GpsPosition, lot.Size, lot.Condition, lot.Description, lot.DeliveryCosts, lot.Royalties, lot.Priority),
                User: userId,
            })), delay(() => append(singleton_1(createElement(RenderShipping, {
                deliveryOptionCosts: lot.DeliveryCosts,
            })), delay(() => {
                let matchValue_3, bid;
                return append((matchValue_3 = state.Bid, (matchValue_3 != null) ? ((bid = (matchValue_3 | 0), singleton_1(createElement(RenderDisplayProvision, {
                    lotId: lot.Id,
                    newBid: bid,
                    userId: userId,
                })))) : singleton_1(null)), delay(() => append(singleton_1(createElement("div", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["margin-top-desktop30-mobile10"])]), delay(() => {
                    let matchValue_4;
                    return append((matchValue_4 = [state.Bid, userId], (matchValue_4[0] != null) ? ((matchValue_4[1] != null) ? ((empty())) : singleton_1(["className", join(" ", ["margin-bottom-desktop30-mobile10"])])) : singleton_1(["className", join(" ", ["margin-bottom-desktop30-mobile10"])])), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        if (userId == null) {
                            return singleton_1(renderBidFormLock);
                        }
                        else {
                            const userId_1 = userId;
                            return singleton_1(createElement(RenderBidForm, {
                                lotId: lot.Id,
                                userId: userId_1,
                                state: state,
                                dispatch: patternInput[1],
                            }));
                        }
                    }))))])));
                }))))))), delay(() => append(singleton_1(connectionStatus), delay(() => singleton_1(renderBiddingHistory({
                    HasReservedPrice: hasReservedPrice,
                    History: state.Lot.History,
                }))))))));
            }))))))));
        })))),
    });
}

export function Render(renderInputProps) {
    const date = renderInputProps.date;
    const userId = renderInputProps.userId;
    const lot = renderInputProps.lot;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(biddingApi().getLot([lot.Id, userId]), []);
    switch (data.tag) {
        case 1: {
            return mediumInline(p("loading.bidding"));
        }
        case 3: {
            const value = data.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const x = data.fields[0];
            const matchValue = x.Status;
            switch (matchValue.tag) {
                case 1:
                case 2: {
                    return createElement(Display, {
                        lot: lot,
                        userId: userId,
                        hasReservedPrice: x.HasReservedPrice,
                        date: date,
                    });
                }
                case 3: {
                    return createElement("div", {
                        className: "bid-history-container-padding",
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((!equals(lot.BidState, new LotStatus(3, new SellLotState(2)))) ? singleton_1(renderMaximumBid2(x)) : empty(), delay(() => append(singleton_1(renderPriceFinished({
                            History: x.History,
                            Lot: lot,
                        })), delay(() => append(singleton_1(createElement(RenderShipping, {
                            deliveryOptionCosts: lot.DeliveryCosts,
                        })), delay(() => singleton_1(renderBiddingHistory({
                            HasReservedPrice: x.HasReservedPrice,
                            History: x.History,
                        })))))))))))),
                    });
                }
                default: {
                    return null;
                }
            }
        }
        default: {
            return null;
        }
    }
}

