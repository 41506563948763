import { Cmd_ofSub } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { class_type, union_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { split, isNullOrEmpty } from "./fable_modules/fable-library.3.6.1/String.js";
import { fold } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { isDigit } from "./fable_modules/fable-library.3.6.1/Char.js";
import { removeUser } from "./LocalStorage.js";
import { ProxyRequestException__get_StatusCode, ProxyRequestException } from "./fable_modules/Fable.Remoting.Client.7.10.0/Types.fs.js";
import { LotIdModule_toString, LotIdModule_fromString, AuctionIdModule_fromString } from "./bidflow/src/infrastructure/Auction.js";
import numeral from "numeral";

export function Cmd_fromAsync(operation) {
    return Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(operation, (_arg1) => {
            dispatch(_arg1);
            return singleton.Zero();
        })));
    });
}

export function Async_map(f, computation) {
    return singleton.Delay(() => singleton.Bind(computation, (_arg1) => singleton.Return(f(_arg1))));
}

export function Async_tee(f, computation) {
    return singleton.Delay(() => singleton.Bind(computation, (_arg1) => {
        const x = _arg1;
        f(x);
        return singleton.Return(x);
    }));
}

export class Deferred$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasNotStartedYet", "InProgress", "Resolved"];
    }
}

export function Deferred$1$reflection(gen0) {
    return union_type("Extensions.Deferred`1", [gen0], Deferred$1, () => [[], [], [["Item", gen0]]]);
}

export class AsyncOperationStatus$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Started", "Finished"];
    }
}

export function AsyncOperationStatus$1$reflection(gen0) {
    return union_type("Extensions.AsyncOperationStatus`1", [gen0], AsyncOperationStatus$1, () => [[], [["Item", gen0]]]);
}

export function String_isDigit(s) {
    if (isNullOrEmpty(s)) {
        return false;
    }
    else {
        return fold((s_1, x) => {
            if (isDigit(x)) {
                return s_1;
            }
            else {
                return false;
            }
        }, true, s.trim().split(""));
    }
}

export function Remoting_handleNonAuth(computation) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.ReturnFrom(computation)), (_arg1) => {
        let pattern_matching_result, ex_1;
        if (_arg1 instanceof ProxyRequestException) {
            if (ProxyRequestException__get_StatusCode(_arg1) === 401) {
                pattern_matching_result = 0;
                ex_1 = _arg1;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                removeUser();
                document.dispatchEvent(new Event('Fable.Remoting.Unauthorized'));
                return singleton.Return((() => {
                    throw ex_1;
                })());
            }
            case 1: {
                throw _arg1;
                return null;
            }
        }
    }));
}

export function Remoting_subscribe(msg, _model) {
    return Cmd_ofSub((dispatch) => {
        document.addEventListener('Fable.Remoting.Unauthorized', function (e) {(() => {
            dispatch(msg);
        })() });
    });
}

export function Remoting_favouritesChanged(count) {
    document.dispatchEvent(new CustomEvent('Favourites.Changed',{ detail: {count: count}}));
}

export function Remoting_subscribeFavouritesChangedEvent(msg) {
    return Cmd_ofSub((dispatch) => {
        document.addEventListener('Favourites.Changed', function (e) {((x) => {
            dispatch(msg(x));
        })(e.detail.count) });
    });
}

export function Remoting_bidsChanged(lotId) {
    document.dispatchEvent(new CustomEvent('Bids.Changed',{ detail: {lotId: lotId}}));
}

export function Remoting_subscribeBidsChangedEvent(msg) {
    return Cmd_ofSub((dispatch) => {
        document.addEventListener('Bids.Changed', function (e) {((x) => {
            dispatch(msg(x));
        })(e.detail.lotId) });
    });
}

export function Remoting_ordersChanged(auctionId, lotId) {
    document.dispatchEvent(new CustomEvent('Orders.Changed',{ detail: {info: (`${auctionId}+${lotId}`)} }));
}

export function Remoting_subscribeOrdersChangedEvent(msg) {
    return Cmd_ofSub((dispatch) => {
        document.addEventListener('Orders.Changed', function (e) {((x) => {
            const tupleInfo = split(x, ["+"], null, 0);
            if (tupleInfo.length !== 2) {
            }
            else {
                dispatch(msg([AuctionIdModule_fromString(tupleInfo[0]), LotIdModule_fromString(tupleInfo[1])]));
            }
        })(e.detail.info)});
    });
}

export const Remoting_LiveBidding_notifyLotBidding = (arg) => {
    const _lotId = LotIdModule_toString(arg);
    document.dispatchEvent(new CustomEvent('LiveBidding.CurrentLot', { detail: {lotId: _lotId}}));
};

export function Remoting_LiveBidding_subscribeCurrentLotBiddingEvent(msg) {
    return Cmd_ofSub((dispatch) => {
        document.addEventListener('LiveBidding.CurrentLot', function (e) {((arg_1) => {
            dispatch(msg(LotIdModule_fromString(arg_1)));
        })(e.detail.lotId) });
    });
}

export class FlatpickrExtensions_Locales {
    constructor() {
    }
}

export function FlatpickrExtensions_Locales$reflection() {
    return class_type("Extensions.FlatpickrExtensions.Locales", void 0, FlatpickrExtensions_Locales);
}

export function Numeral_formatInt(pattern, x) {
    const y = numeral(x);
    return y.format(pattern);
}

