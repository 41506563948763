import { Record } from "../fable-library.3.6.1/Types.js";
import { bool_type, record_type, array_type, option_type, class_type, string_type, enum_type, int32_type } from "../fable-library.3.6.1/Reflection.js";
import { split, endsWith, printf, toText } from "../fable-library.3.6.1/String.js";
import { value as value_11 } from "../fable-library.3.6.1/Option.js";

export class HubRecords_InvocationMessage$1 extends Record {
    constructor(type, headers, invocationId, target, arguments$, streamIds) {
        super();
        this.type = (type | 0);
        this.headers = headers;
        this.invocationId = invocationId;
        this.target = target;
        this.arguments = arguments$;
        this.streamIds = streamIds;
    }
}

export function HubRecords_InvocationMessage$1$reflection(gen0) {
    return record_type("Fable.SignalR.Protocol.HubRecords.InvocationMessage`1", [gen0], HubRecords_InvocationMessage$1, () => [["type", enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]])], ["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", option_type(string_type)], ["target", string_type], ["arguments", array_type(gen0)], ["streamIds", option_type(array_type(string_type))]]);
}

export class HubRecords_StreamInvocationMessage$1 extends Record {
    constructor(type, target, headers, invocationId, arguments$, streamIds) {
        super();
        this.type = (type | 0);
        this.target = target;
        this.headers = headers;
        this.invocationId = invocationId;
        this.arguments = arguments$;
        this.streamIds = streamIds;
    }
}

export function HubRecords_StreamInvocationMessage$1$reflection(gen0) {
    return record_type("Fable.SignalR.Protocol.HubRecords.StreamInvocationMessage`1", [gen0], HubRecords_StreamInvocationMessage$1, () => [["type", enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]])], ["target", string_type], ["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", string_type], ["arguments", array_type(gen0)], ["streamIds", option_type(array_type(string_type))]]);
}

export class HubRecords_StreamItemMessage$1 extends Record {
    constructor(type, headers, invocationId, item) {
        super();
        this.type = (type | 0);
        this.headers = headers;
        this.invocationId = invocationId;
        this.item = item;
    }
}

export function HubRecords_StreamItemMessage$1$reflection(gen0) {
    return record_type("Fable.SignalR.Protocol.HubRecords.StreamItemMessage`1", [gen0], HubRecords_StreamItemMessage$1, () => [["type", enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]])], ["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", option_type(string_type)], ["item", gen0]]);
}

export class HubRecords_CompletionMessage$1 extends Record {
    constructor(type, headers, invocationId, error, result) {
        super();
        this.type = (type | 0);
        this.headers = headers;
        this.invocationId = invocationId;
        this.error = error;
        this.result = result;
    }
}

export function HubRecords_CompletionMessage$1$reflection(gen0) {
    return record_type("Fable.SignalR.Protocol.HubRecords.CompletionMessage`1", [gen0], HubRecords_CompletionMessage$1, () => [["type", enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]])], ["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", string_type], ["error", option_type(string_type)], ["result", option_type(gen0)]]);
}

export class HubRecords_PingMessage extends Record {
    constructor(type) {
        super();
        this.type = (type | 0);
    }
}

export function HubRecords_PingMessage$reflection() {
    return record_type("Fable.SignalR.Protocol.HubRecords.PingMessage", [], HubRecords_PingMessage, () => [["type", enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]])]]);
}

export class HubRecords_CloseMessage extends Record {
    constructor(type, error, allowReconnect) {
        super();
        this.type = (type | 0);
        this.error = error;
        this.allowReconnect = allowReconnect;
    }
}

export function HubRecords_CloseMessage$reflection() {
    return record_type("Fable.SignalR.Protocol.HubRecords.CloseMessage", [], HubRecords_CloseMessage, () => [["type", enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]])], ["error", option_type(string_type)], ["allowReconnect", option_type(bool_type)]]);
}

export class HubRecords_CancelInvocationMessage extends Record {
    constructor(type, headers, invocationId) {
        super();
        this.type = (type | 0);
        this.headers = headers;
        this.invocationId = invocationId;
    }
}

export function HubRecords_CancelInvocationMessage$reflection() {
    return record_type("Fable.SignalR.Protocol.HubRecords.CancelInvocationMessage", [], HubRecords_CancelInvocationMessage, () => [["type", enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]])], ["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", option_type(string_type)]]);
}

const Json_TextMessageFormat_recordSeparatorChar = String.fromCharCode(30);

const Json_TextMessageFormat_recordSeparator = Json_TextMessageFormat_recordSeparatorChar;

export function Json_TextMessageFormat_write(output) {
    return toText(printf("%s%s"))(output)(Json_TextMessageFormat_recordSeparator);
}

export function Json_TextMessageFormat_parse(input) {
    if (endsWith(input, Json_TextMessageFormat_recordSeparator)) {
        const arr = split(input, [Json_TextMessageFormat_recordSeparatorChar], null, 0);
        return arr.slice(0, (arr.length - 2) + 1);
    }
    else {
        throw (new Error("Message is incomplete."));
    }
}

export class Json_JsonProtocol {
    constructor() {
    }
}

export function Json_JsonProtocol$reflection() {
    return class_type("Fable.SignalR.Protocol.Json.JsonProtocol", void 0, Json_JsonProtocol);
}

export function Json_JsonProtocol_$ctor() {
    return new Json_JsonProtocol();
}

export function MsgPack_parseMsg(msg) {
    let msg_4, msg_6, matchValue, invocationId_7, msg_8, fail, matchValue_1, err, msg_11, msg_2;
    switch (msg.tag) {
        case 2: {
            return (msg_4 = (new HubRecords_InvocationMessage$1(1, msg.fields[0], msg.fields[1], msg.fields[2], Array.from(msg.fields[3]), msg.fields[4])), ((msg_4.target === "") ? (() => {
                throw (new Error("Invalid payload for Invocation message."));
            })() : (void 0), ((msg_4.invocationId != null) ? ((value_11(msg_4.invocationId) === "") ? (() => {
                throw (new Error("Invalid payload for Invocation message."));
            })() : (void 0)) : (void 0), msg_4)));
        }
        case 3: {
            return (msg_6 = (new HubRecords_StreamItemMessage$1(2, msg.fields[0], msg.fields[1], msg.fields[2])), (matchValue = msg_6.invocationId, (matchValue != null) ? ((matchValue !== "") ? ((invocationId_7 = matchValue, msg_6)) : (() => {
                throw (new Error("Invalid payload for StreamItem message."));
            })()) : (() => {
                throw (new Error("Invalid payload for StreamItem message."));
            })()));
        }
        case 4: {
            return (msg_8 = (new HubRecords_CompletionMessage$1(3, msg.fields[0], msg.fields[1], msg.fields[2], msg.fields[3])), (fail = (() => {
                throw (new Error("Invalid payload for Completion message."));
            }), ((matchValue_1 = [msg_8.result, msg_8.error], (matchValue_1[0] == null) ? ((matchValue_1[1] != null) ? ((err = matchValue_1[1], (err === "") ? fail() : (void 0))) : ((msg_8.invocationId === "") ? fail() : (void 0))) : ((matchValue_1[1] != null) ? fail() : ((msg_8.invocationId === "") ? fail() : (void 0)))), msg_8)));
        }
        case 6: {
            return new HubRecords_CancelInvocationMessage(5, msg.fields[0], msg.fields[1]);
        }
        case 7: {
            return new HubRecords_PingMessage(6);
        }
        case 8: {
            return new HubRecords_CloseMessage(7, msg.fields[0], msg.fields[1]);
        }
        case 1: {
            return (msg_11 = (new HubRecords_InvocationMessage$1(1, msg.fields[0], msg.fields[1], msg.fields[2], [msg.fields[3], msg.fields[4]], msg.fields[5])), ((msg_11.target === "") ? (() => {
                throw (new Error("Invalid payload for Invocation message."));
            })() : (void 0), ((msg_11.invocationId != null) ? ((value_11(msg_11.invocationId) === "") ? (() => {
                throw (new Error("Invalid payload for Invocation message."));
            })() : (void 0)) : (void 0), msg_11)));
        }
        case 5: {
            return new HubRecords_StreamInvocationMessage$1(4, msg.fields[2], msg.fields[0], msg.fields[1], Array.from(msg.fields[3]), msg.fields[4]);
        }
        default: {
            return (msg_2 = (new HubRecords_InvocationMessage$1(1, msg.fields[0], msg.fields[1], msg.fields[2], Array.from(msg.fields[3]), msg.fields[4])), ((msg_2.target === "") ? (() => {
                throw (new Error("Invalid payload for Invocation message."));
            })() : (void 0), ((msg_2.invocationId != null) ? ((value_11(msg_2.invocationId) === "") ? (() => {
                throw (new Error("Invalid payload for Invocation message."));
            })() : (void 0)) : (void 0), msg_2)));
        }
    }
}

export class MsgPack_MsgPackProtocol {
    constructor() {
    }
}

export function MsgPack_MsgPackProtocol$reflection() {
    return class_type("Fable.SignalR.Protocol.MsgPack.MsgPackProtocol", void 0, MsgPack_MsgPackProtocol);
}

export function MsgPack_MsgPackProtocol_$ctor() {
    return new MsgPack_MsgPackProtocol();
}

