import { record_type, obj_type, option_type, union_type, string_type, lambda_type, unit_type, class_type } from "../fable-library.3.6.1/Reflection.js";
import * as signalr from "@microsoft/signalr";
import { Record, Union } from "../fable-library.3.6.1/Types.js";
import { startAsPromise, awaitPromise, startImmediate, createCancellationToken, cancel } from "../fable-library.3.6.1/Async.js";
import { postAndAsyncReply, post, receive, start } from "../fable-library.3.6.1/MailboxProcessor.js";
import { singleton } from "../fable-library.3.6.1/AsyncBuilder.js";
import { iterate as iterate_1, singleton as singleton_1, append, empty } from "../fable-library.3.6.1/List.js";
import { iterate } from "../fable-library.3.6.1/Seq.js";
import { map, defaultArg, toArray } from "../fable-library.3.6.1/Option.js";
import { empty as empty_1, FSharpMap__Add, FSharpMap__Remove, FSharpMap__TryFind } from "../fable-library.3.6.1/Map.js";
import { newGuid as newGuid_1 } from "../fable-library.3.6.1/Guid.js";

export class Subject$1 {
    constructor() {
    }
    next(item) {
        const this$ = this;
        this$.next(item);
    }
    error(err) {
        const this$ = this;
        this$.error(err);
    }
    complete() {
        const this$ = this;
        this$.complete();
    }
    subscribe(observer) {
        const this$ = this;
        const sub = this$.subscribe(observer);
        return {
            Dispose() {
                sub.dispose();
            },
        };
    }
}

export function Subject$1$reflection(gen0) {
    return class_type("Fable.SignalR.Subject`1", [gen0], Subject$1);
}

export const Bindings_signalR = signalr;

export class HubMailbox$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProcessSends", "Send", "ServerRsp", "StartInvocation"];
    }
}

export function HubMailbox$2$reflection(gen0, gen1) {
    return union_type("Fable.SignalR.HubMailbox`2", [gen0, gen1], HubMailbox$2, () => [[], [["callback", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))]], [["connectionId", string_type], ["invocationId", class_type("System.Guid")], ["rsp", gen1]], [["msg", gen0], ["replyChannel", class_type("Microsoft.FSharp.Control.FSharpAsyncReplyChannel`1", [gen1])]]]);
}

export class Handlers extends Record {
    constructor(onClose, onMessage, onReconnected, onReconnecting) {
        super();
        this.onClose = onClose;
        this.onMessage = onMessage;
        this.onReconnected = onReconnected;
        this.onReconnecting = onReconnecting;
    }
}

export function Handlers$reflection() {
    return record_type("Fable.SignalR.Handlers", [], Handlers, () => [["onClose", option_type(lambda_type(option_type(class_type("System.Exception")), unit_type))], ["onMessage", option_type(lambda_type(obj_type, unit_type))], ["onReconnected", option_type(lambda_type(option_type(string_type), unit_type))], ["onReconnecting", option_type(lambda_type(option_type(class_type("System.Exception")), unit_type))]]);
}

export function Handlers_get_empty() {
    return new Handlers(void 0, void 0, void 0, void 0);
}

export class HubConnection$5 {
    constructor(hub, handlers) {
        this.hub = hub;
        this.cts = createCancellationToken();
        this.mailbox = start((inbox) => {
            const loop = (waitingInvocations, waitingConnections) => singleton.Delay(() => {
                let waitingConnections_1;
                if ((this.hub.state) === "Connected") {
                    startImmediate(HubConnection$5__processSends_Z64277630(this, waitingConnections), this.cts);
                    waitingConnections_1 = empty();
                }
                else {
                    waitingConnections_1 = waitingConnections;
                }
                return singleton.Bind(receive(inbox), (_arg1) => {
                    let action, invocationId, matchValue, hubId_2, serverMsg, newGuid, newConnections_1, this$;
                    const msg = _arg1;
                    const hubId = this.hub.connectionId;
                    return singleton.ReturnFrom((msg.tag === 1) ? ((action = msg.fields[0], loop(waitingInvocations, append(((this.hub.state) === "Connected") ? ((startImmediate(action(), this.cts), empty())) : singleton_1(action), waitingConnections_1)))) : ((msg.tag === 2) ? ((invocationId = msg.fields[1], (matchValue = [hubId, msg.fields[0], msg.fields[2]], (matchValue[0] != null) ? ((matchValue[0] === matchValue[1]) ? ((hubId_2 = matchValue[0], (iterate((reply) => {
                        reply.reply(matchValue[2]);
                    }, toArray(FSharpMap__TryFind(waitingInvocations, invocationId))), loop(FSharpMap__Remove(waitingInvocations, invocationId), waitingConnections_1)))) : loop(waitingInvocations, waitingConnections_1)) : loop(waitingInvocations, waitingConnections_1)))) : ((msg.tag === 3) ? ((serverMsg = msg.fields[0], (newGuid = newGuid_1(), (newConnections_1 = (((this.hub.state) === "Connected") ? ((startImmediate((this$ = this.hub, awaitPromise(this$.invoke("Invoke", serverMsg, newGuid))), this.cts), empty())) : singleton_1(() => {
                        const this$_1 = this.hub;
                        return awaitPromise(this$_1.invoke("Invoke", serverMsg, newGuid));
                    })), loop(FSharpMap__Add(waitingInvocations, newGuid, msg.fields[1]), append(newConnections_1, waitingConnections_1)))))) : ((startImmediate(HubConnection$5__processSends_Z64277630(this, waitingConnections_1), this.cts), loop(waitingInvocations, empty()))))));
                });
            });
            return loop(empty_1(), empty());
        }, this.cts);
        this.onRsp = ((arg) => {
            let tupledArg;
            post(this.mailbox, (tupledArg = arg, new HubMailbox$2(2, tupledArg[0], tupledArg[1], tupledArg[2])));
        });
        const this$_2 = new Handlers(handlers.onClose, handlers.onMessage, defaultArg(map((f) => ((strOpt) => {
            post(this.mailbox, new HubMailbox$2(0));
            f(strOpt);
        }), handlers.onReconnected), (_arg1_1) => {
            post(this.mailbox, new HubMailbox$2(0));
        }), handlers.onReconnecting);
        const hub_1 = this.hub;
        iterate((arg00_3) => {
            hub_1.onclose(arg00_3);
        }, toArray(this$_2.onClose));
        iterate((arg00_4) => {
            hub_1.on("Send", arg00_4);
        }, toArray(this$_2.onMessage));
        iterate((arg00_5) => {
            hub_1.onreconnected(arg00_5);
        }, toArray(this$_2.onReconnected));
        iterate((arg00_6) => {
            hub_1.onreconnecting(arg00_6);
        }, toArray(this$_2.onReconnecting));
        this.hub.on("Invoke", ((rsp) => {
            this.onRsp([rsp.connectionId, rsp.invocationId, rsp.message]);
        }));
    }
    Dispose() {
        const _ = this;
        _.hub.off("Invoke", _.onRsp);
        cancel(_.cts);
        const pr = _.hub.stop();
        pr.then();
    }
    get baseUrl() {
        const this$ = this;
        return HubConnection$5__get_baseUrl(this$);
    }
    get connectionId() {
        const this$ = this;
        return HubConnection$5__get_connectionId(this$);
    }
    invoke(msg) {
        const this$ = this;
        return HubConnection$5__invoke_2B595(this$, msg);
    }
    invokeAsPromise(msg) {
        const this$ = this;
        return HubConnection$5__invokeAsPromise_2B595(this$, msg);
    }
    get keepAliveInterval() {
        const this$ = this;
        return HubConnection$5__get_keepAliveInterval(this$) | 0;
    }
    send(msg) {
        const this$ = this;
        return HubConnection$5__send_2B595(this$, msg);
    }
    sendAsPromise(msg) {
        const this$ = this;
        return HubConnection$5__sendAsPromise_2B595(this$, msg);
    }
    sendNow(msg) {
        const this$ = this;
        HubConnection$5__sendNow_2B595(this$, msg);
    }
    get serverTimeout() {
        const this$ = this;
        return HubConnection$5__get_serverTimeout(this$) | 0;
    }
    get state() {
        const this$ = this;
        return HubConnection$5__get_state(this$);
    }
    streamTo(subject) {
        const this$ = this;
        return HubConnection$5__streamTo_Z28B2D9AB(this$, subject);
    }
    streamToAsPromise(subject) {
        const this$ = this;
        return HubConnection$5__streamToAsPromise_Z28B2D9AB(this$, subject);
    }
    streamToNow(subject) {
        const this$ = this;
        HubConnection$5__streamToNow_Z28B2D9AB(this$, subject);
    }
    streamFrom(msg) {
        const this$ = this;
        return HubConnection$5__streamFrom_2B594(this$, msg);
    }
    streamFromAsPromise(msg) {
        const this$ = this;
        return HubConnection$5__streamFromAsPromise_2B594(this$, msg);
    }
}

export function HubConnection$5$reflection(gen0, gen1, gen2, gen3, gen4) {
    return class_type("Fable.SignalR.HubConnection`5", [gen0, gen1, gen2, gen3, gen4], HubConnection$5);
}

export function HubConnection$5_$ctor_3ED56BCC(hub, handlers) {
    return new HubConnection$5(hub, handlers);
}

export function HubConnection$5__get_baseUrl(_) {
    return _.hub.baseUrl;
}

export function HubConnection$5__get_connectionId(_) {
    return _.hub.connectionId;
}

export function HubConnection$5__invoke_2B595(_, msg) {
    return postAndAsyncReply(_.mailbox, (reply) => (new HubMailbox$2(3, msg, reply)));
}

export function HubConnection$5__invokeAsPromise_2B595(this$, msg) {
    return startAsPromise(HubConnection$5__invoke_2B595(this$, msg));
}

export function HubConnection$5__get_keepAliveInterval(_) {
    return _.hub.keepAliveIntervalInMilliseconds;
}

export function HubConnection$5__off(_) {
    _.hub.off("Send");
}

export function HubConnection$5__onClose_15DD00C6(_, callback) {
    _.hub.onclose(callback);
}

export function HubConnection$5__onMessage_2163CAFC(_, callback) {
    _.hub.on("Send", callback);
}

export function HubConnection$5__onReconnected_Z2ADBE6BC(_, callback) {
    _.hub.onreconnected(callback);
}

export function HubConnection$5__onReconnecting_15DD00C6(_, callback) {
    _.hub.onreconnecting(callback);
}

export function HubConnection$5__send_2B595(_, msg) {
    return singleton.Delay(() => singleton.Return(post(_.mailbox, new HubMailbox$2(1, () => awaitPromise(_.hub.send("Send", msg))))));
}

export function HubConnection$5__sendAsPromise_2B595(this$, msg) {
    return startAsPromise(HubConnection$5__send_2B595(this$, msg));
}

export function HubConnection$5__sendNow_2B595(this$, msg) {
    startImmediate(HubConnection$5__send_2B595(this$, msg), this$.cts);
}

export function HubConnection$5__get_serverTimeout(_) {
    return _.hub.serverTimeoutInMilliseconds;
}

export function HubConnection$5__start(_) {
    return singleton.Delay(() => (((_.hub.state) === "Disconnected") ? singleton.Bind(awaitPromise(_.hub.start()), () => {
        post(_.mailbox, new HubMailbox$2(0));
        return singleton.Zero();
    }) : singleton.Zero()));
}

export function HubConnection$5__startAsPromise(this$) {
    return startAsPromise(HubConnection$5__start(this$));
}

export function HubConnection$5__startNow(this$) {
    startImmediate(HubConnection$5__start(this$), this$.cts);
}

export function HubConnection$5__get_state(_) {
    return _.hub.state;
}

export function HubConnection$5__stop(_) {
    return singleton.Delay(() => (((_.hub.state) !== "Disconnected") ? singleton.Bind(awaitPromise(_.hub.stop()), () => singleton.Return()) : singleton.Zero()));
}

export function HubConnection$5__stopAsPromise(this$) {
    return startAsPromise(HubConnection$5__stop(this$));
}

export function HubConnection$5__stopNow(this$) {
    startImmediate(HubConnection$5__stop(this$), this$.cts);
}

export function HubConnection$5__streamFrom_2B594(_, msg) {
    return postAndAsyncReply(_.mailbox, (reply) => (new HubMailbox$2(1, () => singleton.Delay(() => singleton.Return(reply.reply(_.hub.stream("StreamFrom", msg)))))));
}

export function HubConnection$5__streamFromAsPromise_2B594(this$, msg) {
    return startAsPromise(HubConnection$5__streamFrom_2B594(this$, msg));
}

export function HubConnection$5__streamTo_Z28B2D9AB(_, subject) {
    return singleton.Delay(() => singleton.Return(post(_.mailbox, new HubMailbox$2(1, () => awaitPromise(_.hub.send("StreamTo", subject))))));
}

export function HubConnection$5__streamToAsPromise_Z28B2D9AB(this$, subject) {
    return startAsPromise(HubConnection$5__streamTo_Z28B2D9AB(this$, subject));
}

export function HubConnection$5__streamToNow_Z28B2D9AB(this$, subject) {
    startImmediate(HubConnection$5__streamTo_Z28B2D9AB(this$, subject), this$.cts);
}

function HubConnection$5__processSends_Z64277630(this$, pendingActions) {
    return singleton.Delay(() => {
        iterate_1((a) => {
            startImmediate(a(), this$.cts);
        }, pendingActions);
        return singleton.Zero();
    });
}

