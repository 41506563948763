import { Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { class_type, record_type, int32_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";

export class Settings extends Record {
    constructor(slidesToShow) {
        super();
        this.slidesToShow = (slidesToShow | 0);
    }
}

export function Settings$reflection() {
    return record_type("Components.Slider.Settings", [], Settings, () => [["slidesToShow", int32_type]]);
}

export class Responsive extends Record {
    constructor(breakpoint, settings) {
        super();
        this.breakpoint = (breakpoint | 0);
        this.settings = settings;
    }
}

export function Responsive$reflection() {
    return record_type("Components.Slider.Responsive", [], Responsive, () => [["breakpoint", int32_type], ["settings", Settings$reflection()]]);
}

export class Slick {
    constructor() {
    }
}

export function Slick$reflection() {
    return class_type("Components.Slider.Slick", void 0, Slick);
}

