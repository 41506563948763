import { t } from "../Localization.js";
import { Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, list_type, bool_type, string_type, union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { singleton as singleton_1, append as append_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { React_functionComponent_2F9D7239 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function p(key) {
    return t("home." + key);
}

export class Size extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Small", "Normal", "Medium", "Large"];
    }
}

export function Size$reflection() {
    return union_type("Components.Loader.Size", [], Size, () => [[], [], [], []]);
}

export class Props extends Record {
    constructor(IconSize, Text$, Inline, IconClasses) {
        super();
        this.IconSize = IconSize;
        this.Text = Text$;
        this.Inline = Inline;
        this.IconClasses = IconClasses;
    }
}

export function Props$reflection() {
    return record_type("Components.Loader.Props", [], Props, () => [["IconSize", Size$reflection()], ["Text", string_type], ["Inline", bool_type], ["IconClasses", list_type(string_type)]]);
}

export function Props_get_Default() {
    return new Props(new Size(0), p("loading"), false, ofArray(["fa", "fa-spinner", "fa-pulse"]));
}

export function size(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "fa-2x";
        }
        case 2: {
            return "fa-3x";
        }
        case 3: {
            return "fa-4x";
        }
        default: {
            return "fa-1x";
        }
    }
}

export const view = React_functionComponent_2F9D7239((props) => {
    let props_8, props_6, props_3, elms;
    return createElement("div", {
        style: createObj(toList(delay(() => append(props.Inline ? singleton(["position", "relative"]) : singleton(["position", "absolute"]), delay(() => append(singleton(["top", 0]), delay(() => append(singleton(["width", 100 + "%"]), delay(() => append(singleton(["height", 100 + "%"]), delay(() => append(singleton(["display", "flex"]), delay(() => append(singleton(["justifyContent", "center"]), delay(() => singleton(["alignItems", "center"])))))))))))))))),
        className: "has-text-grey",
        children: Interop_reactApi.Children.toArray([(props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-mobile"], ["className", "has-text-grey"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
            className: join(" ", append_1(props.IconClasses, singleton_1(size(props.IconSize)))),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray([props.Text]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))]),
    });
});

export const large = view((() => {
    const inputRecord = Props_get_Default();
    return new Props(new Size(3), "", inputRecord.Inline, inputRecord.IconClasses);
})());

export const largeGreen = view((() => {
    const inputRecord = Props_get_Default();
    return new Props(new Size(3), "", inputRecord.Inline, inputRecord.IconClasses);
})());

export function medium(text) {
    let inputRecord;
    return view((inputRecord = Props_get_Default(), new Props(new Size(2), text, inputRecord.Inline, inputRecord.IconClasses)));
}

export function small(text) {
    let inputRecord;
    return view((inputRecord = Props_get_Default(), new Props(new Size(0), text, inputRecord.Inline, inputRecord.IconClasses)));
}

export function mediumInline(text) {
    return view(new Props(new Size(2), text, true, Props_get_Default().IconClasses));
}

export function smallInline(text) {
    return view(new Props(new Size(0), text, true, Props_get_Default().IconClasses));
}

export function fullScreenMedium(text) {
    let elms;
    const props_1 = ofArray([["className", "is-fullheight-with-navbar"], ["style", {
        opacity: 0.5,
    }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(medium(text)), createElement("div", {
        className: "hero-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]);
    return createElement("section", createObj(Helpers_combineClasses("hero", props_1)));
}

export function fullScreenSmall(text) {
    let elms;
    const props_1 = ofArray([["className", "is-fullheight-with-navbar"], ["style", {
        opacity: 0.5,
    }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(small(text)), createElement("div", {
        className: "hero-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]);
    return createElement("section", createObj(Helpers_combineClasses("hero", props_1)));
}

