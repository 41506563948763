import { singleton as singleton_1, map, ofArray, head, tail, isEmpty, item, mapIndexed, length as length_1 } from "../../../fable_modules/fable-library.3.6.1/List.js";
import { FSharpException } from "../../../fable_modules/fable-library.3.6.1/Types.js";
import { class_type } from "../../../fable_modules/fable-library.3.6.1/Reflection.js";
import { equals } from "../../../fable_modules/fable-library.3.6.1/Util.js";
import { singleton } from "../../../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { sleep } from "../../../fable_modules/fable-library.3.6.1/Async.js";
import { toList, ofList } from "../../../fable_modules/fable-library.3.6.1/Map.js";
import { ofNullable, defaultArg } from "../../../fable_modules/fable-library.3.6.1/Option.js";
import { split, padRight, padLeft, substring } from "../../../fable_modules/fable-library.3.6.1/String.js";
import { toList as toList_1 } from "../../../fable_modules/fable-library.3.6.1/Seq.js";
import { rangeDouble } from "../../../fable_modules/fable-library.3.6.1/Range.js";

export function List_swap(idx1, idx2, lst) {
    if ((((idx1 >= 0) ? (idx1 < length_1(lst)) : false) ? (idx2 >= 0) : false) ? (idx2 < length_1(lst)) : false) {
        return mapIndexed((i, v) => {
            if (i === idx1) {
                return item(idx2, lst);
            }
            else if (i === idx2) {
                return item(idx1, lst);
            }
            else {
                return v;
            }
        }, lst);
    }
    else {
        return lst;
    }
}

export function List_swapToLeft(position) {
    const idx1 = (position - 1) | 0;
    return (lst) => List_swap(idx1, position, lst);
}

export function List_swapToRight(position) {
    const idx2 = (position + 1) | 0;
    return (lst) => List_swap(position, idx2, lst);
}

export class Retry_RetryFailed extends FSharpException {
    constructor() {
        super();
    }
}

export function Retry_RetryFailed$reflection() {
    return class_type("Bidflow.Infrastructure.Extensions.Retry.RetryFailed", void 0, Retry_RetryFailed, class_type("System.Exception"));
}

function Retry_RetryFailed__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof Retry_RetryFailed) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function Retry_execute(delays, onError, f) {
    return singleton.Delay(() => {
        if (!isEmpty(delays)) {
            const delays_1 = tail(delays);
            return singleton.TryWith(singleton.Delay(() => singleton.ReturnFrom(f)), (_arg1) => singleton.Bind(onError([_arg1, delays_1]), () => singleton.Bind(sleep(head(delays)), () => singleton.ReturnFrom(Retry_execute(delays_1, onError, f)))));
        }
        else {
            return singleton.Return((() => {
                throw (new Retry_RetryFailed());
            })());
        }
    });
}

export function Retry_execExponential(onError, f) {
    return Retry_execute(ofArray([1000, 3000, 10000]), onError, f);
}

export function Map_mapKey(f, m) {
    return ofList(map((tupledArg) => [f(tupledArg[0]), tupledArg[1]], toList(m)));
}

export function Map_keys(m) {
    return map((tuple) => tuple[0], toList(m));
}

export function String_splitBy(chunkSize, s) {
    if (chunkSize < 0) {
        throw (new Error("Chunk size should be non negative\\nParameter name: chunkSize"));
    }
    const s_1 = defaultArg(ofNullable(s), "");
    const matchValue = s_1.length | 0;
    if (matchValue === 0) {
        return singleton_1("");
    }
    else if (chunkSize === 0) {
        return singleton_1("");
    }
    else if (matchValue <= chunkSize) {
        return singleton_1(s_1);
    }
    else {
        const l_2 = matchValue | 0;
        return map((x) => substring(s_1, x, ((l_2 - (x * chunkSize)) > chunkSize) ? chunkSize : (l_2 - (x * chunkSize))), toList_1(rangeDouble(0, chunkSize, ~(~((l_2 - 1) / chunkSize)))));
    }
}

export function String_truncate(length, s) {
    return head(String_splitBy(length, s));
}

export function String_padLeft(length, padChar, s) {
    return padLeft(defaultArg(ofNullable(s), ""), length, padChar);
}

export function String_padRight(length, padChar, s) {
    return padRight(defaultArg(ofNullable(s), ""), length, padChar);
}

export function String_split(c, s) {
    return split(defaultArg(ofNullable(s), ""), [c], null, 0);
}

export function Utils_String_truncateAndPadLeft(length, padChar) {
    return (arg) => String_padLeft(length, padChar, String_truncate(length, arg));
}

export function Utils_String_truncateAndPadRight(length, padChar) {
    return (arg) => String_padRight(length, padChar, String_truncate(length, arg));
}

export function System_String__String_TruncateAndPadLeft_413E0D0A(this$, length, padChar) {
    return Utils_String_truncateAndPadLeft(length, padChar)(this$);
}

export function System_String__String_TruncateAndPadRight_413E0D0A(this$, length, padChar) {
    return Utils_String_truncateAndPadRight(length, padChar)(this$);
}

