import { t as t_1 } from "./Localization.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { bool_type, class_type, list_type, union_type, unit_type, record_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi } from "./Communication.js";
import { ResetPasswordRequest } from "./Shared/Shared.js";
import { Validator$1__Test, Validator$1__IsValid_Z4CF01147, Validator$1__End_Z5E18B1E2, validateSync } from "./fable_modules/Fable.Validation.0.2.1/Validation.fs.js";
import { join, isNullOrWhiteSpace } from "./fable_modules/fable-library.3.6.1/String.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList, exists } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { isLetter, isDigit } from "./fable_modules/fable-library.3.6.1/Char.js";
import { empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton as singleton_2, cons, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Url_getCurrentUrlPage, Form_Field_errorsAsString, Form_Field_hasErrors } from "./Common.js";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Route, toPath } from "./Router.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";

export function p(key) {
    return t_1("changePassword." + key);
}

export class Types_Form extends Record {
    constructor(Password, PasswordConfirmation) {
        super();
        this.Password = Password;
        this.PasswordConfirmation = PasswordConfirmation;
    }
}

export function Types_Form$reflection() {
    return record_type("ResetPassword.Types.Form", [], Types_Form, () => [["Password", string_type], ["PasswordConfirmation", string_type]]);
}

export class Types_State extends Record {
    constructor(Code, Form, Changing, FormErrors, IsNeedValidation) {
        super();
        this.Code = Code;
        this.Form = Form;
        this.Changing = Changing;
        this.FormErrors = FormErrors;
        this.IsNeedValidation = IsNeedValidation;
    }
}

export function Types_State$reflection() {
    return record_type("ResetPassword.Types.State", [], Types_State, () => [["Code", string_type], ["Form", Types_Form$reflection()], ["Changing", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsNeedValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PasswordChanged", "PasswordConfirmationChanged", "ResetPassword"];
    }
}

export function Types_Msg$reflection() {
    return union_type("ResetPassword.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_change(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(usersApi().resetPassword(new ResetPasswordRequest(state.Code, state.Form.Password)), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Reset password code is invalid")))) : singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Validation_c(key) {
    return t_1("common." + key);
}

export function Validation_validate(formInfo) {
    return validateSync(true, (t) => (new Types_Form(Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (value) => {
        if (value === "") {
            return true;
        }
        else if (!isNullOrWhiteSpace(value)) {
            if ((value.length >= 8) ? exists((arg00) => isDigit(arg00), value.split("")) : false) {
                return exists((arg00_1) => isLetter(arg00_1), value.split(""));
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    })(Validation_c("error.password"))(Validator$1__Test(t, "NewPassword", formInfo.Password))), Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (confirmPassword) => {
        if ((confirmPassword === "") ? (formInfo.Password === "") : false) {
            return true;
        }
        else {
            return formInfo.Password === confirmPassword;
        }
    })(Validation_c("error.passwordConfirmation"))(Validator$1__Test(t, "RepeatNewPassword", formInfo.PasswordConfirmation))))));
}

export function Validation_validateIf(needValidate, x) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return [x, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [x, empty()];
    }
}

export function State_init(id) {
    return [new Types_State(id, new Types_Form("", ""), new Deferred$1(0), empty(), false), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        const patternInput_1 = Validation_validateIf(state.IsNeedValidation, new Types_Form(state.Form.Password, msg.fields[0]));
        return [new Types_State(state.Code, patternInput_1[0], state.Changing, patternInput_1[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Code, state.Form, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Code, state.Form, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
        }
        else {
            const matchValue = Validation_validate(state.Form);
            if (matchValue.tag === 1) {
                return [new Types_State(state.Code, state.Form, state.Changing, matchValue.fields[0], state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Code, matchValue.fields[0], new Deferred$1(1), empty(), state.IsNeedValidation), Cmd_fromAsync(Cmd_change(state))];
            }
        }
    }
    else {
        const patternInput = Validation_validateIf(state.IsNeedValidation, new Types_Form(msg.fields[0], state.Form.PasswordConfirmation));
        return [new Types_State(state.Code, patternInput[0], state.Changing, patternInput[1], state.IsNeedValidation), Cmd_none()];
    }
}

export function View_renderResetPasswordForm(state, dispatch, width) {
    const fieldStyleOnError = ofArray([["borderColor", "#FF0000"], ["boxShadow", "none"]]);
    return createElement("form", {
        onSubmit: (x) => {
            x.preventDefault();
            dispatch(new Types_Msg(2, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", {
            className: join(" ", ["create-account-title"]),
            children: p("text2"),
        })), delay(() => {
            let elms, props_7;
            return append(singleton_1((elms = singleton_2((props_7 = toList(delay(() => append(singleton_1(["className", "has-icons-left"]), delay(() => append(singleton_1(["style", {
                marginBottom: 0,
            }]), delay(() => append(Form_Field_hasErrors("NewPassword")(state.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => {
                let props_1, props_3, props_5;
                return singleton_1(["children", Interop_reactApi.Children.toArray([(props_1 = toList(delay(() => append((width.tag === 0) ? singleton_1(["className", "is-medium"]) : ((width.tag === 1) ? singleton_1(["className", "is-medium"]) : ((width.tag === 2) ? singleton_1(["className", "is-medium"]) : singleton_1(["className", "is-large"]))), delay(() => append(singleton_1(["type", "password"]), delay(() => append(singleton_1(["disabled", equals(state.Changing, new Deferred$1(1))]), delay(() => append(singleton_1(["placeholder", p("placeholder.newPassword")]), delay(() => singleton_1(["onChange", (ev) => {
                    dispatch(new Types_Msg(0, ev.target.value));
                }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_1))))), (props_3 = ofArray([["style", {
                    color: "#FF0000",
                }], ["children", Form_Field_errorsAsString("NewPassword")(state.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_3)))), (props_5 = singleton_2(["className", join(" ", ["fas", "fa-lock", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_5))))])]);
            })))))))), createElement("div", createObj(Helpers_combineClasses("control", props_7))))), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => {
                let elms_1, props_16;
                return append(singleton_1((elms_1 = singleton_2((props_16 = toList(delay(() => append(singleton_1(["className", "has-icons-left"]), delay(() => append(singleton_1(["style", {
                    paddingBottom: 10,
                }]), delay(() => append(Form_Field_hasErrors("RepeatNewPassword")(state.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => {
                    let props_10, props_12, props_14;
                    return singleton_1(["children", Interop_reactApi.Children.toArray([(props_10 = toList(delay(() => append((width.tag === 0) ? singleton_1(["className", "is-medium"]) : ((width.tag === 1) ? singleton_1(["className", "is-medium"]) : ((width.tag === 2) ? singleton_1(["className", "is-medium"]) : singleton_1(["className", "is-large"]))), delay(() => append(singleton_1(["type", "password"]), delay(() => append(singleton_1(["disabled", equals(state.Changing, new Deferred$1(1))]), delay(() => append(singleton_1(["placeholder", p("placeholder.repeatPassword")]), delay(() => singleton_1(["onChange", (ev_1) => {
                        dispatch(new Types_Msg(1, ev_1.target.value));
                    }]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_10))))), (props_12 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("RepeatNewPassword")(state.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_12)))), (props_14 = singleton_2(["className", join(" ", ["fas", "fa-lock", "is-left"])]), createElement("span", createObj(Helpers_combineClasses("icon", props_14))))])]);
                })))))))), createElement("div", createObj(Helpers_combineClasses("control", props_16))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))), delay(() => {
                    let props_19;
                    return append(singleton_1((props_19 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(equals(state.Changing, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["className", "is-uppercase"]), delay(() => append(singleton_1(["className", join(" ", ["create-account-button"])]), delay(() => singleton_1(["children", p("button.save")]))))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_19))))), delay(() => {
                        let props_21;
                        const matchValue = state.Changing;
                        let pattern_matching_result, error;
                        if (matchValue.tag === 1) {
                            pattern_matching_result = 0;
                        }
                        else if (matchValue.tag === 2) {
                            if (matchValue.fields[0].tag === 1) {
                                pattern_matching_result = 1;
                                error = matchValue.fields[0].fields[0];
                            }
                            else {
                                pattern_matching_result = 0;
                            }
                        }
                        else {
                            pattern_matching_result = 0;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                return singleton_1(null);
                            }
                            case 1: {
                                return singleton_1((props_21 = ofArray([["style", {
                                    color: "#FF0000",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }], ["children", error]]), createElement("p", createObj(Helpers_combineClasses("help", props_21)))));
                            }
                        }
                    }));
                }));
            }));
        })))))),
    });
}

export function View_view(state, dispatch, width) {
    return createElement("form", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["background-container-login-forgot-create", "create-account-column"])]), delay(() => append((width.tag === 0) ? singleton_1(["style", {}]) : ((width.tag === 1) ? singleton_1(["style", {}]) : ((width.tag === 2) ? singleton_1(["style", {
        minHeight: 814,
    }]) : ((width.tag === 3) ? singleton_1(["style", {
        minHeight: 814,
    }]) : singleton_1(["style", {
        minHeight: 500,
    }])))), delay(() => {
        let props_6;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "login-box"], ["style", {
                maxHeight: 400,
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let value_29;
                const matchValue = state.Changing;
                let pattern_matching_result;
                if (matchValue.tag === 1) {
                    pattern_matching_result = 0;
                }
                else if (matchValue.tag === 2) {
                    if (matchValue.fields[0].tag === 0) {
                        pattern_matching_result = 1;
                    }
                    else {
                        pattern_matching_result = 0;
                    }
                }
                else {
                    pattern_matching_result = 0;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return singleton_1(View_renderResetPasswordForm(state, dispatch, width));
                    }
                    case 1: {
                        return append(singleton_1(createElement("div", {
                            style: {
                                padding: 20,
                                marginBottom: 20,
                                borderBottom: (((1 + "px ") + "dashed") + " ") + "#ccc",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("p", {
                                className: join(" ", ["create-account-title"]),
                                children: p("success.title"),
                            }), createElement("div", {
                                className: "buy-text",
                                children: Interop_reactApi.Children.toArray([(value_29 = p("success.body.text"), createElement("p", {
                                    children: [value_29],
                                }))]),
                            })]),
                        })), delay(() => {
                            let props_4;
                            return singleton_1((props_4 = ofArray([["className", join(" ", ["buy-button-big"])], ["children", p("button.login")], ["href", toPath(new Route(6, Url_getCurrentUrlPage()))]]), createElement("a", createObj(Helpers_combineClasses("button", props_4)))));
                        }));
                    }
                }
            }))))]]), createElement("div", createObj(Helpers_combineClasses("box", props_6))))]),
        })])]);
    }))))))));
}

export function Component_resetPassword(component_resetPasswordInputProps) {
    const code = component_resetPasswordInputProps.code;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(code), (msg, state) => State_update(msg, state), [code]);
    return View_view(patternInput[0], patternInput[1], useFeliz_React__React_useResponsive_Static_Z1648B8FF());
}

