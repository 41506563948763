import { React_contextProvider_138D2F56, useReact_useContext_37FA55CF, useReact_useEffect_Z101E1A95, useReact_useEffect_Z5234A374, React_createDisposable_3A5B6456, useReact_useEffectOnce_Z5ECA432F, useReact_useMemo_CF4EA67, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505, React_createContext_1AE444D8 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { HubConnectionBuilder$5_$ctor_Z66CB2AA1, HubConnectionBuilder$5__withUrl_Z721C83C5, HubConnectionBuilder$5__withAutomaticReconnect, HubConnectionBuilder$5__onReconnected_Z2ADBE6BC, HubConnectionBuilder$5__onReconnecting_15DD00C6, HubConnectionBuilder$5__configureLogging_2D37BB17 } from "../fable_modules/Fable.SignalR.0.11.4/SignalR.fs.js";
import { HubConnection$5__stopNow, HubConnection$5__startNow, HubConnection$5_$ctor_3ED56BCC, Bindings_signalR } from "../fable_modules/Fable.SignalR.0.11.4/HubConnection.fs.js";
import { Json_TextMessageFormat_write, Json_TextMessageFormat_parse, HubRecords_CloseMessage$reflection, HubRecords_PingMessage$reflection, HubRecords_CancelInvocationMessage$reflection, HubRecords_StreamInvocationMessage$1$reflection, HubRecords_CompletionMessage$1$reflection, HubRecords_StreamItemMessage$1$reflection, HubRecords_InvocationMessage$1$reflection, Json_JsonProtocol_$ctor, MsgPack_parseMsg, MsgPack_MsgPackProtocol_$ctor } from "../fable_modules/Fable.SignalR.0.11.4/Protocols.fs.js";
import { join, toFail, printf, toText } from "../fable_modules/fable-library.3.6.1/String.js";
import { isEmpty, singleton, reverse, cons, empty } from "../fable_modules/fable-library.3.6.1/List.js";
import { Reader__Read_24524716, Reader_$ctor_6C95DA22 } from "../fable_modules/Fable.Remoting.MsgPack.1.9.0/Read.fs.js";
import { toString, fromInteger, op_Subtraction, compare, fromBits, op_Addition } from "../fable_modules/fable-library.3.6.1/Long.js";
import { enum_type, int32_type, unit_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { StreamFrom_SubscribeToLotEventsArgs, StreamFrom_SubscribeToLiveAuctionArgs, StreamFrom_Action, StreamFrom_SubscribeToCatalogEventsArgs, Action$reflection, StreamFrom_Action$reflection, StreamFrom_Response$reflection, Response$reflection } from "../Shared/Bidding.js";
import { InvokeArg$1$reflection, MsgPack_Msg$4, MsgPack_Msg$4$reflection } from "../fable_modules/Fable.SignalR.0.11.4/Shared.fs.js";
import { writeObject } from "../fable_modules/Fable.Remoting.MsgPack.1.9.0/Write.fs.js";
import { append, choose, addRangeInPlace } from "../fable_modules/fable-library.3.6.1/Array.js";
import { equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { SimpleJson_readPath, SimpleJson_parse } from "../fable_modules/Fable.SimpleJson.3.20.0/SimpleJson.fs.js";
import { toArray, defaultArg, some, map, value as value_11 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B, Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.20.0/Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.20.0/TypeInfo.Converter.fs.js";
import { Result_Map, FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { map as map_1, iterate } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { StreamSubscriber$1 } from "../fable_modules/Fable.SignalR.0.11.4/Types.fs.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { createElement } from "react";

export const hubContext = React_createContext_1AE444D8("SignalR context");

export function useFeliz_React__React_useSignalrConnection_Static_53FFDA8(dependencies) {
    let connection_1;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setIsConnected = patternInput[1];
    return [(connection_1 = useReact_useRef_1505(useReact_useMemo_CF4EA67(() => {
        let protocol, protocol_1;
        const _ = HubConnectionBuilder$5__configureLogging_2D37BB17(HubConnectionBuilder$5__onReconnecting_15DD00C6(HubConnectionBuilder$5__onReconnected_Z2ADBE6BC(HubConnectionBuilder$5__withAutomaticReconnect(HubConnectionBuilder$5__withUrl_Z721C83C5(HubConnectionBuilder$5_$ctor_Z66CB2AA1(new Bindings_signalR.HubConnectionBuilder()), "/SignalR")), (_arg1) => {
            setIsConnected(true);
        }), (_arg2) => {
            setIsConnected(false);
        }), 1);
        return HubConnection$5_$ctor_3ED56BCC(_["hub@10"].withHubProtocol(_.useMsgPack ? ((protocol = MsgPack_MsgPackProtocol_$ctor(), {
            name: "messagepack",
            version: 1,
            transferFormat: 2,
            parseMessages(input, logger) {
                return Array.from((() => {
                    let arg10_1;
                    try {
                        const buffer_1 = input;
                        const reader = Reader_$ctor_6C95DA22(new Uint8Array(buffer_1));
                        const read = (pos_mut, xs_mut) => {
                            read:
                            while (true) {
                                const pos = pos_mut, xs = xs_mut;
                                const matchValue = op_Addition(op_Addition(Reader__Read_24524716(reader, class_type("System.UInt64")), pos), fromBits(1, 0, true));
                                if (compare(op_Subtraction(fromInteger(buffer_1.byteLength, true, 2), matchValue), fromBits(0, 0, true)) > 0) {
                                    pos_mut = matchValue;
                                    xs_mut = cons(MsgPack_parseMsg(Reader__Read_24524716(reader, MsgPack_Msg$4$reflection(unit_type, Response$reflection(), Response$reflection(), StreamFrom_Response$reflection()))), xs);
                                    continue read;
                                }
                                else {
                                    return cons(MsgPack_parseMsg(Reader__Read_24524716(reader, MsgPack_Msg$4$reflection(unit_type, Response$reflection(), Response$reflection(), StreamFrom_Response$reflection()))), xs);
                                }
                                break;
                            }
                        };
                        return reverse(read(fromBits(0, 0, true), empty()));
                    }
                    catch (e) {
                        logger.log(4, (arg10_1 = e.message, toText(printf("An error occured during message deserialization: %s"))(arg10_1)));
                        return empty();
                    }
                })());
            },
            writeMessage(msg_2) {
                let matchValue_1, invocation, matchValue_2, invocation_1, arg10_3, streamItem, completion, streamInvocation, cancelInvocation, close;
                const message = msg_2;
                const outArr = [];
                writeObject((matchValue_1 = (message.type | 0), (matchValue_1 === 1) ? ((invocation = message, (matchValue_2 = invocation.target, (matchValue_2 === "Invoke") ? ((invocation.arguments.length === 2) ? (new MsgPack_Msg$4(1, invocation.headers, invocation.invocationId, invocation.target, invocation.arguments[0], invocation.arguments[1], invocation.streamIds)) : ((invocation_1 = message, new MsgPack_Msg$4(2, invocation_1.headers, invocation_1.invocationId, invocation_1.target, invocation_1.arguments, invocation_1.streamIds)))) : ((matchValue_2 === "Send") ? (new MsgPack_Msg$4(0, invocation.headers, invocation.invocationId, invocation.target, invocation.arguments, invocation.streamIds)) : ((matchValue_2 === "StreamTo") ? (new MsgPack_Msg$4(0, invocation.headers, invocation.invocationId, invocation.target, invocation.arguments, invocation.streamIds)) : ((arg10_3 = invocation.target, toFail(printf("Invalid Invocation Target: %s"))(arg10_3)))))))) : ((matchValue_1 === 2) ? ((streamItem = message, new MsgPack_Msg$4(3, streamItem.headers, streamItem.invocationId, streamItem.item))) : ((matchValue_1 === 3) ? ((completion = message, new MsgPack_Msg$4(4, completion.headers, completion.invocationId, completion.error, completion.result))) : ((matchValue_1 === 4) ? ((streamInvocation = message, new MsgPack_Msg$4(5, streamInvocation.headers, streamInvocation.invocationId, streamInvocation.target, streamInvocation.arguments, streamInvocation.streamIds))) : ((matchValue_1 === 5) ? ((cancelInvocation = message, new MsgPack_Msg$4(6, cancelInvocation.headers, cancelInvocation.invocationId))) : ((matchValue_1 === 6) ? (new MsgPack_Msg$4(7)) : ((matchValue_1 === 7) ? ((close = message, new MsgPack_Msg$4(8, close.error, close.allowReconnect))) : toFail(printf("Invalid message: %A"))(message)))))))), MsgPack_Msg$4$reflection(StreamFrom_Action$reflection(), Action$reflection(), unit_type, unit_type), outArr);
                if (compare(fromInteger(outArr.length, true, 2), fromBits(2147483648, 0, true)) > 0) {
                    throw (new Error("Messages over 2GB are not supported."));
                }
                else {
                    const msgArr = [];
                    writeObject(fromInteger(outArr.length, true, 2), class_type("System.UInt64"), msgArr);
                    addRangeInPlace(outArr, msgArr);
                    return (new Uint8Array(msgArr)).buffer;
                }
            },
        })) : ((protocol_1 = Json_JsonProtocol_$ctor(), {
            name: "json",
            version: 1,
            transferFormat: 1,
            parseMessages(input_1, logger_2) {
                const input_2 = input_1;
                const logger_3 = logger_2;
                return Array.from(((typeof input_2) === "string") ? (equals(input_2, "") ? [] : (() => {
                    let arg10_9;
                    try {
                        return choose((m) => {
                            let msg_4;
                            const parsedRaw = SimpleJson_parse(m);
                            let _arg2_1;
                            const parsedRaw_1 = parsedRaw;
                            const msgType_1 = value_11(map((arg00_4) => Convert_fromJson(arg00_4, createTypeInfo(enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]]))), SimpleJson_readPath(singleton("type"), parsedRaw))) | 0;
                            switch (msgType_1) {
                                case 1: {
                                    let _arg1_1;
                                    try {
                                        _arg1_1 = (new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_InvocationMessage$1$reflection(Response$reflection())))));
                                    }
                                    catch (ex) {
                                        _arg1_1 = (new FSharpResult$2(1, ex.message));
                                    }
                                    _arg2_1 = ((_arg1_1.tag === 1) ? Result_Map((arg_1) => {
                                        let msg_6;
                                        return (msg_6 = arg_1, ((msg_6.target === "") ? (() => {
                                            throw (new Error("Invalid payload for Invocation message."));
                                        })() : (void 0), ((msg_6.invocationId != null) ? ((value_11(msg_6.invocationId) === "") ? (() => {
                                            throw (new Error("Invalid payload for Invocation message."));
                                        })() : (void 0)) : (void 0), msg_6)));
                                    }, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_InvocationMessage$1$reflection(InvokeArg$1$reflection(Response$reflection())))));
                                        }
                                        catch (ex_1) {
                                            return new FSharpResult$2(1, ex_1.message);
                                        }
                                    })()) : (new FSharpResult$2(0, (msg_4 = _arg1_1.fields[0], ((msg_4.target === "") ? (() => {
                                        throw (new Error("Invalid payload for Invocation message."));
                                    })() : (void 0), ((msg_4.invocationId != null) ? ((value_11(msg_4.invocationId) === "") ? (() => {
                                        throw (new Error("Invalid payload for Invocation message."));
                                    })() : (void 0)) : (void 0), msg_4))))));
                                    break;
                                }
                                case 2: {
                                    _arg2_1 = Result_Map((arg_3) => {
                                        let msg_8, matchValue_3, invocationId_1;
                                        return (msg_8 = arg_3, (matchValue_3 = msg_8.invocationId, (matchValue_3 != null) ? ((matchValue_3 !== "") ? ((invocationId_1 = matchValue_3, msg_8)) : (() => {
                                            throw (new Error("Invalid payload for StreamItem message."));
                                        })()) : (() => {
                                            throw (new Error("Invalid payload for StreamItem message."));
                                        })()));
                                    }, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_StreamItemMessage$1$reflection(StreamFrom_Response$reflection()))));
                                        }
                                        catch (ex_2) {
                                            return new FSharpResult$2(1, ex_2.message);
                                        }
                                    })());
                                    break;
                                }
                                case 3: {
                                    _arg2_1 = Result_Map((arg_5) => {
                                        let msg_10, fail, matchValue_4, err;
                                        return (msg_10 = arg_5, (fail = (() => {
                                            throw (new Error("Invalid payload for Completion message."));
                                        }), ((matchValue_4 = [msg_10.result, msg_10.error], (matchValue_4[0] == null) ? ((matchValue_4[1] != null) ? ((err = matchValue_4[1], (err === "") ? fail() : (void 0))) : ((msg_10.invocationId === "") ? fail() : (void 0))) : ((matchValue_4[1] != null) ? fail() : ((msg_10.invocationId === "") ? fail() : (void 0)))), msg_10)));
                                    }, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_CompletionMessage$1$reflection(Response$reflection()))));
                                        }
                                        catch (ex_3) {
                                            return new FSharpResult$2(1, ex_3.message);
                                        }
                                    })());
                                    break;
                                }
                                case 4: {
                                    _arg2_1 = Result_Map((arg_6) => arg_6, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_StreamInvocationMessage$1$reflection(unit_type))));
                                        }
                                        catch (ex_4) {
                                            return new FSharpResult$2(1, ex_4.message);
                                        }
                                    })());
                                    break;
                                }
                                case 5: {
                                    _arg2_1 = Result_Map((arg_7) => arg_7, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_CancelInvocationMessage$reflection())));
                                        }
                                        catch (ex_5) {
                                            return new FSharpResult$2(1, ex_5.message);
                                        }
                                    })());
                                    break;
                                }
                                case 6: {
                                    _arg2_1 = Result_Map((arg_8) => arg_8, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_PingMessage$reflection())));
                                        }
                                        catch (ex_6) {
                                            return new FSharpResult$2(1, ex_6.message);
                                        }
                                    })());
                                    break;
                                }
                                case 7: {
                                    _arg2_1 = Result_Map((arg_9) => arg_9, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_CloseMessage$reflection())));
                                        }
                                        catch (ex_7) {
                                            return new FSharpResult$2(1, ex_7.message);
                                        }
                                    })());
                                    break;
                                }
                                default: {
                                    _arg2_1 = toFail(printf("Invalid message: %A"))(parsedRaw_1);
                                }
                            }
                            if (_arg2_1.tag === 1) {
                                logger_3.log(4, toText(printf("Unknown message type: %s"))(_arg2_1.fields[0]));
                                return void 0;
                            }
                            else {
                                return some(_arg2_1.fields[0]);
                            }
                        }, Json_TextMessageFormat_parse(input_2));
                    }
                    catch (e_2) {
                        logger_3.log(4, (arg10_9 = e_2.message, toText(printf("An error occured during message deserialization: %s"))(arg10_9)));
                        return [];
                    }
                })()) : ((logger_3.log(4, "Invalid input for JSON hub protocol. Expected a string, got an array buffer instead."), [])));
            },
            writeMessage(msg_12) {
                return Json_TextMessageFormat_write(Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(msg_12));
            },
        }))).build(), _.handlers);
    }, defaultArg(dependencies, []))), (useReact_useEffectOnce_Z5ECA432F(() => {
        let objectArg;
        HubConnection$5__startNow(connection_1.current);
        return React_createDisposable_3A5B6456((objectArg = connection_1.current, () => {
            HubConnection$5__stopNow(objectArg);
        }));
    }), useReact_useRef_1505(connection_1.current))), patternInput[0]];
}

export function useFeliz_React__React_useStreamChoose_Static_Z46D7ECF9(hub, subscriptionArgs, onMessage, dependencies) {
    const subscription = useReact_useRef_1505(void 0);
    useReact_useEffect_Z5234A374(() => React_createDisposable_3A5B6456(() => {
        iterate((sub) => {
            sub.Dispose();
        }, toArray(subscription.current));
    }), dependencies);
    useReact_useEffect_Z101E1A95(() => {
        let subscriber;
        startImmediate((subscriber = (new StreamSubscriber$1(onMessage, (err) => {
            console.log(some(err));
        }, () => {
            console.log(some("complete"));
        })), singleton_1.Delay(() => {
            if (subscriptionArgs == null) {
                return singleton_1.Zero();
            }
            else {
                return singleton_1.Bind(hub.current.streamFrom(subscriptionArgs), (_arg1) => {
                    let sub_1;
                    subscription.current = ((sub_1 = (_arg1.subscribe(subscriber)), {
                        Dispose() {
                            sub_1.dispose();
                        },
                    }));
                    return singleton_1.Zero();
                });
            }
        })));
    }, dependencies);
}

export function useFeliz_React__React_useCatalogStream_Static_5365EDEF(onMessage, lotIds, userId, dependencies) {
    let array2;
    const patternInput = useReact_useContext_37FA55CF(hubContext);
    const hub = patternInput[0];
    useFeliz_React__React_useStreamChoose_Static_Z46D7ECF9(hub, (!isEmpty(lotIds)) ? (new StreamFrom_Action(2, new StreamFrom_SubscribeToCatalogEventsArgs(lotIds, userId))) : (void 0), onMessage, (array2 = [join("", map_1((value) => toString(value), lotIds)), hub.current.connectionId], append(defaultArg(dependencies, []), array2)));
    return [hub, patternInput[1]];
}

export function Feliz_React__React_useLiveAuctionStream_Static_Z2812504D(onMessage, userId, lang, dependencies) {
    let array2;
    const patternInput = useReact_useContext_37FA55CF(hubContext);
    const hub = patternInput[0];
    useFeliz_React__React_useStreamChoose_Static_Z46D7ECF9(hub, new StreamFrom_Action(1, new StreamFrom_SubscribeToLiveAuctionArgs(userId, lang)), onMessage, (array2 = [hub.current.connectionId], append(defaultArg(dependencies, []), array2)));
    return [hub, patternInput[1]];
}

export function Feliz_React__React_useLotStream_Static_75765E85(onMessage, lotId, userId, dependencies) {
    let array2;
    const patternInput = useReact_useContext_37FA55CF(hubContext);
    const hub = patternInput[0];
    useFeliz_React__React_useStreamChoose_Static_Z46D7ECF9(hub, new StreamFrom_Action(0, new StreamFrom_SubscribeToLotEventsArgs(lotId, userId)), onMessage, (array2 = [hub.current.connectionId], append(defaultArg(dependencies, []), array2)));
    return [hub, patternInput[1]];
}

export class Context {
    constructor() {
    }
}

export function Context$reflection() {
    return class_type("Components.SignalR.Context", void 0, Context);
}

export function Context_Provider_701EED78(context_Provider_701EED78InputProps) {
    const children = context_Provider_701EED78InputProps.children;
    const patternInput = useFeliz_React__React_useSignalrConnection_Static_53FFDA8();
    return React_contextProvider_138D2F56(hubContext, [patternInput[0], patternInput[1]], children);
}

export function Context_Provider_2B31D457(context_Provider_2B31D457InputProps) {
    const children = context_Provider_2B31D457InputProps.children;
    return createElement(Context_Provider_701EED78, {
        children: [children],
    });
}

