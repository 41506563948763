import { t as t_1 } from "../Localization.js";
import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, unit_type, bool_type, list_type, class_type, record_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { Validator$1__Test, Validator$1__IsValid_Z4CF01147, Validator$1__End_Z5E18B1E2, validateSync } from "../fable_modules/Fable.Validation.0.2.1/Validation.fs.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.6.1/String.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList, exists } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { isLetter, isDigit } from "../fable_modules/fable-library.3.6.1/Char.js";
import { empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Settings_ChangePassword } from "../Shared/Shared.js";
import { settingsApi } from "../Communication.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { value as value_101 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Form_Field_errorsAsString, Form_Field_hasErrors } from "../Common.js";

export function c(key) {
    return t_1("common." + key);
}

export class Form extends Record {
    constructor(CurrentPassword, NewPassword, RepeatNewPassword) {
        super();
        this.CurrentPassword = CurrentPassword;
        this.NewPassword = NewPassword;
        this.RepeatNewPassword = RepeatNewPassword;
    }
}

export function Form$reflection() {
    return record_type("Components.ChangePassword.Form", [], Form, () => [["CurrentPassword", string_type], ["NewPassword", string_type], ["RepeatNewPassword", string_type]]);
}

export class State extends Record {
    constructor(UserId, Form, FormErrors, IsNeedValidation, ChangePasswordOperation) {
        super();
        this.UserId = UserId;
        this.Form = Form;
        this.FormErrors = FormErrors;
        this.IsNeedValidation = IsNeedValidation;
        this.ChangePasswordOperation = ChangePasswordOperation;
    }
}

export function State$reflection() {
    return record_type("Components.ChangePassword.State", [], State, () => [["UserId", class_type("System.Int64")], ["Form", Form$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsNeedValidation", bool_type], ["ChangePasswordOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CurrentPasswordChanged", "NewPasswordChanged", "RepeatNewPasswordChanged", "ChangePassword"];
    }
}

export function Msg$reflection() {
    return union_type("Components.ChangePassword.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Validation_validate(formInfo) {
    return validateSync(true, (t) => (new Form(Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (pass) => (pass !== ""))(c("error.passwordRequired"))(Validator$1__Test(t, "CurrentPassword", formInfo.CurrentPassword))), Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (value) => {
        if (((value === "") ? (formInfo.CurrentPassword === "") : false) ? (formInfo.RepeatNewPassword === "") : false) {
            return true;
        }
        else if (!isNullOrWhiteSpace(value)) {
            if (((value.length >= 8) ? exists((arg00) => isDigit(arg00), value.split("")) : false) ? exists((arg00_1) => isLetter(arg00_1), value.split("")) : false) {
                return value !== formInfo.CurrentPassword;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    })(c("error.password"))(Validator$1__Test(t, "NewPassword", formInfo.NewPassword))), Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (confirmPassword) => {
        if (((confirmPassword === "") ? (formInfo.CurrentPassword === "") : false) ? (formInfo.NewPassword === "") : false) {
            return true;
        }
        else {
            return formInfo.NewPassword === confirmPassword;
        }
    })(c("error.passwordConfirmation"))(Validator$1__Test(t, "RepeatNewPassword", formInfo.RepeatNewPassword))))));
}

export function Validation_validateIf(x, needValidate) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return matchValue.fields[0];
        }
        else {
            return empty();
        }
    }
    else {
        return empty();
    }
}

export function changePassword(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Settings_ChangePassword(state.UserId, state.Form.CurrentPassword, state.Form.NewPassword);
        return singleton.Bind(settingsApi().changePassword(request), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, c("error.password.invalid"))))) : singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))));
    }), (_arg2) => singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(userId, unitVar1) {
    return [new State(userId, new Form("", "", ""), empty(), false, new Deferred$1(0)), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        let newForm_1;
        const inputRecord_1 = state.Form;
        newForm_1 = (new Form(inputRecord_1.CurrentPassword, msg.fields[0], inputRecord_1.RepeatNewPassword));
        return [new State(state.UserId, newForm_1, value_101(Validation_validateIf(newForm_1, state.IsNeedValidation)), state.IsNeedValidation, state.ChangePasswordOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        let newForm_2;
        const inputRecord_2 = state.Form;
        newForm_2 = (new Form(inputRecord_2.CurrentPassword, inputRecord_2.NewPassword, msg.fields[0]));
        return [new State(state.UserId, newForm_2, value_101(Validation_validateIf(newForm_2, state.IsNeedValidation)), state.IsNeedValidation, state.ChangePasswordOperation), Cmd_none()];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.UserId, state.Form, state.FormErrors, state.IsNeedValidation, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else {
            const matchValue = Validation_validate(state.Form);
            if (matchValue.tag === 1) {
                return [new State(state.UserId, state.Form, matchValue.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new State(state.UserId, state.Form, empty(), true, new Deferred$1(1)), Cmd_fromAsync(changePassword(state))];
            }
        }
    }
    else {
        let newForm;
        const inputRecord = state.Form;
        newForm = (new Form(msg.fields[0], inputRecord.NewPassword, inputRecord.RepeatNewPassword));
        return [new State(state.UserId, newForm, value_101(Validation_validateIf(newForm, state.IsNeedValidation)), state.IsNeedValidation, state.ChangePasswordOperation), Cmd_none()];
    }
}

export function p(key) {
    return t_1("profileSettings." + key);
}

export function ChangePasswordComponent(changePasswordComponentInputProps) {
    let elms, props_7, props_3, elms_1, props_15, props_11, elms_2, props_23, props_19, elms_3, elms_4, props_33, props_31;
    const userId = changePasswordComponentInputProps.userId;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => init(userId, void 0), (msg, state) => update(msg, state), [userId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const fieldStyleOnError = ofArray([["borderColor", "#FF0000"], ["boxShadow", "none"]]);
    let serverError;
    const matchValue = state_1.ChangePasswordOperation;
    let pattern_matching_result, error;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            serverError = error;
            break;
        }
        case 1: {
            serverError = "";
            break;
        }
    }
    let saveProfile;
    const matchValue_1 = state_1.ChangePasswordOperation;
    let pattern_matching_result_1;
    if (matchValue_1.tag === 2) {
        if (matchValue_1.fields[0].tag === 0) {
            pattern_matching_result_1 = 0;
        }
        else {
            pattern_matching_result_1 = 1;
        }
    }
    else {
        pattern_matching_result_1 = 1;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            const props = ofArray([["className", "is-success"], ["children", p("change.password.notification")]]);
            saveProfile = createElement("div", createObj(Helpers_combineClasses("notification", props)));
            break;
        }
        case 1: {
            saveProfile = null;
            break;
        }
    }
    return react.createElement(react.Fragment, {}, (elms = singleton_2((props_7 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("CurrentPassword")(state_1.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "password"]), delay(() => {
        let value_11;
        return append(singleton_1((value_11 = state_1.Form.CurrentPassword, ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_11)) : false) {
                e.value = value_11;
            }
        }])), delay(() => append(singleton_1(["onChange", (ev) => {
            dispatch(new Msg(0, ev.target.value));
        }]), delay(() => singleton_1(["placeholder", p("currentPasword.placeholder")])))));
    })))))))))), (props_3 = ofArray([["style", {
        color: "#FF0000",
    }], ["children", Form_Field_errorsAsString("CurrentPassword")(state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_3)))), createElement("span", {
        className: join(" ", ["icon", "is-small", "is-left"]),
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-lock"]),
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_7))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton_2((props_15 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("NewPassword")(state_1.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "password"]), delay(() => {
        let value_34;
        return append(singleton_1((value_34 = state_1.Form.NewPassword, ["ref", (e_1) => {
            if ((!(e_1 == null)) ? (!equals(e_1.value, value_34)) : false) {
                e_1.value = value_34;
            }
        }])), delay(() => append(singleton_1(["onChange", (ev_1) => {
            dispatch(new Msg(1, ev_1.target.value));
        }]), delay(() => singleton_1(["placeholder", p("newPassword.placeholder")])))));
    })))))))))), (props_11 = ofArray([["style", {
        color: "#FF0000",
    }], ["children", Form_Field_errorsAsString("NewPassword")(state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_11)))), createElement("span", {
        className: join(" ", ["icon", "is-small", "is-left"]),
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-lock"]),
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_15))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_2 = singleton_2((props_23 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("RepeatNewPassword")(state_1.FormErrors) ? singleton_1(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "password"]), delay(() => {
        let value_57;
        return append(singleton_1((value_57 = state_1.Form.RepeatNewPassword, ["ref", (e_2) => {
            if ((!(e_2 == null)) ? (!equals(e_2.value, value_57)) : false) {
                e_2.value = value_57;
            }
        }])), delay(() => append(singleton_1(["onChange", (ev_2) => {
            dispatch(new Msg(2, ev_2.target.value));
        }]), delay(() => singleton_1(["placeholder", p("repeatPassword.placeholder")])))));
    })))))))))), (props_19 = ofArray([["style", {
        color: "#FF0000",
    }], ["children", Form_Field_errorsAsString("RepeatNewPassword")(state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_19)))), createElement("span", {
        className: join(" ", ["icon", "is-small", "is-left"]),
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-lock"]),
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_23))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (elms_3 = singleton_2(createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "is-danger"], ["children", serverError]]))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_4 = singleton_2(createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray([saveProfile]),
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    })), (props_33 = ofArray([["className", join(" ", ["is-pulled-right"])], ["children", Interop_reactApi.Children.toArray([(props_31 = toList(delay(() => append(singleton_1(["className", "is-outlined"]), delay(() => append(equals(state_1.ChangePasswordOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", p("button.change.password")]), delay(() => singleton_1(["onClick", (x) => {
        x.preventDefault();
        dispatch(new Msg(3, new AsyncOperationStatus$1(0)));
    }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_33)))));
}

