import { getToken } from "./LocalStorage.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "./fable_modules/Fable.Remoting.Client.7.10.0/Remoting.fs.js";
import { Subscribe_ISubscribeApi$reflection, Orders_IOrdersApi$reflection, Payment_IPaymentApi$reflection, Settings_INonAuthSettingsApi$reflection, Settings_ISettingsApi$reflection, UploadImage_IImagesApi$reflection, HomeInfo_IHomeInfoApi$reflection, Lots_LotsAuthApi$reflection, Lots_LotsApi$reflection, Users_UserApi$reflection, Auth_SignInApi$reflection, Route_builder } from "./Shared/Shared.js";
import { IBiddingAuthorizedApi$reflection, IBiddingApi$reflection } from "./Shared/Bidding.js";

function token() {
    return `Bearer ${getToken()}`;
}

export function authApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: Auth_SignInApi$reflection,
    });
}

export function usersApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: Users_UserApi$reflection,
    });
}

export function lotsApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: Lots_LotsApi$reflection,
    });
}

export function lotsAuthApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Lots_LotsAuthApi$reflection,
    });
}

export function homeApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: HomeInfo_IHomeInfoApi$reflection,
    });
}

export function imagesApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: UploadImage_IImagesApi$reflection,
    });
}

export function settingsApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Settings_ISettingsApi$reflection,
    });
}

export function nonAuthSettingsApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: Settings_INonAuthSettingsApi$reflection,
    });
}

export function biddingApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: IBiddingApi$reflection,
    });
}

export function biddingAuthApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: IBiddingAuthorizedApi$reflection,
    });
}

export function paymentApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Payment_IPaymentApi$reflection,
    });
}

export function ordersApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Orders_IOrdersApi$reflection,
    });
}

export function subscribeToEmailApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: Subscribe_ISubscribeApi$reflection,
    });
}

