import { t } from "../Localization.js";
import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { Settings_ContactPersonInfo, Settings_BankInfo, Settings_Address, Settings_CompanyInfo, Settings_CompanyAccount, Settings_CompanyAccount$reflection } from "../Shared/Shared.js";
import { record_type, bool_type, class_type, list_type, union_type, string_type, unit_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Result_MapError, FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi, usersApi } from "../Communication.js";
import { FSharpMap__get_IsEmpty, empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PhoneModule_ofString, PhoneModule_toString, EmailModule_ofString, EmailModule_toString, CountryCodeModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { CompanyAccount_validateIf } from "../Validation.js";
import { CountryModule_shortCode } from "../bidflow/src/infrastructure/Countries.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { append as append_1, cons, map, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { Img_grayJean } from "../Css.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { interpolate, toText, join } from "../fable_modules/fable-library.3.6.1/String.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { CountriesInput_getSortedList, Form_Field_errorsAsString, Form_Field_hasErrors } from "../Common.js";
import { ChangePasswordComponent } from "./ChangePassword.js";

export function p(key) {
    return t("profileSettings." + key);
}

export function f(key) {
    return t("country." + key);
}

export function c(key) {
    return t("common." + key);
}

export class State extends Record {
    constructor(Form, SaveFormOperation, FormErrors, NeedValidateForm, ResidentCountry) {
        super();
        this.Form = Form;
        this.SaveFormOperation = SaveFormOperation;
        this.FormErrors = FormErrors;
        this.NeedValidateForm = NeedValidateForm;
        this.ResidentCountry = ResidentCountry;
    }
}

export function State$reflection() {
    return record_type("Client.EditCompanyAccount.State", [], State, () => [["Form", Settings_CompanyAccount$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedValidateForm", bool_type], ["ResidentCountry", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadResidentCountry", "EmailChanged", "CompanyNameChanged", "AddressChanged", "ZipCodeChanged", "CityChanged", "CountryChanged", "ContactPersonFirstNameChanged", "ContactPersonLastNameChanged", "ContactPersonPhoneChanged", "ContactPersonEmailChanged", "OrganizationNumberChanged", "VatNumberChanged", "ClearingNumberChanged", "BankNumberChanged", "BankChanged", "IbanCountryChanged", "IbanChanged", "SendNewslettersChanged", "SendEmailOnOverbidChanged", "SaveForm"];
    }
}

export function Msg$reflection() {
    return union_type("Client.EditCompanyAccount.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function loadResidentCountry() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(usersApi().loadResidentCompanySettings(), (_arg1) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.Country)))))), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function saveProfile(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(settingsApi().updateCompanyAccount(form), (_arg2) => singleton.Return(new Msg(20, new AsyncOperationStatus$1(1, Result_MapError((_arg1) => "Account does not exists", _arg2)))))), (_arg3) => singleton.Return(new Msg(20, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg3.message))))));
}

export function init(account, unitVar1) {
    return [new State(account, new Deferred$1(0), empty(), false, new Deferred$1(0)), Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0)))];
}

export function withForm(form, state) {
    return new State(form, state.SaveFormOperation, state.FormErrors, state.NeedValidateForm, state.ResidentCountry);
}

export function withCompanyInfo(x, state) {
    let inputRecord;
    return withForm((inputRecord = state.Form, new Settings_CompanyAccount(inputRecord.UserId, inputRecord.Email, x, inputRecord.BankInfo, inputRecord.Address, inputRecord.ContactPerson, inputRecord.SendNewsletters, inputRecord.SendEmailOnOverbid)), state);
}

export function withContactPerson(x, state) {
    let inputRecord;
    return withForm((inputRecord = state.Form, new Settings_CompanyAccount(inputRecord.UserId, inputRecord.Email, inputRecord.CompanyInfo, inputRecord.BankInfo, inputRecord.Address, x, inputRecord.SendNewsletters, inputRecord.SendEmailOnOverbid)), state);
}

export function withAddress(x, state) {
    let inputRecord;
    return withForm((inputRecord = state.Form, new Settings_CompanyAccount(inputRecord.UserId, inputRecord.Email, inputRecord.CompanyInfo, inputRecord.BankInfo, x, inputRecord.ContactPerson, inputRecord.SendNewsletters, inputRecord.SendEmailOnOverbid)), state);
}

export function withBankInfo(x, state) {
    let inputRecord;
    return withForm((inputRecord = state.Form, new Settings_CompanyAccount(inputRecord.UserId, inputRecord.Email, inputRecord.CompanyInfo, x, inputRecord.Address, inputRecord.ContactPerson, inputRecord.SendNewsletters, inputRecord.SendEmailOnOverbid)), state);
}

export function residentCountry(stateResidentCountry) {
    let pattern_matching_result, result;
    if (stateResidentCountry.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (stateResidentCountry.tag === 2) {
        if (stateResidentCountry.fields[0].tag === 0) {
            pattern_matching_result = 2;
            result = stateResidentCountry.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return CountryCodeModule_empty;
        }
        case 1: {
            return CountryCodeModule_empty;
        }
        case 2: {
            return result;
        }
    }
}

export function validate(state) {
    const patternInput = CompanyAccount_validateIf(residentCountry(state.ResidentCountry), state.NeedValidateForm, state.Form);
    return new State(patternInput[0], state.SaveFormOperation, patternInput[1], state.NeedValidateForm, state.ResidentCountry);
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12, inputRecord_13, inputRecord_14, inputRecord_15, inputRecord_16, inputRecord_17, inputRecord_18;
    if (msg.tag === 1) {
        return [validate(withForm((inputRecord = state.Form, new Settings_CompanyAccount(inputRecord.UserId, msg.fields[0], inputRecord.CompanyInfo, inputRecord.BankInfo, inputRecord.Address, inputRecord.ContactPerson, inputRecord.SendNewsletters, inputRecord.SendEmailOnOverbid)), state)), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [validate(withCompanyInfo((inputRecord_1 = state.Form.CompanyInfo, new Settings_CompanyInfo(msg.fields[0], inputRecord_1.OrganizationNumber, inputRecord_1.VatNumber)), state)), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [validate(withCompanyInfo((inputRecord_2 = state.Form.CompanyInfo, new Settings_CompanyInfo(inputRecord_2.Name, msg.fields[0], inputRecord_2.VatNumber)), state)), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [validate(withCompanyInfo((inputRecord_3 = state.Form.CompanyInfo, new Settings_CompanyInfo(inputRecord_3.Name, inputRecord_3.OrganizationNumber, msg.fields[0])), state)), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [validate(withAddress((inputRecord_4 = state.Form.Address, new Settings_Address(msg.fields[0], inputRecord_4.ZipCode, inputRecord_4.City, inputRecord_4.Country)), state)), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [validate(withAddress((inputRecord_5 = state.Form.Address, new Settings_Address(inputRecord_5.Address, msg.fields[0], inputRecord_5.City, inputRecord_5.Country)), state)), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [validate(withAddress((inputRecord_6 = state.Form.Address, new Settings_Address(inputRecord_6.Address, inputRecord_6.ZipCode, msg.fields[0], inputRecord_6.Country)), state)), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [validate(withAddress((inputRecord_7 = state.Form.Address, new Settings_Address(inputRecord_7.Address, inputRecord_7.ZipCode, inputRecord_7.City, msg.fields[0])), state)), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [validate(withBankInfo((inputRecord_8 = state.Form.BankInfo, new Settings_BankInfo(msg.fields[0], inputRecord_8.BankNumber, inputRecord_8.Bank, inputRecord_8.Comment, inputRecord_8.IbanCountry, inputRecord_8.Iban)), state)), Cmd_none()];
    }
    else if (msg.tag === 14) {
        return [validate(withBankInfo((inputRecord_9 = state.Form.BankInfo, new Settings_BankInfo(inputRecord_9.ClearingNumber, msg.fields[0], inputRecord_9.Bank, inputRecord_9.Comment, inputRecord_9.IbanCountry, inputRecord_9.Iban)), state)), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [validate(withBankInfo((inputRecord_10 = state.Form.BankInfo, new Settings_BankInfo(inputRecord_10.ClearingNumber, inputRecord_10.BankNumber, msg.fields[0], inputRecord_10.Comment, inputRecord_10.IbanCountry, inputRecord_10.Iban)), state)), Cmd_none()];
    }
    else if (msg.tag === 16) {
        return [validate(withBankInfo((inputRecord_11 = state.Form.BankInfo, new Settings_BankInfo(inputRecord_11.ClearingNumber, inputRecord_11.BankNumber, inputRecord_11.Bank, inputRecord_11.Comment, msg.fields[0], inputRecord_11.Iban)), state)), Cmd_none()];
    }
    else if (msg.tag === 17) {
        return [validate(withBankInfo((inputRecord_12 = state.Form.BankInfo, new Settings_BankInfo(inputRecord_12.ClearingNumber, inputRecord_12.BankNumber, inputRecord_12.Bank, inputRecord_12.Comment, inputRecord_12.IbanCountry, msg.fields[0])), state)), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [validate(withContactPerson((inputRecord_13 = state.Form.ContactPerson, new Settings_ContactPersonInfo(msg.fields[0], inputRecord_13.LastName, inputRecord_13.Phone, inputRecord_13.Email)), state)), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [validate(withContactPerson((inputRecord_14 = state.Form.ContactPerson, new Settings_ContactPersonInfo(inputRecord_14.FirstName, msg.fields[0], inputRecord_14.Phone, inputRecord_14.Email)), state)), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [validate(withContactPerson((inputRecord_15 = state.Form.ContactPerson, new Settings_ContactPersonInfo(inputRecord_15.FirstName, inputRecord_15.LastName, msg.fields[0], inputRecord_15.Email)), state)), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [validate(withContactPerson((inputRecord_16 = state.Form.ContactPerson, new Settings_ContactPersonInfo(inputRecord_16.FirstName, inputRecord_16.LastName, inputRecord_16.Phone, msg.fields[0])), state)), Cmd_none()];
    }
    else if (msg.tag === 18) {
        return [validate(withForm((inputRecord_17 = state.Form, new Settings_CompanyAccount(inputRecord_17.UserId, inputRecord_17.Email, inputRecord_17.CompanyInfo, inputRecord_17.BankInfo, inputRecord_17.Address, inputRecord_17.ContactPerson, msg.fields[0], inputRecord_17.SendEmailOnOverbid)), state)), Cmd_none()];
    }
    else if (msg.tag === 19) {
        return [validate(withForm((inputRecord_18 = state.Form, new Settings_CompanyAccount(inputRecord_18.UserId, inputRecord_18.Email, inputRecord_18.CompanyInfo, inputRecord_18.BankInfo, inputRecord_18.Address, inputRecord_18.ContactPerson, inputRecord_18.SendNewsletters, msg.fields[0])), state)), Cmd_none()];
    }
    else if (msg.tag === 20) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.Form, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.NeedValidateForm, state.ResidentCountry), Cmd_none()];
        }
        else {
            const errors = CompanyAccount_validateIf(residentCountry(state.ResidentCountry), true, state.Form)[1];
            if (FSharpMap__get_IsEmpty(errors)) {
                return [new State(state.Form, new Deferred$1(1), errors, true, state.ResidentCountry), Cmd_fromAsync(saveProfile(state.Form))];
            }
            else {
                return [new State(state.Form, new Deferred$1(0), errors, true, state.ResidentCountry), Cmd_none()];
            }
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new State(state.Form, state.SaveFormOperation, state.FormErrors, state.NeedValidateForm, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
        else {
            return [new State(state.Form, state.SaveFormOperation, state.FormErrors, state.NeedValidateForm, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
    }
    else {
        return [new State(state.Form, state.SaveFormOperation, state.FormErrors, state.NeedValidateForm, new Deferred$1(1)), Cmd_fromAsync(loadResidentCountry())];
    }
}

export function CountryCodeToString(c_1) {
    if (c_1 === CountryCodeModule_empty) {
        return "-";
    }
    else {
        return CountryModule_shortCode(c_1);
    }
}

export function ProfileSettings(companyAccount) {
    let props_222, props_218, props_220, s_8, dispatch_1, state_2, elms_4, props_7, s, props_39, elms, props_12, value_29, elms_1, props_20, props_16, elms_2, props_28, props_24, elms_3, props_36, props_32, dispatch_2, state_3, elms_9, props_46, s_1, props_82, elms_5, props_53, props_49, elms_6, props_61, props_57, elms_7, props_69, props_65, elms_8, props_79, props_75, dispatch_4, state_5, props_177, props_130, s_5, elms_20, elms_15, props_138, props_132, props_134, elms_16, props_147, props_141, props_143, elms_17, props_155, props_151, elms_18, props_165, props_161, elms_19, props_173, props_168, props_170, dispatch_3, state_4, props_124, props_89, s_2, elms_14, elms_10, props_96, props_92, elms_11, props_104, props_100, elms_12, props_112, props_108, elms_13, props_120, props_116, props_214, props_211, s_7, elms_27, dispatch_5, state_6, props_194, props_183, s_6, elms_23, elms_21, elms_22, elms_24, elms_25, elms_26, props_204, props_202;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => init(companyAccount, void 0), (msg, state) => update(msg, state), [companyAccount.UserId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isSaving = (state_1.SaveFormOperation.tag === 1) ? true : false;
    let saveError;
    const matchValue_1 = state_1.SaveFormOperation;
    let pattern_matching_result, error;
    if (matchValue_1.tag === 2) {
        if (matchValue_1.fields[0].tag === 1) {
            pattern_matching_result = 0;
            error = matchValue_1.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            saveError = createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", error]]))));
            break;
        }
        case 1: {
            saveError = null;
            break;
        }
    }
    let saveProfile_1;
    const matchValue_2 = state_1.SaveFormOperation;
    let pattern_matching_result_1;
    if (matchValue_2.tag === 2) {
        if (matchValue_2.fields[0].tag === 0) {
            pattern_matching_result_1 = 0;
        }
        else {
            pattern_matching_result_1 = 1;
        }
    }
    else {
        pattern_matching_result_1 = 1;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            const props_2 = ofArray([["className", "is-success"], ["children", p("notification.notification")]]);
            saveProfile_1 = createElement("div", createObj(Helpers_combineClasses("notification", props_2)));
            break;
        }
        case 1: {
            saveProfile_1 = null;
            break;
        }
    }
    const fieldStyleOnError = ofArray([["borderColor", "#FF0000"], ["boxShadow", "none"]]);
    return createElement("div", {
        style: {
            backgroundImage: ("url(\u0027" + Img_grayJean) + "\u0027)",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                padding: 5,
            },
            children: Interop_reactApi.Children.toArray([(props_222 = ofArray([["style", {
                marginTop: 40,
                marginBottom: 30,
                backgroundColor: "#fff",
                color: "#5F5F5F",
                padding: 32,
            }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-user", "is-size-3"]),
                style: {
                    padding: ((15 + "px ") + 17) + "px",
                    borderRadius: 50 + "%",
                    backgroundColor: "#F9F9F9",
                    float: "left",
                    marginRight: 20,
                },
            }), (props_218 = ofArray([["children", `${state_1.Form.CompanyInfo.Name}`], ["style", {
                color: "#5F5F5F",
            }]]), createElement("p", createObj(Helpers_combineClasses("title", props_218)))), (props_220 = ofArray([["children", toText(interpolate("%s%P() %A%P()", [p("customNumber"), state_1.Form.UserId]))], ["style", {
                color: "#5F5F5F",
            }]]), createElement("p", createObj(Helpers_combineClasses("subtitle", props_220))))])]]), createElement("div", createObj(Helpers_combineClasses("container", props_222)))), createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("fieldset", {
                    disabled: isSaving,
                    className: "container",
                    style: {
                        marginBottom: 100,
                        backgroundColor: "#fff",
                        padding: 32,
                    },
                    children: Interop_reactApi.Children.toArray([(s_8 = p("title"), createElement("h3", {
                        className: "title is-3",
                        children: s_8,
                    })), (dispatch_1 = dispatch, (state_2 = state_1, react.createElement(react.Fragment, {}, createElement("hr", {}), (elms_4 = ofArray([(props_7 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s = p("company.info.title"), createElement("h4", {
                        className: "subtitle is-4",
                        children: s,
                    })), createElement("div", {
                        className: "has-text-grey",
                        children: p("company.info.subtitle"),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7)))), (props_39 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_12 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-medium"])], ["type", "text"], ["disabled", true], (value_29 = EmailModule_toString(state_2.Form.Email), ["ref", (e) => {
                        if ((!(e == null)) ? (!equals(e.value, value_29)) : false) {
                            e.value = value_29;
                        }
                    }])]))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-envelope"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_12))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    })), (elms_1 = singleton_1((props_20 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("CompanyName")(state_2.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_45;
                        return append(singleton_2((value_45 = state_2.Form.CompanyInfo.Name, ["ref", (e_1) => {
                            if ((!(e_1 == null)) ? (!equals(e_1.value, value_45)) : false) {
                                e_1.value = value_45;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev) => {
                            dispatch_1(new Msg(2, ev.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("company.name.placeholder")])))));
                    })))))))))), (props_16 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("CompanyName")(state_2.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_16)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-map-marker"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_20))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    })), (elms_2 = singleton_1((props_28 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("OrganizationNumber")(state_2.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_68;
                        return append(singleton_2((value_68 = state_2.Form.CompanyInfo.OrganizationNumber.trim(), ["ref", (e_2) => {
                            if ((!(e_2 == null)) ? (!equals(e_2.value, value_68)) : false) {
                                e_2.value = value_68;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_1) => {
                            dispatch_1(new Msg(11, ev_1.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("organization.number.placeholder")])))));
                    })))))))))), (props_24 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("OrganizationNumber")(state_2.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_24)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-map-marker"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_28))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                    })), (elms_3 = singleton_1((props_36 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("VatNumber")(state_2.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_91;
                        return append(singleton_2((value_91 = state_2.Form.CompanyInfo.VatNumber, ["ref", (e_3) => {
                            if ((!(e_3 == null)) ? (!equals(e_3.value, value_91)) : false) {
                                e_3.value = value_91;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_2) => {
                            dispatch_1(new Msg(12, ev_2.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("vat.number.placeholder")])))));
                    })))))))))), (props_32 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("VatNumber")(state_2.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_32)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-map-marker"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_36))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_39))))]), createElement("div", {
                        className: "columns",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                    }))))), (dispatch_2 = dispatch, (state_3 = state_1, react.createElement(react.Fragment, {}, createElement("hr", {}), (elms_9 = ofArray([(props_46 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s_1 = p("address.address"), createElement("h4", {
                        className: "subtitle is-4",
                        children: s_1,
                    })), createElement("div", {
                        className: "has-text-grey",
                        children: p("address.subtitle"),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_46)))), (props_82 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_1((props_53 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("Address")(state_3.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_130;
                        return append(singleton_2((value_130 = state_3.Form.Address.Address, ["ref", (e_4) => {
                            if ((!(e_4 == null)) ? (!equals(e_4.value, value_130)) : false) {
                                e_4.value = value_130;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_3) => {
                            dispatch_2(new Msg(3, ev_3.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("address.placeholder")])))));
                    })))))))))), (props_49 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("Address")(state_3.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_49)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-map-marker"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_53))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                    })), (elms_6 = singleton_1((props_61 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("ZipCode")(state_3.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_153;
                        return append(singleton_2((value_153 = state_3.Form.Address.ZipCode, ["ref", (e_5) => {
                            if ((!(e_5 == null)) ? (!equals(e_5.value, value_153)) : false) {
                                e_5.value = value_153;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_4) => {
                            dispatch_2(new Msg(4, ev_4.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("postalCode.placeholder")])))));
                    })))))))))), (props_57 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("ZipCode")(state_3.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_57)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-map-marker"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_61))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                    })), (elms_7 = singleton_1((props_69 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("City")(state_3.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_176;
                        return append(singleton_2((value_176 = state_3.Form.Address.City, ["ref", (e_6) => {
                            if ((!(e_6 == null)) ? (!equals(e_6.value, value_176)) : false) {
                                e_6.value = value_176;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_5) => {
                            dispatch_2(new Msg(5, ev_5.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("city.placeholder")])))));
                    })))))))))), (props_65 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("City")(state_3.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_65)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-map-marker"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_69))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                    })), (elms_8 = singleton_1((props_79 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(toList(delay(() => append(Form_Field_hasErrors("Country")(state_3.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["select", "is-fullwidth", "is-medium"])]), delay(() => {
                        let value_206;
                        return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("select", createObj(ofArray([["className", "select-profile"], ["style", {
                            height: 37.5 + "px",
                        }], ["name", "country"], (value_206 = state_3.Form.Address.Country, ["ref", (e_7) => {
                            if ((!(e_7 == null)) ? (!equals(e_7.value, value_206)) : false) {
                                e_7.value = value_206;
                            }
                        }]), ["onChange", (ev_6) => {
                            dispatch_2(new Msg(6, ev_6.target.value));
                        }], ["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => createElement("option", {
                            value: tupledArg[0],
                            children: tupledArg[1],
                        }), CountriesInput_getSortedList())))]])))])]);
                    })))))))), (props_75 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("Country")(state_3.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_75)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-map-marker"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_79))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_82))))]), createElement("div", {
                        className: "columns",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                    }))))), (dispatch_4 = dispatch, (state_5 = state_1, react.createElement(react.Fragment, {}, createElement("hr", {}), (props_177 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_130 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s_5 = p("bank.info.title"), createElement("h4", {
                        className: "subtitle is-4",
                        children: s_5,
                    })), createElement("div", {
                        className: "has-text-grey",
                        children: p("bank.info.subtitle"),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_130)))), (elms_20 = ofArray([(elms_15 = singleton_1((props_138 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props_132 = toList(delay(() => append(singleton_2(["className", join(" ", ["is-medium"])]), delay(() => append(Form_Field_hasErrors("ClearingNumber")(state_5.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["inputMode", "numeric"]), delay(() => {
                        let value_359;
                        return append(singleton_2((value_359 = state_5.Form.BankInfo.ClearingNumber, ["ref", (e_12) => {
                            if ((!(e_12 == null)) ? (!equals(e_12.value, value_359)) : false) {
                                e_12.value = value_359;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_11) => {
                            dispatch_4(new Msg(13, ev_11.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("clearing.number.placeholder")])))));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_132))))), (props_134 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("ClearingNumber")(state_5.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_134)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fas", "fa-university"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_138))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
                    })), (elms_16 = singleton_1((props_147 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props_141 = toList(delay(() => append(singleton_2(["className", join(" ", ["is-medium"])]), delay(() => append(Form_Field_hasErrors("BankNumber")(state_5.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["inputMode", "numeric"]), delay(() => {
                        let value_383;
                        return append(singleton_2((value_383 = state_5.Form.BankInfo.BankNumber, ["ref", (e_13) => {
                            if ((!(e_13 == null)) ? (!equals(e_13.value, value_383)) : false) {
                                e_13.value = value_383;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_12) => {
                            dispatch_4(new Msg(14, ev_12.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("banknumber.placeholder")])))));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_141))))), (props_143 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("BankNumber")(state_5.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_143)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fas", "fa-university"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_147))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
                    })), (elms_17 = singleton_1((props_155 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("Bank")(state_5.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_407;
                        return append(singleton_2((value_407 = state_5.Form.BankInfo.Bank, ["ref", (e_14) => {
                            if ((!(e_14 == null)) ? (!equals(e_14.value, value_407)) : false) {
                                e_14.value = value_407;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_13) => {
                            dispatch_4(new Msg(15, ev_13.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("bank.placeholder")])))));
                    })))))))))), (props_151 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("Bank")(state_5.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_151)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fas", "fa-university"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_155))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
                    })), (elms_18 = singleton_1((props_165 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                        className: join(" ", ["select", "is-fullwidth", "is-medium"]),
                        children: Interop_reactApi.Children.toArray([createElement("select", createObj(toList(delay(() => append(singleton_2(["className", "select-profile"]), delay(() => append(singleton_2(["style", {
                            height: 37.5 + "px",
                        }]), delay(() => append(Form_Field_hasErrors("IbanCountry")(state_5.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["name", "ibanCountry"]), delay(() => {
                            let value_437;
                            return append(singleton_2((value_437 = state_5.Form.BankInfo.IbanCountry, ["ref", (e_15) => {
                                if ((!(e_15 == null)) ? (!equals(e_15.value, value_437)) : false) {
                                    e_15.value = value_437;
                                }
                            }])), delay(() => append(singleton_2(["onChange", (ev_14) => {
                                dispatch_4(new Msg(16, ev_14.target.value));
                            }]), delay(() => {
                                let list2;
                                return singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg_1) => createElement("option", {
                                    value: tupledArg_1[0],
                                    children: tupledArg_1[1],
                                }), (list2 = CountriesInput_getSortedList(), append_1(singleton_1([CountryCodeModule_empty, p("selectCountry")]), list2)))))]);
                            }))));
                        }))))))))))))]),
                    }), (props_161 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("IbanCountry")(state_5.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_161)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fas", "fa-university"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_165))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
                    })), (elms_19 = singleton_1((props_173 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props_168 = toList(delay(() => append(singleton_2(["className", join(" ", ["is-medium"])]), delay(() => append(singleton_2(["inputMode", "numeric"]), delay(() => append(Form_Field_hasErrors("IBAN")(state_5.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => {
                        let value_464;
                        return append(singleton_2((value_464 = state_5.Form.BankInfo.Iban, ["ref", (e_16) => {
                            if ((!(e_16 == null)) ? (!equals(e_16.value, value_464)) : false) {
                                e_16.value = value_464;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_15) => {
                            dispatch_4(new Msg(17, ev_15.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("IBAN.placeholder")])))));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_168))))), (props_170 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("IBAN")(state_5.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_170)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: CountryCodeToString(state_5.Form.BankInfo.IbanCountry),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_173))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
                    }))]), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_177))))))), (dispatch_3 = dispatch, (state_4 = state_1, react.createElement(react.Fragment, {}, createElement("hr", {}), (props_124 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_89 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s_2 = p("personal.info.company.title"), createElement("h4", {
                        className: "subtitle is-4",
                        children: s_2,
                    })), createElement("div", {
                        className: "has-text-grey",
                        children: p("personal.info.company.subtitle"),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_89)))), (elms_14 = ofArray([(elms_10 = singleton_1((props_96 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("ContactPerson.Email")(state_4.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["type", "email"]), delay(() => {
                        let value_250;
                        return append(singleton_2((value_250 = EmailModule_toString(state_4.Form.ContactPerson.Email), ["ref", (e_8) => {
                            if ((!(e_8 == null)) ? (!equals(e_8.value, value_250)) : false) {
                                e_8.value = value_250;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_7) => {
                            dispatch_3(new Msg(10, EmailModule_ofString(ev_7.target.value)));
                        }]), delay(() => singleton_2(["placeholder", p("email.placeholder")])))));
                    })))))))))), (props_92 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("ContactPerson.Email")(state_4.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_92)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fas", "fa-envelope"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_96))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                    })), (elms_11 = singleton_1((props_104 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("FirstName")(state_4.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_273;
                        return append(singleton_2((value_273 = state_4.Form.ContactPerson.FirstName, ["ref", (e_9) => {
                            if ((!(e_9 == null)) ? (!equals(e_9.value, value_273)) : false) {
                                e_9.value = value_273;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_8) => {
                            dispatch_3(new Msg(7, ev_8.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("firstName.placeholder")])))));
                    })))))))))), (props_100 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("FirstName")(state_4.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_100)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-user"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_104))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                    })), (elms_12 = singleton_1((props_112 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(Form_Field_hasErrors("LastName")(state_4.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_296;
                        return append(singleton_2((value_296 = state_4.Form.ContactPerson.LastName, ["ref", (e_10) => {
                            if ((!(e_10 == null)) ? (!equals(e_10.value, value_296)) : false) {
                                e_10.value = value_296;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_9) => {
                            dispatch_3(new Msg(8, ev_9.target.value));
                        }]), delay(() => singleton_2(["placeholder", p("lastName.placeholder")])))));
                    })))))))))), (props_108 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("LastName")(state_4.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_108)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-user"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_112))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                    })), (elms_13 = singleton_1((props_120 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(Form_Field_hasErrors("ContactPerson.Phone")(state_4.FormErrors) ? singleton_2(["style", createObj(fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_2(["type", "text"]), delay(() => {
                        let value_320;
                        return append(singleton_2((value_320 = PhoneModule_toString(state_4.Form.ContactPerson.Phone), ["ref", (e_11) => {
                            if ((!(e_11 == null)) ? (!equals(e_11.value, value_320)) : false) {
                                e_11.value = value_320;
                            }
                        }])), delay(() => append(singleton_2(["onChange", (ev_10) => {
                            dispatch_3(new Msg(9, PhoneModule_ofString(ev_10.target.value)));
                        }]), delay(() => singleton_2(["placeholder", p("phone.placeholder")])))));
                    })))))))))), (props_116 = ofArray([["style", {
                        color: "#FF0000",
                    }], ["children", Form_Field_errorsAsString("ContactPerson.Phone")(state_4.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_116)))), createElement("span", {
                        className: join(" ", ["icon", "is-small", "is-left"]),
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-phone"]),
                        })]),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_120))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
                    }))]), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_124))))))), react.createElement(react.Fragment, {}, createElement("hr", {}), (props_214 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_211 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s_7 = p("change.password.title"), createElement("h4", {
                        className: "subtitle is-4",
                        children: s_7,
                    })), createElement("div", {
                        className: "has-text-grey",
                        children: p("change.password.subtitle"),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_211)))), (elms_27 = singleton_1(createElement(ChangePasswordComponent, {
                        userId: state_1.Form.UserId,
                    })), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_27)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_214))))), (dispatch_5 = dispatch, (state_6 = state_1, react.createElement(react.Fragment, {}, createElement("hr", {}), (props_194 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_183 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s_6 = p("notification.title"), createElement("h4", {
                        className: "subtitle is-4",
                        children: s_6,
                    })), createElement("div", {
                        className: "has-text-grey",
                        children: p("notification.subtitle"),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_183)))), (elms_23 = ofArray([(elms_21 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "overbid"], ["className", "is-success"], ["checked", state_6.Form.SendEmailOnOverbid], ["onChange", (ev_16) => {
                        dispatch_5(new Msg(19, ev_16.target.checked));
                    }]]))))), createElement("label", {
                        htmlFor: "overbid",
                        children: p("outbided"),
                    })]), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
                    })), (elms_22 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "newsletter"], ["className", "is-success"], ["checked", state_6.Form.SendNewsletters], ["onChange", (ev_17) => {
                        dispatch_5(new Msg(18, ev_17.target.checked));
                    }]]))))), createElement("label", {
                        htmlFor: "newsletter",
                        children: p("newsletters"),
                    })]), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
                    }))]), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_194))))))), react.createElement(react.Fragment, {}, createElement("hr", {}), (elms_24 = singleton_1(createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray([saveError]),
                    })), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_24)),
                    })), (elms_25 = singleton_1(createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray([saveProfile_1]),
                    })), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_25)),
                    })), (elms_26 = singleton_1((props_204 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_202 = toList(delay(() => append(isSaving ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["onClick", (e_17) => {
                        e_17.preventDefault();
                        dispatch(new Msg(20, new AsyncOperationStatus$1(0)));
                    }]), delay(() => singleton_2(["children", p("button.saveChanges")]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_202))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_204))))), createElement("div", {
                        className: "block",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_26)),
                    })))]),
                })]),
            })]),
        })]),
    });
}

