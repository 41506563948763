import { t as t_1 } from "./Localization.js";
import { Record, Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { bool_type, list_type, int32_type, class_type, record_type, string_type, union_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { Payment_PaymentLink$reflection } from "./Shared/Shared.js";
import { Validator$1__NotBlank_2B595, Validator$1__IsMail, Validator$1__Test, Validator$1__IsValid_Z4CF01147, Validator$1__End_Z5E18B1E2, validateSync } from "./fable_modules/Fable.Validation.0.2.1/Validation.fs.js";
import { PhoneModule_toString, EmailModule_toString, EmailModule_ofString } from "./bidflow/src/infrastructure/Auction.js";
import { create, match } from "./fable_modules/fable-library.3.6.1/RegExp.js";
import { empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { map, fold, empty as empty_1, ofArray, singleton } from "./fable_modules/fable-library.3.6.1/List.js";
import { value as value_143 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { rgba } from "./fable_modules/Feliz.1.45.0/Colors.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_2, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { interpolate, printf, toText, join } from "./fable_modules/fable-library.3.6.1/String.js";
import { createElement } from "react";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { mediumInline } from "./Components/Loader.js";
import { Form_getFieldError } from "./Common.js";
import { CountryModule_possibleCountries } from "./bidflow/src/infrastructure/Countries.js";
import { Route, toPath } from "./Router.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export function p(key) {
    return t_1("shoppingCart." + key);
}

export function c(key) {
    return t_1("common." + key);
}

export function f(key) {
    return t_1("country." + key);
}

export class DeliveryType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Pickup", "DeliverySuccessAddress", "DeliveryNeedsToEstimate"];
    }
}

export function DeliveryType$reflection() {
    return union_type("Delivery.DeliveryType", [], DeliveryType, () => [[], [], []]);
}

export class Step extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeDelivery", "PaymentDelivery", "Confirmation"];
    }
}

export function Step$reflection() {
    return union_type("Delivery.Step", [], Step, () => [[], [], []]);
}

export class Form extends Record {
    constructor(Name, Email, Phone, Address, ZipCode, City, Country) {
        super();
        this.Name = Name;
        this.Email = Email;
        this.Phone = Phone;
        this.Address = Address;
        this.ZipCode = ZipCode;
        this.City = City;
        this.Country = Country;
    }
}

export function Form$reflection() {
    return record_type("Delivery.Form", [], Form, () => [["Name", string_type], ["Email", string_type], ["Phone", string_type], ["Address", string_type], ["ZipCode", string_type], ["City", string_type], ["Country", string_type]]);
}

export class State extends Record {
    constructor(LinkState, LinkId, Country, UserId, Step, DeliveryChoose, DeliverySum, DeliveryAddress, Form, FormErrors, NeedValidateForm) {
        super();
        this.LinkState = LinkState;
        this.LinkId = LinkId;
        this.Country = Country;
        this.UserId = UserId;
        this.Step = Step;
        this.DeliveryChoose = DeliveryChoose;
        this.DeliverySum = (DeliverySum | 0);
        this.DeliveryAddress = DeliveryAddress;
        this.Form = Form;
        this.FormErrors = FormErrors;
        this.NeedValidateForm = NeedValidateForm;
    }
}

export function State$reflection() {
    return record_type("Delivery.State", [], State, () => [["LinkState", string_type], ["LinkId", string_type], ["Country", string_type], ["UserId", class_type("System.Int64")], ["Step", Step$reflection()], ["DeliveryChoose", DeliveryType$reflection()], ["DeliverySum", int32_type], ["DeliveryAddress", string_type], ["Form", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Form$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Form$reflection()]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedValidateForm", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetLink", "LoadForm", "GoToStep", "ChangeDeliveryChoose", "AddressChanged", "ZipCodeChanged", "CityChanged", "CountryChanged"];
    }
}

export function Msg$reflection() {
    return union_type("Delivery.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Payment_PaymentLink$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Payment_PaymentLink$reflection()]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Form$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Form$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", Step$reflection()]], [["Item", DeliveryType$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function Validation_validate(formInfo) {
    return validateSync(true, (t) => {
        let arg10_1;
        const Email = EmailModule_ofString(Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (email) => {
            if ((((((email.indexOf("ä") >= 0) ? true : (email.indexOf("Ä") >= 0)) ? true : (email.indexOf("ö") >= 0)) ? true : (email.indexOf("Ö") >= 0)) ? true : (email.indexOf("å") >= 0)) ? true : (email.indexOf("Å") >= 0)) {
                return false;
            }
            else {
                return true;
            }
        })(p("error.email"))((arg10_1 = Validator$1__Test(t, "Email", EmailModule_toString(formInfo.Email)), Validator$1__IsMail(t, c("error.email"), arg10_1)))));
        const Name = Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, c("error.firstName"))(Validator$1__Test(t, "Name", formInfo.Name)));
        const Address = Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, c("error.address"))(Validator$1__Test(t, "Address", formInfo.Address)));
        const ZipCode = Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, c("error.postalCode"))(Validator$1__Test(t, "ZipCode", formInfo.ZipCode)));
        const City = Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, c("error.postalAddress"))(Validator$1__Test(t, "City", formInfo.City)));
        const Country = Validator$1__End_Z5E18B1E2(t, Validator$1__Test(t, "Country", formInfo.Country));
        return new Form(Name, Email, Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (phone) => (match(create("(( [+] [(]? [0-9]{1,3} [)]?)|( [(]? [0-9]{4} [)]?))\\s* [)]? [-\\s\\.]? [(]? [0-9]{1,3} [)]?( [-\\s\\.]? [0-9]{3})( [-\\s\\.]? [0-9]{3,4})"), PhoneModule_toString(phone)) != null))(c("error.phone"))(Validator$1__Test(t, "Phone", formInfo.Phone))), Address, ZipCode, City, Country);
    });
}

export function Validation_validateIf(x, needValidate) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return matchValue.fields[0];
        }
        else {
            return empty();
        }
    }
    else {
        return empty();
    }
}

export function init(userId) {
    return [new State("", "", "", userId, new Step(0), new DeliveryType(0), 0, "", new Deferred$1(0), empty(), false), Cmd_batch(singleton(Cmd_OfFunc_result(new Msg(1, userId, new AsyncOperationStatus$1(0)))))];
}

export function update(msg, state) {
    const matchValue = [msg, state.Form];
    let pattern_matching_result, x, ex, step, form, x_1, form_1, x_2, form_2, x_3, form_3, x_4, delivery;
    if (matchValue[0].tag === 1) {
        if (matchValue[0].fields[1].tag === 1) {
            if (matchValue[0].fields[1].fields[0].tag === 1) {
                pattern_matching_result = 2;
                ex = matchValue[0].fields[1].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
                x = matchValue[0].fields[1].fields[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else if (matchValue[0].tag === 2) {
        pattern_matching_result = 3;
        step = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 4) {
        if (matchValue[1].tag === 2) {
            if (matchValue[1].fields[0].tag === 0) {
                pattern_matching_result = 4;
                form = matchValue[1].fields[0].fields[0];
                x_1 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 9;
            }
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[1].tag === 2) {
            if (matchValue[1].fields[0].tag === 0) {
                pattern_matching_result = 5;
                form_1 = matchValue[1].fields[0].fields[0];
                x_2 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 9;
            }
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[1].tag === 2) {
            if (matchValue[1].fields[0].tag === 0) {
                pattern_matching_result = 6;
                form_2 = matchValue[1].fields[0].fields[0];
                x_3 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 9;
            }
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (matchValue[0].tag === 7) {
        if (matchValue[1].tag === 2) {
            if (matchValue[1].fields[0].tag === 0) {
                pattern_matching_result = 7;
                form_3 = matchValue[1].fields[0].fields[0];
                x_4 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 9;
            }
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (matchValue[0].tag === 3) {
        pattern_matching_result = 8;
        delivery = matchValue[0].fields[0];
    }
    else {
        pattern_matching_result = 9;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, new Deferred$1(1), state.FormErrors, state.NeedValidateForm), Cmd_none()];
        }
        case 1: {
            return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, new Deferred$1(2, new FSharpResult$2(0, x)), state.FormErrors, state.NeedValidateForm), Cmd_OfFunc_result(new Msg(5, x.ZipCode))];
        }
        case 2: {
            return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, new Deferred$1(2, new FSharpResult$2(1, ex)), state.FormErrors, state.NeedValidateForm), Cmd_none()];
        }
        case 3: {
            return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, state.Form, state.FormErrors, state.NeedValidateForm), Cmd_none()];
        }
        case 4: {
            const newForm = new Form(form.Name, form.Email, form.Phone, x_1, form.ZipCode, form.City, form.Country);
            return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, new Deferred$1(2, new FSharpResult$2(0, newForm)), value_143(Validation_validateIf(newForm, state.NeedValidateForm)), state.NeedValidateForm), Cmd_none()];
        }
        case 5: {
            const newForm_1 = new Form(form_1.Name, form_1.Email, form_1.Phone, form_1.Address, x_2, form_1.City, form_1.Country);
            const errors_1 = Validation_validateIf(newForm_1, state.NeedValidateForm);
            const matchValue_1 = newForm_1.ZipCode;
            switch (matchValue_1) {
                case "427001": {
                    return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, new Deferred$1(2, new FSharpResult$2(0, newForm_1)), value_143(errors_1), state.NeedValidateForm), Cmd_OfFunc_result(new Msg(3, new DeliveryType(1)))];
                }
                case "427000": {
                    return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, new Deferred$1(2, new FSharpResult$2(0, newForm_1)), value_143(errors_1), state.NeedValidateForm), Cmd_OfFunc_result(new Msg(3, new DeliveryType(1)))];
                }
                default: {
                    return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, new Deferred$1(2, new FSharpResult$2(0, newForm_1)), value_143(errors_1), state.NeedValidateForm), Cmd_OfFunc_result(new Msg(3, new DeliveryType(2)))];
                }
            }
        }
        case 6: {
            const newForm_2 = new Form(form_2.Name, form_2.Email, form_2.Phone, form_2.Address, form_2.ZipCode, x_3, form_2.Country);
            return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, new Deferred$1(2, new FSharpResult$2(0, newForm_2)), value_143(Validation_validateIf(newForm_2, state.NeedValidateForm)), state.NeedValidateForm), Cmd_none()];
        }
        case 7: {
            const newForm_3 = new Form(form_3.Name, form_3.Email, form_3.Phone, form_3.Address, form_3.ZipCode, form_3.City, x_4);
            return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, state.DeliveryChoose, state.DeliverySum, state.DeliveryAddress, new Deferred$1(2, new FSharpResult$2(0, newForm_3)), value_143(Validation_validateIf(newForm_3, state.NeedValidateForm)), state.NeedValidateForm), Cmd_none()];
        }
        case 8: {
            switch (delivery.tag) {
                case 1: {
                    return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, delivery, 200, state.DeliveryAddress, state.Form, state.FormErrors, state.NeedValidateForm), Cmd_none()];
                }
                case 2: {
                    return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, delivery, 0, state.DeliveryAddress, state.Form, state.FormErrors, state.NeedValidateForm), Cmd_none()];
                }
                default: {
                    return [new State(state.LinkState, state.LinkId, state.Country, state.UserId, state.Step, delivery, 0, state.DeliveryAddress, state.Form, state.FormErrors, state.NeedValidateForm), Cmd_none()];
                }
            }
        }
        case 9: {
            return [state, Cmd_none()];
        }
    }
}

export const styleBox = ["style", {
    backgroundColor: "#fff",
    textAlign: "left",
    color: "#444",
    marginBottom: 10,
    boxShadow: (((((((0 + "px ") + 0) + "px ") + 2) + "px ") + 1) + "px ") + rgba(232, 229, 232, 1),
}];

export function Delivery(deliveryInputProps) {
    const dispatch = deliveryInputProps.dispatch;
    const state = deliveryInputProps.state;
    const props_48 = ofArray([styleBox, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_4, props, props_2;
        return append(singleton_1((props_4 = ofArray([["className", "has-text-primary"], ["className", join(" ", ["is-size-5-mobile", "is-size-4"])], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-text-left"], ["className", "has-text-success"], ["style", {
            fontWeight: "bold",
        }], ["className", "is-size-5-mobile"], ["className", "is-size-4"], ["children", "Delivery*"]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = toList(delay(() => append(singleton_1(["className", "has-text-right"]), delay(() => append(singleton_1(["className", "has-text-success"]), delay(() => append(singleton_1(["style", {
            fontWeight: "bold",
        }]), delay(() => append(singleton_1(["className", "is-size-5-mobile"]), delay(() => append(singleton_1(["className", "is-size-4"]), delay(() => {
            const matchValue = state.DeliveryChoose;
            switch (matchValue.tag) {
                case 0: {
                    return singleton_1(["children", "To be estimating"]);
                }
                case 1: {
                    return singleton_1(["children", "200 SEK"]);
                }
                default: {
                    return singleton_1(["children", "To be estimating"]);
                }
            }
        })))))))))))), createElement("div", createObj(Helpers_combineClasses("column", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_4))))), delay(() => {
            let matchValue_1, elms, elms_2, props_17, elms_1, props_8;
            return append((matchValue_1 = state.Form, (matchValue_1.tag === 1) ? singleton_1((elms = singleton(mediumInline("Loading ...")), createElement("section", {
                className: "section",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))) : ((matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? append(singleton_1((elms_2 = singleton((props_17 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const errors = Form_getFieldError(state.FormErrors, "Address");
                return append(singleton_1(createElement("input", createObj(toList(delay(() => append((!equals(errors, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                    let value_46;
                    return append(singleton_1((value_46 = matchValue_1.fields[0].fields[0].Address, ["ref", (e) => {
                        if ((!(e == null)) ? (!equals(e.value, value_46)) : false) {
                            e.value = value_46;
                        }
                    }])), delay(() => append(singleton_1(["onChange", (ev) => {
                        dispatch(new Msg(4, ev.target.value));
                    }]), delay(() => singleton_1(["placeholder", p("address.placeholder")])))));
                }))))))))))), delay(() => {
                    let props_12;
                    return append(singleton_1((props_12 = ofArray([["className", "is-danger"], ["children", fold((s, x) => toText(printf("%s %s"))(s)(x), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_12))))), delay(() => {
                        let props_15;
                        return singleton_1((props_15 = ofArray([["className", join(" ", ["is-small", "is-left"])], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-map-marker"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_15)))));
                    }));
                }));
            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_17))))), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))), delay(() => {
                let elms_3, props_26;
                return append(singleton_1((elms_3 = singleton((props_26 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const errors_1 = Form_getFieldError(state.FormErrors, "ZipCode");
                    return append(singleton_1(createElement("input", createObj(toList(delay(() => append((!equals(errors_1, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                        let value_70;
                        return append(singleton_1((value_70 = matchValue_1.fields[0].fields[0].ZipCode, ["ref", (e_1) => {
                            if ((!(e_1 == null)) ? (!equals(e_1.value, value_70)) : false) {
                                e_1.value = value_70;
                            }
                        }])), delay(() => append(singleton_1(["onChange", (ev_1) => {
                            dispatch(new Msg(5, ev_1.target.value));
                        }]), delay(() => singleton_1(["placeholder", p("postalCode.placeholder")])))));
                    }))))))))))), delay(() => {
                        let props_21;
                        return append(singleton_1((props_21 = ofArray([["className", "is-danger"], ["children", fold((s_1, x_1) => toText(printf("%s %s"))(s_1)(x_1), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_21))))), delay(() => {
                            let props_24;
                            return singleton_1((props_24 = ofArray([["className", join(" ", ["is-small", "is-left"])], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                                className: join(" ", ["fa", "fa-map-marker"]),
                            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_24)))));
                        }));
                    }));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_26))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))), delay(() => {
                    let elms_4, props_34;
                    return append(singleton_1((elms_4 = singleton((props_34 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        const errors_2 = Form_getFieldError(state.FormErrors, "CityAddress");
                        return append(singleton_1(createElement("input", createObj(toList(delay(() => append((!equals(errors_2, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                            let value_94;
                            return append(singleton_1((value_94 = matchValue_1.fields[0].fields[0].City, ["ref", (e_2) => {
                                if ((!(e_2 == null)) ? (!equals(e_2.value, value_94)) : false) {
                                    e_2.value = value_94;
                                }
                            }])), delay(() => append(singleton_1(["onChange", (ev_2) => {
                                dispatch(new Msg(6, ev_2.target.value));
                            }]), delay(() => singleton_1(["placeholder", p("postalAddress.placeholder")])))));
                        }))))))))))), delay(() => {
                            let props_30;
                            return append(singleton_1((props_30 = ofArray([["className", "is-danger"], ["children", fold((s_2, x_2) => toText(printf("%s %s"))(s_2)(x_2), "", errors_2)]]), createElement("p", createObj(Helpers_combineClasses("help", props_30))))), delay(() => singleton_1(createElement("span", {
                                className: join(" ", ["icon", "is-small", "is-left"]),
                                children: Interop_reactApi.Children.toArray([createElement("i", {
                                    className: join(" ", ["fa", "fa-map-marker"]),
                                })]),
                            }))));
                        }));
                    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_34))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                    }))), delay(() => {
                        let elms_5, props_44;
                        return singleton_1((elms_5 = singleton((props_44 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            const errors_3 = Form_getFieldError(state.FormErrors, "CountryId");
                            return append(singleton_1(createElement("span", createObj(toList(delay(() => append((!equals(errors_3, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["className", join(" ", ["select", "is-fullwidth", "is-medium"])]), delay(() => {
                                let value_119;
                                return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("select", createObj(ofArray([["name", "country"], (value_119 = matchValue_1.fields[0].fields[0].Country, ["ref", (e_3) => {
                                    if ((!(e_3 == null)) ? (!equals(e_3.value, value_119)) : false) {
                                        e_3.value = value_119;
                                    }
                                }]), ["onChange", (ev_3) => {
                                    dispatch(new Msg(7, ev_3.target.value));
                                }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x_3) => createElement("option", {
                                    value: x_3.Code,
                                    children: f(toText(interpolate("%A%P()", [x_3.NameSwedish]))),
                                }), CountryModule_possibleCountries)))]])))])]);
                            }))))))))), delay(() => {
                                let props_40;
                                return append(singleton_1((props_40 = ofArray([["className", "is-danger"], ["children", fold((s_3, x_4) => toText(printf("%s %s"))(s_3)(x_4), "", errors_3)]]), createElement("p", createObj(Helpers_combineClasses("help", props_40))))), delay(() => singleton_1(createElement("span", {
                                    className: join(" ", ["icon", "is-small", "is-left"]),
                                    children: Interop_reactApi.Children.toArray([createElement("i", {
                                        className: join(" ", ["fa", "fa-map-marker"]),
                                    })]),
                                }))));
                            }));
                        }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_44))))), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                        })));
                    }));
                }));
            })) : singleton_1((elms_1 = singleton((props_8 = ofArray([["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("h1", {
                children: [matchValue_1.fields[0].fields[0]],
            })])]]), createElement("div", createObj(Helpers_combineClasses("notification", props_8))))), createElement("section", {
                className: "section",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            })))) : singleton_1((elms = singleton(mediumInline("Loading ...")), createElement("section", {
                className: "section",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))))), delay(() => (equals(state.DeliveryChoose, new DeliveryType(2)) ? singleton_1(createElement("p", {
                children: ["We need more time to estimate the delivery to your address. Please create the order and our manager will send the price and further instructions when it is ready. You don\u0027t have to pay for anything now."],
            })) : empty_2())));
        }));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("column", props_48)));
}

export function ShoppingCard(shoppingCardInputProps) {
    let props_16, props_14, props_12, props_10, elms_2, elms, props, elms_1, props_3, props_8;
    const dispatch = shoppingCardInputProps.dispatch;
    const state = shoppingCardInputProps.state;
    return createElement("div", {
        style: {
            padding: ((((((50 + "px ") + 0) + "px ") + 100) + "px ") + 0) + "px",
        },
        children: Interop_reactApi.Children.toArray([(props_16 = singleton(["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-8"], ["children", Interop_reactApi.Children.toArray([createElement(Delivery, {
            state: state,
            dispatch: dispatch,
        }), (props_10 = ofArray([["className", "is-6"], ["className", "is-offset-3"], ["className", "my-5"], ["className", "py-5"], ["children", Interop_reactApi.Children.toArray([(elms_2 = ofArray([(elms = singleton((props = ofArray([["style", {
            width: 100 + "%",
        }], ["className", "is-dark"], ["className", "is-outlined"], ["children", "Cancel"], ["href", toPath(new Route(17))]]), createElement("a", createObj(Helpers_combineClasses("button", props))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton((props_3 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["style", {
            width: 100 + "%",
        }]), delay(() => {
            const matchValue = state.Step;
            switch (matchValue.tag) {
                case 1: {
                    return append(singleton_1(["children", p("button.click.to.pay")]), delay(() => singleton_1(["onClick", (_arg2) => {
                        dispatch(new Msg(2, new Step(2)));
                        window.scroll(0, 0);
                    }])));
                }
                case 2: {
                    return singleton_1(["children", ""]);
                }
                default: {
                    return append(singleton_1(["children", "Proceed to checkout"]), delay(() => singleton_1(["onClick", (_arg1) => {
                        dispatch(new Msg(2, new Step(2)));
                        window.scroll(0, 0);
                    }])));
                }
            }
        })))))), createElement("a", createObj(Helpers_combineClasses("button", props_3))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (props_8 = ofArray([["className", "is-6"], ["className", "is-offset-3"], ["className", "py-0"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([createElement("div", {})])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_14))))])]), createElement("div", createObj(Helpers_combineClasses("container", props_16))))]),
    });
}

export const confirmation = createElement("div", {
    style: {
        padding: ((((((100 + "px ") + 0) + "px ") + 100) + "px ") + 0) + "px",
    },
    children: Interop_reactApi.Children.toArray([(() => {
        let props_11, props_9, props_6;
        const props_13 = singleton(["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-8"], styleBox, ["children", Interop_reactApi.Children.toArray([createElement("p", {
            className: "py-5",
            className: join(" ", ["is-size-5-mobile", "is-size-3"]),
            style: {
                textAlign: "center",
            },
            className: "has-text-success",
            children: "Confirmation*",
        }), createElement("div", {
            className: "py-5",
            style: {
                textAlign: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Thank you for your order - "],
            }), createElement("strong", {
                className: "is-size-4",
                children: "13584",
            }), createElement("span", {
                className: "is-size-4",
                children: " *",
            })]),
        }), createElement("p", {
            className: "pb-5",
            style: {
                textAlign: "center",
            },
            children: "You can see the status in order history. You will get the receipt via email*",
        }), createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-primary"], ["className", "mb-5"], ["style", {
                width: 300,
            }], ["children", "Order history*"], ["href", toPath(new Route(17))]]), createElement("a", createObj(Helpers_combineClasses("button", props_6))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_11))))])]);
        return createElement("div", createObj(Helpers_combineClasses("container", props_13)));
    })()]),
});

export function render(state, dispatch) {
    return createElement("div", {
        className: join(" ", ["background-container"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Step;
            switch (matchValue.tag) {
                case 1: {
                    return singleton_1(createElement(ShoppingCard, {
                        state: state,
                        dispatch: dispatch,
                    }));
                }
                case 2: {
                    return singleton_1(confirmation);
                }
                default: {
                    return singleton_1(createElement(ShoppingCard, {
                        state: state,
                        dispatch: dispatch,
                    }));
                }
            }
        })))),
    });
}

export function RenderDelivery(renderDeliveryInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderDeliveryInputProps.userId), (msg, state) => update(msg, state), []);
    return render(patternInput[0], patternInput[1]);
}

