import { t } from "../Localization.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "../Communication.js";
import { mediumInline } from "../Components/Loader.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { StatusAuction } from "../Shared/Shared.js";
import { Timer } from "../Home.js";
import { now, op_Subtraction } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { totalSeconds } from "../fable_modules/fable-library.3.6.1/TimeSpan.js";

export function p(key) {
    return t("auctionClosed." + key);
}

export function lAF(key) {
    return t("lotDetails." + key);
}

export function h(key) {
    return t("home." + key);
}

export function Page(pageInputProps) {
    const isConnected = pageInputProps.isConnected;
    const lastAuction = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getNextOrLastFinishedAuction(), [isConnected]);
    switch (lastAuction.tag) {
        case 1: {
            return mediumInline(h("loading"));
        }
        case 3: {
            const value = lastAuction.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const lastAuction_1 = lastAuction.fields[0];
            return createElement("div", {
                className: join(" ", ["home-box"]),
                style: {
                    width: 100 + "%",
                    minHeight: 600,
                    padding: ((((((50 + "px ") + 50) + "px ") + 120) + "px ") + 50) + "px",
                    textAlign: "center",
                    backgroundSize: "cover",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_5, props_2, s, value_33;
                    return (!isConnected) ? singleton((props_5 = ofArray([["className", "mt-3"], ["className", "is-centered"], ["className", "has-text-centered"], ["className", "has-text-white"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "pr-3"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-unlink"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_2)))), (s = lAF("noConnection"), createElement("span", {
                        className: "",
                        children: s,
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_5))))) : singleton(createElement("p", {
                        style: {
                            fontSize: 50 + "px",
                            color: "#FFFFFF",
                            fontWeight: "bold",
                        },
                        children: Interop_reactApi.Children.toArray([(value_33 = p("title.2"), createElement("p", {
                            children: [value_33],
                        })), createElement("div", {
                            style: {
                                marginTop: 20,
                            },
                            className: "live_auction_not_started",
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let copyOfStruct;
                                return equals(lastAuction_1.Status, new StatusAuction(1)) ? singleton(createElement(Timer, {
                                    time: ~(~((copyOfStruct = op_Subtraction(lastAuction_1.Date, now()), totalSeconds(copyOfStruct)))),
                                })) : singleton(null);
                            })))),
                        })]),
                    }));
                })))),
            });
        }
        default: {
            return null;
        }
    }
}

