import { update, init, State__Elapsed } from "./Timer.js";
import { Localized_formatDate, t } from "../Localization.js";
import { minutes, hours as hours_1, days as days_1, totalSeconds } from "../fable_modules/fable-library.3.6.1/TimeSpan.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { now } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export function elapsed(state) {
    const elapsed_1 = State__Elapsed(state);
    const days = t("timeInfo.days");
    const hours = t("timeInfo.hours");
    const min = t("timeInfo.minutes");
    const longMin = t("timeInfo.longMinutes");
    if (totalSeconds(elapsed_1) < 60) {
        return `<1 ${longMin}`;
    }
    else {
        const patternInput = [days_1(elapsed_1), hours_1(elapsed_1), minutes(elapsed_1)];
        const m = patternInput[2] | 0;
        const h = patternInput[1] | 0;
        const d = patternInput[0] | 0;
        if (d > 0) {
            return `${d}${days} ${h}${hours}`;
        }
        else if (h > 0) {
            return `${h}${hours} ${m}${min}`;
        }
        else {
            return `${m} ${longMin}`;
        }
    }
}

export function TimerInCatalog(timerInCatalogInputProps) {
    let props_1;
    const dependencies = timerInCatalogInputProps.dependencies;
    const dateUntil = timerInCatalogInputProps.dateUntil;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(defaultArg(dateUntil, now()), 1000), (msg, state) => update(msg, state), dependencies);
    const text = (dateUntil == null) ? "" : elapsed(patternInput[0]);
    return createElement("span", {
        className: join(" ", ["thumbnail-number-item"]),
        children: Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "clockInCatalog"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["far", "fa-clock"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_1)))), createElement("span", {
            className: "",
            children: text,
        })]),
    });
}

export function TimerInLotDetails(timerInLotDetailsInputProps) {
    let elms, props_2, props_15, props_13, props_11;
    const time = timerInLotDetailsInputProps.time;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(time, 1000), (msg, state) => update(msg, state), [time]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const isVisible = patternInput_1[0];
    let timeToShow;
    const elapsed_1 = State__Elapsed(patternInput[0]);
    const days = t("timeInfo.days");
    const hours = t("timeInfo.hours");
    const min = t("timeInfo.minutes");
    const longMin = t("timeInfo.longMinutes");
    let timeInNumbers;
    if (totalSeconds(elapsed_1) < 60) {
        timeInNumbers = (`<1 ${longMin}`);
    }
    else {
        const patternInput_2 = [days_1(elapsed_1), hours_1(elapsed_1), minutes(elapsed_1)];
        const m = patternInput_2[2] | 0;
        const h = patternInput_2[1] | 0;
        const d = patternInput_2[0] | 0;
        timeInNumbers = ((d > 0) ? (`${d}${days} ${h}${hours} ${m}${min}`) : ((h > 0) ? (`${h}${hours} ${m}${min}`) : (`${m} ${longMin}`)));
    }
    const props_4 = ofArray([["className", "lot-details-delivery-options"], ["style", {
        paddingTop: 0,
    }], ["children", Interop_reactApi.Children.toArray([(elms = singleton(createElement("i", {
        className: join(" ", ["far", "fa-clock", "lot-details-icon"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_2 = ofArray([["className", join(" ", ["timerLotDetails"])], ["style", {
        textTransform: "none",
    }], ["className", "has-text-centered"], ["children", timeInNumbers]]), createElement("span", createObj(Helpers_combineClasses("", props_2))))])]]);
    timeToShow = createElement("div", createObj(Helpers_combineClasses("column", props_4)));
    const timeBoxDetails = createElement("div", {
        style: {
            padding: ((((((5 + "px ") + 15) + "px ") + 15) + "px ") + 15) + "px",
            margin: 20,
            textAlign: "left",
            backgroundColor: "#ececec",
            borderRadius: 5,
            textTransform: "none",
            fontSize: 14 + "px",
            fontWeight: 400,
            whiteSpace: "pre-line",
        },
        children: Interop_reactApi.Children.toArray([createElement("p", {
            children: t("timeInfo.title"),
        }), createElement("p", {
            style: {
                fontWeight: 600,
            },
            children: Localized_formatDate("EEEE, dd MMM yyyy HH:mm OOOO", "EEEE, dd MMM yyyy HH:mm OOOO", time),
        }), createElement("p", {
            children: t("timeInfo.subtitle"),
        })]),
    });
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", ["card-lot-shipping"]),
            children: Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-mobile"], ["style", {
                marginTop: 3,
            }], ["className", "collapse-height-column"], ["className", "mb-0"], ["children", Interop_reactApi.Children.toArray([timeToShow, (props_13 = ofArray([["className", "lot-details-delivery-options"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                style: {
                    fontSize: 12 + "px",
                    color: "#9E9E9E",
                    marginRight: 10,
                },
                className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => (isVisible ? singleton_1("fa-chevron-up") : singleton_1("fa-chevron-down"))))))),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13))))])], ["onClick", (x) => {
                x.preventDefault();
                patternInput_1[1](!isVisible);
            }]]), createElement("div", createObj(Helpers_combineClasses("columns", props_15)))), createElement("div", {
                className: join(" ", ["collapse-ex"]),
                style: createObj(toList(delay(() => (isVisible ? append(singleton_1(["overflow", "visible"]), delay(() => append(singleton_1(["height", ~(~document.getElementById("collapse-mobile-timer").clientHeight)]), delay(() => singleton_1(["overflow", "hidden"]))))) : empty())))),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    id: "collapse-mobile-timer",
                    className: join(" ", ["growUp"]),
                    style: {
                        backgroundColor: "#F1F1F1",
                    },
                    children: Interop_reactApi.Children.toArray([timeBoxDetails]),
                })]),
            })]),
        })]),
    });
}

