import { Handlers, Handlers_get_empty } from "./HubConnection.fs.js";
import { class_type } from "../fable-library.3.6.1/Reflection.js";
import { ConnectionBuilder_$ctor, ConnectionBuilder__build } from "./HttpClient.fs.js";

export class HubConnectionBuilder$5 {
    constructor(hub) {
        this["hub@10"] = hub;
        this.handlers = Handlers_get_empty();
        this.useMsgPack = false;
    }
}

export function HubConnectionBuilder$5$reflection(gen0, gen1, gen2, gen3, gen4) {
    return class_type("Fable.SignalR.HubConnectionBuilder`5", [gen0, gen1, gen2, gen3, gen4], HubConnectionBuilder$5);
}

export function HubConnectionBuilder$5_$ctor_Z66CB2AA1(hub) {
    return new HubConnectionBuilder$5(hub);
}

export function HubConnectionBuilder$5__configureLogging_2D37BB17(this$, logLevel) {
    this$["hub@10"] = this$["hub@10"].configureLogging(logLevel);
    return this$;
}

export function HubConnectionBuilder$5__configureLogging_303398F8(this$, logger) {
    this$["hub@10"] = this$["hub@10"].configureLogging(logger);
    return this$;
}

export function HubConnectionBuilder$5__configureLogging_Z721C83C5(this$, logLevel) {
    this$["hub@10"] = this$["hub@10"].configureLogging(logLevel);
    return this$;
}

export function HubConnectionBuilder$5__onClose_15DD00C6(this$, callback) {
    let inputRecord;
    this$.handlers = ((inputRecord = this$.handlers, new Handlers(callback, inputRecord.onMessage, inputRecord.onReconnected, inputRecord.onReconnecting)));
    return this$;
}

export function HubConnectionBuilder$5__onMessage_2163CAFC(this$, callback) {
    let inputRecord;
    this$.handlers = ((inputRecord = this$.handlers, new Handlers(inputRecord.onClose, (arg10) => {
        callback(arg10);
    }, inputRecord.onReconnected, inputRecord.onReconnecting)));
    return this$;
}

export function HubConnectionBuilder$5__onReconnected_Z2ADBE6BC(this$, callback) {
    let inputRecord;
    this$.handlers = ((inputRecord = this$.handlers, new Handlers(inputRecord.onClose, inputRecord.onMessage, callback, inputRecord.onReconnecting)));
    return this$;
}

export function HubConnectionBuilder$5__onReconnecting_15DD00C6(this$, callback) {
    let inputRecord;
    this$.handlers = ((inputRecord = this$.handlers, new Handlers(inputRecord.onClose, inputRecord.onMessage, inputRecord.onReconnected, callback)));
    return this$;
}

export function HubConnectionBuilder$5__useMessagePack(this$) {
    this$.useMsgPack = true;
    return this$;
}

export function HubConnectionBuilder$5__withUrl_Z721C83C5(this$, url) {
    this$["hub@10"] = this$["hub@10"].withUrl(url);
    return this$;
}

export function HubConnectionBuilder$5__withUrl_Z2327FFDB(this$, url, transportType) {
    this$["hub@10"] = this$["hub@10"].withUrl(url, transportType);
    return this$;
}

export function HubConnectionBuilder$5__withUrl_Z6B680FF3(this$, url, options) {
    this$["hub@10"] = this$["hub@10"].withUrl(url, ConnectionBuilder__build(options(ConnectionBuilder_$ctor())));
    return this$;
}

export function HubConnectionBuilder$5__withHubProtocol_Z68E7A1BE(this$, protocol) {
    this$["hub@10"] = this$["hub@10"].withHubProtocol(protocol);
    return this$;
}

export function HubConnectionBuilder$5__withAutomaticReconnect(this$) {
    this$["hub@10"] = this$["hub@10"].withAutomaticReconnect();
    return this$;
}

export function HubConnectionBuilder$5__withAutomaticReconnect_Z3DDD5B6A(this$, retryDelays) {
    this$["hub@10"] = this$["hub@10"].withAutomaticReconnect(Array.from(retryDelays));
    return this$;
}

export function HubConnectionBuilder$5__withAutomaticReconnect_3C7EFEAD(this$, reconnectPolicy) {
    this$["hub@10"] = this$["hub@10"].withAutomaticReconnect(reconnectPolicy);
    return this$;
}

