import * as overbid from "../../public/sound/overbid.mp3";
import * as finishbidding from "../../public/sound/finishbidding.mp3";
import { isNullOrEmpty } from "./fable_modules/fable-library.3.6.1/String.js";
import { ImageUrlModule_toString, ImageUrlModule_empty, ImageUrlModule_fromString } from "./bidflow/src/infrastructure/Auction.js";
import { map, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import * as logo from "../../public/img/logo.svg";
import * as Bidflowlogo_2$002E0_white from "../../public/img/Bidflowlogo_2.0_white.svg";
import * as flagBritain from "../../public/img/flagBritain.svg";
import * as flagSweden from "../../public/img/flagSweden.svg";
import * as flagFinland from "../../public/img/flagFinland.svg";
import * as headerBackground from "../../public/img/headerBackground.png";
import * as start_2020 from "../../public/img/start_2020.jpg";
import * as start_2022 from "../../public/img/start_2022.jpg";
import * as nastakval2 from "../../public/img/nastakval2.jpg";
import * as nyckelfardigt from "../../public/img/nyckelfardigt.jpg";
import * as DIBS_shop from "../../public/img/DIBS_shop.png";
import * as border_footer from "../../public/img/border_footer.svg";
import * as greyBackground from "../../public/img/greyBackground.jpg";
import * as borderBottomGreyBackground from "../../public/img/borderBottomGreyBackground.svg";
import * as greenBackgroundBuy from "../../public/img/greenBackgroundBuy.jpg";
import * as photo$0020placeholder from "../../public/img/photo placeholder.png";
import * as contactBackground from "../../public/img/contactBackground.jpg";
import * as gray_jean from "../../public/img/gray_jean.png";
import * as tappe from "../../public/img/tappe.jpg";
import * as kristoffer from "../../public/img/kristoffer.jpg";
import * as simone from "../../public/img/simone.jpg";
import * as alexander from "../../public/img/alexander.jpg";
import * as rasmus from "../../public/img/rasmus.jpg";
import * as gun from "../../public/img/gun.jpg";
import * as Fredrik from "../../public/img/Fredrik.jpg";
import * as Jonathan from "../../public/img/Jonathan.jpg";
import * as Mikaela from "../../public/img/Mikaela.jpg";
import * as Nichlas from "../../public/img/Nichlas.jpg";
import * as Niclas from "../../public/img/Niclas.jpg";
import * as Raimo from "../../public/img/Raimo.jpg";
import * as Terese from "../../public/img/Terese.jpg";
import * as Tony from "../../public/img/Tony.jpg";
import * as FredrikK from "../../public/img/FredrikK.jpg";
import * as marie from "../../public/img/marie.jpg";
import * as onlinebg from "../../public/img/onlinebg.jpg";
import * as video_thumbnail from "../../public/img/video_thumbnail.svg";
import * as award1 from "../../public/img/award1.png";
import * as award2 from "../../public/img/award2.png";
import * as award3 from "../../public/img/award3.png";
import * as Anders from "../../public/sajab/Anders.png";
import * as K_G from "../../public/sajab/K-G.png";
import * as Linda from "../../public/sajab/Linda.png";
import * as manne from "../../public/sajab/manne.jpg";
import * as marten from "../../public/sajab/marten.jpg";
import * as Rolf$0020Johansson from "../../public/sajab/Rolf Johansson.jpg";
import * as stig from "../../public/sajab/stig.jpg";
import * as micke from "../../public/sajab/micke.png";
import * as jesper from "../../public/sajab/jesper.png";
import * as joakim from "../../public/sajab/joakim.png";
import * as agriculture_back1 from "../../public/sajab/agriculture_back1.jpg";
import * as agriculture_back2 from "../../public/sajab/agriculture_back2.jpg";
import * as home_background1 from "../../public/sajab/home_background1.jpg";
import * as background_aboutUs from "../../public/sajab/background_aboutUs.jpg";
import * as multiseller from "../../public/sajab/multiseller.png";
import * as home_11 from "../../public/helander/home_11.jpg";
import * as buy$0020copy from "../../public/helander/buy copy.jpg";
import * as home_3 from "../../public/helander/home_3.png";
import * as home_4 from "../../public/helander/home_4.jpg";
import * as Intedentit1 from "../../public/helander/Intedentit1.jpg";
import * as buy1 from "../../public/helander/buy1.jpg";
import * as buy from "../../public/helander/buy.jpg";
import * as buy2 from "../../public/helander/buy2.jpg";
import * as sell1 from "../../public/helander/sell1.jpg";
import * as sell2 from "../../public/helander/sell2.jpg";
import * as sell3 from "../../public/helander/sell3.jpg";
import * as sell4 from "../../public/helander/sell4.jpg";
import * as sell from "../../public/helander/sell.jpg";
import * as helanderFAQ from "../../public/helander/helanderFAQ.jpg";
import * as faq from "../../public/helander/faq.jpg";
import * as contacts from "../../public/helander/contacts.jpg";
import * as contact from "../../public/helander/contact.jpg";
import * as conditionGoods from "../../public/helander/conditionGoods.jpg";
import * as HanneM from "../../public/helander/HanneM.jpg";
import * as AkiS from "../../public/helander/AkiS.jpg";
import * as MikaS from "../../public/helander/MikaS.jpg";
import * as PetriP from "../../public/helander/PetriP.jpg";
import * as ArmandsK from "../../public/helander/ArmandsK.jpg";
import * as BorjeB from "../../public/helander/BorjeB.jpg";
import * as ErkkiL from "../../public/helander/ErkkiL.jpg";
import * as AavaA from "../../public/helander/AavaA.jpg";
import * as ReaR from "../../public/helander/ReaR.jpg";
import * as Kerttu from "../../public/helander/Kerttu.jpg";
import * as Migi from "../../public/helander/Migi.jpg";
import * as MerjaK from "../../public/helander/MerjaK.jpg";
import * as Sanna from "../../public/helander/Sanna.jpg";
import * as subscribeHelander from "../../public/helander/subscribeHelander.jpg";
import * as icon1 from "../../public/skane/icon1.png";
import * as icon2 from "../../public/skane/icon2.png";
import * as icon3 from "../../public/skane/icon3.png";
import * as icon4 from "../../public/skane/icon4.png";
import * as gold from "../../public/skane/gold.jpg";
import * as lund from "../../public/skane/lund.jpg";
import * as sell_1 from "../../public/skane/sell.jpg";
import * as sellvisit from "../../public/skane/sellvisit.jpg";
import * as contact_1 from "../../public/skane/contact.jpg";
import * as Adam from "../../public/skane/Adam.jpg";
import * as AnnaRodman from "../../public/skane/AnnaRodman.jpeg";
import * as AgnetaEnroth from "../../public/skane/AgnetaEnroth.jpeg";
import * as Andreas from "../../public/skane/Andreas.jpg";
import * as Dahlman from "../../public/skane/Dahlman.jpg";
import * as HakanFingal from "../../public/skane/HakanFingal.jpg";
import * as HakanOhlson from "../../public/skane/HakanOhlson.jpg";
import * as joakim_1 from "../../public/skane/joakim.jpg";
import * as Joel from "../../public/skane/Joel.jpg";
import * as Per from "../../public/skane/Per.jpg";
import * as stanislaw from "../../public/skane/stanislaw.jpg";
import * as sellgoldpage from "../../public/skane/sellgoldpage.jpg";
import * as selllundpage from "../../public/skane/selllundpage.jpg";
import * as sellpage from "../../public/skane/sellpage.jpg";
import * as sellvisitpage from "../../public/skane/sellvisitpage.jpg";
import * as estateSkane from "../../public/skane/estateSkane.jpeg";
import * as selluniqe from "../../public/skane/selluniqe.jpeg";
import * as dodsbo from "../../public/skane/dodsbo.jpg";
import * as herrgard from "../../public/skane/herrgard.jpg";
import * as samling from "../../public/skane/samling.jpg";
import * as smycken from "../../public/skane/smycken.jpg";
import * as buyBackground from "../../public/skane/buyBackground.png";
import * as contactBackground_1 from "../../public/haraldssons/contactBackground.png";
import * as suitcase from "../../public/haraldssons/suitcase.png";

export const Style_primaryColor = "#5BAF63";

export const Sounds_overBid = overbid;

export const Sounds_finishBidding = finishbidding;

export const Img_is150x100 = [150, 100];

export const Img_is200x200 = [200, 200];

export const Img_is400x250 = [400, 250];

export const Img_is800x533 = [800, 533];

export const Img_is1800x1200 = [1800, 1200];

export const Img_is2736x1824 = [2736, 1824];

export const Img_is3504x2336 = [3504, 2336];

export function Img_Url_originalImageUrlInPng(image) {
    const version = isNullOrEmpty(image.Version) ? "bidflow" : image.Version;
    return ImageUrlModule_fromString(`https://img.imageboss.me/${version}/cdn/format:png/${image.Id}`);
}

export function Img_Url_originalImageUrl(image) {
    const version = isNullOrEmpty(image.Version) ? "bidflow" : image.Version;
    return ImageUrlModule_fromString(`https://img.imageboss.me/${version}/cdn/format:jpg/${image.Id}`);
}

export function Img_Url_url(w, h, image) {
    const version = isNullOrEmpty(image.Version) ? "bidflow" : image.Version;
    return ImageUrlModule_fromString(`https://img.imageboss.me/${version}/cover:center/${w}x${h}/format:jpg/${image.Id}`);
}

export function Img_Url_urlContain(w, h, image) {
    const version = isNullOrEmpty(image.Version) ? "bidflow" : image.Version;
    return ImageUrlModule_fromString(`https://img.imageboss.me/${version}/cover:contain/${w}x${h}/format:jpg/${image.Id}`);
}

export function Img_Url_urlEntropy(w, h, image) {
    const version = isNullOrEmpty(image.Version) ? "bidflow" : image.Version;
    return ImageUrlModule_fromString(`https://img.imageboss.me/${version}/cover:entropy/${w}x${h}/format:jpg/${image.Id}`);
}

export const Img_Url_medium = (image) => Img_Url_url(Img_is200x200[0], Img_is200x200[1], image);

export const Img_Url_mediumContains1800 = (image) => Img_Url_urlContain(Img_is1800x1200[0], Img_is1800x1200[1], image);

export const Img_Url_mediumContains1800Options = (arg) => defaultArg(map(Img_Url_mediumContains1800, arg), ImageUrlModule_empty);

export const Img_originalImagePath = (image) => Img_Url_originalImageUrl(image);

export const Img_originalImageUrlInPng = (image) => Img_Url_originalImageUrlInPng(image);

export const Img_originalImagePathContains = (image) => Img_Url_urlContain(Img_is3504x2336[0], Img_is3504x2336[1], image);

export const Img_lotExtraLargeImagePath = (image) => Img_Url_url(Img_is2736x1824[0], Img_is2736x1824[1], image);

export const Img_lotExtraLargeImagePathOption = (arg) => defaultArg(map(Img_lotExtraLargeImagePath, arg), ImageUrlModule_empty);

export const Img_lotExtraLargeImagePathOptionContains = (arg) => defaultArg(map(Img_originalImagePathContains, arg), ImageUrlModule_empty);

export const Img_lotMedium800ImagePath = (image) => Img_Url_url(Img_is800x533[0], Img_is800x533[1], image);

export const Img_lotMedium800ImagePathEntropy = (image) => Img_Url_urlEntropy(Img_is800x533[0], Img_is800x533[1], image);

export const Img_lotMedium800ImagePathContain = (image) => Img_Url_urlContain(Img_is800x533[0], Img_is800x533[1], image);

export const Img_lotMedium800ImagePathOption = (arg) => defaultArg(map(Img_lotMedium800ImagePath, arg), ImageUrlModule_empty);

export const Img_lotMediumImagePath = (image) => Img_Url_url(Img_is400x250[0], Img_is400x250[1], image);

export const Img_lotMediumImagePathEntropy = (image) => Img_Url_urlEntropy(Img_is400x250[0], Img_is400x250[1], image);

export const Img_lotMediumImagePathContain = (image) => Img_Url_urlContain(Img_is400x250[0], Img_is400x250[1], image);

export const Img_lotMediumImagePathOption = (arg) => defaultArg(map(Img_lotMediumImagePath, arg), ImageUrlModule_empty);

export const Img_lotMinimumImagePath = (image) => Img_Url_url(Img_is150x100[0], Img_is150x100[1], image);

export const Img_lotMinimumImagePathOption = (arg) => defaultArg(map(Img_lotMinimumImagePath, arg), ImageUrlModule_empty);

export const Img_logo = logo;

export const Img_logoNew = Bidflowlogo_2$002E0_white;

export const Img_flagBritain = flagBritain;

export const Img_flagSweden = flagSweden;

export const Img_flagFinland = flagFinland;

export const Img_headerBackground = headerBackground;

export const Img_startBackground = start_2020;

export const Img_startDownBackground = start_2022;

export const Img_startContainer = nastakval2;

export const Img_sellImg = nyckelfardigt;

export const Img_shop = DIBS_shop;

export const Img_borderFooter = border_footer;

export const Img_greyBackground = greyBackground;

export const Img_borderBottomGreyBackground = borderBottomGreyBackground;

export const Img_greenBackgroundBuy = greenBackgroundBuy;

export const Img_imagePlaceholder = photo$0020placeholder;

export const Img_contactBackground = contactBackground;

export const Img_grayJean = gray_jean;

export const Img_tappe = tappe;

export const Img_kristoffer = kristoffer;

export const Img_simone = simone;

export const Img_alexander = alexander;

export const Img_rasmus = rasmus;

export const Img_gun = gun;

export const Img_fredrik = Fredrik;

export const Img_jonathan = Jonathan;

export const Img_mikaela = Mikaela;

export const Img_nichlas = Nichlas;

export const Img_niclas = Niclas;

export const Img_raimo = Raimo;

export const Img_terese = Terese;

export const Img_tony = Tony;

export const Img_fredrikK = FredrikK;

export const Img_marie = marie;

export const Img_actionBackground = onlinebg;

export const Img_videoThumbnail = video_thumbnail;

export const Img_award1 = award1;

export const Img_award2 = award2;

export const Img_award3 = award3;

export const Img_andersSajab = Anders;

export const Img_kgSajab = K_G;

export const Img_lindaSajab = Linda;

export const Img_manneSajab = manne;

export const Img_martenSajab = marten;

export const Img_rolfSajab = Rolf$0020Johansson;

export const Img_stigSajab = stig;

export const Img_mickeSajab = micke;

export const Img_jesperSajab = jesper;

export const Img_joakimSajab = joakim;

export const Img_agricultureBackground1 = agriculture_back1;

export const Img_agricultureBackground2 = agriculture_back2;

export const Img_homeBackgroundSajab = home_background1;

export const Img_aboutUsBackgroundSajab = background_aboutUs;

export const Img_multisellerSajab = multiseller;

export const Img_home1Helander = home_11;

export const Img_home2Helander = buy$0020copy;

export const Img_home3Helander = home_3;

export const Img_home4Helander = home_4;

export const Img_home5Helander = Intedentit1;

export const Img_buy2Helander = buy1;

export const Img_buyHelander = buy;

export const Img_buy4Helander = buy2;

export const Img_sell1Helander = sell1;

export const Img_sell2Helander = sell2;

export const Img_sell3Helander = sell3;

export const Img_sell4Helander = sell4;

export const Img_sellHelander = sell;

export const Img_faqHelander = helanderFAQ;

export const Img_faqHelanderNew = faq;

export const Img_contactsHelander = contacts;

export const Img_contactsHelanderNew = contact;

export const Img_conditionGoods = conditionGoods;

export const Img_hanneHelander = HanneM;

export const Img_akiHelander = AkiS;

export const Img_mikaHelander = MikaS;

export const Img_petriHelander = PetriP;

export const Img_armandsHelander = ArmandsK;

export const Img_borjeHelander = BorjeB;

export const Img_erkkiHelander = ErkkiL;

export const Img_aavaHelander = AavaA;

export const Img_reaHelander = ReaR;

export const Img_dogHelander = Kerttu;

export const Img_dog2Helander = Migi;

export const Img_merjaHelander = MerjaK;

export const Img_sannaHelander = Sanna;

export const Img_subscribeHelander = subscribeHelander;

export const Img_icon1 = icon1;

export const Img_icon2 = icon2;

export const Img_icon3 = icon3;

export const Img_icon4 = icon4;

export const Img_homeGold = gold;

export const Img_homeLund = lund;

export const Img_homeSell = sell_1;

export const Img_homeVisit = sellvisit;

export const Img_contactBackgroundSkane = contact_1;

export const Img_adamSkane = Adam;

export const Img_annaSkane = AnnaRodman;

export const Img_agnetaEnrothSkane = AgnetaEnroth;

export const Img_andreasSkane = Andreas;

export const Img_dahlmanSkane = Dahlman;

export const Img_fingalSkane = HakanFingal;

export const Img_ohlsomSkane = HakanOhlson;

export const Img_joakimSkane = joakim_1;

export const Img_joelSkane = Joel;

export const Img_perSkane = Per;

export const Img_stanislawSkane = stanislaw;

export const Img_gold = sellgoldpage;

export const Img_lund = selllundpage;

export const Img_main = sellpage;

export const Img_visit = sellvisitpage;

export const Img_estate = estateSkane;

export const Img_uniqSkane = selluniqe;

export const Img_dodsbo = dodsbo;

export const Img_herrgard = herrgard;

export const Img_samling = samling;

export const Img_smycken = smycken;

export const Img_buyBackgroundSkane = buyBackground;

export const Img_contactBackgroundHaraldssons = contactBackground_1;

export const Img_suitcaseHaraldssons = suitcase;

export function Img_onErrorLoadImage(fallbackImage, e) {
    const elem = e.target;
    elem.onerror = ((value) => {
    });
    elem.src = ImageUrlModule_toString(fallbackImage);
}

export function Img_onImageLoadFail(x) {
    Img_onErrorLoadImage(Img_imagePlaceholder, x);
}

