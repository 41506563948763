import { t as t_1 } from "./Localization.js";
import { CountryCodeModule_$007CEU$007CNonEU$007C, CountryCodeModule_empty, EmailModule_toString, EmailModule_ofString } from "./bidflow/src/infrastructure/Auction.js";
import { validateSync, Validator$1__NotBlank_2B595, Validator$1__IsValid_Z4CF01147, Validator$1__IsMail, Validator$1__Test, Validator$1__End_Z5E18B1E2 } from "./fable_modules/Fable.Validation.0.2.1/Validation.fs.js";
import { Form_testerPhone } from "./Common.js";
import { Subscribe_Subscriber, Settings_CompanyAccount, Settings_ContactPersonInfo, Settings_CompanyInfo, Settings_PersonalAccount, Settings_PersonalInfo, Settings_BankInfo, Settings_Address } from "./Shared/Shared.js";
import { length, exists } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { isWhiteSpace, isLetterOrDigit, isPunctuation, isSeparator, isSymbol, isLetter, isDigit } from "./fable_modules/fable-library.3.6.1/Char.js";
import { isNullOrWhiteSpace } from "./fable_modules/fable-library.3.6.1/String.js";
import { empty } from "./fable_modules/fable-library.3.6.1/Map.js";

export function p(key) {
    return t_1("common." + key);
}

export function Common_emailWithName(fieldName, v) {
    return (arg_3) => {
        let arg10_1;
        return EmailModule_ofString(Validator$1__End_Z5E18B1E2(v, (arg10_1 = Validator$1__Test(v, fieldName, EmailModule_toString(arg_3)), Validator$1__IsMail(v, p("error.email"), arg10_1))));
    };
}

export const Common_email = (v) => Common_emailWithName("Email", v);

export function Common_phoneWithName(fieldName, v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (phone) => Form_testerPhone(phone))(p("error.phone"))(Validator$1__Test(v, fieldName, arg_1)));
}

export const Common_phone = (v) => Common_phoneWithName("Phone", v);

export function Common_firstName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.firstName"))(Validator$1__Test(v, "FirstName", arg_1)));
}

export function Common_lastName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.lastName"))(Validator$1__Test(v, "LastName", arg_1)));
}

export function Address_address(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.address"))(Validator$1__Test(v, "Address", arg_1)));
}

export function Address_zipCode(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => (value.length <= 10))(p("error.postalCode"))(Validator$1__Test(v, "ZipCode", arg_1)));
}

export function Address_city(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.city.required"))(Validator$1__Test(v, "City", arg_1)));
}

export function Address_validate(v, x) {
    return new Settings_Address(Address_address(v)(x.Address), Address_zipCode(v)(x.ZipCode), Address_city(v)(x.City), x.Country);
}

export function BankInfo_testerBank(value) {
    if ((((exists((arg00) => isDigit(arg00), value) ? (!exists((arg00_1) => isLetter(arg00_1), value)) : false) ? (!exists((arg00_2) => isSymbol(arg00_2), value)) : false) ? (!exists((arg00_3) => isSeparator(arg00_3), value)) : false) ? (!exists((arg00_4) => isPunctuation(arg00_4), value)) : false) {
        return true;
    }
    else {
        return length(value) <= 0;
    }
}

export function BankInfo_testerIban(value) {
    if ((((exists((arg00) => isLetterOrDigit(arg00), value) ? (!exists((arg00_1) => isSymbol(arg00_1), value)) : false) ? (!exists((arg00_2) => isSeparator(arg00_2), value)) : false) ? (!exists((arg00_3) => isWhiteSpace(arg00_3), value)) : false) ? (!exists((arg00_4) => isPunctuation(arg00_4), value)) : false) {
        return true;
    }
    else {
        return length(value) <= 0;
    }
}

export function BankInfo_clearingNumber(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => BankInfo_testerBank(value))(p("error.clearing"))(Validator$1__Test(v, "ClearingNumber", arg_1)));
}

export function BankInfo_bankNumber(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => BankInfo_testerBank(value))(p("error.bank.number"))(Validator$1__Test(v, "BankNumber", arg_1)));
}

export function BankInfo_bank(v) {
    return (arg) => Validator$1__End_Z5E18B1E2(v, Validator$1__Test(v, "Bank", arg));
}

export function BankInfo_testIban(iban, country) {
    if (!isNullOrWhiteSpace(iban)) {
        return country !== CountryCodeModule_empty;
    }
    else {
        return true;
    }
}

export function BankInfo_testedIsEmptyIban(iban, v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (country) => BankInfo_testIban(iban, country))(p("error.ibanCountryIsRequired"))(Validator$1__Test(v, "IbanCountry", arg_1)));
}

export function BankInfo_iban(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => BankInfo_testerIban(value))(p("error.iban"))(Validator$1__Test(v, "IBAN", arg_1)));
}

export function BankInfo_validate(v, x) {
    return new Settings_BankInfo(BankInfo_clearingNumber(v)(x.ClearingNumber), BankInfo_bankNumber(v)(x.BankNumber), BankInfo_bank(v)(x.Bank), x.Comment, BankInfo_testedIsEmptyIban(x.Iban, v)(x.IbanCountry), BankInfo_iban(v)(x.Iban));
}

export function PersonalAccount_PersonalInfo_testPersonalNumber(basicSettings, userCountry, number) {
    if (basicSettings == null) {
        return true;
    }
    else if (basicSettings.CollectPersonNumber) {
        return !isNullOrWhiteSpace(number);
    }
    else {
        return true;
    }
}

export function PersonalAccount_PersonalInfo_personalNumber(basicSettings, userCountry, v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (number) => PersonalAccount_PersonalInfo_testPersonalNumber(basicSettings, userCountry, number))(p("error.personal.number"))(Validator$1__Test(v, "PersonalNumber", arg_1)));
}

export function PersonalAccount_PersonalInfo_validate(basicSettings, userCountry, v, info) {
    return new Settings_PersonalInfo(Common_firstName(v)(info.FirstName), Common_lastName(v)(info.LastName), Common_phone(v)(info.Phone), PersonalAccount_PersonalInfo_personalNumber(basicSettings, userCountry, v)(info.PersonalNumber));
}

export function PersonalAccount_validate(basicSettings, person) {
    return validateSync(true, (t) => {
        const Email = Common_email(t)(person.Email);
        const PersonalInfo = PersonalAccount_PersonalInfo_validate(basicSettings, person.Address.Country, t, person.PersonalInfo);
        const Address = Address_validate(t, person.Address);
        return new Settings_PersonalAccount(person.UserId, Email, PersonalInfo, BankInfo_validate(t, person.BankInfo), Address, person.SendNewsletters, person.SendEmailOnOverbid);
    });
}

export function PersonalAccount_validateIf(basicSettings, condition, person) {
    if (condition) {
        const matchValue = PersonalAccount_validate(basicSettings, person);
        if (matchValue.tag === 1) {
            return [person, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [person, empty()];
    }
}

export function CompanyAccount_CompanyInfo_companyName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.companyName"))(Validator$1__Test(v, "CompanyName", arg_1)));
}

export function CompanyAccount_CompanyInfo_organizationNumber(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.company.number"))(Validator$1__Test(v, "OrganizationNumber", arg_1)));
}

export function CompanyAccount_CompanyInfo_testVatNumber(residentCountry, userCountry, vatNumber) {
    let pattern_matching_result;
    if (CountryCodeModule_$007CEU$007CNonEU$007C(userCountry).tag === 0) {
        if (userCountry !== residentCountry) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return !isNullOrWhiteSpace(vatNumber);
        }
        case 1: {
            return true;
        }
    }
}

export function CompanyAccount_CompanyInfo_vatNumber(residentCountry, userCountry, v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (vatNumber) => CompanyAccount_CompanyInfo_testVatNumber(residentCountry, userCountry, vatNumber))(p("error.company.vat"))(Validator$1__Test(v, "VatNumber", arg_1)));
}

export function CompanyAccount_CompanyInfo_validate(residentCountry, userCountry, v, info) {
    return new Settings_CompanyInfo(CompanyAccount_CompanyInfo_companyName(v)(info.Name), CompanyAccount_CompanyInfo_organizationNumber(v)(info.OrganizationNumber), CompanyAccount_CompanyInfo_vatNumber(residentCountry, userCountry, v)(info.VatNumber));
}

export function CompanyAccount_ContactPerson_validate(v, info) {
    return new Settings_ContactPersonInfo(Common_firstName(v)(info.FirstName), Common_lastName(v)(info.LastName), Common_phone(v)(info.Phone), Common_emailWithName("ContactPerson.Email", v)(info.Email));
}

export function CompanyAccount_validate(residentCountry, person) {
    return validateSync(true, (v) => {
        const Email = Common_email(v)(person.Email);
        const CompanyInfo = CompanyAccount_CompanyInfo_validate(residentCountry, person.Address.Country, v, person.CompanyInfo);
        const Address = Address_validate(v, person.Address);
        return new Settings_CompanyAccount(person.UserId, Email, CompanyInfo, BankInfo_validate(v, person.BankInfo), Address, CompanyAccount_ContactPerson_validate(v, person.ContactPerson), person.SendNewsletters, person.SendEmailOnOverbid);
    });
}

export function CompanyAccount_validateIf(residentCountry, condition, company) {
    if (condition) {
        const matchValue = CompanyAccount_validate(residentCountry, company);
        if (matchValue.tag === 1) {
            return [company, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [company, empty()];
    }
}

export function SubscribeToEmails_validate(person) {
    return validateSync(true, (v) => {
        const Email = Common_email(v)(person.Email);
        return new Subscribe_Subscriber(Common_firstName(v)(person.FirstName), Common_lastName(v)(person.LastName), Email, person.ConfirmPolicy);
    });
}

export function SubscribeToEmails_validateIf(condition, x) {
    if (condition) {
        const matchValue = SubscribeToEmails_validate(x);
        if (matchValue.tag === 1) {
            return [x, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [x, empty()];
    }
}

