import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_1, empty as empty_1 } from "../fable_modules/fable-library.3.6.1/List.js";
import { ZoomPanPinch } from "../../../Components/ZoomPanPinch.jsx";

export function Zoom(zoomInputProps) {
    return null;
}

export function View(viewInputProps) {
    const link = viewInputProps.link;
    const setVisible = viewInputProps.setVisible;
    const isVisible = viewInputProps.isVisible;
    const props_8 = toList(delay(() => append(isVisible ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let props_6, props_4, elms;
        return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (props_6 = ofArray([["style", {
            width: 100 + "vw",
            height: 100 + "vh",
            top: 20,
        }], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["style", {
            border: (((0 + "px ") + "none") + " ") + "#000000",
            width: 30,
            height: 30,
            borderRadius: 2,
            position: "fixed",
            left: 20,
            zIndex: 100,
        }], ["onClick", (_arg1) => {
            setVisible(false);
        }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
            className: "fa fa-times",
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_4)))), createElement(ZoomPanPinch, {
            src: link,
        })])]]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_6))))])]);
    }))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_8)));
}

