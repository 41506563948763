import { useReact_useEffectOnce_3A5B6456, useReact_useRef_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { iterate } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { toArray } from "../fable_modules/fable-library.3.6.1/Option.js";
import { createElement } from "react";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { paymentApi } from "../Communication.js";
import { medium } from "./Loader.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";

export function CustomHtml(customHtmlInputProps) {
    const snippet = customHtmlInputProps.snippet;
    const divRef = useReact_useRef_1505(void 0);
    useReact_useEffectOnce_3A5B6456(() => {
        iterate((x) => {
            x.appendChild(document.createRange().createContextualFragment(snippet));
        }, toArray(divRef.current));
    });
    return createElement("div", {
        ref: divRef,
    });
}

export function Component() {
    const checkoutUI = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(singleton.Delay(() => singleton.ReturnFrom(paymentApi().createTestSveaPayment()))), []);
    let checkoutUI_1;
    let pattern_matching_result, ui, x, error;
    if (checkoutUI.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (checkoutUI.tag === 2) {
        if (checkoutUI.fields[0].tag === 1) {
            pattern_matching_result = 2;
            x = checkoutUI.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
            ui = checkoutUI.fields[0].fields[0];
        }
    }
    else if (checkoutUI.tag === 3) {
        pattern_matching_result = 3;
        error = checkoutUI.fields[0];
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            checkoutUI_1 = medium("Loading");
            break;
        }
        case 1: {
            checkoutUI_1 = createElement(CustomHtml, {
                snippet: ui,
            });
            break;
        }
        case 2: {
            checkoutUI_1 = createElement("div", {
                children: [x],
            });
            break;
        }
        case 3: {
            const value_1 = error.message;
            checkoutUI_1 = createElement("div", {
                children: [value_1],
            });
            break;
        }
    }
    return createElement("div", {
        className: join(" ", ["fixed-body-checkout"]),
        children: Interop_reactApi.Children.toArray([createElement("h2", {
            className: "title is-2",
            children: "Test payment with 1 SEK",
        }), checkoutUI_1]),
    });
}

