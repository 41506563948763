import { useReact_useEffectOnce_3A5B6456, useReact_useRef_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { iterate } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { toArray } from "./fable_modules/fable-library.3.6.1/Option.js";
import { createElement } from "react";
import * as react from "react";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { paymentApi } from "./Communication.js";
import { Payment_CreatePaymentLinkRequest } from "./Shared/Shared.js";
import { medium } from "./Components/Loader.js";

export function CustomHtml(customHtmlInputProps) {
    const snippet = customHtmlInputProps.snippet;
    const divRef = useReact_useRef_1505(void 0);
    useReact_useEffectOnce_3A5B6456(() => {
        iterate((x) => {
            x.appendChild(document.createRange().createContextualFragment(snippet));
        }, toArray(divRef.current));
    });
    return createElement("div", {
        ref: divRef,
    });
}

export function RenderCheckout(renderCheckoutInputProps) {
    let value_1;
    const auctionId = renderCheckoutInputProps.auctionId;
    const checkoutUI = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(singleton.Delay(() => singleton.ReturnFrom(paymentApi().createSveaPayment(new Payment_CreatePaymentLinkRequest(0, auctionId))))), [auctionId]);
    return react.createElement(react.Fragment, {}, (checkoutUI.tag === 1) ? medium("Loading") : ((checkoutUI.tag === 2) ? ((checkoutUI.fields[0].tag === 1) ? createElement("div", {
        children: [checkoutUI.fields[0].fields[0]],
    }) : createElement(CustomHtml, {
        snippet: checkoutUI.fields[0].fields[0],
    })) : ((checkoutUI.tag === 3) ? ((value_1 = checkoutUI.fields[0].message, createElement("div", {
        children: [value_1],
    }))) : medium("Loading"))));
}

