import react_anchor_link_smooth_scroll from "react-anchor-link-smooth-scroll";
import { class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";

export const scrollableAnchor = react_anchor_link_smooth_scroll;

export class Scrollable {
    constructor() {
    }
}

export function Scrollable$reflection() {
    return class_type("ScrollableAnchor.Scrollable", void 0, Scrollable);
}

