import { t } from "./Localization.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Browser_Types_File__File_ReadAsByteArray } from "./fable_modules/Fable.Remoting.Client.7.10.0/Extensions.fs.js";
import { UploadImage_Image$reflection, UploadImage_UploadImageInfo } from "./Shared/Shared.js";
import { imagesApi } from "./Communication.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { comparePrimitives, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { append as append_1, filter, tryFindIndex, singleton as singleton_2, cons, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { map as map_1, empty, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { union_type, record_type, class_type, string_type, list_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { empty as empty_1 } from "./fable_modules/fable-library.3.6.1/Set.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { map, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { List_swapToRight, List_swapToLeft } from "./bidflow/src/infrastructure/Extensions.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { RenderImage } from "./Components/Image.js";

export function b(key) {
    return t("appointmentBooking." + key);
}

export function UploadImage(uploadImageInputProps) {
    const onImageAdded = uploadImageInputProps.onImageAdded;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setError = patternInput[1];
    const error = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setInProgress = patternInput_1[1];
    const inProgress = patternInput_1[0];
    const uploadFileCallback = useReact_useCallback_93353E((arg) => {
        let file;
        startImmediate((file = arg, singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            setError("");
            setInProgress(true);
            return singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file), (_arg1) => {
                const args = new UploadImage_UploadImageInfo(_arg1, file.name);
                return singleton.Bind(imagesApi().uploadImage(args), (_arg2) => {
                    if (_arg2.tag === 1) {
                        setError(_arg2.fields[0]);
                        setInProgress(false);
                        return singleton.Zero();
                    }
                    else {
                        setInProgress(false);
                        onImageAdded(_arg2.fields[0]);
                        return singleton.Zero();
                    }
                });
            });
        }), (_arg3) => {
            setError(_arg3.message);
            setInProgress(false);
            return singleton.Zero();
        }))));
    }, []);
    const props_8 = ofArray([["className", "is-light"], ["className", "has-text-centered"], ["className", "is-fullwidth"], ["disabled", inProgress], ["style", {
        minHeight: 90 + "px",
    }], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["tabIndex", -1], ["disabled", inProgress], ["onChange", (ev) => {
        const files = ev.target.files;
        if ((!(files == null)) ? (files.length > 0) : false) {
            uploadFileCallback(files.item(0));
        }
    }]]))))), createElement("span", {
        style: {
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_3;
            return append(singleton_1((props_3 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (inProgress ? singleton_1(["className", join(" ", ["fas", "fa-spinner", "fa-spin"])]) : singleton_1(["className", join(" ", ["fa", "fa-plus"])]))))))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))), delay(() => append(singleton_1(createElement("span", {
                className: join(" ", ["is-size-7-tablet", "is-size-6-mobile", "is-size-6-desktop"]),
                children: inProgress ? b("loading") : b("add.photo"),
            })), delay(() => ((error !== "") ? singleton_1(createElement("span", {
                children: [error],
            })) : empty())))));
        })))),
    })])]]);
    return createElement("button", createObj(Helpers_combineClasses("button", props_8)));
}

export class State extends Record {
    constructor(Images, Folders) {
        super();
        this.Images = Images;
        this.Folders = Folders;
    }
}

export function State$reflection() {
    return record_type("Client.EditImages.State", [], State, () => [["Images", list_type(UploadImage_Image$reflection())], ["Folders", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnImageAdded", "MoveImageLeft", "MoveImageRight", "DeleteImage"];
    }
}

export function Msg$reflection() {
    return union_type("Client.EditImages.Msg", [], Msg, () => [[["Item", UploadImage_Image$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function init(images) {
    return [new State(images, empty_1({
        Compare: (x, y) => comparePrimitives(x, y),
    })), Cmd_none()];
}

export function update(onImagesChanged, msg, state) {
    switch (msg.tag) {
        case 1: {
            const newState_1 = new State(defaultArg(map((x_1) => List_swapToLeft(x_1)(state.Images), tryFindIndex((x) => (x.Id === msg.fields[0]), state.Images)), state.Images), state.Folders);
            onImagesChanged(newState_1.Images);
            return [newState_1, Cmd_none()];
        }
        case 2: {
            const newState_2 = new State(defaultArg(map((x_3) => List_swapToRight(x_3)(state.Images), tryFindIndex((x_2) => (x_2.Id === msg.fields[0]), state.Images)), state.Images), state.Folders);
            onImagesChanged(newState_2.Images);
            return [newState_2, Cmd_none()];
        }
        case 3: {
            const newState_3 = new State(filter((x_4) => (x_4.Id !== msg.fields[0]), state.Images), state.Folders);
            onImagesChanged(newState_3.Images);
            return [newState_3, Cmd_none()];
        }
        default: {
            const newState = new State(append_1(state.Images, singleton_2(msg.fields[0])), state.Folders);
            onImagesChanged(newState.Images);
            return [newState, Cmd_none()];
        }
    }
}

export function EditImages(editImagesInputProps) {
    let props_9;
    const dependencies = editImagesInputProps.dependencies;
    const onImagesChanged = editImagesInputProps.onImagesChanged;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(editImagesInputProps.images), (msg, state) => update(onImagesChanged, msg, state), dependencies);
    const dispatch = patternInput[1];
    return react.createElement(react.Fragment, {}, (props_9 = ofArray([["className", "is-multiline"], ["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(map_1((image) => {
        let props_3, props, s;
        const props_5 = ofArray([["className", "is-one-fifth-fullhd"], ["className", "is-one-fifth-desktop"], ["className", "is-4-desktop"], ["className", "is-4-tablet"], ["className", "is-6-mobile"], ["className", "pl-0"], ["children", Interop_reactApi.Children.toArray([createElement(RenderImage, image), (props_3 = ofArray([["className", "is-small"], ["className", "is-danger"], ["className", "is-fullwidth"], ["className", "mt-2"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
            cursor: "pointer",
        }], ["className", join(" ", ["far", "fa-trash-alt"])], ["onClick", (_arg1) => {
            dispatch(new Msg(3, image.Id));
        }]]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), (s = b("delete"), createElement("span", {
            className: "",
            children: s,
        }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_3))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("column", props_5)));
    }, patternInput[0].Images), delay(() => {
        let props_7;
        return singleton_1((props_7 = ofArray([["className", "is-one-fifth-fullhd"], ["className", "is-one-fifth-desktop"], ["className", "is-4-desktop"], ["className", "is-4-tablet"], ["className", "is-6-mobile"], ["className", "pl-0"], ["children", Interop_reactApi.Children.toArray([createElement(UploadImage, {
            onImageAdded: (arg) => {
                dispatch(new Msg(0, arg));
            },
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7)))));
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_9)))));
}

