import { t } from "./Localization.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { singleton, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Route, toPath } from "./Router.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function p(key) {
    return t("bankIDVerification." + key);
}

export function View() {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF();
    return createElement("div", createObj(toList(delay(() => append(singleton(["className", join(" ", ["background-container-login-forgot-create", "create-account-column"])]), delay(() => append((width.tag === 0) ? singleton(["style", {}]) : ((width.tag === 1) ? singleton(["style", {}]) : ((width.tag === 2) ? singleton(["style", {
        minHeight: 814,
    }]) : singleton(["style", {
        minHeight: 640,
    }]))), delay(() => {
        let props_12;
        return singleton(["children", Interop_reactApi.Children.toArray([(props_12 = toList(delay(() => append(singleton(["className", "login-box"]), delay(() => append(singleton(["style", {
            maxHeight: 450,
        }]), delay(() => {
            let value_27, value_51;
            return patternInput[0] ? singleton(["children", Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    padding: 20,
                    marginBottom: 20,
                    borderBottom: (((1 + "px ") + "dashed") + " ") + "#ccc",
                },
                children: Interop_reactApi.Children.toArray([createElement("p", {
                    className: join(" ", ["create-account-title"]),
                    children: p("title"),
                }), createElement("div", {
                    style: {
                        fontSize: 16 + "px",
                        paddingBottom: 20,
                    },
                    children: Interop_reactApi.Children.toArray([(value_27 = p("text1"), createElement("p", {
                        children: [value_27],
                    }))]),
                })]),
            }), createElement("button", {
                className: join(" ", ["buy-button-big"]),
                children: p("button.request"),
                onClick: (_arg1) => {
                    patternInput[1](false);
                },
            }), createElement("p", {}), createElement("a", {
                className: join(" ", ["button", "buy-button-big"]),
                children: p("button.registration"),
                href: toPath(new Route(10)),
            })])]) : singleton(["children", Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    padding: 20,
                    marginBottom: 20,
                    borderBottom: (((1 + "px ") + "dashed") + " ") + "#ccc",
                },
                children: Interop_reactApi.Children.toArray([createElement("p", {
                    className: join(" ", ["create-account-title"]),
                    children: p("title"),
                }), createElement("div", {
                    className: "buy-text",
                    children: Interop_reactApi.Children.toArray([(value_51 = p("text2"), createElement("p", {
                        children: [value_51],
                    }))]),
                })]),
            }), createElement("a", {
                className: join(" ", ["button", "buy-button-big"]),
                children: p("button.login"),
                href: toPath(new Route(0)),
            })])]);
        })))))), createElement("div", createObj(Helpers_combineClasses("box", props_12))))])]);
    }))))))));
}

