import { t } from "../Localization.js";
import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { Settings_BankInfo, Settings_Address, Settings_PersonalInfo, Settings_PersonalAccount, Users_CompanyBasicSettings$reflection, Settings_PersonalAccount$reflection } from "../Shared/Shared.js";
import { record_type, bool_type, class_type, list_type, union_type, string_type, unit_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Result_MapError, FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi, usersApi } from "../Communication.js";
import { FSharpMap__get_IsEmpty, empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../fable_modules/fable-library.3.6.1/Option.js";
import { PersonalAccount_validateIf } from "../Validation.js";
import { PhoneModule_ofString, PhoneModule_toString, EmailModule_toString, CountryCodeModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { CountryModule_shortCode } from "../bidflow/src/infrastructure/Countries.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { append as append_1, cons, map, empty as empty_2, fold, isEmpty, singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { large } from "../Components/Loader.js";
import { Img_grayJean } from "../Css.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { interpolate, toText, join } from "../fable_modules/fable-library.3.6.1/String.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { CountriesInput_getSortedList, Form_getFieldError } from "../Common.js";
import { ChangePasswordComponent } from "./ChangePassword.js";

export function p(key) {
    return t("profileSettings." + key);
}

export function f(key) {
    return t("country." + key);
}

export function c(key) {
    return t("common." + key);
}

export class State extends Record {
    constructor(Form, SaveFormOperation, FormErrors, NeedValidateForm, BasicCompanySettings) {
        super();
        this.Form = Form;
        this.SaveFormOperation = SaveFormOperation;
        this.FormErrors = FormErrors;
        this.NeedValidateForm = NeedValidateForm;
        this.BasicCompanySettings = BasicCompanySettings;
    }
}

export function State$reflection() {
    return record_type("Client.EditPersonalAccount.State", [], State, () => [["Form", Settings_PersonalAccount$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedValidateForm", bool_type], ["BasicCompanySettings", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Users_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Users_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadBasicSettings", "EmailChanged", "FirstNameChanged", "LastNameChanged", "AddressChanged", "ZipCodeChanged", "CityChanged", "CountryChanged", "PhoneChanged", "PersonNumberChanged", "SendNewslettersChanged", "SendEmailOnOverbidChanged", "ClearingNumberChanged", "BankNumberChanged", "BankChanged", "IbanCountryChanged", "IbanChanged", "SaveForm"];
    }
}

export function Msg$reflection() {
    return union_type("Client.EditPersonalAccount.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Users_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Users_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function loadResidentCountry() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(usersApi().loadResidentCompanySettings(), (_arg1) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function saveProfile(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(settingsApi().updatePersonalAccount(form), (_arg2) => singleton.Return(new Msg(17, new AsyncOperationStatus$1(1, Result_MapError((_arg1) => p("error.errorAccountNotExist"), _arg2)))))), (_arg3) => singleton.Return(new Msg(17, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg3.message))))));
}

export function init(account, unitVar1) {
    return [new State(account, new Deferred$1(0), empty(), false, new Deferred$1(0)), Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0)))];
}

export function withForm(form, state) {
    return new State(form, state.SaveFormOperation, state.FormErrors, state.NeedValidateForm, state.BasicCompanySettings);
}

export function withPersonalInfo(x, state) {
    let inputRecord;
    return withForm((inputRecord = state.Form, new Settings_PersonalAccount(inputRecord.UserId, inputRecord.Email, x, inputRecord.BankInfo, inputRecord.Address, inputRecord.SendNewsletters, inputRecord.SendEmailOnOverbid)), state);
}

export function withAddress(x, state) {
    let inputRecord;
    return withForm((inputRecord = state.Form, new Settings_PersonalAccount(inputRecord.UserId, inputRecord.Email, inputRecord.PersonalInfo, inputRecord.BankInfo, x, inputRecord.SendNewsletters, inputRecord.SendEmailOnOverbid)), state);
}

export function withBankInfo(x, state) {
    let inputRecord;
    return withForm((inputRecord = state.Form, new Settings_PersonalAccount(inputRecord.UserId, inputRecord.Email, inputRecord.PersonalInfo, x, inputRecord.Address, inputRecord.SendNewsletters, inputRecord.SendEmailOnOverbid)), state);
}

export function basicSettings(stateResidentCountry) {
    let pattern_matching_result, s;
    if (stateResidentCountry.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (stateResidentCountry.tag === 2) {
        if (stateResidentCountry.fields[0].tag === 0) {
            pattern_matching_result = 2;
            s = stateResidentCountry.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return void 0;
        }
        case 1: {
            return void 0;
        }
        case 2: {
            return some(s);
        }
    }
}

export function validate(state) {
    const patternInput = PersonalAccount_validateIf(basicSettings(state.BasicCompanySettings), state.NeedValidateForm, state.Form);
    return new State(patternInput[0], state.SaveFormOperation, patternInput[1], state.NeedValidateForm, state.BasicCompanySettings);
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12, inputRecord_13, inputRecord_14, inputRecord_15;
    if (msg.tag === 1) {
        return [validate(withForm((inputRecord = state.Form, new Settings_PersonalAccount(inputRecord.UserId, msg.fields[0], inputRecord.PersonalInfo, inputRecord.BankInfo, inputRecord.Address, inputRecord.SendNewsletters, inputRecord.SendEmailOnOverbid)), state)), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [validate(withPersonalInfo((inputRecord_1 = state.Form.PersonalInfo, new Settings_PersonalInfo(msg.fields[0], inputRecord_1.LastName, inputRecord_1.Phone, inputRecord_1.PersonalNumber)), state)), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [validate(withPersonalInfo((inputRecord_2 = state.Form.PersonalInfo, new Settings_PersonalInfo(inputRecord_2.FirstName, msg.fields[0], inputRecord_2.Phone, inputRecord_2.PersonalNumber)), state)), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [validate(withPersonalInfo((inputRecord_3 = state.Form.PersonalInfo, new Settings_PersonalInfo(inputRecord_3.FirstName, inputRecord_3.LastName, msg.fields[0], inputRecord_3.PersonalNumber)), state)), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [validate(withPersonalInfo((inputRecord_4 = state.Form.PersonalInfo, new Settings_PersonalInfo(inputRecord_4.FirstName, inputRecord_4.LastName, inputRecord_4.Phone, msg.fields[0])), state)), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [validate(withAddress((inputRecord_5 = state.Form.Address, new Settings_Address(msg.fields[0], inputRecord_5.ZipCode, inputRecord_5.City, inputRecord_5.Country)), state)), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [validate(withAddress((inputRecord_6 = state.Form.Address, new Settings_Address(inputRecord_6.Address, msg.fields[0], inputRecord_6.City, inputRecord_6.Country)), state)), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [validate(withAddress((inputRecord_7 = state.Form.Address, new Settings_Address(inputRecord_7.Address, inputRecord_7.ZipCode, msg.fields[0], inputRecord_7.Country)), state)), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [validate(withAddress((inputRecord_8 = state.Form.Address, new Settings_Address(inputRecord_8.Address, inputRecord_8.ZipCode, inputRecord_8.City, msg.fields[0])), state)), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [validate(withBankInfo((inputRecord_9 = state.Form.BankInfo, new Settings_BankInfo(msg.fields[0], inputRecord_9.BankNumber, inputRecord_9.Bank, inputRecord_9.Comment, inputRecord_9.IbanCountry, inputRecord_9.Iban)), state)), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [validate(withBankInfo((inputRecord_10 = state.Form.BankInfo, new Settings_BankInfo(inputRecord_10.ClearingNumber, msg.fields[0], inputRecord_10.Bank, inputRecord_10.Comment, inputRecord_10.IbanCountry, inputRecord_10.Iban)), state)), Cmd_none()];
    }
    else if (msg.tag === 14) {
        return [validate(withBankInfo((inputRecord_11 = state.Form.BankInfo, new Settings_BankInfo(inputRecord_11.ClearingNumber, inputRecord_11.BankNumber, msg.fields[0], inputRecord_11.Comment, inputRecord_11.IbanCountry, inputRecord_11.Iban)), state)), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [validate(withBankInfo((inputRecord_12 = state.Form.BankInfo, new Settings_BankInfo(inputRecord_12.ClearingNumber, inputRecord_12.BankNumber, inputRecord_12.Bank, inputRecord_12.Comment, msg.fields[0], inputRecord_12.Iban)), state)), Cmd_none()];
    }
    else if (msg.tag === 16) {
        return [validate(withBankInfo((inputRecord_13 = state.Form.BankInfo, new Settings_BankInfo(inputRecord_13.ClearingNumber, inputRecord_13.BankNumber, inputRecord_13.Bank, inputRecord_13.Comment, inputRecord_13.IbanCountry, msg.fields[0])), state)), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [validate(withForm((inputRecord_14 = state.Form, new Settings_PersonalAccount(inputRecord_14.UserId, inputRecord_14.Email, inputRecord_14.PersonalInfo, inputRecord_14.BankInfo, inputRecord_14.Address, msg.fields[0], inputRecord_14.SendEmailOnOverbid)), state)), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [validate(withForm((inputRecord_15 = state.Form, new Settings_PersonalAccount(inputRecord_15.UserId, inputRecord_15.Email, inputRecord_15.PersonalInfo, inputRecord_15.BankInfo, inputRecord_15.Address, inputRecord_15.SendNewsletters, msg.fields[0])), state)), Cmd_none()];
    }
    else if (msg.tag === 17) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.Form, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.NeedValidateForm, state.BasicCompanySettings), Cmd_none()];
        }
        else {
            const errors = PersonalAccount_validateIf(basicSettings(state.BasicCompanySettings), true, state.Form)[1];
            if (FSharpMap__get_IsEmpty(errors)) {
                return [new State(state.Form, new Deferred$1(1), errors, true, state.BasicCompanySettings), Cmd_fromAsync(saveProfile(state.Form))];
            }
            else {
                return [new State(state.Form, new Deferred$1(0), errors, true, state.BasicCompanySettings), Cmd_none()];
            }
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new State(state.Form, state.SaveFormOperation, state.FormErrors, state.NeedValidateForm, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
        else {
            return [new State(state.Form, state.SaveFormOperation, state.FormErrors, state.NeedValidateForm, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
    }
    else {
        return [new State(state.Form, state.SaveFormOperation, state.FormErrors, state.NeedValidateForm, new Deferred$1(1)), Cmd_fromAsync(loadResidentCountry())];
    }
}

export function CountryCodeToString(c_1) {
    if (c_1 === CountryCodeModule_empty) {
        return "-";
    }
    else {
        return CountryModule_shortCode(c_1);
    }
}

export function ProfileSettings(personalAccount) {
    let props_189, props_185, props_187, s_19, dispatch_3, state_13, props_144, props_103, s_11, elms_16, dispatch_1, state_2, elms_4, props_7, s, props_43, elms, props_14, elms_1, props_22, elms_2, props_30, elms_3, props_40, dispatch_2, state_7, props_97, props_50, s_5, elms_10, elms_5, props_58, elms_6, props_67, elms_7, props_75, elms_8, props_85, elms_9, props_93, props_181, props_178, s_18, elms_23, dispatch_4, state_18, props_161, props_150, s_17, elms_19, elms_17, elms_18, elms_20, elms_21, elms_22, props_171, props_169;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => init(personalAccount, void 0), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const isSaving = (state_1.SaveFormOperation.tag === 1) ? true : false;
    let saveError;
    const matchValue_1 = state_1.SaveFormOperation;
    let pattern_matching_result, error;
    if (matchValue_1.tag === 2) {
        if (matchValue_1.fields[0].tag === 1) {
            pattern_matching_result = 0;
            error = matchValue_1.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            saveError = createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", error]]))));
            break;
        }
        case 1: {
            saveError = null;
            break;
        }
    }
    let saveProfile_1;
    const matchValue_2 = state_1.SaveFormOperation;
    let pattern_matching_result_1;
    if (matchValue_2.tag === 2) {
        if (matchValue_2.fields[0].tag === 0) {
            pattern_matching_result_1 = 0;
        }
        else {
            pattern_matching_result_1 = 1;
        }
    }
    else {
        pattern_matching_result_1 = 1;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            const props_2 = ofArray([["className", "is-success"], ["children", p("notification.notification")]]);
            saveProfile_1 = createElement("div", createObj(Helpers_combineClasses("notification", props_2)));
            break;
        }
        case 1: {
            saveProfile_1 = null;
            break;
        }
    }
    const personalInfo = state_1.Form.PersonalInfo;
    const matchValue_3 = state_1.BasicCompanySettings;
    let pattern_matching_result_2, s_20, error_1;
    if (matchValue_3.tag === 1) {
        pattern_matching_result_2 = 0;
    }
    else if (matchValue_3.tag === 2) {
        if (matchValue_3.fields[0].tag === 1) {
            pattern_matching_result_2 = 2;
            error_1 = matchValue_3.fields[0].fields[0];
        }
        else {
            pattern_matching_result_2 = 1;
            s_20 = matchValue_3.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result_2 = 0;
    }
    switch (pattern_matching_result_2) {
        case 0: {
            return large;
        }
        case 1: {
            const dispatch_6 = patternInput[1];
            const state_19 = state_1;
            return createElement("div", {
                style: {
                    backgroundImage: ("url(\u0027" + Img_grayJean) + "\u0027)",
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        padding: 5,
                    },
                    children: Interop_reactApi.Children.toArray([(props_189 = ofArray([["style", {
                        marginTop: 40,
                        marginBottom: 30,
                        backgroundColor: "#fff",
                        color: "#5F5F5F",
                        padding: 32,
                    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-user", "is-size-3"]),
                        style: {
                            padding: ((15 + "px ") + 17) + "px",
                            borderRadius: 50 + "%",
                            backgroundColor: "#F9F9F9",
                            float: "left",
                            marginRight: 20,
                        },
                    }), (props_185 = ofArray([["children", `${personalInfo.FirstName} ${personalInfo.LastName}`], ["style", {
                        color: "#5F5F5F",
                    }]]), createElement("p", createObj(Helpers_combineClasses("title", props_185)))), (props_187 = ofArray([["children", toText(interpolate("%s%P() %A%P()", [p("customNumber"), state_19.Form.UserId]))], ["style", {
                        color: "#5F5F5F",
                    }]]), createElement("p", createObj(Helpers_combineClasses("subtitle", props_187))))])]]), createElement("div", createObj(Helpers_combineClasses("container", props_189)))), createElement("div", {
                        children: Interop_reactApi.Children.toArray([createElement("fieldset", {
                            disabled: isSaving,
                            className: "container",
                            style: {
                                marginBottom: 100,
                                backgroundColor: "#fff",
                                padding: 32,
                            },
                            children: Interop_reactApi.Children.toArray([(s_19 = p("title"), createElement("h3", {
                                className: "title is-3",
                                children: s_19,
                            })), (dispatch_3 = dispatch_6, (state_13 = state_19, react.createElement(react.Fragment, {}, createElement("hr", {}), (props_144 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_103 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s_11 = p("personal.info.title"), createElement("h4", {
                                className: "subtitle is-4",
                                children: s_11,
                            })), createElement("div", {
                                className: "has-text-grey",
                                children: p("personal.info.subtitle"),
                            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_103)))), (elms_16 = toList(delay(() => {
                                let elms_11, props_108, value_297;
                                return append(singleton_1((elms_11 = singleton_2((props_108 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-medium"])], ["disabled", true], ["type", "email"], (value_297 = EmailModule_toString(state_13.Form.Email), ["ref", (e_9) => {
                                    if ((!(e_9 == null)) ? (!equals(e_9.value, value_297)) : false) {
                                        e_9.value = value_297;
                                    }
                                }])]))), createElement("span", {
                                    className: join(" ", ["icon", "is-small", "is-left"]),
                                    children: Interop_reactApi.Children.toArray([createElement("i", {
                                        className: join(" ", ["fas", "fa-envelope"]),
                                    })]),
                                })])]]), createElement("div", createObj(Helpers_combineClasses("control", props_108))))), createElement("div", {
                                    className: "field",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                                }))), delay(() => {
                                    let elms_12, props_116;
                                    return append(singleton_1((elms_12 = singleton_2((props_116 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                        const errors_9 = Form_getFieldError(state_13.FormErrors, "FirstName");
                                        return append(singleton_1(createElement("input", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append((!isEmpty(errors_9)) ? singleton_1(["style", {
                                            borderColor: "#FF0000",
                                            boxShadow: "none",
                                        }]) : empty_1(), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                                            let value_316;
                                            return append(singleton_1((value_316 = state_13.Form.PersonalInfo.FirstName, ["ref", (e_10) => {
                                                if ((!(e_10 == null)) ? (!equals(e_10.value, value_316)) : false) {
                                                    e_10.value = value_316;
                                                }
                                            }])), delay(() => append(singleton_1(["onChange", (ev_9) => {
                                                dispatch_3(new Msg(2, ev_9.target.value));
                                            }]), delay(() => singleton_1(["placeholder", p("firstName.placeholder")])))));
                                        }))))))))))), delay(() => {
                                            let props_112;
                                            return append(singleton_1((props_112 = ofArray([["style", {
                                                color: "#FF0000",
                                            }], ["children", fold((s_12, x_9) => (`${s_12} ${x_9}`), "", errors_9)]]), createElement("p", createObj(Helpers_combineClasses("help", props_112))))), delay(() => singleton_1(createElement("span", {
                                                className: join(" ", ["icon", "is-small", "is-left"]),
                                                children: Interop_reactApi.Children.toArray([createElement("i", {
                                                    className: join(" ", ["fa", "fa-user"]),
                                                })]),
                                            }))));
                                        }));
                                    }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_116))))), createElement("div", {
                                        className: "field",
                                        children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                                    }))), delay(() => {
                                        let elms_13, props_124;
                                        return append(singleton_1((elms_13 = singleton_2((props_124 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                            const errors_10 = Form_getFieldError(state_13.FormErrors, "LastName");
                                            return append(singleton_1(createElement("input", createObj(toList(delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append((!equals(errors_10, empty_2())) ? singleton_1(["style", {
                                                borderColor: "#FF0000",
                                                boxShadow: "none",
                                            }]) : empty_1(), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                                                let value_341;
                                                return append(singleton_1((value_341 = state_13.Form.PersonalInfo.LastName, ["ref", (e_11) => {
                                                    if ((!(e_11 == null)) ? (!equals(e_11.value, value_341)) : false) {
                                                        e_11.value = value_341;
                                                    }
                                                }])), delay(() => append(singleton_1(["onChange", (ev_10) => {
                                                    dispatch_3(new Msg(3, ev_10.target.value));
                                                }]), delay(() => singleton_1(["placeholder", p("lastName.placeholder")])))));
                                            }))))))))))), delay(() => {
                                                let props_120;
                                                return append(singleton_1((props_120 = ofArray([["style", {
                                                    color: "#FF0000",
                                                }], ["children", fold((s_13, x_10) => (`${s_13} ${x_10}`), "", errors_10)]]), createElement("p", createObj(Helpers_combineClasses("help", props_120))))), delay(() => singleton_1(createElement("span", {
                                                    className: join(" ", ["icon", "is-small", "is-left"]),
                                                    children: Interop_reactApi.Children.toArray([createElement("i", {
                                                        className: join(" ", ["fa", "fa-user"]),
                                                    })]),
                                                }))));
                                            }));
                                        }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_124))))), createElement("div", {
                                            className: "field",
                                            children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
                                        }))), delay(() => {
                                            let elms_14, props_132;
                                            return append(singleton_1((elms_14 = singleton_2((props_132 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                                const errors_11 = Form_getFieldError(state_13.FormErrors, "Phone");
                                                return append(singleton_1(createElement("input", createObj(toList(delay(() => append((!equals(errors_11, empty_2())) ? singleton_1(["style", {
                                                    borderColor: "#FF0000",
                                                    boxShadow: "none",
                                                }]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                                                    let value_367;
                                                    return append(singleton_1((value_367 = PhoneModule_toString(state_13.Form.PersonalInfo.Phone), ["ref", (e_12) => {
                                                        if ((!(e_12 == null)) ? (!equals(e_12.value, value_367)) : false) {
                                                            e_12.value = value_367;
                                                        }
                                                    }])), delay(() => append(singleton_1(["onChange", (ev_11) => {
                                                        dispatch_3(new Msg(8, PhoneModule_ofString(ev_11.target.value)));
                                                    }]), delay(() => singleton_1(["placeholder", p("phone.placeholder")])))));
                                                }))))))))))), delay(() => {
                                                    let props_128;
                                                    return append(singleton_1((props_128 = ofArray([["style", {
                                                        color: "#FF0000",
                                                    }], ["children", fold((s_15, x_11) => (`${s_15} ${x_11}`), "", errors_11)]]), createElement("p", createObj(Helpers_combineClasses("help", props_128))))), delay(() => singleton_1(createElement("span", {
                                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                                        children: Interop_reactApi.Children.toArray([createElement("i", {
                                                            className: join(" ", ["fa", "fa-phone"]),
                                                        })]),
                                                    }))));
                                                }));
                                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_132))))), createElement("div", {
                                                className: "field",
                                                children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
                                            }))), delay(() => {
                                                let elms_15, props_140;
                                                return s_20.CollectPersonNumber ? singleton_1((elms_15 = singleton_2((props_140 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                                    const errors_12 = Form_getFieldError(state_13.FormErrors, "PersonalNumber");
                                                    return append(singleton_1(createElement("input", createObj(toList(delay(() => append((!equals(errors_12, empty_2())) ? singleton_1(["style", {
                                                        borderColor: "#FF0000",
                                                        boxShadow: "none",
                                                    }]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                                                        let value_392;
                                                        return append(singleton_1((value_392 = state_13.Form.PersonalInfo.PersonalNumber, ["ref", (e_13) => {
                                                            if ((!(e_13 == null)) ? (!equals(e_13.value, value_392)) : false) {
                                                                e_13.value = value_392;
                                                            }
                                                        }])), delay(() => append(singleton_1(["onChange", (ev_12) => {
                                                            dispatch_3(new Msg(9, ev_12.target.value));
                                                        }]), delay(() => singleton_1(["placeholder", p("personal.number.placeholder")])))));
                                                    }))))))))))), delay(() => {
                                                        let props_136;
                                                        return append(singleton_1((props_136 = ofArray([["style", {
                                                            color: "#FF0000",
                                                        }], ["children", fold((s_16, x_12) => (`${s_16} ${x_12}`), "", errors_12)]]), createElement("p", createObj(Helpers_combineClasses("help", props_136))))), delay(() => singleton_1(createElement("span", {
                                                            className: join(" ", ["icon", "is-small", "is-left"]),
                                                            children: Interop_reactApi.Children.toArray([createElement("i", {
                                                                className: join(" ", ["fa", "fa-user"]),
                                                            })]),
                                                        }))));
                                                    }));
                                                }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_140))))), createElement("div", {
                                                    className: "field",
                                                    children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
                                                }))) : empty_1();
                                            }));
                                        }));
                                    }));
                                }));
                            })), createElement("div", {
                                className: "column",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
                            }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_144))))))), (dispatch_1 = dispatch_6, (state_2 = state_19, react.createElement(react.Fragment, {}, createElement("hr", {}), (elms_4 = ofArray([(props_7 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s = p("address.address"), createElement("h4", {
                                className: "subtitle is-4",
                                children: s,
                            })), createElement("div", {
                                className: "has-text-grey",
                                children: p("address.subtitle"),
                            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7)))), (props_43 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_2((props_14 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                const errors = Form_getFieldError(state_2.FormErrors, "Address");
                                return append(singleton_1(createElement("input", createObj(toList(delay(() => append((!equals(errors, empty_2())) ? singleton_1(["style", {
                                    borderColor: "#FF0000",
                                    boxShadow: "none",
                                }]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                                    let value_27;
                                    return append(singleton_1((value_27 = state_2.Form.Address.Address, ["ref", (e) => {
                                        if ((!(e == null)) ? (!equals(e.value, value_27)) : false) {
                                            e.value = value_27;
                                        }
                                    }])), delay(() => append(singleton_1(["onChange", (ev) => {
                                        dispatch_1(new Msg(4, ev.target.value));
                                    }]), delay(() => singleton_1(["placeholder", p("address.placeholder")])))));
                                }))))))))))), delay(() => {
                                    let props_10;
                                    return append(singleton_1((props_10 = ofArray([["style", {
                                        color: "#FF0000",
                                    }], ["children", fold((s_1, x) => (`${s_1} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_10))))), delay(() => singleton_1(createElement("span", {
                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                        children: Interop_reactApi.Children.toArray([createElement("i", {
                                            className: join(" ", ["fa", "fa-map-marker"]),
                                        })]),
                                    }))));
                                }));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_14))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms)),
                            })), (elms_1 = singleton_2((props_22 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                const errors_1 = Form_getFieldError(state_2.FormErrors, "ZipCode");
                                return append(singleton_1(createElement("input", createObj(toList(delay(() => append((!equals(errors_1, empty_2())) ? singleton_1(["style", {
                                    borderColor: "#FF0000",
                                    boxShadow: "none",
                                }]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                                    let value_52;
                                    return append(singleton_1((value_52 = state_2.Form.Address.ZipCode, ["ref", (e_1) => {
                                        if ((!(e_1 == null)) ? (!equals(e_1.value, value_52)) : false) {
                                            e_1.value = value_52;
                                        }
                                    }])), delay(() => append(singleton_1(["onChange", (ev_1) => {
                                        dispatch_1(new Msg(5, ev_1.target.value));
                                    }]), delay(() => singleton_1(["placeholder", p("postalCode.placeholder")])))));
                                }))))))))))), delay(() => {
                                    let props_18;
                                    return append(singleton_1((props_18 = ofArray([["style", {
                                        color: "#FF0000",
                                    }], ["children", fold((s_2, x_1) => (`${s_2} ${x_1}`), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_18))))), delay(() => singleton_1(createElement("span", {
                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                        children: Interop_reactApi.Children.toArray([createElement("i", {
                                            className: join(" ", ["fa", "fa-map-marker"]),
                                        })]),
                                    }))));
                                }));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_22))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                            })), (elms_2 = singleton_2((props_30 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                const errors_2 = Form_getFieldError(state_2.FormErrors, "City");
                                return append(singleton_1(createElement("input", createObj(toList(delay(() => append((!equals(errors_2, empty_2())) ? singleton_1(["style", {
                                    borderColor: "#FF0000",
                                    boxShadow: "none",
                                }]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["input", "is-medium"])]), delay(() => append(singleton_1(["type", "text"]), delay(() => {
                                    let value_77;
                                    return append(singleton_1((value_77 = state_2.Form.Address.City, ["ref", (e_2) => {
                                        if ((!(e_2 == null)) ? (!equals(e_2.value, value_77)) : false) {
                                            e_2.value = value_77;
                                        }
                                    }])), delay(() => append(singleton_1(["onChange", (ev_2) => {
                                        dispatch_1(new Msg(6, ev_2.target.value));
                                    }]), delay(() => singleton_1(["placeholder", p("city.placeholder")])))));
                                }))))))))))), delay(() => {
                                    let props_26;
                                    return append(singleton_1((props_26 = ofArray([["style", {
                                        color: "#FF0000",
                                    }], ["children", fold((s_3, x_2) => (`${s_3} ${x_2}`), "", errors_2)]]), createElement("p", createObj(Helpers_combineClasses("help", props_26))))), delay(() => singleton_1(createElement("span", {
                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                        children: Interop_reactApi.Children.toArray([createElement("i", {
                                            className: join(" ", ["fa", "fa-map-marker"]),
                                        })]),
                                    }))));
                                }));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_30))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                            })), (elms_3 = singleton_2((props_40 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                const errors_3 = Form_getFieldError(state_2.FormErrors, "Country");
                                return append(singleton_1(createElement("span", createObj(toList(delay(() => append((!equals(errors_3, empty_2())) ? singleton_1(["style", {
                                    borderColor: "#FF0000",
                                    boxShadow: "none",
                                }]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["select", "is-fullwidth", "is-medium"])]), delay(() => {
                                    let value_109;
                                    return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("select", createObj(ofArray([["className", "select-profile"], ["style", {
                                        height: 37.5 + "px",
                                    }], ["name", "country"], (value_109 = state_2.Form.Address.Country, ["ref", (e_3) => {
                                        if ((!(e_3 == null)) ? (!equals(e_3.value, value_109)) : false) {
                                            e_3.value = value_109;
                                        }
                                    }]), ["onChange", (ev_3) => {
                                        dispatch_1(new Msg(7, ev_3.target.value));
                                    }], ["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => createElement("option", {
                                        value: tupledArg[0],
                                        children: tupledArg[1],
                                    }), CountriesInput_getSortedList())))]])))])]);
                                }))))))))), delay(() => {
                                    let props_36;
                                    return append(singleton_1((props_36 = ofArray([["style", {
                                        color: "#FF0000",
                                    }], ["children", fold((s_4, x_3) => (`${s_4} ${x_3}`), "", errors_3)]]), createElement("p", createObj(Helpers_combineClasses("help", props_36))))), delay(() => singleton_1(createElement("span", {
                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                        children: Interop_reactApi.Children.toArray([createElement("i", {
                                            className: join(" ", ["fa", "fa-map-marker"]),
                                        })]),
                                    }))));
                                }));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_40))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                            }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_43))))]), createElement("div", {
                                className: "columns",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                            }))))), (dispatch_2 = dispatch_6, (state_7 = state_19, react.createElement(react.Fragment, {}, createElement("hr", {}), (props_97 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_50 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s_5 = p("bank.info.title"), createElement("h4", {
                                className: "subtitle is-4",
                                children: s_5,
                            })), createElement("div", {
                                className: "has-text-grey",
                                children: p("bank.info.subtitle"),
                            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_50)))), (elms_10 = ofArray([(elms_5 = singleton_2((props_58 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let props_52, value_151;
                                const errors_4 = Form_getFieldError(state_7.FormErrors, "ClearingNumber");
                                return append(singleton_1((props_52 = ofArray([["className", join(" ", ["is-medium"])], ["inputMode", "numeric"], (value_151 = state_7.Form.BankInfo.ClearingNumber, ["ref", (e_4) => {
                                    if ((!(e_4 == null)) ? (!equals(e_4.value, value_151)) : false) {
                                        e_4.value = value_151;
                                    }
                                }]), ["onChange", (ev_4) => {
                                    dispatch_2(new Msg(12, ev_4.target.value));
                                }], ["placeholder", p("clearing.number.placeholder")]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_52)))))), delay(() => {
                                    let props_54;
                                    return append(singleton_1((props_54 = ofArray([["style", {
                                        color: "#FF0000",
                                    }], ["children", fold((s_6, x_4) => (`${s_6} ${x_4}`), "", errors_4)]]), createElement("p", createObj(Helpers_combineClasses("help", props_54))))), delay(() => singleton_1(createElement("span", {
                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                        children: Interop_reactApi.Children.toArray([createElement("i", {
                                            className: join(" ", ["fas", "fa-university"]),
                                        })]),
                                    }))));
                                }));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_58))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                            })), (elms_6 = singleton_2((props_67 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let props_61, value_174;
                                const errors_5 = Form_getFieldError(state_7.FormErrors, "BankNumber");
                                return append(singleton_1((props_61 = ofArray([["className", join(" ", ["is-medium"])], ["inputMode", "numeric"], (value_174 = state_7.Form.BankInfo.BankNumber, ["ref", (e_5) => {
                                    if ((!(e_5 == null)) ? (!equals(e_5.value, value_174)) : false) {
                                        e_5.value = value_174;
                                    }
                                }]), ["onChange", (ev_5) => {
                                    dispatch_2(new Msg(13, ev_5.target.value));
                                }], ["placeholder", p("banknumber.placeholder")]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_61)))))), delay(() => {
                                    let props_63;
                                    return append(singleton_1((props_63 = ofArray([["style", {
                                        color: "#FF0000",
                                    }], ["children", fold((s_7, x_5) => (`${s_7} ${x_5}`), "", errors_5)]]), createElement("p", createObj(Helpers_combineClasses("help", props_63))))), delay(() => singleton_1(createElement("span", {
                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                        children: Interop_reactApi.Children.toArray([createElement("i", {
                                            className: join(" ", ["fas", "fa-university"]),
                                        })]),
                                    }))));
                                }));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_67))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                            })), (elms_7 = singleton_2((props_75 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let value_197;
                                const errors_6 = Form_getFieldError(state_7.FormErrors, "Bank");
                                return append(singleton_1(createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-medium"])], ["type", "text"], (value_197 = state_7.Form.BankInfo.Bank, ["ref", (e_6) => {
                                    if ((!(e_6 == null)) ? (!equals(e_6.value, value_197)) : false) {
                                        e_6.value = value_197;
                                    }
                                }]), ["onChange", (ev_6) => {
                                    dispatch_2(new Msg(14, ev_6.target.value));
                                }], ["placeholder", p("bank.placeholder")]])))), delay(() => {
                                    let props_71;
                                    return append(singleton_1((props_71 = ofArray([["style", {
                                        color: "#FF0000",
                                    }], ["children", fold((s_8, x_6) => (`${s_8} ${x_6}`), "", errors_6)]]), createElement("p", createObj(Helpers_combineClasses("help", props_71))))), delay(() => singleton_1(createElement("span", {
                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                        children: Interop_reactApi.Children.toArray([createElement("i", {
                                            className: join(" ", ["fas", "fa-university"]),
                                        })]),
                                    }))));
                                }));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_75))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                            })), (elms_8 = singleton_2((props_85 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                const errors_7 = Form_getFieldError(state_7.FormErrors, "IbanCountry");
                                return append(singleton_1(createElement("span", createObj(toList(delay(() => append((!equals(errors_7, empty_2())) ? singleton_1(["style", {
                                    borderColor: "#FF0000",
                                    boxShadow: "none",
                                }]) : empty_1(), delay(() => append(singleton_1(["className", join(" ", ["select", "is-fullwidth", "is-medium"])]), delay(() => {
                                    let value_229, list2;
                                    return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("select", createObj(ofArray([["className", "select-profile"], ["style", {
                                        height: 37.5 + "px",
                                    }], ["name", "ibanCountry"], (value_229 = state_7.Form.BankInfo.IbanCountry, ["ref", (e_7) => {
                                        if ((!(e_7 == null)) ? (!equals(e_7.value, value_229)) : false) {
                                            e_7.value = value_229;
                                        }
                                    }]), ["onChange", (ev_7) => {
                                        dispatch_2(new Msg(15, ev_7.target.value));
                                    }], ["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg_1) => createElement("option", {
                                        value: tupledArg_1[0],
                                        children: tupledArg_1[1],
                                    }), (list2 = CountriesInput_getSortedList(), append_1(singleton_2([CountryCodeModule_empty, p("selectCountry")]), list2)))))]])))])]);
                                }))))))))), delay(() => {
                                    let props_81;
                                    return append(singleton_1((props_81 = ofArray([["style", {
                                        color: "#FF0000",
                                    }], ["children", fold((s_9, x_7) => (`${s_9} ${x_7}`), "", errors_7)]]), createElement("p", createObj(Helpers_combineClasses("help", props_81))))), delay(() => singleton_1(createElement("span", {
                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                        children: Interop_reactApi.Children.toArray([createElement("i", {
                                            className: join(" ", ["fas", "fa-university"]),
                                        })]),
                                    }))));
                                }));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_85))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                            })), (elms_9 = singleton_2((props_93 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let props_88, value_255;
                                const errors_8 = Form_getFieldError(state_7.FormErrors, "IBAN");
                                return append(singleton_1((props_88 = ofArray([["className", join(" ", ["is-medium"])], ["inputMode", "numeric"], (value_255 = state_7.Form.BankInfo.Iban, ["ref", (e_8) => {
                                    if ((!(e_8 == null)) ? (!equals(e_8.value, value_255)) : false) {
                                        e_8.value = value_255;
                                    }
                                }]), ["onChange", (ev_8) => {
                                    dispatch_2(new Msg(16, ev_8.target.value));
                                }], ["placeholder", p("IBAN.placeholder")]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_88)))))), delay(() => {
                                    let props_90;
                                    return append(singleton_1((props_90 = ofArray([["style", {
                                        color: "#FF0000",
                                    }], ["children", fold((s_10, x_8) => (`${s_10} ${x_8}`), "", errors_8)]]), createElement("p", createObj(Helpers_combineClasses("help", props_90))))), delay(() => singleton_1(createElement("span", {
                                        className: join(" ", ["icon", "is-small", "is-left"]),
                                        children: CountryCodeToString(state_7.Form.BankInfo.IbanCountry),
                                    }))));
                                }));
                            }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_93))))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                            }))]), createElement("div", {
                                className: "column",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                            }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_97))))))), react.createElement(react.Fragment, {}, createElement("hr", {}), (props_181 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_178 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s_18 = p("change.password.title"), createElement("h4", {
                                className: "subtitle is-4",
                                children: s_18,
                            })), createElement("div", {
                                className: "has-text-grey",
                                children: p("change.password.subtitle"),
                            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_178)))), (elms_23 = singleton_2(createElement(ChangePasswordComponent, {
                                userId: state_19.Form.UserId,
                            })), createElement("div", {
                                className: "column",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
                            }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_181))))), (dispatch_4 = dispatch_6, (state_18 = state_19, react.createElement(react.Fragment, {}, createElement("hr", {}), (props_161 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_150 = ofArray([["className", "is-two-fifths"], ["children", Interop_reactApi.Children.toArray([(s_17 = p("notification.title"), createElement("h4", {
                                className: "subtitle is-4",
                                children: s_17,
                            })), createElement("div", {
                                className: "has-text-grey",
                                children: p("notification.subtitle"),
                            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_150)))), (elms_19 = ofArray([(elms_17 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "overbid"], ["className", "is-success"], ["checked", state_18.Form.SendEmailOnOverbid], ["onChange", (ev_13) => {
                                dispatch_4(new Msg(11, ev_13.target.checked));
                            }]]))))), createElement("label", {
                                htmlFor: "overbid",
                                children: p("outbided"),
                            })]), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
                            })), (elms_18 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "newsletter"], ["className", "is-success"], ["checked", state_18.Form.SendNewsletters], ["onChange", (ev_14) => {
                                dispatch_4(new Msg(10, ev_14.target.checked));
                            }]]))))), createElement("label", {
                                htmlFor: "newsletter",
                                children: p("newsletters"),
                            })]), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
                            }))]), createElement("div", {
                                className: "column",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
                            }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_161))))))), react.createElement(react.Fragment, {}, createElement("hr", {}), (elms_20 = singleton_2(createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray([saveError]),
                            })), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
                            })), (elms_21 = singleton_2(createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray([saveProfile_1]),
                            })), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
                            })), (elms_22 = singleton_2((props_171 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_169 = toList(delay(() => append(isSaving ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["onClick", (e_14) => {
                                e_14.preventDefault();
                                dispatch_6(new Msg(17, new AsyncOperationStatus$1(0)));
                            }]), delay(() => singleton_1(["children", p("button.saveChanges")]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_169))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_171))))), createElement("div", {
                                className: "block",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
                            })))]),
                        })]),
                    })]),
                })]),
            });
        }
        case 2: {
            const props_196 = ofArray([["style", {
                whiteSpace: "pre-line",
                marginTop: 10,
            }], ["className", "is-danger"], ["children", error_1]]);
            return createElement("div", createObj(Helpers_combineClasses("notification", props_196)));
        }
    }
}

