import { Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, bool_type, string_type, option_type, lambda_type, unit_type, int32_type, union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { parse } from "../fable_modules/fable-library.3.6.1/Int32.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export class BiddingButtonState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Disabled", "Blocked", "Active"];
    }
}

export function BiddingButtonState$reflection() {
    return union_type("Components.Buttons.BiddingButtonState", [], BiddingButtonState, () => [[], [], []]);
}

export class BiddingButtonProps extends Record {
    constructor(OnChange, Value, OnClick, ButtonText, State, Confirm, Key, IsLoading) {
        super();
        this.OnChange = OnChange;
        this.Value = Value;
        this.OnClick = OnClick;
        this.ButtonText = ButtonText;
        this.State = State;
        this.Confirm = Confirm;
        this.Key = Key;
        this.IsLoading = IsLoading;
    }
}

export function BiddingButtonProps$reflection() {
    return record_type("Components.Buttons.BiddingButtonProps", [], BiddingButtonProps, () => [["OnChange", lambda_type(int32_type, unit_type)], ["Value", option_type(int32_type)], ["OnClick", lambda_type(unit_type, unit_type)], ["ButtonText", string_type], ["State", BiddingButtonState$reflection()], ["Confirm", bool_type], ["Key", string_type], ["IsLoading", bool_type]]);
}

export function RenderBiddingButton(props) {
    let props_8, props_6, props_2;
    const props_10 = ofArray([["key", props.Key], ["children", Interop_reactApi.Children.toArray([(props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", join(" ", ["has-icons-left"])], ["style", {
        display: "flex",
    }], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton(["readOnly", true]), delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("input"), delay(() => append(singleton("card-lot-bid"), delay(() => {
        if (props.Value != null) {
            const matchValue_1 = props.State;
            switch (matchValue_1.tag) {
                case 1:
                case 2: {
                    return props.Confirm ? singleton("inputInCatalogActiveConfirmedLive") : singleton("inputInCatalogActiveNonConfirmedLive");
                }
                default: {
                    return singleton("inputInCatalogDisableLive");
                }
            }
        }
        else {
            return singleton("inputInCatalogDisableLive");
        }
    })))))))]), delay(() => append(singleton(["type", "text"]), delay(() => append(singleton(["onChange", (ev) => {
        props.OnChange(parse(ev.target.value, 511, false, 32));
    }]), delay(() => {
        let value_12;
        const matchValue_3 = props.Value;
        if (matchValue_3 == null) {
            return empty();
        }
        else {
            return singleton((value_12 = (matchValue_3 | 0), ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_12)) : false) {
                    e.value = (value_12 | 0);
                }
            }]));
        }
    })))))))))))), (props_2 = ofArray([["className", join(" ", ["fas", "fa-lock", "fa-2x", "is-left"])], ["style", {
        color: "#FFFFFF",
        height: 2 + "em",
    }]]), createElement("span", createObj(Helpers_combineClasses("icon", props_2)))), createElement("button", createObj(toList(delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("search-button"), delay(() => append(singleton("home-box"), delay(() => append(singleton("button-in-lot-details"), delay(() => {
        if (props.Value != null) {
            const matchValue_5 = props.State;
            switch (matchValue_5.tag) {
                case 1:
                case 2: {
                    return singleton("buttonInLotDetailsActive");
                }
                default: {
                    return singleton("buttonInLotDetailsDisable");
                }
            }
        }
        else {
            return singleton("buttonInLotDetailsDisable");
        }
    })))))))))]), delay(() => append(props.IsLoading ? append(singleton(["disabled", true]), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: "has-text-white",
        className: join(" ", ["fa", "fa-circle-notch", "fa-pulse"]),
    })])]))) : singleton(["children", props.ButtonText]), delay(() => singleton(["onClick", (_arg1) => {
        props.OnClick();
    }])))))))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_6))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_8))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("control", props_10)));
}

