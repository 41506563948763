import { Union, Record } from "../fable-library.3.6.1/Types.js";
import { union_type, bool_type, array_type, option_type, record_type, class_type, string_type } from "../fable-library.3.6.1/Reflection.js";

export class InvokeArg$1 extends Record {
    constructor(connectionId, invocationId, message) {
        super();
        this.connectionId = connectionId;
        this.invocationId = invocationId;
        this.message = message;
    }
}

export function InvokeArg$1$reflection(gen0) {
    return record_type("Fable.SignalR.Shared.InvokeArg`1", [gen0], InvokeArg$1, () => [["connectionId", string_type], ["invocationId", class_type("System.Guid")], ["message", gen0]]);
}

export class MsgPack_Msg$4 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Invocation", "InvokeInvocation", "InvocationExplicit", "StreamItem", "Completion", "StreamInvocation", "CancelInvocation", "Ping", "Close"];
    }
}

export function MsgPack_Msg$4$reflection(gen0, gen1, gen2, gen3) {
    return union_type("Fable.SignalR.Shared.MsgPack.Msg`4", [gen0, gen1, gen2, gen3], MsgPack_Msg$4, () => [[["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", option_type(string_type)], ["target", string_type], ["args", array_type(gen1)], ["streamIds", option_type(array_type(string_type))]], [["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", option_type(string_type)], ["target", string_type], ["msg", gen1], ["invokeId", class_type("System.Guid")], ["streamIds", option_type(array_type(string_type))]], [["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", option_type(string_type)], ["target", string_type], ["args", array_type(InvokeArg$1$reflection(gen1))], ["streamIds", option_type(array_type(string_type))]], [["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", option_type(string_type)], ["item", gen3]], [["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", string_type], ["errMsg", option_type(string_type)], ["result", option_type(gen2)]], [["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", string_type], ["target", string_type], ["args", array_type(gen0)], ["streamIds", option_type(array_type(string_type))]], [["headers", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["invocationId", option_type(string_type)]], [], [["errMsg", option_type(string_type)], ["allowReconnect", option_type(bool_type)]]]);
}

