import { Image } from "../Shared/Shared.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { ImageUrlModule_toString } from "../bidflow/src/infrastructure/Auction.js";
import { Img_lotExtraLargeImagePath, Img_lotMinimumImagePath } from "../Css.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export function RenderImage(image) {
    let elms_1, elms, props_2, props_14;
    const image_1 = new Image(image.Id, image.Version);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setFullScreen = patternInput[1];
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([(elms_1 = singleton((elms = singleton((props_2 = ofArray([["style", {
            cursor: "pointer",
        }], ["children", Interop_reactApi.Children.toArray([createElement("figure", {
            className: "is-16by9",
            className: join(" ", ["image"]),
            children: Interop_reactApi.Children.toArray([createElement("img", {
                key: `${image_1.Id}`,
                src: ImageUrlModule_toString(Img_lotMinimumImagePath(image_1)),
                alt: "Placeholder image",
            })]),
        })])], ["onClick", (_arg1) => {
            setFullScreen(true);
        }]]), createElement("div", createObj(Helpers_combineClasses("card-image", props_2))))), createElement("div", {
            className: "card",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (props_14 = toList(delay(() => append(patternInput[0] ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
            let elms_3, elms_2;
            return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg2) => {
                setFullScreen(false);
            }])))), (elms_3 = singleton((elms_2 = singleton(createElement("figure", {
                className: "is-16by9",
                className: join(" ", ["image"]),
                children: Interop_reactApi.Children.toArray([createElement("img", {
                    src: ImageUrlModule_toString(Img_lotExtraLargeImagePath(image_1)),
                    alt: "Placeholder image",
                })]),
            })), createElement("div", {
                className: "card-image",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))), createElement("div", {
                className: "modal-content",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            })), createElement("button", createObj(Helpers_combineClasses("modal-close", ofArray([["aria-label", "close"], ["onClick", (_arg3) => {
                setFullScreen(false);
            }]]))))])]);
        })))), createElement("div", createObj(Helpers_combineClasses("modal", props_14))))]),
    });
}

