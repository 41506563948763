import { LocalizedStringModule_getTranslationOrEmpty, LocalizedStringModule_findFirstNotEmptyTranslation, LangModule_$007CSwedish$007CEnglish$007CFinnish$007C, LocalizationContext, LangModule_toString, LangModule_fromString, LangModule_swedish } from "./bidflow/src/infrastructure/Localization.js";
import { ofArray, append, contains, singleton } from "./fable_modules/fable-library.3.6.1/List.js";
import { HomeInfo_LocalizationSettings } from "./Shared/Shared.js";
import { stringHash, createAtom } from "./fable_modules/fable-library.3.6.1/Util.js";
import { defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import i18next from "i18next";
import * as index from "../../locales/index.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { isNullOrEmpty } from "./fable_modules/fable-library.3.6.1/String.js";
import { fi, sv } from "date-fns/locale";
import { format as format_3 } from "date-fns";

const localStorageKey = "lng";

const defaultSettings = new HomeInfo_LocalizationSettings(singleton(LangModule_swedish), LangModule_swedish);

export let Settings_currentSettings = createAtom(defaultSettings);

function tryGetCurrentLanguageFromLocalStorage() {
    try {
        return LangModule_fromString(localStorage.getItem(localStorageKey));
    }
    catch (matchValue) {
        return void 0;
    }
}

function setCurrentLanguageToLocalStorage(lang) {
    localStorage.setItem(localStorageKey, LangModule_toString(lang));
}

export function getSupportedLanguages() {
    return Settings_currentSettings().SupportedLanguages;
}

export function getPrimaryLanguage() {
    return Settings_currentSettings().PrimaryLanguage;
}

export function getCurrentLanguage() {
    return defaultArg(tryGetCurrentLanguageFromLocalStorage(), Settings_currentSettings().PrimaryLanguage);
}

export function setCurrentLanguage(lang) {
    if (contains(lang, Settings_currentSettings().SupportedLanguages, {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })) {
        setCurrentLanguageToLocalStorage(lang);
    }
    else {
        throw (new Error(`The given language is unknown: ${lang}`));
    }
}

export function getLocalizationContext() {
    return new LocalizationContext(Settings_currentSettings().SupportedLanguages, Settings_currentSettings().PrimaryLanguage, getCurrentLanguage());
}

export const i18n = i18next;

export const resources = index;

export const initParams = {
    lng: LangModule_toString(getCurrentLanguage()),
    resources: resources,
};

export function initLocalization(initParams_1) {
    const init = startImmediate(singleton_1.Delay(() => {
        let matchValue, isCurrLangNotSupported;
        if ((matchValue = tryGetCurrentLanguageFromLocalStorage(), (matchValue == null) ? ((setCurrentLanguageToLocalStorage(Settings_currentSettings().PrimaryLanguage), Settings_currentSettings().PrimaryLanguage !== defaultSettings.PrimaryLanguage)) : ((isCurrLangNotSupported = (!contains(matchValue, Settings_currentSettings().SupportedLanguages, {
            Equals: (x, y) => (x === y),
            GetHashCode: (x) => stringHash(x),
        })), (isCurrLangNotSupported ? setCurrentLanguageToLocalStorage(Settings_currentSettings().PrimaryLanguage) : (void 0), isCurrLangNotSupported))))) {
            document.location.reload();
            return singleton_1.Zero();
        }
        else {
            return singleton_1.Zero();
        }
    }));
    return i18n.init(initParams_1);
}

export function t(key) {
    return i18n.t("localization:" + key);
}

export function tWithArgs(key, args) {
    return i18n.t(("localization:" + key), args);
}

export function Localized_chooseTranslation(inEnglish, inSweden) {
    const choose = (primary, secondary) => {
        if (isNullOrEmpty(primary)) {
            return secondary;
        }
        else {
            return primary;
        }
    };
    const matchValue = getCurrentLanguage();
    if (LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(matchValue).tag === 0) {
        return choose(inSweden, inEnglish);
    }
    else {
        return choose(inEnglish, inSweden);
    }
}

export function Localized_formatDate(formatSwedish, formatDateFinnish, date) {
    const lng = getCurrentLanguage();
    const activePatternResult22708 = LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(lng);
    if (activePatternResult22708.tag === 0) {
        const locale = sv;
        const formatOptions = {};
        formatOptions.locale = locale;
        return format_3(date, formatSwedish, formatOptions);
    }
    else if (activePatternResult22708.tag === 2) {
        const locale_1 = fi;
        const formatOptions_1 = {};
        formatOptions_1.locale = locale_1;
        return format_3(date, formatDateFinnish, formatOptions_1);
    }
    else {
        return format_3(date, formatSwedish);
    }
}

export function Localized_translate(ls) {
    const ctx = getLocalizationContext();
    return defaultArg(LocalizedStringModule_findFirstNotEmptyTranslation(append(ofArray([ctx.CurrentLanguage, ctx.PrimaryLanguage]), ctx.SupportedLanguages), ls), "");
}

export function Localized_translateTo(lang, ls) {
    return LocalizedStringModule_getTranslationOrEmpty(lang, ls);
}

