import { tWithArgs, Localized_formatDate, Localized_translate, t } from "../Localization.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { SearchCriteria, Resource$1 } from "../Shared/Shared.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { homeApi, lotsApi } from "../Communication.js";
import { createElement } from "react";
import * as react from "react";
import { empty, map, singleton as singleton_1, append, collect, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { ofArray, singleton as singleton_2, isEmpty } from "../fable_modules/fable-library.3.6.1/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { rgba } from "../fable_modules/Feliz.1.45.0/Colors.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { navigateTo, Route, AuctionState, toPath } from "../Router.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { addSeconds, toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Async_map, Remoting_LiveBidding_subscribeCurrentLotBiddingEvent } from "../Extensions.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Img_actionBackground } from "../Css.js";
import { viewTimer } from "../Home.js";
import { scrollableAnchor } from "../Components/ScrollableAnchor.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { large } from "../Components/Loader.js";

export function p(key) {
    return t("futureAuction." + key);
}

export function c(key) {
    return t("categories." + key);
}

export function ViewCategory(lastAuction) {
    let categoryList_2, children;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Resource$1(0));
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    let offset = 0;
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(lotsApi().getTimerSettings(), (_arg2) => {
            patternInput_1[1](~(~(_arg2 * 1.25)));
            return singleton.Return();
        })));
    });
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(lotsApi().getLotsOfFutureAuction(lastAuction.Id), (_arg1) => {
            patternInput[1](new Resource$1(1, _arg1));
            return singleton.Return();
        })));
    });
    return react.createElement(react.Fragment, {}, (categoryList_2 = patternInput[0], (categoryList_2.tag === 1) ? ((children = toList(delay(() => collect((priority_1) => {
        let priority, name;
        return (!isEmpty(priority_1.CategoryLots)) ? append(singleton_1((priority = (priority_1.Priority | 0), (name = ((priority === 0) ? p("low.priority.catalog.title") : ((priority === 1) ? p("main.priority.catalog.title") : ((priority === 2) ? p("high.priority.catalog.title") : (`Unexpected priority value: ${priority}`)))), createElement("div", {
            style: {
                display: "contents",
                color: "#FFFFFF",
            },
            children: Interop_reactApi.Children.toArray([createElement("tr", {
                style: {
                    borderBottom: (((1 + "px ") + "dashed") + " ") + rgba(255, 255, 255, 0.12),
                    fontWeight: "bold",
                },
                className: join(" ", ["previousAuctions", "is-size-6-mobile", "is-size-5", "has-text-left"]),
                children: Interop_reactApi.Children.toArray([createElement("td", {
                    children: name,
                })]),
            })]),
        })))), delay(() => map((category) => createElement("a", {
            style: {
                display: "contents",
                color: "#FFFFFF",
            },
            href: toPath(new Route(3, new AuctionState(lastAuction.Id, 1, new SearchCriteria(3, category.CategoryId)))),
            children: Interop_reactApi.Children.toArray([createElement("tr", {
                style: {
                    borderBottom: (((1 + "px ") + "dashed") + " ") + rgba(255, 255, 255, 0.12),
                },
                className: join(" ", ["previousAuctions", "is-size-6-mobile", "is-size-5"]),
                children: Interop_reactApi.Children.toArray([createElement("td", {
                    className: "has-text-left",
                    children: Localized_translate(category.CategoryName),
                }), createElement("td", createObj(toList(delay(() => {
                    let copyOfStruct;
                    const time = (offset === 0) ? Localized_formatDate("HH:mm", "HH.mm", toLocalTime(lastAuction.Date)) : Localized_formatDate("HH:mm", "HH.mm", (copyOfStruct = addSeconds(lastAuction.Date, patternInput_1[0] * offset), toLocalTime(copyOfStruct)));
                    offset = ((offset + category.NumberOfLots) | 0);
                    return append(singleton_1(["className", "has-text-right"]), delay(() => singleton_1(["children", time])));
                }))))]),
            })]),
        }), priority_1.CategoryLots))) : empty();
    }, categoryList_2.fields[0]))), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))) : createElement("div", {
        style: {
            textTransform: "uppercase",
            fontSize: 15 + "px",
            fontWeight: 300,
        },
        children: p("loading"),
    })));
}

export class State_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LiveAuctionBiddingOn"];
    }
}

export function State_Msg$reflection() {
    return union_type("FutureAuction.State.Msg", [], State_Msg, () => [[["Item", class_type("System.Int64")]]]);
}

export function State_init() {
    return [false, Cmd_batch(singleton_2(Remoting_LiveBidding_subscribeCurrentLotBiddingEvent((arg0) => (new State_Msg(0, arg0)))))];
}

export function State_update(msg, state) {
    return [state, navigateTo(new Route(15))];
}

export function RenderPage(renderPageInputProps) {
    let elms, props_8;
    const auctionId = renderPageInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Resource$1(0));
    const lastAuction = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Resource$1(0));
    const settings = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useElmish_Static_645B1FB7(State_init, (msg, state) => State_update(msg, state), []);
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(Async_map((x) => defaultArg(map_1((arg0_1) => (new FSharpResult$2(0, arg0_1)), x), new FSharpResult$2(1, "Found no published auction.")), defaultArg(map_1(lotsApi().auctionById, auctionId), Async_map((arg0) => arg0, homeApi().getNextOrLastFinishedAuction()))), (_arg1) => {
            patternInput[1](new Resource$1(1, _arg1));
            return singleton.Bind(homeApi().getTimerSettings(), (_arg2) => {
                patternInput_1[1](new Resource$1(1, _arg2));
                return singleton.Return();
            });
        })));
    });
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", ["home-box", "future-box"]),
            style: {
                backgroundImage: ("url(\u0027" + Img_actionBackground) + "\u0027)",
            },
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: p("title"),
            }), createElement("p", {
                className: join(" ", ["future-auction-timer-font-size"]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((lastAuction.tag === 1) ? ((lastAuction.fields[0].tag === 1) ? singleton_1(null) : singleton_1(viewTimer(lastAuction.fields[0].fields[0].Status, lastAuction.fields[0].fields[0].Date, true))) : singleton_1(null)))))),
            }), Interop_reactApi.createElement(scrollableAnchor, {
                href: "#steps",
                className: "future-auction-btn-margin",
                children: Interop_reactApi.Children.toArray([createElement("a", {
                    className: join(" ", ["white-black", "is-centered"]),
                    children: p("button"),
                })]),
            }), createElement("div", {
                className: join(" ", ["tableCategories"]),
                children: Interop_reactApi.Children.toArray([(elms = singleton_2((props_8 = ofArray([["style", {
                    backgroundColor: "transparent",
                }], ["className", join(" ", ["is-fullwidth"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let children;
                    return append(singleton_1(createElement("thead", {
                        style: {
                            color: "#FFFFFF",
                            borderBottom: (((1 + "px ") + "dashed") + " ") + rgba(255, 255, 255, 0.12),
                        },
                        children: Interop_reactApi.Children.toArray([(children = ofArray([createElement("th", {
                            className: "has-text-left",
                            style: {
                                color: "#FFFFFF",
                            },
                            children: c("categories"),
                        }), createElement("th", {
                            className: "has-text-right",
                            style: {
                                color: "#FFFFFF",
                            },
                            children: c("startTime"),
                        })]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))]),
                    })), delay(() => ((lastAuction.tag === 1) ? ((lastAuction.fields[0].tag === 1) ? singleton_1(null) : singleton_1(createElement(ViewCategory, lastAuction.fields[0].fields[0]))) : singleton_1(null))));
                }))))]]), createElement("table", createObj(Helpers_combineClasses("table", props_8))))), createElement("div", {
                    className: "table-container",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))]),
            })]),
        }), createElement("div", {
            id: "steps",
            className: join(" ", ["home-box", "is-size-5-mobile", "is-size-4", "backgroundFutureAuction"]),
            style: {
                padding: ((60 + "px ") + 0) + "px",
                color: "#FFFFFF",
                textAlign: "center",
                whiteSpace: "pre-line",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                if (settings.tag === 1) {
                    const timer = settings.fields[0];
                    return append(singleton_1(createElement("p", {
                        children: p("text1"),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-arrow-down", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        children: p("text2"),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-arrow-down", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        children: tWithArgs("futureAuction.text3", {
                            timer: timer[0],
                        }),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-arrow-down", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        children: tWithArgs("futureAuction.text4", {
                            timer: timer[1],
                        }),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-arrow-down", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        children: p("text5"),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-arrow-down", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        children: p("text6"),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-arrow-down", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        children: p("text7"),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-arrow-down", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        children: p("text8"),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-arrow-down", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        children: p("text9"),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-arrow-down", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    })), delay(() => append(singleton_1(createElement("p", {
                        children: p("text10"),
                        style: {
                            padding: 30,
                        },
                    })), delay(() => singleton_1(createElement("p", {
                        className: join(" ", ["icon", "sell-list", "fas", "fa-thumbs-up", "is-medium"]),
                        style: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "center",
                        },
                    }))))))))))))))))))))))))))))))))))))))));
                }
                else {
                    return singleton_1(large);
                }
            })))),
        })]),
    });
}

