import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, record_type, int32_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { now, op_Subtraction } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { totalSeconds } from "../fable_modules/fable-library.3.6.1/TimeSpan.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { sleep } from "../fable_modules/fable-library.3.6.1/Async.js";
import { compare } from "../fable_modules/fable-library.3.6.1/Date.js";
import { Cmd_fromAsync } from "../Extensions.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export class State extends Record {
    constructor(LastTick, TickUntil, Delay) {
        super();
        this.LastTick = LastTick;
        this.TickUntil = TickUntil;
        this.Delay = (Delay | 0);
    }
}

export function State$reflection() {
    return record_type("Components.Timer.State", [], State, () => [["LastTick", class_type("System.DateTimeOffset")], ["TickUntil", class_type("System.DateTimeOffset")], ["Delay", int32_type]]);
}

export function State__Elapsed(self) {
    return op_Subtraction(self.TickUntil, self.LastTick);
}

export function State__ElapsedSeconds(self) {
    let copyOfStruct;
    return ~(~Math.ceil((copyOfStruct = State__Elapsed(self), totalSeconds(copyOfStruct))));
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Tick"];
    }
}

export function Msg$reflection() {
    return union_type("Components.Timer.Msg", [], Msg, () => [[]]);
}

export function delayTick(delay) {
    return singleton.Delay(() => singleton.Bind(sleep(delay), () => singleton.Return(new Msg(0))));
}

export function nextCmd(state) {
    if (compare(state.LastTick, state.TickUntil) < 0) {
        return Cmd_fromAsync(delayTick(state.Delay));
    }
    else {
        return Cmd_none();
    }
}

export function init(date, delay) {
    const state = new State(now(), date, delay);
    return [state, nextCmd(state)];
}

export function update(msg, state) {
    const nextState = new State(now(), state.TickUntil, state.Delay);
    return [nextState, nextCmd(nextState)];
}

